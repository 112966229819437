import axiosInstance from "../../../utils/axiosInstance";
import CarbonFootprint from "../../../domain/Logistic/Distribution/CarbonFootprint";

export const getAllCarbonFootprintData = async (filters) => {
    try {
        const {data} = await axiosInstance.post(
            '/DisaFootPrint/sel',
            {
                startDate: filters.startDate,
                endDate: filters.endDate,
                kpI_Value: filters.calcConsumption,
                theorical_Value: filters.theoreticalConsumption,
                emission_Factor: filters.emissionFactor,
            }
        );
        return data.map(e => CarbonFootprint.map(e));
    }catch (e) {
        return Promise.reject(e);
    }
}