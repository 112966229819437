import React from 'react';
import ImportFileDialog from "../../../../Common/Dialogs/ImportFileDialog";
import ContainedButton from "../../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {useDispatch, useSelector} from "react-redux";
import {closeDialog, openDialog} from "../../../../../redux/reducers/FleetManagement/filesReader";
import {dialogsList} from "../constants";
import {getDataFromFile} from "../../../../../redux/thunks/FleetManagement/Tachograph/filesReader";

const Header = () => {
    const id = 'file-reader';
    const {dialogs} = useSelector(({fleetManagement}) => fleetManagement.filesReader);
    const dispatch = useDispatch();
    const handleOpenFileReader = () => {
        dispatch(openDialog(dialogsList.reader));
    }
    const handleCloseFileReader = () => {
        dispatch(closeDialog(dialogsList.reader));
    }
    const handleOnReadFile = (file) => {
        dispatch(getDataFromFile(file));
        handleCloseFileReader();
    }
    return (
        <React.Fragment>
            <ContainedButton
                text={<IntlMessages id='read.file' />}
                color='primary'
                onClick={handleOpenFileReader}
            />
            <ImportFileDialog
                id={id}
                title={<IntlMessages id='read.file' />}
                setOpen={handleCloseFileReader}
                open={dialogs.some(dialog => dialog === dialogsList.reader)}
                onImport={handleOnReadFile}
                accept='.DDD,.TGD'
            />
        </React.Fragment>
    );
};

export default Header;