import React from "react";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import {setCurrentProviderHeadquarter, openDialog} from "../../../../../../redux/reducers/Logistic/providers";
import {useDispatch} from "react-redux";
import {dialogsList} from "../../constants";

const TableRow = ({headquarter}) => {
    const dispatch = useDispatch();
    const onEdit = () => {
        dispatch(setCurrentProviderHeadquarter(headquarter));
        dispatch(openDialog(dialogsList.editHeadquarterDialog));
    };
    const onDelete = () => {

    }
    return (
        <StyledTableRow>
            <StyledTableCell>{headquarter.name}</StyledTableCell>
            <StyledTableCell>{headquarter.isFiscal ? "Si" : "No"}</StyledTableCell>
            <StyledTableCell>{headquarter.streetName}</StyledTableCell>
            <StyledTableCell>{headquarter.number}</StyledTableCell>
            <StyledTableCell>{headquarter.zipCode}</StyledTableCell>
            <StyledTableCell>{headquarter.district}</StyledTableCell>
            <StyledTableCell>{headquarter.province}</StyledTableCell>
            <StyledTableCell>{headquarter.country}</StyledTableCell>
            <StyledTableCell>{headquarter.latitude}</StyledTableCell>
            <StyledTableCell>{headquarter.longitude}</StyledTableCell>
            <StyledTableCell>
                <IconButton onClick={onEdit}>
                    <EditIcon />
                </IconButton>
                <IconButton onClick={onDelete}>
                    <DeleteIcon />
                </IconButton>
            </StyledTableCell>
        </StyledTableRow>
    );
};

export default TableRow;
