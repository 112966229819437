import MaintenancePlan from "./MaintenancePlan";

class VehicleMaintenancePlan {
  constructor(id, vehicle, plans, state) {
    this.id = id;
    this.vehicle = vehicle;
    this.plans = plans;
    this.state = state;
    this.plansString = plans.map(p => p.name).join(", ");
  }

  static map = plan => {
    return new VehicleMaintenancePlan(
      plan.nu_id_vehiculo,
      plan.vc_placa,
      plan.ls_planes.map(p => ({
        id: p.nu_id_plan_mantenimiento,
        name: p.vc_desc_plan_mantenimiento
      })),
      true
    );
  };
}

export default VehicleMaintenancePlan;
