import React from "react";
import PageContainer from "../../../../../components/FleetManagement/DailyOperation/VehicleMaintenancePlans/PageContainer";
import Table from "../../../../../components/FleetManagement/DailyOperation/VehicleMaintenancePlans/Table";
import EditDialog from "../../../../../components/FleetManagement/DailyOperation/VehicleMaintenancePlans/EditDialog";

const VehicleMaintenancePlans = () => {
  // const dispatch = useDispatch();
  // const {current, dialog: {name, open}} = useSelector(({fleetManagement}) => fleetManagement.vehicleMaintenancePlans);
  // const onReject = () => {
  //     dispatch(closeVehicleMaintenancePlansDialog());
  //     dispatch(setCurrentVehicleMaintenancePlan(null));
  // }
  // const onDelete = () => {
  //     dispatch(closeVehicleMaintenancePlansDialog());
  //     dispatch(updateVehicleMaintenancePlanState(current, false));
  // }
  // const onRestore = () => {
  //     dispatch(closeVehicleMaintenancePlansDialog());
  //     dispatch(updateVehicleMaintenancePlanState(current, true));
  // }
  return (
    <PageContainer>
      <Table />
      {/*<Confirmation*/}
      {/*    open={(open && dialogs.DELETE_DIALOG === name)}*/}
      {/*    text={<IntlMessages id={"fleet.management.maintenance.plan.delete.text"}/>}*/}
      {/*    onReject={onReject}*/}
      {/*    onAccept={onDelete}*/}
      {/*/>*/}
      {/*<Confirmation*/}
      {/*    open={(open && dialogs.RESTORE_DIALOG === name)}*/}
      {/*    text={<IntlMessages id={"fleet.management.maintenance.plan.restore.text"}/>}*/}
      {/*    onReject={onReject}*/}
      {/*    onAccept={onRestore}*/}
      {/*/>*/}
      <EditDialog />
    </PageContainer>
  );
};

VehicleMaintenancePlans.propTypes = {};

export default VehicleMaintenancePlans;
