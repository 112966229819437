import {createSlice} from "@reduxjs/toolkit";

const INIT_STATE = {
    data: {}
}

const generalSlice = createSlice({
    name: 'general',
    initialState: INIT_STATE,
    reducers: {
        updateData: (state, action) => {
            const {data, name} = action.payload;
            state.data[name] = data;
        },
    },
    extraReducers: (builder) => {
        builder.addCase('@@router/LOCATION_CHANGE', state => {
            state.data = INIT_STATE.data;
        });
    }
});

export default generalSlice.reducer;

export const {
    updateData,
} = generalSlice.actions;