import React from 'react';
import PieChart from "./PieChart";
import BarChart from "./BarChart";

export const charts = {
    pieChart: {
        chart: PieChart,
    },
    barChart: {
        chart: BarChart,
    }
}

export const COLORS = ['#64dd17', '#ff6d00', '#ffd776', '#ff1744'];