import React from "react";
import PageContainer from "../../../../../components/FleetManagement/DailyOperation/VehicleCorrectives/PageContainer";
import Table from "../../../../../components/FleetManagement/DailyOperation/VehicleCorrectives/Table";
import FloatingButtonWithAdd from "../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import {dialogs} from "../../../../../components/FleetManagement/DailyOperation/CorrectiveTypes/constants";
import {useDispatch} from "react-redux";
import {openVehicleCorrectivesDialog} from "../../../../../redux/actions/FleetManagement/VehicleCorrectives";
import EditDialog from "../../../../../components/FleetManagement/DailyOperation/VehicleCorrectives/TransactionsDialogs/EditDialog";
import AddDialog from "../../../../../components/FleetManagement/DailyOperation/VehicleCorrectives/TransactionsDialogs/AddDialog";

const VehicleCorrectives = () => {
  const dispatch = useDispatch();

  const onAdd = () => {
    dispatch(openVehicleCorrectivesDialog(dialogs.ADD_DIALOG));
  };
  return (
    <PageContainer>
      <Table />
      <AddDialog />
      <EditDialog />
      <FloatingButtonWithAdd onAddClicked={onAdd} />
    </PageContainer>
  );
};

VehicleCorrectives.propTypes = {};

export default VehicleCorrectives;
