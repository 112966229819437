import React from 'react';
import {useIntl} from "react-intl";
import useForm from "../../../../hooks/Common/useForm";
import {parseDateToInput} from "../../../../utils/dates";
import GridContainer from "../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../../Common/Forms/OutlinedInput";
import OutlinedDatePicker from "../../../Common/Forms/OutlinedDatePicker";

const CardForm = ({id, initData, onSubmit}) => {
    const {cardNumber, expirationDate, name} = initData;
    const intl = useIntl();
    const {formData, setFormData, handleSubmit} = useForm({
        cardNumber,
        name,
        expirationDate: parseDateToInput(expirationDate),
    });

    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        label={intl.messages['name']}
                        value={formData.name}
                        onChange={value => setFormData('name', value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        label={intl.messages['card.number']}
                        value={formData.cardNumber}
                        onChange={value => setFormData('cardNumber', value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedDatePicker
                        label={intl.messages['expiration.date']}
                        value={formData.expirationDate}
                        onChange={value => setFormData('expirationDate', value)}
                        fullWidth
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default CardForm;