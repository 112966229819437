import React from "react";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/core/styles";
import GridContainer from "../../../../@jumbo/components/GridContainer";
import {
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  useMediaQuery
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useTheme} from "@material-ui/styles";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "auto"
  },
  paper: {
    width: 200,
    height: 230,
    overflow: "auto"
  },
  button: {
    margin: theme.spacing(0.5, 0)
  }
}));

const not = (a, b) => {
  return a.filter(value => b.indexOf(value) === -1);
};

const intersection = (a, b) => {
  return a.filter(value => b.indexOf(value) !== -1);
};

const TransferList = ({right, setRight, left, setLeft, field, filtered}) => {
  const classes = useStyles();
  const theme = useTheme();
  const cellphone = useMediaQuery(theme.breakpoints.down("sm"));
  const [ checked, setChecked ] = React.useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [ ...checked ];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAllRight = () => {
    if (filtered.length > 0) {
      setRight(right.concat(filtered));
      setLeft(left.filter(d => !filtered.some(e => d.id === e.id)));
    } else {
      setRight(right.concat(left));
      setLeft([]);
    }
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);
  };

  const customList = items => (
    <Paper className={classes.paper}>
      <List dense component="div" role="list">
        {items.map((value, index) => {
          const labelId = `transfer-list-item-${index}-label`;

          return (
            <ListItem
              key={index}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{"aria-labelledby": labelId}}
                />
              </ListItemIcon>
              <ListItemText
                id={labelId}
                primary={field ? value[field] : value}
              />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Paper>
  );

  return (
    <GridContainer
      spacing={2}
      direction={cellphone ? "column" : "row"}
      justifyContent="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>{customList(filtered.length > 0 ? filtered : left)}</Grid>
      <Grid item>
        <Grid
          container
          direction={cellphone ? "row" : "column"}
          alignItems="center"
        >
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
          >
            &lt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(right)}</Grid>
    </GridContainer>
  );
};

TransferList.propTypes = {
  right: PropTypes.array,
  left: PropTypes.array,
  setRight: PropTypes.func.isRequired,
  setLeft: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  filtered: PropTypes.array
};

TransferList.defaultProps = {
  right: [],
  left: [],
  field: "",
  filtered: []
};

export default TransferList;
