import React from 'react';
import PageContainer from "../../../../../components/Logistic/Purchases/ProviderOrders/PageContainer";
import ProviderOrdersTable from "../../../../../components/Logistic/Purchases/ProviderOrders/ProviderOrdersTable";
import FloatingButtonWithAdd from "../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import {useDispatch} from "react-redux";
import {openDialog} from "../../../../../redux/reducers/Logistic/providerOrders";
import {dialogsList} from "../../../../../components/Logistic/Purchases/ProviderOrders/constants";
import AddProviderOrderDialog from "../../../../../components/Logistic/Purchases/ProviderOrders/AddProviderOrderDialog";
import EditProviderOrderDialog
    from "../../../../../components/Logistic/Purchases/ProviderOrders/EditProviderOrderDialog";

const ProviderOrders = () => {
    const dispatch = useDispatch();
    return (
        <PageContainer>
            <ProviderOrdersTable />
            <AddProviderOrderDialog />
            <EditProviderOrderDialog />
            <FloatingButtonWithAdd onAddClicked={() => dispatch(openDialog(dialogsList.addOrdersDialog))} />
        </PageContainer>
    );
};

export default ProviderOrders;