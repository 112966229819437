import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {v4 as uuidV4} from 'uuid';

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular,
    },
}));

export default function ListItem({title, contentStyles, children}) {
    const classes = useStyles();
    const id = uuidV4();
    return (
        <Accordion
            TransitionProps={{
                unmountOnExit: true,
            }}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${id}-content`}
                id={`${id}-header`}
            >
                <Typography className={classes.heading}>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                style={contentStyles}
            >
                {children}
            </AccordionDetails>
        </Accordion>
    );
}