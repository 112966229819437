import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import useForm from "../../../../../../hooks/Common/useForm";
import ParamSelect from "./ParamSelect";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import OutlinedDatePicker from "../../../../../Common/Forms/OutlinedDatePicker";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import {setServiceFilters} from "../../../../../../redux/reducers/BackOffice/teltonika";
import {getTeltonikaData} from "../../../../../../redux/thunks/BackOffice/teltonika";

const initialData = {
    param: '',
    paramValue: '',
    startDate: '',
    endDate: '',
}

const Form = () => {
    const id = 'teltonika-filters';
    const {serviceFilters} = useSelector(({backOffice}) => backOffice.teltonika);
    const dispatch = useDispatch();
    const {formData, setFormData, handleSubmit, resetForm} = useForm(initialData);
    const onSubmit = data => {
        dispatch(getTeltonikaData(data));
    }
    const onClearFilters = () => {
        resetForm();
        dispatch(setServiceFilters(initialData));
    }
    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <ParamSelect
                    value={formData.param}
                    setValue={value => setFormData('param', value)}
                />
                <Grid item xs={12} md={4}>
                    <OutlinedInput
                        value={formData.paramValue}
                        onChange={value => setFormData('paramValue', value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <OutlinedDatePicker
                        label={<IntlMessages id='start.date' />}
                        value={formData.startDate}
                        onChange={value => setFormData('startDate', value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <OutlinedDatePicker
                        label={<IntlMessages id='end.date' />}
                        value={formData.endDate}
                        onChange={value => setFormData('endDate', value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <ContainedButton
                        type='submit'
                        form={id}
                        color='primary'
                        text={<IntlMessages id='filters.button.apply' />}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <ContainedButton
                        onClick={onClearFilters}
                        text={<IntlMessages id='filters.button.clear' />}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form;