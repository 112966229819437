import React from "react";
import PropTypes from "prop-types";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => {
  return (
    <StyledTableRow>
      <StyledTableCell>
        <IntlMessages id={"fleet.management.maintenance.plan.table.name"} />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id={"fleet.management.maintenance.plan.table.quantity"} />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id={"fleet.management.maintenance.plan.table.cost"} />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id={"fleet.management.maintenance.plan.table.active"} />
      </StyledTableCell>
      <StyledTableCell />
    </StyledTableRow>
  );
};

TableHeader.propTypes = {};

export default TableHeader;
