import React from "react";
import useForm from "../../../../../../hooks/Common/useForm";
import TransferList from "../../../../../Common/Forms/TransferList";
import Filters from "./Filters";
import FilterBuilder from "../../../../../../utils/filters";

const initData = {
  plaque: "",
  tags: []
};

const VehiclesForm = ({id, initialData, onEditRole}) => {
  const {formData, setFormData, handleSubmit} = useForm(initialData);
  const {
    formData: filtersData,
    setFormData: setFiltersData,
    resetForm
  } = useForm(initData);
  const filteredVehicles = new FilterBuilder(formData.vehicles)
    .byFieldIncludesMultiOptions("tags", filtersData.tags.map(t => t.id))
    .byFieldIncludes("plaque", filtersData.plaque)
    .build();
  React.useEffect(
    () => {
      resetForm();
    },
    [ formData ]
  );
  return (
    <React.Fragment>
      <Filters formData={filtersData} setFormData={setFiltersData} />
      <form id={id} onSubmit={handleSubmit(onEditRole)}>
        <TransferList
          right={formData.selected}
          left={formData.vehicles}
          filtered={filteredVehicles}
          setLeft={value => setFormData("vehicles", value)}
          setRight={value => setFormData("selected", value)}
          field="plaque"
        />
      </form>
    </React.Fragment>
  );
};

export default VehiclesForm;
