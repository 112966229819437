import React from "react";
import PropTypes from "prop-types";

import IntlMessages from "@jumbo/utils/IntlMessages";
import PageContainerComponent from "components/Common/Layouts/PageContainer";

const breadcrumbs = [
    {
        label: <IntlMessages id={"sidebar.dasboard.fleetManagement"} />,
        link: "/fleet-management/tachograph/files"
    },
    {
        label: (
            <IntlMessages
                id={"fleet.management.tachograph.files.reader.title"}
            />
        ),
        isActive: true
    }
];

const PageContainer = ({children}) => (
    <PageContainerComponent
        heading={<IntlMessages id="fleet.management.tachograph.files.reader.title" />}
        description={
            <IntlMessages id="fleet.management.tachograph.files.reader.description" />
        }
        breadcrumbs={breadcrumbs}
    >
        {children}
    </PageContainerComponent>
);

PageContainer.propTypes = {
    children: PropTypes.node
};

export default PageContainer;
