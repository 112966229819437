import React from 'react';
import BasicInfoForm from "./BasicInfoForm";
import ProvidersList from "./ProvidersList";
import LocationForm from "./LocationForm";
import ControlForm from "./ControlForm/ControlForm";
import ItemsList from "./ItemsList";
import "./styles.css";
import {locationType} from "../constants";

const ProviderOrderForm = ({initData = {}, onSubmit, editable = true}) => {
    return (
        <div className="order-form">
            {initData?.id &&
                <React.Fragment>
                    <BasicInfoForm initData={initData}/>
                </React.Fragment>
            }
            <ProvidersList
                providers={initData.providers}
                editable={editable}
            />
            <LocationForm
                name={locationType.origin}
                location={initData.origin}
                editable={editable}
            />
            <LocationForm
                name={locationType.destination}
                location={initData.destination}
                editable={editable}
            />
            <ControlForm
                control={initData.control}
                editable={editable}
            />
            <ItemsList
                items={initData.items}
                editable={editable}
            />
        </div>
    );
};

export default ProviderOrderForm;