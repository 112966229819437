import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, IconButton} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Confirmation from "../../../../Common/Dialogs/Confirmation";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import HeaderDialog from "./HeaderDialog";
import AddViewDialog from "./AddViewDialog";
import {removeSummaryDocumentsView} from "../../../../../redux/actions/RRHH/Documentation";

const ButtonsArea = ({view}) => {
  const [ openAdd, setOpenAdd ] = React.useState(false);
  const [ openEdit, setOpenEdit ] = React.useState(false);
  const [ openDelete, setOpenDelete ] = React.useState(false);
  const {selectedInfo} = useSelector(({rrhh}) => rrhh.documentation.summary);
  const dispatch = useDispatch();
  const onDelete = () => {
      dispatch(removeSummaryDocumentsView(view));
      setOpenDelete(false);
  };
  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        style={{gap: "1em"}}
      >
        <IconButton onClick={() => setOpenAdd(true)}>
          <AddCircleOutlineIcon />
        </IconButton>
        {selectedInfo.editable && (
          <React.Fragment>
            <IconButton onClick={() => setOpenEdit(true)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => setOpenDelete(true)}>
              <DeleteIcon />
            </IconButton>
          </React.Fragment>
        )}
      </Box>
      <Confirmation
        text={
          <IntlMessages
            id={
              "rrhh.employees.documentation.summary.mode.headers.delete.dialog.text"
            }
          />
        }
        open={openDelete}
        onAccept={onDelete}
        onReject={() => setOpenDelete(false)}
      />
      <HeaderDialog open={openEdit} setOpen={setOpenEdit} />
      <AddViewDialog open={openAdd} setOpen={setOpenAdd} />
    </React.Fragment>
  );
};

export default ButtonsArea;
