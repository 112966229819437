import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DataGrid} from "@mui/x-data-grid";
import {useIntl} from "react-intl";
import {IconButton} from "@material-ui/core";
import {dialogsList} from "./constants";
import {fetchAllDrivingControlEvents, getAllEventMedia} from "../../../../redux/thunks/Localization/drivingControl";
import {openDialog, setCurrentEvent} from "../../../../redux/reducers/Localization/drivingControl";
import ShowIcon from '@material-ui/icons/Visibility';
import {parseDateToGlobalFormatWithSeconds} from "../../../../utils/dates";

const Table = () => {
    const dispatch = useDispatch();
    const {events, filters, rowCount} = useSelector(({localization}) => localization.drivingControl);
    const intl = useIntl();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(50);
    React.useEffect(() => {
        dispatch(fetchAllDrivingControlEvents({filters, page, pageSize}));
    }, [page, pageSize, filters]);
    const handleSelectEvent = (row) => {
        dispatch(openDialog(dialogsList.DETAILS_DIALOG));
        dispatch(setCurrentEvent(row));
        dispatch(getAllEventMedia({event: row}));
    }
    const columns = [
        {
            field: 'id',
            hide: true
        },
        {
            field: 'deviceID',
            headerName: `${intl.messages['unit']} ${intl.messages['id']}`,
            width: 200,
        },
        {
            field: 'driverID',
            headerName: `${intl.messages['driver']} ${intl.messages['id']}`,
            width: 200,
        },
        {
            field: 'tripID',
            headerName: `${intl.messages['trip']} ${intl.messages['id']}`,
            width: 200,
        },
        {
            field: 'eventType',
            headerName: intl.messages['event.type'],
            width: 200,
        },
        {
            field: 'eventDateTime',
            headerName: intl.messages['time'],
            valueFormatter: ({value}) => parseDateToGlobalFormatWithSeconds(value),
            width: 200,
        },
        {
            field: 'lastKnownStreetAddress',
            headerName: intl.messages['location'],
            width: 200,
        },
        {
            field: 'actions',
            headerName: intl.messages['details'],
            type: 'actions',
            renderCell: ({row}) => {
                return (
                    <>
                        <IconButton onClick={() => handleSelectEvent(row)}>
                            <ShowIcon />
                        </IconButton>
                    </>
                )
            }
        }
    ];
    const onPageChange = (page) => {
        setPage(page);
    }
    const onPageSizeChange = (pageSize) => {
        setPageSize(pageSize);
    }
    return (
        <DataGrid
            columns={columns}
            rows={events}
            style={{
                height: '70vh'
            }}
            disableColumnMenu
            disableSelectionOnClick
            pagination
            rowCount={rowCount}
            page={page}
            onPageChange={onPageChange}
            rowsPerPageOptions={[50, 100]}
            onPageSizeChange={onPageSizeChange}
            pageSize={pageSize}
            paginationMode='server'
        />
    );
};

export default Table;