import React from "react";
import PropTypes from "prop-types";

import IntlMessages from "@jumbo/utils/IntlMessages";
import PageContainerComponent from "components/Common/Layouts/PageContainer";

const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.dasboard.fleetManagement"} />,
    link: "/fleet-management/vehicles/file"
  },
  {
    label: <IntlMessages id="fleet.management.workshop.request.sidebar" />,
    isActive: true
  }
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={<IntlMessages id="fleet.management.workshop.request.title" />}
    description={
      <IntlMessages id="fleet.management.workshop.request.description" />
    }
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

PageContainer.propTypes = {
  children: PropTypes.node
};

export default PageContainer;
