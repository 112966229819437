import React from 'react';
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import {parseLoweCamelCaseToTitleText} from "../../../../../../utils/parsers";
import ListItem from "./ListItem";
import Table from "./Table";
import {v4 as uuidV4} from 'uuid';

const Form = ({title, data = {}, excludeHeaders = []}) => {
    return (
        <ListItem
            title={title}
        >
            <GridContainer spacing={2}>
                {Object.entries(data)
                    .filter(([_, value]) => value)
                    .filter(([key]) => !excludeHeaders.includes(key))
                    .map(([key, value]) => (
                    !Array.isArray(value)
                        ? <React.Fragment key={uuidV4()}>
                            {key.toLowerCase().includes('grafica')
                                ? <Grid item xs={12} key={key}>
                                    <picture>
                                        <img src={`data:image/svg+xml;base64,${value}`} alt={key}/>
                                    </picture>
                                </Grid>
                                : <Grid item xs={12} md={4} key={key}>
                                    <OutlinedInput
                                        label={parseLoweCamelCaseToTitleText(key)}
                                        value={value}
                                        fullWidth
                                        disabled
                                    />
                                </Grid>
                            }
                        </React.Fragment>
                        : <Grid item xs={12} key={key}>
                            <Table
                                data={value}
                                isList={false}
                            />
                        </Grid>
                ))}
            </GridContainer>
        </ListItem>
    );
};

export default Form;