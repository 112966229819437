import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import React from "react";

export default {
  vehicle: [
    {
      rule: v => v.trim() !== "",
      message: <IntlMessages id="form.field.error" />
    }
  ]
};
