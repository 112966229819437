import React, {useCallback} from 'react';
import {useSelector} from "react-redux";
import moment from "moment";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import {Box} from "@material-ui/core";
import {useIntl} from "react-intl";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

const Table = () => {
    const {data, localFilters} = useSelector(({backOffice}) => backOffice.teltonika);
    const intl = useIntl();

    const getHeaders = useCallback(() => {
        const headers = [
            {field: 'id', hide: true},
            {
                field: 'imei',
                headerName: intl.messages["imei"],
                minWidth: 150,
            },
            {
                field: 'plotDate',
                headerName: intl.messages['plot.date'],
                valueFormatter: ({value, row}) => {
                    const {event} = row;
                    return calculateDate(event, value)
                },
                minWidth: 180,
            },
            {
                field: 'receivedDate',
                headerName: intl.messages["received.plot.date"],
                valueFormatter: ({value, row}) => {
                    const {event} = row;
                    return calculateDate(event, value)
                },
                minWidth: 200,
            },
            {
                field: 'priority',
                headerName: intl.messages["priority"],
                minWidth: 110,
            },
            {
                field: 'latitude',
                headerName: intl.messages['latitude'],
                renderHeader: () => (<IntlMessages id='latitude' />),
                minWidth: 110,
            },
            {
                field: 'longitude',
                minWidth: 110,
            },
            {
                field: 'altitude',
                headerName: intl.messages['altitude'],
                minWidth: 110,
            },
            {
                field: 'angle',
                headerName: intl.messages['angle'],
            },
            {
                field: 'sat',
                headerName: intl.messages['sat'],
            },
            {
                field: 'speed',
                headerName: intl.messages['speed'],
                minWidth: 110,
            },
            {
                field: 'event',
                headerName: intl.messages['event.io'],
                minWidth: 120,
                renderCell: ({value}) => (
                    <Box
                        style={{
                            backgroundColor: value.toString() === localFilters.event.toString()? '#e8d900': 'transparent'
                        }}
                    >
                        {value}
                    </Box>
                )
            },
        ]
        const extraHeaders = Object.keys(data[0]?.extraData || {});
        return [
            ...headers,
            ...extraHeaders.map((header) => ({
                field: header,
                headerName: header,
                minWidth: 120
            }))
        ]
    }, [data, localFilters])
    const calculateDate = (event, date) => {
        let newDate = new Date(0).setUTCSeconds(date);
        newDate = moment(newDate)
            .add(Number(localFilters.timeFix || 0), 'hour')
            .format("DD/MM/YYYY HH:mm:ss");
        return newDate;
    }
    return (
        <DataGrid
            columns={getHeaders()}
            rows={data.map(row => ({...row, ...row.extraData}))}
            disableSelectionOnClick
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            rowHeight={40}
            checkboxSelection
            components={{
                Toolbar: CustomToolbar,
            }}
        />
    );
};

export default Table;