import React from "react";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import useForm from "../../../../../../hooks/Common/useForm";


const initData = {
  providerCode: "",
};

const Form = ({id, onSubmit}) => {
  const {formData, setFormData, errors, handleSubmit} = useForm(
    initData,
  );
  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={6}>
          <OutlinedInput
            fullWidth
            hasError={errors.providerCode.length > 0}
            error={errors.providerCode[0]}
            value={formData.providerCode}
            label={<IntlMessages id="nif" />}
            onChange={value => setFormData("providerCode", value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
