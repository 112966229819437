import React from "react";
import PageContainer from "../../../../../components/FleetManagement/Vehicles/Documentation/PageContainer";
import ViewerContainer from "../../../../../components/FleetManagement/Vehicles/Documentation/ViewerContainer";

const Documentation = () => {
  return (
    <PageContainer>
      <ViewerContainer />
    </PageContainer>
  );
};

export default Documentation;
