import React from "react";
import PropTypes from "prop-types";
import MultipleSelect from "../../../../Common/Forms/Select/MultipleSelect";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {useFetch} from "../../../../../hooks/Common/useFetch";
import {getAllMaintenancePlans} from "../../../../../services/FleetManagement/DailyOperation/MaintenancePlansService";

const MaintenancePlanSelect = ({setValue, id, fullWidth = true, value}) => {
  const {data} = useFetch(getAllMaintenancePlans, {}, []);
  return (
    <MultipleSelect
      id={id}
      onChange={value => setValue(value)}
      value={value}
      label={
        <IntlMessages id={"fleet.management.common.select.maintenance.plans"} />
      }
      fullWidth={fullWidth}
      options={Object.values(data)}
    />
  );
};

MaintenancePlanSelect.propTypes = {
  setValue: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
  value: PropTypes.array.isRequired
};

export default MaintenancePlanSelect;
