import React from 'react';
import useForm from "../../../hooks/Common/useForm";
import GridContainer from "../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import TaxSelect from "../common/TaxSelect";
import DeductionTypeSelect from "../common/DeductionTypeSelect";

const INIT_DATA = {
    name: '',
    code: '',
    unitPrice: 0,
    tax: '',
    taxPercentage: 0,
    total: 0,
    deductionType: null
};

const Form = ({id, initData = {}, onSubmit}) => {
    const {formData, setFormData, setValues, handleSubmit} = useForm(INIT_DATA);
    React.useEffect(() => {
        setValues({
            ...initData,
            tax: initData.taxId,
            taxPercentage: initData?.taxId ? ((initData.total / initData.unitPrice) - 1) * 100 : 0,
        });
    }, []);

    const handleTaxOnChange = ({value, percentage}) => {
        if(percentage) {
            setValues({
                tax: value,
                taxPercentage: percentage,
                total: (formData.unitPrice * (1 + percentage / 100)).toFixed(2),
            });
        } else {
            setFormData('tax', value);
        }
    }
    const handleChangeUnitPrice = (unitPrice) => {
        setValues({
            unitPrice: unitPrice,
            total: (unitPrice * (1 + formData.taxPercentage / 100)).toFixed(2),
        });
    }
    const handleChangeTotal = (total) => {
        setValues({
            total,
            unitPrice: (total / (1 + formData.taxPercentage / 100)).toFixed(0),
        });
    }
    return (
        <form
            id={id}
            onSubmit={handleSubmit(onSubmit)}
        >
            <GridContainer spacing={2}>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        name="name"
                        label={<IntlMessages id="name"/>}
                        value={formData.name}
                        onChange={(value) => setFormData('name', value)}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        name="code"
                        label={<IntlMessages id="code"/>}
                        value={formData.code}
                        onChange={(value) => setFormData('code', value)}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        name="unitPrice"
                        label={<IntlMessages id="unit.price"/>}
                        value={formData.unitPrice}
                        onChange={handleChangeUnitPrice}
                        fullWidth
                        required
                        type='number'
                        inputProps={{min: 0, max: 100, step: 0.01}}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TaxSelect
                        value={formData.tax}
                        onChange={handleTaxOnChange}
                        initValue={initData.taxId}
                    />
                </Grid>
                <Grid item xs={12} md={6} style={{alignContent: 'center'}}>
                    <OutlinedInput
                        label={<IntlMessages id="total"/>}
                        value={formData.total}
                        fullWidth
                        onChange={handleChangeTotal}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <DeductionTypeSelect
                        value={formData.deductionType}
                        onChange={(value) => setFormData('deductionType', value)}
                        initValue={initData.deductionType}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form;