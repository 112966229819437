import React from "react";
import AuthWrapper from "../../@jumbo/components/Common/authComponents/AuthWrapper";
import {useStyles} from "../../@jumbo/components/Common/authComponents/ResendSignUp/styles";
import ResendCodeForm from "../../@jumbo/components/Common/authComponents/ResendSignUp/ResendCodeForm";
import CmtImage from "../../@coremat/CmtImage";
import Typography from "@material-ui/core/Typography";
import ContentLoader from "../../@jumbo/components/ContentLoader";
import useForm from "../../hooks/Common/useForm";
import {useHistory} from "react-router-dom";

const initialData = {
  email: "",
  code: ""
};

const ResendSignUp = () => {
  const classes = useStyles();
  const history = useHistory();
  const {formData, setFormData} = useForm(initialData);
  const onSend = () => {
    setTimeout(() => {
      history.replace("/signin");
    }, 1500);
  };
  return (
    <AuthWrapper variant="bgColor">
      <div className={classes.authContent}>
        <div className={"mb-7"}>
          <CmtImage src={"/images/logo.png"} />
        </div>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Resend SignUp
        </Typography>
        <ResendCodeForm
          formData={formData}
          setFormData={setFormData}
          onSend={onSend}
        />
        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default ResendSignUp;
