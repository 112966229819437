import React from "react";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import ViewSelect from "./ViewSelect";
import {useDispatch, useSelector} from "react-redux";
import {fetchAllVehicleMaintenances} from "../../../../../redux/actions/FleetManagement/VehicleMaintenances";
import ButtonsArea from "./ButtonsArea";

const ViewControls = props => {
  const [ view, setView ] = React.useState("");
  const {views} = useSelector(
    ({fleetManagement}) => fleetManagement.vehicleMaintenances
  );
  const dispatch = useDispatch();
  React.useEffect(
    () => {
      dispatch(fetchAllVehicleMaintenances(view, views));
    },
    [ view ]
  );
  return (
    <GridContainer spacing={3}>
      <Grid item xs={12} sm={8}>
        <ViewSelect value={view} onChange={value => setView(value)} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ButtonsArea view={view} />
      </Grid>
    </GridContainer>
  );
};

ViewControls.propTypes = {};

export default ViewControls;
