import React from 'react';
import useForm from "../../../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";

const initData = {
    name: '',
    phone: '',
    email: ''
}
const Form = ({id, onSubmit}) => {
    const {formData, setFormData, handleSubmit} = useForm(initData);
    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12}>
                    <OutlinedInput
                        fullWidth
                        name="name"
                        value={formData.name}
                        onChange={value => setFormData('name', value)}
                        label={<IntlMessages id="name" />}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedInput
                        fullWidth
                        name="phone"
                        value={formData.phone}
                        onChange={value => setFormData('phone', value)}
                        label={<IntlMessages id="phone" />}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedInput
                        fullWidth
                        name="email"
                        value={formData.email}
                        onChange={value => setFormData('email', value)}
                        label={<IntlMessages id="email" />}
                        required
                        type="email"
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form;