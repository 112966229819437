import React from 'react';
import {useFetch} from "../../../../../../../../hooks/Common/useFetch";
import Select from "../../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";
import {fetchAllEmployeesByEntity} from "../../../../../../../../services/EmployeeService";

const EmployeesSelect = ({value, onChange, disabled, errors}) => {
    const {data: employees} = useFetch(fetchAllEmployeesByEntity, {}, []);
    return (
        <Select
            id='vehicle-select'
            label={<IntlMessages id={"rrhh.employees.documentation.list.mode.detail.form.employee"} />}
            value={value}
            onChange={e => onChange(e.target.value)}
            fullWidth
            disabled={disabled}
            error={errors?.employee?.length>0}
            errorMessage={errors?.employee[0]}
        >
            <MenuItem value={''} />
            {employees.map(employee => (
                <MenuItem value={employee.id} key={employee.id}>
                    {employee.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default EmployeesSelect;