import React from "react";
import PropTypes from "prop-types";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Button, Grid} from "@material-ui/core";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import MaintenanceTypesSelect from "../../../../../Common/Selects/MaintenanceTypesSelect";
import {useFetch} from "../../../../../../../hooks/Common/useFetch";
import {getAllMaintenanceTypes} from "../../../../../../../services/FleetManagement/DailyOperation/MaintenanceTypesService";

const AddType = ({onAdd}) => {
  const {data: types} = useFetch(getAllMaintenanceTypes, {}, []);
  const [ type, setType ] = React.useState("");
  const onAddType = () => {
    // console.log(types.find(t => t.id === type), types);
    const newType = types.find(t => t.id === type);
    onAdd({id: newType.id, description: newType.description, cost: 0});
  };
  return (
    <GridContainer spacing={2}>
      <Grid item xs={12} md={6}>
        <MaintenanceTypesSelect
          id={"type-select"}
          value={type}
          setValue={value => setType(value)}
          types={types}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Button onClick={onAddType}>
          <IntlMessages id={"button.add"} />
        </Button>
      </Grid>
    </GridContainer>
  );
};

AddType.propTypes = {
  onAdd: PropTypes.func.isRequired
};

export default AddType;
