import React from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import FormDialog from "../../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {
  addSinisterType,
  closeSinisterTypesDialog
} from "../../../../../../redux/actions/FleetManagement/SinisterTypes";
import Form from "../Form";
import {dialogs} from "../../constants";

const AddDialog = () => {
  const id = "add-sinister-type-form";
  const {dialog: {name}} = useSelector(
    ({fleetManagement}) => fleetManagement.sinisterTypes
  );
  const dispatch = useDispatch();
  const onAdd = data => {
    dispatch(addSinisterType(data));
    onClose();
  };
  const onClose = () => {
    dispatch(closeSinisterTypesDialog());
  };
  return (
    <FormDialog
      id={id}
      title={<IntlMessages id={"fleet.management.sinister.type.add.title"} />}
      submitText={<IntlMessages id={"label.form.save"} />}
      cancelText={<IntlMessages id={"label.form.cancel"} />}
      isOpen={name === dialogs.ADD_DIALOG}
      onClose={onClose}
    >
      {name === dialogs.ADD_DIALOG && (
        <Form initialData={{description: ""}} id={id} onSubmit={onAdd} />
      )}
    </FormDialog>
  );
};

AddDialog.propTypes = {};

export default AddDialog;
