import React from "react";
import PropTypes from "prop-types";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import PageContainerComponent from "../../../Common/Layouts/PageContainer";

const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.dasboard.fleetManagement"} />,
    link: "/rrhh/personal/documents-types"
  },
  {
    label: <IntlMessages id={"rrhh.employees.documents.types.sidebar"} />,
    isActive: true
  }
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={<IntlMessages id="rrhh.employees.documents.types.title" />}
    description={
      <IntlMessages id="rrhh.employees.documents.types.description" />
    }
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

PageContainer.propTypes = {
  children: PropTypes.node
};

export default PageContainer;
