import {useSelector} from "react-redux";
import React from "react";
import Select from "../../../../Common/Forms/Select";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";

const ViewSelect = ({value, onChange}) => {
    const {views} = useSelector(({rrhh}) => rrhh.documentation.summary);
    React.useEffect(() => {
        if(views.length > 0) {
            onChange(views.find(v => v.predetermined)?.id || '');
        }
    }, [views]);
    return (
        <Select
            id='employee-documents-view-select'
            label={<IntlMessages id={ "rrhh.employees.documentation.summary.mode.filters.view"} />}
            value={value}
            onChange={e => onChange(e.target.value)}
            fullWidth
            displayEmpty={false}
        >
            <MenuItem value={''} />
            {views.map(view => (
                <MenuItem value={view.id} key={view.id}>
                    {view.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default ViewSelect;