import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import BaseDialog from "../../Common/BaseDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {closeGenreDialog} from "../../../../../../redux/reducers/ElevatorsManagement/genres";
import {dialogsList} from "../../Common/constants";
import {addGenre} from "../../../../../../redux/thunks/ElevatorManagement/genres";

const AddGenreDialog = () => {
    const {dialogs} = useSelector(({elevatorsManagement}) => elevatorsManagement.genres);
    const dispatch = useDispatch();
    const onSubmit = data => {
        dispatch(addGenre({request: data}));
        dispatch(closeGenreDialog(dialogsList.addDialog));
    }
    return(
        <BaseDialog
            id='edit-ken-form'
            title={<IntlMessages id="add"/>}
            open={dialogs.some(dialog => dialog === dialogsList.addDialog)}
            setOpen={() => dispatch(closeGenreDialog(dialogsList.addDialog))}
            initData={{
                name: '',
            }}
            onSubmit={onSubmit}
        />
    );
};

export default AddGenreDialog;