import {createSlice} from "@reduxjs/toolkit";
import {cloneDeep} from "lodash";
import {getDataFromFile} from "../../thunks/FleetManagement/Tachograph/filesReader";

const INIT_DATA = {
    fileType: null,
    fileData: null,
    dialogs: [],
    currentImage: null
}
const filesReaderSlice = createSlice({
    name: 'filesReader',
    initialState: cloneDeep(INIT_DATA),
    reducers: {
        openDialog: (state, action) => {
            state.dialogs = [
                ...state.dialogs,
                action.payload,
            ];
        },
        closeDialog: (state, action) => {
            state.dialogs = state.dialogs.filter(dialog => dialog !== action.payload);
        },
        setImageViewerFile: (state, action) => {
            state.currentImage = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getDataFromFile.fulfilled, (state, action) => {
            const {type, data} = action.payload;
           state.fileType = type;
           state.fileData = data;
        });
        builder.addCase(
            getDataFromFile.rejected,
            (state, action) => {
                return cloneDeep(INIT_DATA);
        });
    }
})


export default filesReaderSlice.reducer;

export const {openDialog, closeDialog,
                setImageViewerFile} = filesReaderSlice.actions;