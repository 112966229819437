import React from 'react';
import useForm from "../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import OutlinedDateTimePicker from "../../../../Common/Forms/OutlinedDateTimePicker";
import ContainedButton from "../../../../Common/Buttons/ContainedButton";
import FiltersContainer from "../../../../Common/Forms/FiltersContainer";
import {useDispatch} from "react-redux";
import {setFilters} from "../../../../../redux/reducers/Localization/drivingControl";
import UnitsSelect from "./UnitsSelect";
import EventsTypesSelect from "./EventsTypesSelect";
import {fetchDrivingControlEventsExcel} from "../../../../../redux/thunks/Localization/drivingControl";

const initialData = {
    units: [],
    driver: '',
    trip: '',
    eventTypes: [],
    startTime: '',
    endTime: '',
};

const Filters = () => {
    const id = 'driving-control-filters';
    const dispatch = useDispatch();
    const {formData, setFormData, handleSubmit, resetForm} = useForm(initialData);
    const onSubmit = (data) => {
        dispatch(setFilters(data));
    }
    const handleClear = () => {
        resetForm();
        dispatch(setFilters(formData));
    }
    const handleDownload = () => {
       dispatch(fetchDrivingControlEventsExcel({filters: formData}));
    }
    return (
        <FiltersContainer active sx={{
            marginBottom: '1rem'
        }}>
            <form id={id} onSubmit={handleSubmit(onSubmit)}>
                <GridContainer spacing={2}>
                    <Grid item xs={12} md={3}>
                        <UnitsSelect
                            value={formData.units}
                            onChange={value => setFormData('units', value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <OutlinedInput
                            name='driver'
                            label={<IntlMessages id='driver'/>}
                            value={formData.driver}
                            onChange={value => setFormData('driver', value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <OutlinedInput
                            name='trip'
                            label={<IntlMessages id='trip'/>}
                            value={formData.trip}
                            onChange={value => setFormData('trip', value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <EventsTypesSelect
                            value={formData.eventTypes}
                            onChange={value => setFormData('eventTypes', value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <OutlinedDateTimePicker
                            name='startTime'
                            label={<IntlMessages id='start.date'/>}
                            value={formData.startTime}
                            onChange={value => setFormData('startTime', value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <OutlinedDateTimePicker
                            name='endTime'
                            label={<IntlMessages id='end.date'/>}
                            value={formData.endTime}
                            onChange={value => setFormData('endTime', value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <ContainedButton
                            text={<IntlMessages id='apply.filters'/>}
                            form={id}
                            type='submit'
                            fullWidth
                            color='primary'
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <ContainedButton
                            text={<IntlMessages id='clear.filters'/>}
                            fullWidth
                            onClick={handleClear}
                        />
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <ContainedButton
                            text={<IntlMessages id='download'/>}
                            fullWidth
                            color='primary'
                            onClick={handleDownload}
                        />
                    </Grid>
                </GridContainer>
            </form>
        </FiltersContainer>
    );
};

export default Filters;