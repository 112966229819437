import React from "react";
import Select from "../../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";

const ExpirationSelect = ({value, onChange}) => {
  const options = [
    {
      id: 2,
      name: (
        <IntlMessages
          id={
            "fleet.management.vehicles.documentation.list.mode.table.filters.expiration.date.notice"
          }
        />
      )
    },
    {
      id: 3,
      name: (
        <IntlMessages
          id={
            "fleet.management.vehicles.documentation.list.mode.table.filters.expiration.date.expired"
          }
        />
      )
    }
  ];
  return (
    <Select
      id="documents-list-expiration-select"
      label={
        <IntlMessages
          id={
            "fleet.management.vehicles.documentation.list.mode.table.expiration.date"
          }
        />
      }
      value={value}
      onChange={e => onChange(e.target.value)}
      fullWidth
      displayEmpty
    >
      <MenuItem value="">
        <IntlMessages
          id={
            "fleet.management.vehicles.documentation.list.mode.table.filters.expiration.date.all"
          }
        />
      </MenuItem>
      {options.map(option => (
        <MenuItem value={option.id} key={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ExpirationSelect;
