import React from "react";
import PageContainer from "../../../../../components/FleetManagement/DailyOperation/VehicleMaintenance/PageContainer";
import Table from "../../../../../components/FleetManagement/DailyOperation/VehicleMaintenance/Table";
import {useDispatch} from "react-redux";
import {fetchAllVehicleMaintenancesViews} from "../../../../../redux/actions/FleetManagement/VehicleMaintenances";
import ViewControls from "../../../../../components/FleetManagement/DailyOperation/VehicleMaintenance/ViewControls";

const VehicleMaintenance = props => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchAllVehicleMaintenancesViews());
  }, []);
  return (
    <PageContainer>
      <ViewControls />
      <Table />
    </PageContainer>
  );
};

VehicleMaintenance.propTypes = {};

export default VehicleMaintenance;
