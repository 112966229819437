import React from 'react';
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {Accordion, AccordionActions, AccordionDetails, AccordionSummary, Box, Button} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoTabs from "./InfoTabs";


const InfoFooter = () => {
    const {currentIndexFile} = useSelector(({backOffice}) => backOffice.indexFiles);
    const intl = useIntl();
    const [open, setOpen] = React.useState(false);
    const onChange = (e) => {
        setOpen(!open);
    }
    const onClose = () => {
        setOpen(false);
    }
    return (
        <div
            style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%'
            }}
        >
            <Accordion
                expanded={open}
                onChange={onChange}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}

                >
                    <img
                        src={currentIndexFile?.image}
                        alt='device'
                        height={60}
                        width={60}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Typography>
                            {intl.messages["imei"]}: {currentIndexFile?.imei}
                        </Typography>
                        <Typography>
                            {intl.messages["model"]}: {currentIndexFile?.model}
                        </Typography>
                    </Box>
                </AccordionSummary>
                <AccordionDetails
                    style={{
                        height: '50vh'
                    }}
                >
                    <InfoTabs />
                </AccordionDetails>
                <AccordionActions>
                    <Button
                        color="primary"
                        variant='contained'
                        size="small"
                        onClick={onClose}
                    >
                        {intl.messages["minimize.details"]}
                    </Button>
                </AccordionActions>
            </Accordion>
        </div>
    );
};

export default InfoFooter;