import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  closeVehicleMaintenancePlansDialog,
  updateVehicleMaintenancePlan
} from "../../../../../redux/actions/FleetManagement/VehicleMaintenancePlans";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {dialogs} from "../constants";
import Form from "./Form";

const EditDialog = () => {
  const id = "edit-vehicle-maintenance-plan-form";
  const {current, dialog: {name, open}} = useSelector(
    ({fleetManagement}) => fleetManagement.vehicleMaintenancePlans
  );
  const dispatch = useDispatch();
  const onEdit = data => {
    dispatch(updateVehicleMaintenancePlan({id: current.id, ...data}));
    onClose();
  };
  const onClose = () => {
    dispatch(closeVehicleMaintenancePlansDialog());
  };
  return (
    <FormDialog
      id={id}
      title={
        <IntlMessages
          id={"fleet.management.vehicle.maintenance.plan.edit.title"}
        />
      }
      submitText={<IntlMessages id={"label.form.save"} />}
      cancelText={<IntlMessages id={"label.form.cancel"} />}
      isOpen={open && dialogs.EDIT_DIALOG === name}
      onClose={onClose}
    >
      {open &&
      dialogs.EDIT_DIALOG === name && (
        <Form
          initialData={{
            plans: current.plans || []
          }}
          id={id}
          onSubmit={onEdit}
        />
      )}
    </FormDialog>
  );
};

EditDialog.propTypes = {};

export default EditDialog;
