import React from 'react';
import PageContainer from "../../../../../components/BackOffice/Devices/Teltonika/PageContainer";
import Filters from "../../../../../components/BackOffice/Devices/Teltonika/Filters";
import Table from "../../../../../components/BackOffice/Devices/Teltonika/Table";

const Teltonika = () => {
    return (
        <PageContainer>
            <Filters />
            <Table />
        </PageContainer>
    );
};

export default Teltonika;