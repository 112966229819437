import React from 'react';
import Tabs from "../../../../../Common/Tabs";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import Tasks from "./Tasks";
import Changelog from "./Changelog";
import {Container} from "@material-ui/core";
import Details from "./Details/index.js";

const tabs = [
    {
        value: 'details',
        label: <IntlMessages id="device.details" />,
        renderOption: () => <Details />
    },
    {
        value: 'tasks',
        label: <IntlMessages id="tasks" />,
        renderOption: () => <Tasks />
    },
    {
        value: 'changelog',
        label: <IntlMessages id="changes" />,
        renderOption: () => <Changelog />
    }

]
const InfoTabs = () => {
    const [selected, setSelected] = React.useState(tabs[0].value);
    const onChangeTab = (newSelected) => {
        setSelected(newSelected);
    }
    return (
        <Container>
            <Tabs
                value={selected}
                onChange={onChangeTab}
                tabs={tabs}
            >
                {tabs.find(o => o.value === selected)?.renderOption()}
            </Tabs>
        </Container>
    );
};

export default InfoTabs;