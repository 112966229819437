import React, {useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import {useDispatch} from "react-redux";
import useForm from "../../../../../hooks/Common/useForm";
import {applyEmployeeDocumentsTypesFilters} from "../../../../../redux/actions/RRHH/DocumentsTypes";
import FiltersContainer from "../../../../Common/Forms/FiltersContainer";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const initialData = {
  description: "",
  securityControl: ""
};

const Filters = () => {
  const {formData, setFormData} = useForm(initialData);
  const dispatch = useDispatch();
  useEffect(
    () => {
      dispatch(applyEmployeeDocumentsTypesFilters(formData));
    },
    [ formData ]
  );
  return (
    <FiltersContainer active marginBottom={5} onClose={() => {}}>
      <form>
        <GridContainer spacing={4}>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.description}
              id="description-filter"
              label={
                <IntlMessages id="rrhh.employees.documents.types.table.description" />
              }
              onChange={value => setFormData("description", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.securityCode}
              id="security-code-filter"
              label={
                <IntlMessages id="rrhh.employees.documents.types.table.security.control" />
              }
              onChange={value => setFormData("securityCode", value)}
            />
          </Grid>
        </GridContainer>
      </form>
    </FiltersContainer>
  );
};

Filters.propTypes = {};

export default Filters;
