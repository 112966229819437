import React from "react";
import PropTypes from "prop-types";
import StyledTableRow from "../../../../../../Common/Tables/StyledTableRow";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import StyledTableCell from "../../../../../../Common/Tables/StyledTableCell";

const TableHeader = () => {
  return (
    <StyledTableRow>
      <StyledTableCell>
        <IntlMessages
          id={"fleet.management.maintenance.plan.form.table.types.description"}
        />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages
          id={"fleet.management.maintenance.plan.form.table.types.cost"}
        />
      </StyledTableCell>
      <StyledTableCell />
    </StyledTableRow>
  );
};

TableHeader.propTypes = {};

export default TableHeader;
