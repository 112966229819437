import React from "react";
import PageContainer from "../../../../components/RRHH/Documentation/PageContainer";
import ViewerContainer from "../../../../components/RRHH/Documentation/ViewerContainer";

const EmployeesDocumentation = () => {
  return (
    <PageContainer>
      <ViewerContainer />
    </PageContainer>
  );
};

export default EmployeesDocumentation;
