import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import MaintenancePlan from "../../../domain/FleetManagement/DailyOperations/MaintenancePlan";
import Response from "../../../domain/Response";

export const getAllMaintenancePlans = async () => {
  try {
    const {data} = await axiosInstance.post("/VehiculoPlanMantenimiento/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(d => MaintenancePlan.map(d));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createMaintenancePlan = async plan => {
  try {
    const {data} = await axiosInstance.post("/VehiculoPlanMantenimiento/ins", {
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_plan_mantenimiento: plan.name,
      nu_nro_kilometros: plan.kilometers || 0,
      nu_nro_kilometros_preaviso: plan.noticeKilometers || 0,
      nu_nro_horas: plan.engineHours || 0,
      nu_nro_horas_preaviso: plan.noticeHours || 0,
      nu_nro_meses: plan.months || 0,
      nu_nro_dias_preaviso: plan.noticeDays || 0,
      ch_gestion_contador: plan.countManagement,
      ls_det: plan.types.map(t => ({
        nu_id_tipo_mantenimiento: t.id,
        nu_costo: t.cost
      }))
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateMaintenancePlan = async plan => {
  try {
    const {data} = await axiosInstance.post("/VehiculoPlanMantenimiento/upd", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_plan_mantenimiento: plan.id,
      vc_desc_plan_mantenimiento: plan.name,
      nu_nro_kilometros: plan.kilometers || 0,
      nu_nro_kilometros_preaviso: plan.noticeKilometers || 0,
      nu_nro_horas: plan.engineHours || 0,
      nu_nro_horas_preaviso: plan.noticeHours || 0,
      nu_nro_meses: plan.months || 0,
      nu_nro_dias_preaviso: plan.noticeDays || 0,
      ch_gestion_contador: plan.countManagement,
      ls_det: plan.types.map(t => ({
        nu_id_tipo_mantenimiento: t.id,
        nu_costo: t.cost
      }))
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateMaintenancePlanStatus = async (plan, status) => {
  try {
    const {data} = await axiosInstance.post("/VehiculoPlanMantenimiento/del", {
      nu_id_plan_mantenimiento: plan.id,
      bi_estado: status
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
