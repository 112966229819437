import {
  ADD_MAINTENANCE_PLAN,
  APPLY_MAINTENANCE_PLANS_FILTERS,
  CLOSE_MAINTENANCE_PLANS_DIALOG,
  EDIT_MAINTENANCE_PLAN,
  FETCH_ALL_MAINTENANCE_PLANS,
  OPEN_MAINTENANCE_PLANS_DIALOG,
  RESET_CURRENT_MAINTENANCE_PLAN,
  RESET_MAINTENANCE_PLANS_FILTERS,
  RESET_MAINTENANCE_PLANS_STATE,
  SET_CURRENT_MAINTENANCE_PLAN
} from "../../types/FleetManagement/MaintenancePlansTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import MaintenancePlan from "../../../domain/FleetManagement/DailyOperations/MaintenancePlan";
import {
  createMaintenancePlan,
  getAllMaintenancePlans,
  updateMaintenancePlan,
  updateMaintenancePlanStatus
} from "../../../services/FleetManagement/DailyOperation/MaintenancePlansService";

let id = 0;
const plans = [
  new MaintenancePlan(id++, "Plan 1", true, 4, 2, 4, 4, 2, 4, [], 1),
  new MaintenancePlan(id++, "Plan 2", false, 4, 2, 4, 4, 2, 4, [], 1)
];

export const resetMaintenancePlansState = () => ({
  type: RESET_MAINTENANCE_PLANS_STATE
});
export const applyMaintenancePlansFilters = filters => ({
  type: APPLY_MAINTENANCE_PLANS_FILTERS,
  payload: filters
});
export const resetMaintenancePlansFilters = () => ({
  type: RESET_MAINTENANCE_PLANS_FILTERS
});
export const setCurrentMaintenancePlan = plan => ({
  type: SET_CURRENT_MAINTENANCE_PLAN,
  payload: plan
});
export const resetCurrentMaintenancePlan = () => ({
  type: RESET_CURRENT_MAINTENANCE_PLAN
});
export const openMaintenancePlansDialog = name => ({
  type: OPEN_MAINTENANCE_PLANS_DIALOG,
  payload: name
});
export const closeMaintenancePlansDialog = () => ({
  type: CLOSE_MAINTENANCE_PLANS_DIALOG
});

export const fetchAllMaintenancePlans = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllMaintenancePlans()
      .then(plans => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_MAINTENANCE_PLANS, payload: plans});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const addMaintenancePlan = plan => {
  return async dispatch => {
    dispatch(fetchStart());
    createMaintenancePlan(plan)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const newPlan = new MaintenancePlan(
          response.transactionKey,
          plan.name,
          true,
          plan.kilometers,
          plan.months,
          plan.engineHours,
          plan.noticeKilometers,
          plan.noticeHours,
          plan.noticeDays,
          plan.types,
          plan.countManagement
        );
        dispatch({type: ADD_MAINTENANCE_PLAN, payload: newPlan});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const editMaintenancePlan = plan => {
  return async dispatch => {
    dispatch(fetchStart());
    updateMaintenancePlan(plan)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const editPlan = new MaintenancePlan(
          response.transactionKey,
          plan.name,
          plan.status,
          plan.kilometers,
          plan.months,
          plan.engineHours,
          plan.noticeKilometers,
          plan.noticeHours,
          plan.noticeDays,
          plan.types,
          plan.countManagement
        );
        dispatch({type: EDIT_MAINTENANCE_PLAN, payload: editPlan});
        dispatch(resetCurrentMaintenancePlan());
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const editMaintenancePlanState = (plan, status) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateMaintenancePlanStatus(plan, status)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const editPlan = new MaintenancePlan(
          response.transactionKey,
          plan.name,
          status,
          plan.kilometers,
          plan.months,
          plan.engineHours,
          plan.noticeKilometers,
          plan.noticeHours,
          plan.noticeDays,
          plan.types,
          plan.countManagement
        );
        dispatch({type: EDIT_MAINTENANCE_PLAN, payload: editPlan});
        dispatch(resetCurrentMaintenancePlan());
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};
