import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import CorrectiveType from "../../../domain/FleetManagement/DailyOperations/CorrectiveType";
import Response from "../../../domain/Response";

export const getAllCorrectiveTypes = async () => {
  try {
    const {data} = await axiosInstance.post("/VehiculoTipoCorrectivo/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(d => CorrectiveType.map(d));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createCorrectiveType = async type => {
  try {
    const {data} = await axiosInstance.post("/VehiculoTipoCorrectivo/ins", {
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_tipo_correctivo: type.description
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateCorrectiveType = async type => {
  try {
    const {data} = await axiosInstance.post("/VehiculoTipoCorrectivo/upd", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_tipo_correctivo: type.id,
      vc_desc_tipo_correctivo: type.description
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateCorrectiveTypeStatus = async (type, status) => {
  try {
    const {data} = await axiosInstance.post("/VehiculoTipoCorrectivo/del", {
      nu_id_tipo_correctivo: type.id,
      bi_estado: status
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
