import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import React from "react";

export default {
  vehicle: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="form.field.error" />
    }
  ],
  type: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="form.field.error" />
    }
  ],
  expirationDate: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="form.field.error" />
    }
  ],
  noticeDays: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="form.field.error" />
    }
  ],
  file: [
    {rule: v => v.length > 0, message: <IntlMessages id="form.field.error" />}
  ]
};
