import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {dialogsList} from "./constants";
import {addOrganizationCard} from "../../../../redux/thunks/FleetManagement/Tachograph/cards";
import {closeDialog, setCurrentCard} from "../../../../redux/reducers/FleetManagement/tachographCards";
import FormDialog from "../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import CardForm from "./CardForm";

const AddCardDialog = () => {
    const id = 'add-card-dialog';
    const { dialogs, currentCard } = useSelector(({fleetManagement}) => fleetManagement.tachographCards);
    const open = dialogs.some(d => d === dialogsList.addCard);
    const dispatch = useDispatch();
    const onSubmit = (data) => {
        dispatch(addOrganizationCard({
            expirationDate: data.expirationDate,
            cardNumber: data.cardNumber,
            name: data.name,
            typeId: currentCard.typeId,
        }));
        handleOnClose();
    }
    const handleOnClose = () => {
        dispatch(closeDialog(dialogsList.addCard));
        dispatch(setCurrentCard(null));
    }
    return (
        <FormDialog
            id={id}
            title={<IntlMessages id='add' />}
            onClose={handleOnClose}
            isOpen={open}
        >
            {open && <CardForm
                id={id}
                initData={{
                    cardNumber: '',
                    name: '',
                    expirationDate: new Date(),
                }}
                onSubmit={onSubmit}
            />}
        </FormDialog>
    );
};

export default AddCardDialog;