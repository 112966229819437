import React from "react";
import {SortableContainer} from "react-sortable-hoc";
import {Box, Paper} from "@material-ui/core";
import HeaderCell from "./HeaderCell";

const HeadersList = SortableContainer(({headers, onChangeStarred}) => {
  return (
    <Box component={Paper}>
      {headers.map((header, index) => (
        <HeaderCell
          key={index}
          index={index}
          header={header}
          onChangeStarred={onChangeStarred}
        />
      ))}
    </Box>
  );
});

export default HeadersList;
