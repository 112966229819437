import React from "react";
import {useDispatch, useSelector} from "react-redux";
import FormDialog from "../../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import Form from "../Form";
import {dialogs} from "../../constants";
import {
  closeWorkshopRequestDialog, editWorkshopRequest,
  setCurrentWorkshopRequest
} from "../../../../../../redux/actions/FleetManagement/WorkshopRequests";
import {parseDateToInput} from "../../../../../../utils/dates";

const EditDialog = () => {
  const id = "edit-workshop-request-form";
  const {current, dialog: {name}} = useSelector(
    ({fleetManagement}) => fleetManagement.workshopRequests
  );
  const dispatch = useDispatch();
  const onEdit = data => {
    dispatch(editWorkshopRequest({id: current.id, ...data}));
    onClose();
  };
  const onClose = () => {
    dispatch(closeWorkshopRequestDialog());
    dispatch(setCurrentWorkshopRequest(null))
  };
  return (
    <FormDialog
      id={id}
      title={
        <IntlMessages id={"fleet.management.workshop.request.edit.title"} />
      }
      submitText={<IntlMessages id={"label.form.save"} />}
      cancelText={<IntlMessages id={"label.form.cancel"} />}
      isOpen={dialogs.EDIT_DIALOG === name}
      onClose={onClose}
      fullScreen
    >
      {(name === dialogs.EDIT_DIALOG && current) && (
        <Form
          initialData={{
            vehicle: current.vehicle.id || "",
            workshop: current.workshop.id || "",
            date: parseDateToInput(current.date) || "",
            status: current.status.id || "",
            responseDate: parseDateToInput(current.responseDate) || "",
            entranceDate: parseDateToInput(current.entranceDate) || "",
            amount: current.amount || "",
            tasks: current.tasks || [],
          }}
          id={id}
          onSubmit={onEdit}
        />
      )}
    </FormDialog>
  );
};

EditDialog.propTypes = {};

export default EditDialog;
