import React from "react";
import PageContainer from "../../../../../../components/Logistic/ReturnTypes/PageContainer";
import ReturnTypesTable from "../../../../../../components/Logistic/ReturnTypes/ReturnTypesTable";
import {useDispatch, useSelector} from "react-redux";
import AddReturnType from "../../../../../../components/Logistic/ReturnTypes/Dialogs/AddReturnType";
import EditReturnType from "../../../../../../components/Logistic/ReturnTypes/Dialogs/EditReturnType";
import FloatingButtonWithAdd from "../../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import Confirmation from "../../../../../../components/Common/Dialogs/Confirmation";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {
  editReturnTypeState,
  resetCurrentReturnType,
  resetReturnTypesState
} from "../../../../../../redux/actions/Logistic/ReturnTypes";

const ReturnTypes = props => {
  const dispatch = useDispatch();
  const {current} = useSelector(({logistic}) => logistic.returnTypes);
  const [ openAdd, setOpenAdd ] = React.useState(false);
  const [ openEdit, setOpenEdit ] = React.useState(false);
  const [ openDelete, setOpenDelete ] = React.useState(false);
  const [ openRestore, setOpenRestore ] = React.useState(false);
  const onDelete = () => {
    dispatch(editReturnTypeState(current, false));
    setOpenDelete(false);
  };
  const onCancelDelete = () => {
    dispatch(resetCurrentReturnType());
    setOpenDelete(false);
  };
  const onRestore = () => {
    dispatch(editReturnTypeState(current, true));
    setOpenRestore(false);
  };
  const onCancelRestore = () => {
    dispatch(resetCurrentReturnType());
    setOpenRestore(false);
  };
  React.useEffect(() => {
    return () => {
      dispatch(resetReturnTypesState());
    };
  }, []);
  return (
    <PageContainer>
      <ReturnTypesTable
        openEdit={() => setOpenEdit(true)}
        openRestore={() => setOpenRestore(true)}
        openDelete={() => setOpenDelete(true)}
      />
      <AddReturnType addOpen={openAdd} setAddOpen={setOpenAdd} />
      <EditReturnType editOpen={openEdit} setEditOpen={setOpenEdit} />
      <FloatingButtonWithAdd onAddClicked={() => setOpenAdd(true)} />
      <Confirmation
        text={
          <IntlMessages
            id={"logistic.distribution.return.types.delete.description"}
          />
        }
        open={openDelete}
        onAccept={onDelete}
        onReject={onCancelDelete}
      />
      <Confirmation
        text={
          <IntlMessages
            id={"logistic.distribution.return.types.restore.description"}
          />
        }
        open={openRestore}
        onAccept={onRestore}
        onReject={onCancelRestore}
      />
    </PageContainer>
  );
};

export default ReturnTypes;
