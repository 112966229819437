import StyledTableRow from "../../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import React from "react";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages
        id={"rrhh.employees.documentation.list.mode.table.expiration.date"}
      />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages
        id={"rrhh.employees.documentation.list.mode.table.active"}
      />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"form.button.download"} />
    </StyledTableCell>
  </StyledTableRow>
);

export default TableHeader;
