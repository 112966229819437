import React, {useEffect} from "react";
import Filters from "./Filters";
import {connect} from "react-redux";
import useTableSort from "../../../../../hooks/Common/useTableSort";
import FilterBuilder from "../../../../../utils/filters";
import TableContainer from "../../../../Common/Tables/TableContainer";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import {getAllProviders} from "../../../../../redux/thunks/Logistic/providers";


const Table = ({data, dispatch}) => {
  const {tableData, onSort, order, orderBy} = useTableSort(data);
  useEffect(() => {
      dispatch(getAllProviders());
  }, []);
  return (
    <React.Fragment>
      <Filters />
      <TableContainer
          head={<TableHeader onSort={onSort} order={order} orderBy={orderBy} />}
      >
        {tableData.map(provider => (
            <TableBody key={provider.id} provider={provider} />
        ))}
      </TableContainer>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const {data, filters} = state.logistic.providers;
  const filtered = new FilterBuilder(Object.values(data))
    .byFieldIncludes("name", filters.name)
    .byFieldIncludes("providerCode", filters.providerCode)
    .byFieldIncludes("businessName", filters.businessName)
    .build();
  return {data: filtered};
};

export default connect(mapStateToProps)(Table);
