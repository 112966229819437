import {useDispatch} from "react-redux";
import {fetchError, fetchStart, fetchSuccess} from "../../../redux/actions";
import {getBudgetServiceCalc} from "../../../services/Treasury/BillsService";

export const useCalcService = () => {
    const dispatch = useDispatch();
    const calcService = async (service) => {
        try {
            dispatch(fetchStart());
            const serviceResponse = await getBudgetServiceCalc({service});
            dispatch(fetchSuccess());
            return {
                serviceResponse
            };
        }catch (e) {
            dispatch(fetchError(e.message));
        }
    }

    return {
        calcService,
    };
}