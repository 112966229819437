import React, {useEffect} from 'react';
import useForm from "../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import VehicleSelects from "./VehicleSelects";
import DriverSelect from "./DriverSelect";

const initialData = {
    driver: '',
    head: '',
    trailer: ''
}
const Form = ({id, onSubmit, setDisabled}) => {
    const {formData, setFormData, handleSubmit,} = useForm(initialData);
    useEffect(() => {
        setDisabled(!Object.values(formData).some(value => value));
    }, [formData]);
    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <VehicleSelects
                    head={formData.head}
                    trailer={formData.trailer}
                    setHead={value => setFormData('head', value)}
                    setTrailer={value => setFormData('trailer', value)}
                />
                <DriverSelect
                    value={formData.driver}
                    setValue={value => setFormData('driver', value)}
                />
            </GridContainer>
        </form>
    );
};

export default Form;