import React from 'react';
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {
    setCurrentConfigurationVehicleRole,
    updateConfigurationVehicleRole,
    updateConfigurationVehicleRoleData
} from "../../../../../redux/actions/Configuration/VehicleRoles";
import VehiclesForm from "./VehiclesForm";
import {useDispatch, useSelector} from "react-redux";
import DataForm from "./DataForm";
import Tabs from "../../../../Common/Tabs";

const EditVehicleRoleDialog = ({openEdit, setOpenEdit}) => {
    const id = 'edit-vehicle-role-form';
    const {current} = useSelector(({configuration}) => configuration.vehicleRoles);
    const [selected, setSelected] = React.useState('1');
    const dispatch = useDispatch();
    const onEditRoleVehicles = data => {
        const selected = data.selected.filter(v => !v.state).map(v => ({id: v.id, state: true}))
        const vehicles = data.vehicles.filter(v => v.state).map(v => ({id: v.id, state: false}));
        dispatch(updateConfigurationVehicleRole(current, [...selected, ...vehicles]));
        setOpenEdit(false);
    }
    const onEditRoleData = data => {
        dispatch(updateConfigurationVehicleRoleData(current, data));
        setOpenEdit(false);
    }
    const options = [
        {
            value: '1',
            label: <IntlMessages id={"configuration.user.settings.vehicle.roles.edit.information.title"}/>,

        },
        {
            value: '2',
            label: <IntlMessages id={"configuration.user.settings.vehicle.roles.edit.vehicles.title"}/>,

        }
    ];
    const renderForm = () => {
        if(selected === '1') {
            return (
                <DataForm
                    id={id}
                    initialData={{
                        description: current?.description,
                    }}
                    onEditRole={onEditRoleData}
                />
            )
        }else {
            return (
                <VehiclesForm
                    id={id}
                    initialData={{
                        vehicles: current.vehicles.filter(v => !v.state),
                        selected: current.vehicles.filter(v => v.state)
                    }}
                    onEditRole={onEditRoleVehicles}
                />
            )
        }
    }
    return (
        <FormDialog
            id={id}
            isOpen={openEdit}
            onClose={() => {
                setOpenEdit(false);
                dispatch(setCurrentConfigurationVehicleRole(null));
            }}
            title={<IntlMessages id={"configuration.user.settings.vehicle.roles.edit.title"}/>}
            submitText={<IntlMessages id={"label.form.save"}/>}
            cancelText={<IntlMessages id={"label.form.cancel"}/>}
        >
            {current?.vehicles &&
                <Tabs
                    value={selected}
                    onChange={setSelected}
                    tabs={options}
                >
                    {renderForm()}
                </Tabs>
            }
        </FormDialog>
    );
};

export default EditVehicleRoleDialog;