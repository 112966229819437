import React from "react";

import {Grid} from "@material-ui/core";
import validations from "./validations";
import useForm from "../../../../../../../../hooks/Common/useForm";
import FleetManagementFile from "../../../../../../../../domain/FleetManagementFile";
import FormDialog from "../../../../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import GridContainer from "../../../../../../../../@jumbo/components/GridContainer";

const initialData = {
  file: null
};

const FilesDialog = ({id, listFiles, setListFiles, open, setOpen}) => {
  const {formData, setFormData, errors, handleSubmit} = useForm(
    initialData,
    validations
  );
  const onAddNewFiles = data => {
    const newFile = new FleetManagementFile(
      0,
      "",
      data.file.name,
      "",
      "",
      "",
      data.file.file || data.file,
      ""
    );
    setListFiles([ ...listFiles, newFile ]);
    setOpen(false);
  };

  const onSaveFile = data => {
    onAddNewFiles(data);
  };

  return (
    <FormDialog
      id={id}
      open={open}
      onClose={() => setOpen(false)}
      title={
        <IntlMessages id={"fleet.management.common.form.files.add.title"} />
      }
      submitText={<IntlMessages id={"form.button.save"} />}
      cancelText={<IntlMessages id={"form.button.cancel"} />}
    >
      <form id={id} onSubmit={handleSubmit(onSaveFile)}>
        <GridContainer spacing={2}>
          <Grid item xs={12}>
            <input
              id={"new-file-input"}
              type="file"
              onChange={e => {
                const file = e.target.files[0];
                setFormData("file", file);
              }}
            />
          </Grid>
        </GridContainer>
      </form>
    </FormDialog>
  );
};

export default FilesDialog;
