import React from "react";
import {useDispatch, useSelector} from "react-redux";
import FormDialog from "../../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import Form from "../Form";
import {dialogs} from "../../constants";
import {
  addVehicleDocumentsType,
  closeVehicleDocumentsTypesDialog
} from "../../../../../../redux/actions/FleetManagement/DocumentsTypes";

const AddDialog = () => {
  const id = "add-documents-types-form";
  const {dialog: {name}} = useSelector(
    ({fleetManagement}) => fleetManagement.documentsTypes
  );
  const dispatch = useDispatch();
  const onAdd = data => {
    dispatch(addVehicleDocumentsType(data));
    onClose();
  };
  const onClose = () => {
    dispatch(closeVehicleDocumentsTypesDialog());
  };
  return (
    <FormDialog
      id={id}
      title={
        <IntlMessages
          id={"fleet.management.vehicles.documents.types.add.title"}
        />
      }
      submitText={<IntlMessages id={"label.form.save"} />}
      cancelText={<IntlMessages id={"label.form.cancel"} />}
      isOpen={name === dialogs.ADD_DIALOG}
      onClose={onClose}
    >
      {name === dialogs.ADD_DIALOG && (
        <Form
          add
          initialData={{
            description: "",
            securityControl: false
          }}
          id={id}
          onSubmit={onAdd}
        />
      )}
    </FormDialog>
  );
};

AddDialog.propTypes = {};

export default AddDialog;
