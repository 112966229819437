import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {dialogsList} from "./constants";
import {closeDialog} from "../../../redux/reducers/Treasury/taxes";
import {updateTax} from "../../../redux/thunks/Treasury/taxes";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import FormDialog from "../../Common/Dialogs/FormDialog";
import Form from "./Form";

const EditDialog = () => {
    const id = 'edit-tax-form';
    const {dialogs, currentTax} = useSelector(({treasury}) => treasury.taxes);
    const dispatch = useDispatch();
    const open = dialogs.some(dialog => dialog === dialogsList.EDIT_TAX);
    const onSubmit = (data) => {
        dispatch(updateTax({
            request: {
                ...currentTax,
                ...data,
            }
        }));
        onClose();
    };
    const onClose = () => {
        dispatch(closeDialog(dialogsList.EDIT_TAX));
    }
    return (
        <>
            <FormDialog
                id={id}
                isOpen={open}
                onClose={onClose}
                title={<IntlMessages id="edit"/>}
            >
                {open && <Form id={id} onSubmit={onSubmit} initData={currentTax}/>}
            </FormDialog>
        </>
    );
};

export default EditDialog;