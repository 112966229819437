import React from "react";
import {useStyles} from "./styles";
import TextField from "@material-ui/core/TextField";
import IntlMessages from "../../../../utils/IntlMessages";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {Link} from "@material-ui/core";
import {useDispatch} from "react-redux";
import CognitoAuth from "../../../../../services/auth/aws";

const ResendCodeForm = ({formData, setFormData, onSend}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const onSubmit = () => {
    dispatch(CognitoAuth.onResendSignUp(formData.email, onSend));
  };
  return (
    <form>
      <div className={"mb-5"}>
        <TextField
          label={<IntlMessages id="appModule.email" />}
          fullWidth
          onChange={event => setFormData("email", event.target.value)}
          defaultValue={formData.email}
          margin="normal"
          variant="outlined"
          className={classes.textFieldRoot}
        />
      </div>
      <div className={"mb-5"}>
        <Button onClick={onSubmit} variant="contained" color="primary">
          <IntlMessages id="auth.resend.sign.up.send.code" />
        </Button>
      </div>
      <div>
        <Typography>
          Don't remember your email?
          <span className={"ml-2"}>
            <Link href="#">
              <IntlMessages id="appModule.contactSupport" />
            </Link>
          </span>
        </Typography>
      </div>
    </form>
  );
};

export default ResendCodeForm;
