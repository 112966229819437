import React from 'react';
import GridContainer from "../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../../Common/Forms/OutlinedInput";
import {useIntl} from "react-intl";
import OutlinedDatePicker from "../../../Common/Forms/OutlinedDatePicker";
import {IconButton} from "@material-ui/core";
import {openDialog, setCurrentCard} from "../../../../redux/reducers/FleetManagement/tachographCards";
import EditIcon from "@material-ui/icons/Edit";
import {removeOrganizationCard} from "../../../../redux/thunks/FleetManagement/Tachograph/cards";
import DeleteIcon from "@material-ui/icons/Delete";
import {useDispatch} from "react-redux";
import {dialogsList} from "./constants";
import {parseDateToInput} from "../../../../utils/dates";

const OrganizationCard = ({card}) => {
    const {cardNumber, expirationDate, name} = card;
    const intl = useIntl();
    const dispatch = useDispatch();
    const onEdit = (card) => {
        dispatch(setCurrentCard(card));
        dispatch(openDialog(dialogsList.editCard));
    }
    return (
        <GridContainer spacing={2}>
            <Grid item xs={12} md={3}>
                <OutlinedInput
                    label={intl.messages['name']}
                    value={name}
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <OutlinedInput
                    label={intl.messages['card.number']}
                    value={cardNumber}
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <OutlinedDatePicker
                    label={intl.messages['expiration.date']}
                    value={parseDateToInput(expirationDate)}
                    fullWidth
                    disabled
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <IconButton onClick={() => onEdit(card)}>
                    <EditIcon />
                </IconButton>
                <IconButton onClick={() => dispatch(removeOrganizationCard(card))}>
                    <DeleteIcon />
                </IconButton>
            </Grid>
        </GridContainer>
    );
};

export default OrganizationCard;