import React from "react";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import PageContainerComponent from "../../../Common/Layouts/PageContainer";
import PropTypes from "prop-types";
const breadcrumbs = [
  {
    label: <IntlMessages id={"sidebar.workday.dailyOperations"} />,
    link: "/rrhh/workers/"
  },
  {
    label: <IntlMessages id={"rrhh.employees.documentation.sidebar"} />,
    isActive: true
  }
];

const PageContainer = ({children}) => (
  <PageContainerComponent
    heading={<IntlMessages id="rrhh.employees.documentation.title" />}
    description={<IntlMessages id="rrhh.employees.documentation.description" />}
    breadcrumbs={breadcrumbs}
  >
    {children}
  </PageContainerComponent>
);

PageContainer.propTypes = {
  children: PropTypes.node
};

export default PageContainer;
