import React from 'react';
import {DataGrid} from "@mui/x-data-grid";
import {useIntl} from "react-intl";
import {Box, IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ContainedButton from "../../../../Common/Buttons/ContainedButton";
import ServiceDialog from "./ServiceDialog";
import {parseNumberToMoney} from "../../../../../utils/parsers";
import ShowIcon from "@material-ui/icons/Visibility";

const ServicesTable = ({services = {}, setServices, disableEdit = false}) => {
    const servicesList = Object.values(services);
    const [service, setService] = React.useState(null);
    const intl = useIntl();
    const handleEditService = (service) => {
        setService({
            ...service,
            isConcept: !service.serviceId
        });
    }
    const handleDeleteService = (service) => {
        const newServices = services.filter(s => s.id !== service.id);
        setServices(newServices);
    }
    const columns = [
        {
            field: 'id',
            hide: true
        },
        {
            field: 'serviceName',
            headerName: `${intl.messages['service']} / ${intl.messages['concept']}`,
            width: 200,
        },
        {
            field: 'description',
            headerName: intl.messages['description'],
            width: 200,
        },
        {
            field: 'quantity',
            headerName: intl.messages['quantity'],
            width: 200,
        },
        {
            field: 'unitPrice',
            headerName: intl.messages['unit.price'],
            valueFormatter: ({value}) => parseNumberToMoney(value),
            width: 200,
        },
        {
            field: 'discount',
            headerName: `% ${intl.messages['discount']}`,
            width: 200,
        },
        {
            field: 'taxAmount',
            headerName: intl.messages['tax'],
            width: 200,
            valueFormatter: ({row}) => {
                if(row.taxAmount) return `${row.taxAmount}`;
                const taxAmount = (row.unitPrice * row.quantity) * (1 - row.discount/100) * (row.percentage / 100) ;
                return parseNumberToMoney(taxAmount);
            },
        },
        {
            field: 'total',
            headerName: intl.messages['total'],
            valueFormatter: ({row}) => {
                if(row.total) return parseNumberToMoney(row.total);
                const subtotal = (row.unitPrice * row.quantity) * (1 - row.discount/100) * (1 + row.percentage / 100);
                return parseNumberToMoney(subtotal);
            },
            width: 200,
        },
        {
            field: 'actions',
            headerName: intl.messages["table.edit"],
            type: 'actions',
            renderCell: ({row}) => {
                return disableEdit
                    ? <IconButton onClick={() => handleEditService(row)}>
                        <ShowIcon/>
                    </IconButton>
                    :(
                        <>
                            <IconButton onClick={() => handleEditService(row)}>
                                <EditIcon/>
                            </IconButton>
                            <IconButton onClick={() => handleDeleteService(row)}>
                                <DeleteIcon/>
                            </IconButton>
                        </>
                    )
            }
        }
    ];
    const onSubmit = (data) => {
        const serviceIndex = services.findIndex(s => s.id === data.id);
        if(serviceIndex !== -1){
            services[serviceIndex] = data;
            setServices(services);
            return;
        }
        const newServices = [
            ...services,
            data,
        ];
        setServices(newServices);
    }
    return (
        <>
            {!disableEdit &&
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '1rem',
                        margin: '1rem 0',
                    }}
                >
                    <ContainedButton
                        text={`${intl.messages['add']} ${intl.messages['service']}`}
                        color="primary"
                        onClick={() => setService({isConcept: false})}
                    />
                    <ContainedButton
                        text={`${intl.messages['add']} ${intl.messages['concept']}`}
                        color="primary"
                        onClick={() => setService({isConcept: true})}
                    />
                </Box>
            }
            <DataGrid
                columns={columns}
                rows={servicesList}
                style={{
                    height: '40vh'
                }}
                disableColumnMenu
                disableSelectionOnClick
            />
            <ServiceDialog
                service={service}
                onClose={() => setService(null)}
                handleSubmit={onSubmit}
                disableEdit={disableEdit}
            />
        </>
    );
};

export default ServicesTable;