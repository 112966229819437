import React from "react";
import useForm from "../../../../../../hooks/Common/useForm";
import {Box, Divider} from "@material-ui/core";
import EmployeeInfoArea from "./InfoArea";
import FileInfoArea from "./FileInfoArea";
import EmployeeDocumentsList from "./EmployeeDocumentsList";

const Form = ({id, initialData, onSubmit, edit = false, validations = {}}) => {
  const {formData, setFormData, handleSubmit, errors} = useForm(
    initialData,
    validations
  );
  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" style={{gap: "1em"}}>
        <EmployeeInfoArea
          vehicle={formData.employee}
          type={formData.type}
          setFormData={setFormData}
          edit={edit}
          errors={errors}
        />
        <Divider />
        <FileInfoArea
          formData={formData}
          setFormData={setFormData}
          edit={edit}
          errors={errors}
        />
        <Divider />
        {formData.employee &&
        formData.type && (
          <EmployeeDocumentsList
            employee={formData.employee}
            type={formData.type}
          />
        )}
      </Box>
    </form>
  );
};

export default Form;
