import {useSelector} from "react-redux";
import {useStyles} from "../../../../FleetManagement/Vehicles/Documentation/SummaryMode/DocumentsTable/styles";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import TableCellWithSorting from "../../../../Common/Tables/TableCellWithSorting";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import React from "react";

const DocumentsHeader = ({order, orderBy, onSort}) => {
  const {headers} = useSelector(({rrhh}) => rrhh.documentation.summary);
  const classes = useStyles();

  return (
    <StyledTableRow>
      <TableCellWithSorting
        property="employee"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
        className={classes.stickyHeaderCell}
      >
        <IntlMessages
          id={"rrhh.employees.documentation.summary.mode.table.employee"}
        />
      </TableCellWithSorting>
      {Object.values(headers)
        .filter(h => h.active)
        .map((header, index) => (
          <StyledTableCell key={index}>{header.label}</StyledTableCell>
        ))}
    </StyledTableRow>
  );
};

export default DocumentsHeader;
