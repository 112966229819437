import React from 'react';
import {dialogsList} from "./constants";
import {useDispatch, useSelector} from "react-redux";
import ProviderOrderForm from "./ProviderOrderForm";
import {closeDialog} from "../../../../redux/reducers/Logistic/providerOrders";
import {cloneDeep} from "lodash";
import FullScreenDialog from "../../../Common/Dialogs/FullScreenDialog";

const EditProviderOrderDialog = () => {
    const id = dialogsList.editOrderDialog;
    const {dialogs, currentOrder} = useSelector(({logistic}) => logistic.providerOrders);
    const dispatch = useDispatch();
    const isOpen = dialogs.some(dialog => dialog === id);
    const editable = currentOrder?.editable;
    const onSubmit = () => {
        onClose();
    }
    const onClose = () => {
        dispatch(closeDialog(id));
    }
    return (
        <FullScreenDialog
            open={isOpen}
            title="edit"
            handleClose={onClose}
        >
            {(isOpen && currentOrder) && <ProviderOrderForm
                onSubmit={onSubmit}
                initData={cloneDeep(currentOrder)}
                editable={editable}
            />}
        </FullScreenDialog>
    );
};

export default EditProviderOrderDialog;