import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {fetchCarbonFootprintData} from "../../../../redux/actions/Logistic/CarbonFootprint";
import {useIntl} from "react-intl";
import {DataGrid} from "@mui/x-data-grid";
import ExportToolbar from "../../../Common/Tables/DataGrid/ExportToolbar";

const Table = () => {
    const {data, filters} = useSelector(({logistic}) => logistic.carbonFootprint);
    const dispatch = useDispatch();
    const intl = useIntl();
    useEffect(() => {
        if(Object.values(filters).some(v => v)) {
            dispatch(fetchCarbonFootprintData(filters));
        }
    }, [filters])
    const getColumns = useCallback(() => {
        const columns = [
            {
                field: 'id',
                hide: true,
            },
            {
                field: 'saleOrganization',
                headerName: intl.messages['sale.organization'],
                width: 180

            },
            {
                field: 'client',
                headerName: intl.messages['client'],
                width: 180
            },
            {
                field: 'deliveries',
                headerName: intl.messages['deliveries'],
                width: 150
            },
            {
                field: 'liters',
                headerName: intl.messages['total.liters'],
                width: 150
            },
            {
                field: 'tons',
                headerName: intl.messages['total.tons'],
                width: 150
            },
            {
                field: 'goingDeliveryKms',
                headerName: `${intl.messages['delivery.kms']} ${intl.messages['going.loaded']}`,
                width: 150
            },
            {
                field: 'goingTotalKms',
                headerName: `${intl.messages['total.kms']} ${intl.messages['going.loaded']}`,
                width: 150
            },
            {
                field: 'returnDeliveryKms',
                headerName: `${intl.messages['delivery.kms']} ${intl.messages['return.void']}`,
                width: 150
            },
             {
                field: 'returnTotalKms',
                 headerName: `${intl.messages['total.kms']} ${intl.messages['return.void']}`,
                width: 150
            },
             {
                field: 'goingGOConsumption',
                 headerName: `${intl.messages['go.consumption']} ${intl.messages['going.loaded']}`,
                width: 150
            },
            {
                field: 'returnGOConsumption',
                 headerName: `${intl.messages['go.consumption']} ${intl.messages['return.void']}`,
                width: 150
            },
             {
                field: 'co2Kg',
                headerName: intl.messages['kg.co2'],
                width: 150
            },
              {
                field: 'tnKms',
                headerName: intl.messages['tn.km'],
                width: 150
            },
              {
                field: 'emissionIntensity',
                headerName: intl.messages['emission.intensity'],
                width: 150
            },

        ];
        return columns;
    }, [intl]);
    return (
        <DataGrid
            columns={getColumns()}
            rows={data}
            disableSelectionOnClick
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            rowHeight={40}
            checkboxSelection
            components={{
                Toolbar: ExportToolbar,
            }}
        />
    );
};

export default Table;