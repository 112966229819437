import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {
    APPLY_VEHICLES_MAINTENANCES_FILTERS, CLOSE_VEHICLES_MAINTENANCES_VIEWS_DIALOG,
    FETCH_ALL_VEHICLES_MAINTENANCES,
    FETCH_ALL_VEHICLES_MAINTENANCES_VIEWS, OPEN_VEHICLES_MAINTENANCES_VIEWS_DIALOG,
    RESET_VEHICLES_MAINTENANCES_FILTERS,
    RESET_VEHICLES_MAINTENANCES_STATE
} from "../../types/FleetManagement/VehicleMaintencesTypes";
import {
    createVehicleMaintenancesView, editVehicleMaintenancesHeaders,
    getAllVehicleMaintenances,
    getAllVehicleMaintenancesHeaders, getAllVehicleMaintenancesViews
} from "../../../services/FleetManagement/DailyOperation/VehicleMaintenancesService";

const data = [
    {
        id: 1,
        vehicle: "ABC-123",
        1: 1,
        2: 1,
        maintenance: [1, 1],
        tags: [1, 2]
    }
]

const headers = [
    {
        id: 1,
        label: "Tipo 1",
        field: 1,
        active: true,
        order: 1
    },
    {
        id: 2,
        label: "Tipo 2",
        field: 2,
        active: false,
        order: 2
    }
]

const views =  [
    {
        id: 1,
        name: 'View 1',
        status: true,
        isPublic: true,
        predetermined: true,
        editable: true
    }
]

export const openVehicleMaintenancesDialog = dialog => ({type: OPEN_VEHICLES_MAINTENANCES_VIEWS_DIALOG, payload: dialog});
export const closeVehicleMaintenancesDialog = () => ({type: CLOSE_VEHICLES_MAINTENANCES_VIEWS_DIALOG});
export const applyVehicleMaintenancesFilters = filters => ({
    type: APPLY_VEHICLES_MAINTENANCES_FILTERS,
    payload: filters
});
export const resetVehicleMaintenancesFilters = () => ({type: RESET_VEHICLES_MAINTENANCES_FILTERS});
export const fetchAllVehicleMaintenances = (view,  views) => {
    return async dispatch => {
        if(view !== ''){
            dispatch(fetchStart());
            try {
                const data = await getAllVehicleMaintenances(view);
                const headers = await getAllVehicleMaintenancesHeaders(view);
                dispatch(fetchSuccess());
                dispatch({
                    type: FETCH_ALL_VEHICLES_MAINTENANCES,
                    payload: {
                        data,
                        headers,
                        selectedInfo: {
                            viewId: view,
                            editable: views.find(v => v.id === view)?.editable,
                            isPublic: views.find(v => v.id === view)?.isPublic,
                            name: views.find(v => v.id === view)?.name,
                            predetermined: views.find(v => v.id === view)?.predetermined
                        }
                    }
                });
            }catch (e) {
                dispatch(fetchError(e.message));
            }
        }
        else {
            dispatch({type: RESET_VEHICLES_MAINTENANCES_STATE});
        }
    }
}

export const fetchAllVehicleMaintenancesViews = () => {
    return async dispatch => {
        dispatch(fetchStart());
        try {
            dispatch(fetchSuccess());
            const views = await getAllVehicleMaintenancesViews();
            dispatch({type: FETCH_ALL_VEHICLES_MAINTENANCES_VIEWS, payload: views});
        }catch (e) {
            dispatch(fetchError(e.message));
        }
    }
}

export const addVehicleMaintenanceView = data => {
    return async dispatch => {
        dispatch(fetchStart());
        createVehicleMaintenancesView(data)
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                setTimeout(() => {
                    dispatch(fetchAllVehicleMaintenancesViews());
                }, 500);
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    }
}

export const editVehicleMaintenanceHeaders = (view, data, filters, views) => {
    return async dispatch => {
        dispatch(fetchStart());
        editVehicleMaintenancesHeaders({view, ...data})
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                setTimeout(() => {
                    dispatch(fetchAllVehicleMaintenancesViews(filters, views));
                }, 500);
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    }
}