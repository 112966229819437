import axiosInstance from "../../../../utils/axiosInstance";
import {mapToBoolean} from "../../../../utils/mappers";

export const fetchAllUbigeo = async () => {
    try {
        const {data} = await axiosInstance.get("/Ubigeo/sel", );
        return data.map(ubigeo => ({
            id: ubigeo.nu_id_ubigeo,
            name: ubigeo.vc_desc_ubigeo_completa,
            state: mapToBoolean(ubigeo.bi_estado),
        }));
    } catch (e) {
        return Promise.reject(e);
    }
};