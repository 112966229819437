import React, {useEffect} from "react";
import FiltersContainer from "../../../../../Common/Forms/FiltersContainer";
import useForm from "../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {useDispatch} from "react-redux";
import StatusSelect from "./StatusSelect";
import TagsPicker from "../../../../Vehicles/File/VehicleDetailDialog/BasicFile/Form/TagsPicker";
import {applyVehicleCorrectivesFilters} from "../../../../../../redux/actions/FleetManagement/VehicleCorrectives";
import CorrectiveTypesSelect from "../../../Common/Selects/CorrectiveTypesSelect";

const initialData = {
  alias: "",
  plaque: "",
  tags: [],
  type: "",
  status: ""
};

const Filters = () => {
  const {formData, setFormData} = useForm(initialData);
  const dispatch = useDispatch();
  useEffect(
    () => {
      dispatch(applyVehicleCorrectivesFilters(formData));
    },
    [ formData ]
  );
  return (
    <FiltersContainer active marginBottom={5} onClose={() => {}}>
      <form>
        <GridContainer spacing={4}>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.alias}
              id="alias-filter"
              label={
                <IntlMessages id="fleet.management.vehicle.corrective.table.alias" />
              }
              onChange={value => setFormData("alias", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.plaque}
              id="plaque-filter"
              label={
                <IntlMessages id="fleet.management.vehicle.corrective.table.plaque" />
              }
              onChange={value => setFormData("plaque", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <TagsPicker
              value={formData.tags}
              onChange={value => setFormData("tags", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <CorrectiveTypesSelect
              value={formData.type}
              id="type-filter"
              onChange={value => setFormData("type", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <StatusSelect
              value={formData.status}
              id="status-filter"
              onChange={value => setFormData("status", value)}
            />
          </Grid>
        </GridContainer>
      </form>
    </FiltersContainer>
  );
};

Filters.propTypes = {};

export default Filters;
