import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import BaseDialog from "../../Common/BaseDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {closeManufacturersDialog} from "../../../../../../redux/reducers/ElevatorsManagement/manufacturers";
import {dialogsList} from "../../Common/constants";
import {editManufacturer} from "../../../../../../redux/thunks/ElevatorManagement/manufacturers";

const EditDialog = () => {
    const {current, dialogs} = useSelector(({elevatorsManagement}) => elevatorsManagement.manufacturers);
    const dispatch = useDispatch();
    const onSubmit = data => {
        dispatch(editManufacturer({current, request: data}));
        dispatch(closeManufacturersDialog(dialogsList.editDialog));
    }
    return(
        <BaseDialog
            id='edit-ken-form'
            title={<IntlMessages id="edit"/>}
            open={dialogs.some(dialog => dialog === dialogsList.editDialog)}
            setOpen={() => dispatch(closeManufacturersDialog(dialogsList.editDialog))}
            initData={{
                name: current?.name,
            }}
            onSubmit={onSubmit}
        />
    );
};

export default EditDialog;