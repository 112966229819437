import React from 'react';
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import PageContainerComponent from "../../../Common/Layouts/PageContainer";

const breadcrumbs = [
    {
        label: <IntlMessages id={"sidebar.cash.management"} />,
        link: "/treasury/cash-management/operative"
    },
    {
        label: <IntlMessages id={"petty.cash.refund"} />,
        isActive: true
    }
];
const PageContainer = ({children}) => {
    return (
        <PageContainerComponent
            heading={<IntlMessages id={"petty.cash.refund.title"} />}
            description={<IntlMessages id="petty.cash.refund.description" />}
            breadcrumbs={breadcrumbs}
        >
            {children}
        </PageContainerComponent>
    );
};

export default PageContainer;