import React from 'react';
import Select from "../../../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";

const DocumentTypeSelect = ({value, onChange, disabled, types, errors}) => {

    return (
        <Select
            id='vehicle-document-type-select'
            label={<IntlMessages id={ "fleet.management.vehicles.documentation.list.mode.detail.form.type"} />}
            value={value}
            onChange={e => onChange(e.target.value)}
            fullWidth
            disabled={disabled}
            error={errors?.type?.length>0}
            errorMessage={errors?.type[0]}
        >
            <MenuItem value={''} />
            {types.map(type => (
                <MenuItem value={type.id} key={type.id}>
                    {type.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default DocumentTypeSelect;