import React from "react";
import useForm from "../../../../../../hooks/Common/useForm";
import TypesTable from "./TypesTable";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Box, Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import AddType from "./AddType";
import TypeFormDialog from "./TypeFormDialog";
import CountManagementSelect from "./CountManagementSelect";

const Form = ({initialData, id, onSubmit}) => {
  const {formData, setFormData, handleSubmit, errors} = useForm(initialData);
  const [ selectedType, setSelectedType ] = React.useState(null);
  const onAddTypes = type => {
    if (!formData.types.find(t => t.id === type.id)) {
      setFormData("types", [ ...formData.types, type ]);
    }
  };
  const onCloseEditTypeDialog = () => {
    setSelectedType(null);
  };
  const onSubmitEditType = data => {
    const updateTypes = formData.types.map(type => {
      if (selectedType.id !== type.id) {
        return type;
      } else {
        return {
          ...type,
          cost: data.cost
        };
      }
    });
    setFormData("types", updateTypes);
    onCloseEditTypeDialog();
  };
  const onRemoveType = type => {
    setFormData("types", formData.types.filter(t => t.id !== type.id));
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        gap: 5
      }}
    >
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <GridContainer spacing={1}>
          <Grid item xs={12}>
            <OutlinedInput
              id={id + "name"}
              label={
                <IntlMessages
                  id={"fleet.management.maintenance.plan.form.name"}
                />
              }
              value={formData.name}
              onChange={value => setFormData("name", value)}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <OutlinedInput
              id={id + "kilometers"}
              label={
                <IntlMessages
                  id={"fleet.management.maintenance.plan.form.kilometers"}
                />
              }
              value={formData.kilometers}
              onChange={value => setFormData("kilometers", value)}
              fullWidth
              type="number"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <OutlinedInput
              id={id + "-notice-kilometers"}
              label={
                <IntlMessages
                  id={
                    "fleet.management.maintenance.plan.form.notice.kilometers"
                  }
                />
              }
              value={formData.noticeKilometers}
              onChange={value => setFormData("noticeKilometers", value)}
              fullWidth
              type="number"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <OutlinedInput
              id={id + "engine-hours"}
              label={
                <IntlMessages
                  id={"fleet.management.maintenance.plan.form.engine.hours"}
                />
              }
              value={formData.engineHours}
              onChange={value => setFormData("engineHours", value)}
              fullWidth
              type="number"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <OutlinedInput
              id={id + "notice-hours"}
              label={
                <IntlMessages
                  id={"fleet.management.maintenance.plan.form.notice.hours"}
                />
              }
              value={formData.noticeHours}
              onChange={value => setFormData("noticeHours", value)}
              fullWidth
              type="number"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <OutlinedInput
              id={id + "months"}
              label={
                <IntlMessages
                  id={"fleet.management.maintenance.plan.form.months"}
                />
              }
              value={formData.months}
              onChange={value => setFormData("months", value)}
              fullWidth
              type="number"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <OutlinedInput
              id={id + "-notice-days"}
              label={
                <IntlMessages
                  id={"fleet.management.maintenance.plan.form.notice.days"}
                />
              }
              value={formData.noticeDays}
              onChange={value => setFormData("noticeDays", value)}
              fullWidth
              type="number"
            />
          </Grid>
          <Grid item xs={12}>
            <CountManagementSelect
              value={formData.countManagement}
              onChange={value => setFormData("countManagement", value)}
              fullWidth
            />
          </Grid>
        </GridContainer>
      </form>
      <AddType onAdd={onAddTypes} />
      <TypesTable
        types={formData.types}
        setSelectedType={setSelectedType}
        onRemoveType={onRemoveType}
      />
      <TypeFormDialog
        open={Boolean(selectedType)}
        initialData={selectedType}
        onClose={onCloseEditTypeDialog}
        onSubmit={onSubmitEditType}
      />
    </Box>
  );
};

Form.propTypes = {};

export default Form;
