import React from 'react';
import FiltersContainer from "../../../Common/Forms/FiltersContainer";
import Form from "./Form";


const Filters = () => {
    return (
        <>
            <FiltersContainer active>
                <Form />
            </FiltersContainer>
        </>
    );
};

export default Filters;