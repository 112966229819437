import React from "react";

import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

export default {
  description: [
    {
      rule: v => v.trim() !== "",
      message: (
        <IntlMessages id="form.field.error" />
      )
    },
    {
      rule: v => v.length < 50,
      message: (
        <IntlMessages id="length.50.error" />
      )
    }
  ]
};
