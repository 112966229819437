import {mapToBoolean} from "../../../utils/mappers";

class CorrectiveType {
  constructor(id, description, status) {
    this.id = id;
    this.description = description;
    this.status = status;
  }

  static map = type => {
    return new CorrectiveType(
      type.nu_id_tipo_correctivo,
      type.vc_desc_tipo_correctivo,
      mapToBoolean(type.bi_estado)
    );
  };
}

export default CorrectiveType;
