import {mapToBoolean} from "../../utils/mappers";

class EmployeeDocumentType {
  constructor(id, name, status) {
    this.id = id;
    this.name = name;
    this.status = status;
  }

  static map = type => {
    return new EmployeeDocumentType(
      type.nu_id_tipo_documento,
      type.vc_desc_tipo_documento,
      mapToBoolean(type.bi_estado)
    );
  };
}

export default EmployeeDocumentType;
