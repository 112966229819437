import {useFetch} from "../../../../../../../hooks/Common/useFetch";
import React from "react";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";

import EmployeesSelect from "./EmployeesSelect";
import DocumentTypeSelect from "./DocumentTypeSelect";
import {getAllDocumentTypes} from "../../../../../../../services/RRHH/DocumentationTypeService";

const EmployeeInfoArea = ({vehicle, type, setFormData, edit, errors}) => {
  const {data: types, executeService} = useFetch(
    getAllDocumentTypes,
    {vehicle},
    []
  );
  React.useEffect(
    () => {
      executeService();
    },
    [ vehicle ]
  );
  return (
    <GridContainer spacing={2}>
      <Grid item xs={12}>
        <EmployeesSelect
          value={vehicle}
          onChange={value => setFormData("employee", value)}
          disabled={edit}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12}>
        <DocumentTypeSelect
          value={type}
          onChange={value => setFormData("type", value)}
          types={types}
          disabled={edit}
          errors={errors}
        />
      </Grid>
    </GridContainer>
  );
};

export default EmployeeInfoArea;
