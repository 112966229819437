import React from "react";
import {Box} from "@material-ui/core";
import ListTable from "./ListTable";
import FloatingButtonWithAdd from "../../../../Common/Buttons/FloatingButtonWithAdd";
import VehicleDocumentsDialog from "./VehicleDocumentsDialog";
import AddDocumentDialog from "./AddDocumentDialog";

const ListMode = () => {
  const [ openAdd, setOpenAdd ] = React.useState(false);
  return (
    <Box>
      <ListTable />
      <FloatingButtonWithAdd onAddClicked={() => setOpenAdd(true)} />
      <VehicleDocumentsDialog />
      <AddDocumentDialog open={openAdd} setOpen={setOpenAdd} />
    </Box>
  );
};

export default ListMode;
