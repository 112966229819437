import React from "react";
import {Paper, Table, TableBody, TableContainer} from "@material-ui/core";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import {useSelector} from "react-redux";

const HeadquartersTable = () => {
  const {currentProvider =  {}} = useSelector(({logistic}) => logistic.providers);
  const {headquarters = []} = currentProvider;
  return (
    <TableContainer
      component={Paper}
      style={{
        width: "90%",
        margin: "36px 40px"
      }}
    >
      <Table>
        <TableHeader />
        <TableBody>
          {headquarters?.map(headquarter => (
            <TableRow
              key={headquarter.id}
              headquarter={headquarter}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HeadquartersTable;
