import React from 'react';
import FiltersContainer from "../../../Common/Forms/FiltersContainer";
import Form from "./Form";
import {useDispatch, useSelector} from "react-redux";
import {Box} from "@material-ui/core";
import ContainedButton from "../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {openDialog} from "../../../../redux/reducers/Logistic/Planner";
import {dialogsList} from "../constants";

const Filters = () => {
    const {selectedServices} = useSelector(({logistic}) => logistic.planner);
    const dispatch = useDispatch();
    const handleOpenDialog = (dialog) => {
        dispatch(openDialog(dialog));
    }
    return (
        <>
            <FiltersContainer active>
                <Form />
            </FiltersContainer>
            {selectedServices.length > 0 &&
                <Box
                    my={2}
                    style={{
                        gap: 5,
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center'
                    }}
                >
                    <ContainedButton
                        color="primary"
                        text={<IntlMessages id="activate" />}
                        onClick={() => handleOpenDialog(dialogsList.activateRows)}
                    />
                    <ContainedButton
                        color="primary"
                        text={<IntlMessages id="delete" />}
                        onClick={() => handleOpenDialog(dialogsList.deleteRows)}
                    />
                    <ContainedButton
                        color="primary"
                        text={<IntlMessages id="massive.change" />}
                        onClick={() => handleOpenDialog(dialogsList.updateRows)}
                    />
                </Box>
            }
        </>
    );
};

export default Filters;