import {mapToBoolean} from "../../../utils/mappers";

class VehicleMaintenanceView {
  constructor(id, name, status, editable, isPublic, predetermined) {
    this.id = id;
    this.name = name;
    this.status = status;
    this.isPublic = isPublic;
    this.predetermined = predetermined;
    this.editable = editable;
  }

  static map = view => {
    return new VehicleMaintenanceView(
      view.nu_id_tipo_vizualizacion,
      view.vc_desc_tipo_visualizacion,
      mapToBoolean(view.bi_estado),
      mapToBoolean(view.bi_editar),
      mapToBoolean(view.bi_publico),
      mapToBoolean(view.bi_predeterminado)
    );
  };
}

export default VehicleMaintenanceView;
