import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {dialogsList} from "./constants";
import {closeDialog} from "../../../redux/reducers/Treasury/services";
import {updateService} from "../../../redux/thunks/Treasury/services";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import FormDialog from "../../Common/Dialogs/FormDialog";
import Form from "./Form";

const EditDialog = () => {
    const id = 'edit-tax-form';
    const {dialogs, currentService} = useSelector(({treasury}) => treasury.services);
    const dispatch = useDispatch();
    const open = dialogs.some(dialog => dialog === dialogsList.EDIT_SERVICE);
    const onSubmit = (data) => {
        dispatch(updateService({
            request: {
                ...currentService,
                ...data,
            }
        }));
        onClose();
    };
    const onClose = () => {
        dispatch(closeDialog(dialogsList.EDIT_SERVICE));
    }
    return (
        <>
            <FormDialog
                id={id}
                isOpen={open}
                onClose={onClose}
                title={<IntlMessages id="edit"/>}
            >
                {(open && currentService) && <Form id={id} onSubmit={onSubmit} initData={currentService}/>}
            </FormDialog>
        </>
    );
};

export default EditDialog;