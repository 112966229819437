import React, {useEffect} from 'react';
import Grid from "@material-ui/core/Grid";
import ClientSelect
    from "../../../../../../ElevatorsManagement/Elevators/DailyOperations/IndexCard/ElevatorDetailDIalog/ElevatorInformation/ClientSelect";
import useForm from "../../../../../../../hooks/Common/useForm";
import SaveIcon from '@material-ui/icons/Save';
import {IconButton} from "@material-ui/core";


const initialData = {
    organization: ''
}
const EditOrganization = ({onEdit, initialValue}) => {
    const {formData, setFormData} = useForm(initialData);
    const handleEdit = () => {
        onEdit();
    }

    useEffect(() => {
        setFormData('organization', initialValue);
    }, [initialValue]);
    return (
        <Grid item xs={6}
            style={{
                display: 'flex',
                flexDirection: 'row',
                maxWidth: '100%',
                gap: 2
            }}
        >
            <ClientSelect
                value={formData.organization}
                setValue={value => setFormData('organization', value)}
            />
            <IconButton size='small' onClick={handleEdit}>
                <SaveIcon />
            </IconButton>
        </Grid>
    );
};

export default EditOrganization;