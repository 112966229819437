import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import DocumentsTypes from "../../../domain/FleetManagement/Vehicles/DocumentsTypes";
import Response from "../../../domain/Response";

export const getAllVehicleDocumentsTypes = async () => {
  try {
    const {data} = await axiosInstance.post("/Vehiculo_Tipo_Documento/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(d => DocumentsTypes.map(d));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createVehicleDocumentsType = async type => {
  try {
    const {data} = await axiosInstance.post("/Vehiculo_Tipo_Documento/ins", {
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_tipo_documento: type.description,
      bi_control_seguridad: type.securityControl
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateVehicleDocumentsType = async type => {
  try {
    const {data} = await axiosInstance.post("/Vehiculo_Tipo_Documento/upd", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_tipo_documento: type.id,
      vc_desc_tipo_documento: type.description,
      bi_control_seguridad: type.securityControl
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};
