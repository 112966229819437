import React from 'react';
import PageContainer from "../../../../components/Treasury/Services/PageContainer";
import Table from "../../../../components/Treasury/Services/Table";
import AddDialog from "../../../../components/Treasury/Services/AddDialog";
import EditDialog from "../../../../components/Treasury/Services/EditDialog";

const TreasuryServices = () => {
    return (
        <PageContainer>
            <AddDialog />
            <Table />
            <EditDialog />
        </PageContainer>
    );
};

export default TreasuryServices;