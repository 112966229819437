import React from "react";
import {useDispatch, useSelector} from "react-redux";
import FormDialog from "../../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import Form from "../Form";
import {dialogs} from "../../constants";
import {
  addWorkshopRequest,
  closeWorkshopRequestDialog
} from "../../../../../../redux/actions/FleetManagement/WorkshopRequests";

const AddDialog = () => {
  const id = "add-workshop-request-form";
  const {dialog: {name}} = useSelector(
    ({fleetManagement}) => fleetManagement.workshopRequests
  );
  const dispatch = useDispatch();
  const onAdd = data => {
    dispatch(addWorkshopRequest(data, onClose));
  };
  const onClose = () => {
    dispatch(closeWorkshopRequestDialog());
  };
  return (
    <FormDialog
      id={id}
      title={
        <IntlMessages id={"fleet.management.workshop.request.add.title"} />
      }
      submitText={<IntlMessages id={"label.form.save"} />}
      cancelText={<IntlMessages id={"label.form.cancel"} />}
      isOpen={name === dialogs.ADD_DIALOG}
      onClose={onClose}
      fullScreen
    >
      {name === dialogs.ADD_DIALOG && (
        <Form
          add
          initialData={{
            vehicle:  "",
            workshop: "",
          }}
          id={id}
          onSubmit={onAdd}
        />
      )}
    </FormDialog>
  );
};

AddDialog.propTypes = {};

export default AddDialog;
