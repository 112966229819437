import React from "react";
import PropTypes from "prop-types";
import useForm from "../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const DataForm = ({id, initialData, onEditRole}) => {
  const {formData, setFormData, handleSubmit} = useForm(initialData);
  return (
    <form id={id} onSubmit={handleSubmit(onEditRole)}>
      <GridContainer spacing={2}>
        <Grid item xs={12}>
          <OutlinedInput
            id={id + "-description"}
            value={formData.description}
            onChange={value => setFormData("description", value)}
            label={
              <IntlMessages
                id={
                  "configuration.user.settings.vehicle.roles.edit.form.description"
                }
              />
            }
            fullWidth
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

DataForm.propTypes = {
  id: PropTypes.string.isRequired,
  initialData: PropTypes.object.isRequired,
  onEditRole: PropTypes.func.isRequired
};

export default DataForm;
