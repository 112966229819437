import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {fetchAllAvailableRefunds, setSelectedRefunds} from "../../../../redux/actions/Guardians/Refunds";
import {DataGrid} from '@mui/x-data-grid';
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import moment from "moment/moment";
import {IconButton} from "@material-ui/core";
import GetAppIcon from '@material-ui/icons/GetApp';
import {useDownloadFile} from "../../../../hooks/Common/useDownloadFile";

const getColumns = (onClickDownload) =>  [
    {field: 'id', hide: true},
    {field: 'accountingNumber', renderHeader: () => (<IntlMessages id="guardian.accounting.table.id"/>), minWidth: 150},
    {
        field: 'openingDate',
        renderHeader: () => (<IntlMessages id="guardian.accounting.table.start.date"/>),
        minWidth: 200,
        valueFormatter: ({value}) => {
            return moment(value).format("DD/MM/YYYY HH:mm");
        }
    },
    {
        field: 'liquidationDate',
        renderHeader: () => (<IntlMessages id="guardian.accounting.table.end.date"/>),
        minWidth: 200,
        valueFormatter: ({value}) => {
            return moment(value).format("DD/MM/YYYY HH:mm");
        }
    },
    {
        field: 'tags',
        renderHeader: () => (<IntlMessages id="guardian.accounting.table.tags"/>),
        minWidth: 250,
        valueFormatter: ({value}) => {
            return value.join(',');
        }
    },
    {
        field: 'quantity',
        renderHeader: () => (<IntlMessages id="guardian.accounting.table.quantity"/>),
        minWidth: 150
    },
    {
        field: 'pettyCashAmount',
        renderHeader: () => (<IntlMessages id="petty.cash.amount"/>),
        minWidth: 200
    },
    {
        field: 'observations',
        renderHeader: () => (<IntlMessages id="observations"/>),
        minWidth: 200
    },
    {
        field: 'actions',
        type: 'actions',
        renderHeader: () => (<IntlMessages id="download"/>),
        minWidth: 200,
        renderCell: ({row}) => {
            return (
                <>
                    <IconButton onClick={() => onClickDownload(row)}>
                        <GetAppIcon />
                    </IconButton>
                </>
            )
        }
    },
]

const RefundsTable = () => {
    const {filters, data, selectedRefunds} = useSelector(({guardian}) => guardian.refunds);
    const dispatch = useDispatch();
    const {downloadFileByName} = useDownloadFile();
    useEffect(() => {
        dispatch(fetchAllAvailableRefunds(filters));
    }, [filters]);
    const handleChangeSelected = (values) => {
        dispatch(setSelectedRefunds(values));
    }
    const handleDownloadFile = (refund) => {
        downloadFileByName(refund.file);
    }
    return (
        <DataGrid
            columns={getColumns(handleDownloadFile)}
            rows={data}
            disableColumnFilter
            disableColumnMenu
            selectionModel={selectedRefunds}
            onSelectionModelChange={handleChangeSelected}
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            rowHeight={40}
        />
    );
};

export default RefundsTable;