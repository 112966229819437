import React from "react";
import {useDispatch, useSelector} from "react-redux";
import useForm from "../../../../../../hooks/Common/useForm";
import FiltersContainer from "../../../../../Common/Forms/FiltersContainer";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {cloneDeep} from "lodash";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import {setFilters} from "../../../../../../redux/reducers/Logistic/providers";

const initialData = {
    providerCode: '',
    name: '',
    businessName: '',
}

const Filters = () => {
    const id = 'providers-filters-form';
    const {filters} = useSelector(
        ({logistic}) => logistic.providers
    );
    const {formData, setFormData, resetForm, setValues, handleSubmit} = useForm(initialData);
    const dispatch = useDispatch();
    const onApplyFilters = (filters) => {
        dispatch(setFilters(filters));
    };
    const onResetFilters = () => {
        resetForm();
        onApplyFilters(cloneDeep(initialData));
    };
    React.useEffect(() => {
        setValues(filters);
        onApplyFilters(filters);
    }, []);
    return (
      <FiltersContainer
        active
        marginBottom={2}
      >
          <form id={id} onSubmit={handleSubmit(onApplyFilters)}>
              <GridContainer spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                      <OutlinedInput
                          fullWidth
                          value={formData.providerCode}
                          onChange={value => setFormData('providerCode', value)}
                          label={<IntlMessages id="ruc" />}
                      />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                      <OutlinedInput
                          fullWidth
                          value={formData.name}
                          onChange={value => setFormData('name', value)}
                          label={<IntlMessages id="name" />}
                      />
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                      <OutlinedInput
                          fullWidth
                          value={formData.businessName}
                          onChange={value => setFormData('businessName', value)}
                          label={<IntlMessages id="business.name" />}
                      />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                      <ContainedButton
                          text={<IntlMessages id={"filters.button.clear"} />}
                          form={id}
                          onClick={onResetFilters}
                      />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                      <ContainedButton
                          type='submit'
                          color="primary"
                          text={<IntlMessages id={"filters.button.apply"} />}
                      />
                  </Grid>
              </GridContainer>
          </form>
      </FiltersContainer>
    );
};

export default Filters;
