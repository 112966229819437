import React from 'react';
import {useSelector} from "react-redux";
import {Box, Divider, IconButton, Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import EditOrganization from "./EditOrganization";

const Details = () => {
    const {currentIndexFile} = useSelector(({backOffice}) => backOffice.indexFiles);
    const [edit, setEdit] = React.useState(false);
    const onClickEdit = () => {
        setEdit(!edit);
    }
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'row',
                height: '100%',
            }}
        >
            <Box>
                <Typography
                    variant='subtitle2'
                >
                    <IntlMessages id='general' />
                </Typography>
                <GridContainer spacing={2}>
                    <Grid item xs={6}>
                        <IntlMessages id='organization' />:
                    </Grid>
                    <Grid item xs={6}>
                        {edit
                            ? <>
                                <span>{currentIndexFile?.organization?.name}</span>
                                <IconButton size='small' onClick={onClickEdit}>
                                    <EditIcon />
                                </IconButton>
                            </>
                            : <EditOrganization onEdit={onClickEdit} initialValue={currentIndexFile?.organization?.id} />
                        }

                    </Grid>
                    <Grid item xs={6}>
                        <IntlMessages id='group' />:
                    </Grid>
                    <Grid item xs={6}>
                        {currentIndexFile?.group}
                    </Grid>
                    <Grid item xs={6}>
                        <IntlMessages id='description' />:
                    </Grid>
                    <Grid item xs={6}>
                        {currentIndexFile?.description}
                    </Grid>
                    <Grid item xs={6}>
                        <IntlMessages id='serial.number' />:
                    </Grid>
                    <Grid item xs={6}>
                        {currentIndexFile?.serialNumber}
                    </Grid>
                    <Grid item xs={6}>
                        <IntlMessages id='firmware' />
                    </Grid>
                    <Grid item xs={6}>
                        {currentIndexFile?.firmware}
                    </Grid>
                    <Grid item xs={6}>
                        <IntlMessages id='product.code' />
                    </Grid>
                    <Grid item xs={6}>
                        {currentIndexFile?.productCode}
                    </Grid>
                </GridContainer>
            </Box>
            <Divider flexItem orientation="vertical" style={{margin: '0 2rem'}} />
            <Box
                sx={{
                    width: '50%'
                }}
            >
                <Typography
                    variant='subtitle2'
                >
                    <IntlMessages id='sim' />
                </Typography>
                <GridContainer spacing={2}>
                    <Grid item xs={6}>
                        <IntlMessages id='iccid' />:
                    </Grid>
                    <Grid item xs={6}>
                        {currentIndexFile?.iccid}
                    </Grid>
                    <Grid item xs={6}>
                        <IntlMessages id='imsi' />:
                    </Grid>
                    <Grid item xs={6}>
                        {currentIndexFile?.imsi}
                    </Grid>
                </GridContainer>
            </Box>
        </Box>
    );
};

export default Details;