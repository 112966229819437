import React from "react";
import Tabs from "../../../../Common/Tabs";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import ListMode from "../ListMode";
import SummaryMode from "../SummaryMode";
import DashboardMode from "../DashboardMode";

const ViewerContainer = () => {
  const [ selected, setSelected ] = React.useState("1");
  const options = [
    {
      value: "1",
      label: (
        <IntlMessages
          id={"fleet.management.vehicles.documentation.list.mode.title"}
        />
      ),
      view: <ListMode />
    },
    {
      value: "2",
      label: (
        <IntlMessages
          id={"fleet.management.vehicles.documentation.summary.mode.title"}
        />
      ),
      view: <SummaryMode />
    },
    {
      value: "3",
      label: (
          <IntlMessages
              id={"dashboard.mode"}
          />
      ),
      view: <DashboardMode />
    }
  ];
  return (
    <Tabs value={selected} onChange={setSelected} tabs={options}>
      {options.find(o => o.value === selected).view}
    </Tabs>
  );
};

export default ViewerContainer;
