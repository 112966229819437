import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import OrganizationCard from "./OrganizationCard";
import Box from "@material-ui/core/Box";
import {fetchOrganizationAndDriversCards} from "../../../../redux/thunks/FleetManagement/Tachograph/cards";
import {IconButton, Typography} from "@material-ui/core";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {openDialog, setCurrentCard} from "../../../../redux/reducers/FleetManagement/tachographCards";
import {dialogsList} from "./constants";
import AddIcon from "@material-ui/icons/Add";

const OrganizationCardList = () => {
    const {organizationCards} = useSelector(({fleetManagement}) => fleetManagement.tachographCards);
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchOrganizationAndDriversCards());
    }, []);
    const onAdd = () => {
        dispatch(openDialog(dialogsList.addCard));
        dispatch(setCurrentCard({typeId: "4"}));
    };
    return (
        <Box
            style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                gap: 2
            }}
        >
            <Box
                display='flex'
                justifyContent='space-between'
                mb='1rem'
            >
                <Typography variant='h3'>
                    <IntlMessages id='organization.cards' />
                </Typography>
                <IconButton
                    onClick={onAdd}
                    style={{
                        backgroundColor: '#3f51b5',
                        color: 'white'
                    }}
                >
                    <AddIcon />
                </IconButton>
            </Box>

            {Object.values(organizationCards).map((card) => (
                <OrganizationCard
                    key={card.id}
                    card={card}
                />
            ))}
        </Box>
    );
};

export default OrganizationCardList;