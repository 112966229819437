import React from 'react';
import BaseTable from "../../Common/BaseTable";
import {connect} from "react-redux";
import {
    openGenreDialog,
    setCurrentGenre,
    setGenreFilters
} from "../../../../../../redux/reducers/ElevatorsManagement/genres";
import {dialogsList} from "../../Common/constants";
import {fetchAllGenres} from "../../../../../../redux/thunks/ElevatorManagement/genres";
import FilterBuilder from "../../../../../../utils/filters";

const GenreTable = ({data, filters, dispatch}) => {
    React.useEffect(() => {
        dispatch(fetchAllGenres());
    }, []);
    const onEdit = current => {
        dispatch(setCurrentGenre(current));
        dispatch(openGenreDialog(dialogsList.editDialog));
    };
    const onDelete = current => {
        dispatch(setCurrentGenre(current));
        dispatch(openGenreDialog(dialogsList.deleteDialog));

    };
    const onRestore = current => {
        dispatch(setCurrentGenre(current));
        dispatch(openGenreDialog(dialogsList.restoreDialog));
    };
    const onApplyFilters = filters => {
        dispatch(setGenreFilters(filters));
    };
    const onResetFilters = () => {
        dispatch(setGenreFilters(null));
    };
    return (
        <BaseTable
            data={Object.values(data)}
            initData={filters}
            onRestore={onRestore}
            onEdit={onEdit}
            onDelete={onDelete}
            onApplyFilters={onApplyFilters}
            onResetFilters={onResetFilters}
        />
    );
};

const mapStateToProps = state => {
    const {data, filters} = state.elevatorsManagement.genres;
    const filteredData = new FilterBuilder(Object.values(data))
        .byFieldIncludes("id", filters.code)
        .byFieldIncludes("name", filters.name)
        .build();
    return {data: filteredData, filters};
};

export default connect(mapStateToProps)(GenreTable);