import React from "react";
import PropTypes from "prop-types";
import StyledTableRow from "../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

const TableHeader = props => {
  return (
    <StyledTableRow>
      <StyledTableCell>
        <IntlMessages id={"rrhh.employees.documents.types.table.description"} />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages
          id={"rrhh.employees.documents.types.table.security.control"}
        />
      </StyledTableCell>
      <StyledTableCell />
    </StyledTableRow>
  );
};

TableHeader.propTypes = {};

export default TableHeader;
