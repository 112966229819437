import {DataGrid, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import {useDispatch, useSelector} from "react-redux";
import {useIntl} from "react-intl";
import React, {useCallback} from "react";
import {Box} from "@material-ui/core";
import {setSelectedServices} from "../../../../redux/reducers/Logistic/Planner";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

const getStateText = (state) => {
    switch (state) {
        case -100:
            return 'Activado';

        case -200:
            return 'Eliminado';

        case -300:
            return 'Modificado';

        default:
            return 'Pendiente';
    }
}

const getStateColor = (state) => {
    switch (state) {
        case -100:
            return '#53f03f';

        case -200:
            return '#FFA500';

        case -300:
            return '#FFFF00';

        default:
            return '#878787';
    }
}

const Table = () => {
    const {data, selectedServices} = useSelector(({logistic}) => logistic.planner);
    const dispatch = useDispatch();

    const intl = useIntl();

    const getHeaders = useCallback(() => {
        const headers = [
            {
                field: 'id',
                headerName: intl.messages["order.number"],
                minWidth: 180,
            },
            {
                field: 'client',
                headerName: intl.messages['client'],
                minWidth: 300,
            },
            {
                field: 'turn',
                headerName: intl.messages["turn"],
                minWidth: 150,
            },
            {
                field: 'clientCode',
                headerName: intl.messages['client.code'],
                minWidth: 180,
            },
            {
                field: 'driver',
                headerName: intl.messages['driver'],
                minWidth: 180,
            },
            {
                field: 'tract',
                headerName: intl.messages['tract'],
                minWidth: 180,
            },
            {
                field: 'trailer',
                headerName: intl.messages['trailer'],
                minWidth: 180,
            },
            {
                field: 'installationDeparture',
                headerName: intl.messages['installation.departure'],
                minWidth: 180,
            },
            {
                field: 'installationArrival',
                headerName: intl.messages['installation.arrival'],
                minWidth: 180,
            },
            {
                field: 'clientDeparture',
                headerName: intl.messages['client.departure'],
                minWidth: 180,
            },
            {
                field: 'clientArrival',
                headerName: intl.messages['client.arrival'],
                minWidth: 180,
            },
            {
                field: 'centralCode',
                headerName: intl.messages['central.code'],
                minWidth: 180,
            },
            {
                field: 'saleOrganization',
                headerName: intl.messages['sale.organization'],
                minWidth: 180,
            },
            {
                field: 'channel',
                headerName: intl.messages['channel'],
                minWidth: 180,
            },
            {
                field: 'state',
                headerName: intl.messages['state'],
                minWidth: 180,
                valueGetter: ({value}) => getStateText(Number(value)),
                renderCell: ({value, row}) => (
                    <Box
                        px={2}
                        style={{
                            backgroundColor: getStateColor(Number(row.state)),
                            width: '100px',
                            textAlign: 'center',
                        }}
                    >
                        {value}
                    </Box>
                )
            },
        ]
        return [
            ...headers,
        ]
    }, []);
    const handleChangeSelected = (values) => {
        dispatch(setSelectedServices(values));
    }
    return (
        <DataGrid
            style={{
                height: '70vh'
            }}
            columns={getHeaders()}
            rows={data}
            disableSelectionOnClick
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            rowHeight={50}
            checkboxSelection
            selectionModel={selectedServices}
            onSelectionModelChange={handleChangeSelected}
            components={{
                Toolbar: CustomToolbar,
            }}
        />
    );
};

export default Table;