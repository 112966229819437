import React from 'react';
import {dialogsList} from "./constants";
import {useDispatch, useSelector} from "react-redux";
import {closeDialog} from "../../../../redux/reducers/Logistic/providerOrders";
import Confirmation from "../../../Common/Dialogs/Confirmation";
import {useIntl} from "react-intl";
import {addProviderOrder} from "../../../../redux/thunks/Logistic/providerOrders";

const AddProviderOrderDialog = () => {
    const id = dialogsList.addOrdersDialog;
    const intl = useIntl();
    const {dialogs} = useSelector(({logistic}) => logistic.providerOrders);
    const dispatch = useDispatch();
    const isOpen = dialogs.some(dialog => dialog === id);
    const onSubmit = () => {
        dispatch(addProviderOrder());
        onClose();
    }
    const onClose = () => {
        dispatch(closeDialog(id));
    }
    return (
        <Confirmation
            fullScreen
            open={isOpen}
            title={intl.messages["create"]}
            onReject={onClose}
            onAccept={onSubmit}
            text={`${intl.messages["do.you.want.to.create"]} ${intl.messages["an"]} ${intl.messages["order"]} ?`}
        />
    );
};

export default AddProviderOrderDialog;