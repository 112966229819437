import React from "react";

import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import Form from "../Form";
import {useDispatch, useSelector} from "react-redux";
import {dialogsList} from "../../constants";
import {closeDialog} from "../../../../../redux/reducers/Treasury/costCenterType";
import {editCostCenterTypes} from "../../../../../redux/thunks/Treasury/costCenterType";

const EditDialog = () => {
    const id = "edit-form";
    const {dialogs, currentCostCenter} = useSelector(({treasury}) => treasury.costCenterType);
    const dispatch = useDispatch();
    const isOpen = dialogs.some(dialog => dialog === dialogsList.edit);
    const handleClose = () => {
        dispatch(closeDialog(dialogsList.edit));
    }
    const onSubmit = (data) => {
        dispatch(editCostCenterTypes({currentCostCenter, request: data}));
        handleClose();
    }
    return (
        <FormDialog
            id={id}
            isOpen={isOpen}
            title={<IntlMessages id="edit"/>}
            onClose={handleClose}
        >
            {isOpen &&
                <Form
                    id={id}
                    onSubmit={onSubmit}
                    initialData={{
                        ...currentCostCenter,
                    }}
                />}
        </FormDialog>
    )
};

export default EditDialog;
