import React from 'react';
import FormDialog from "../../../../../../../Common/Dialogs/FormDialog";
import {dialogsList} from "../../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {closeDialog} from "../../../../../../../../redux/reducers/Logistic/providerOrders";
import Form from "./Form";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import {addContactToProviderOrder} from "../../../../../../../../redux/thunks/Logistic/providerOrders";

const ContactDialog = ({name}) => {
    const id = `${dialogsList.contactForm}-${name}`;
    const {dialogs, currentOrder} = useSelector(({logistic}) => logistic.providerOrders);
    const dispatch = useDispatch();
    const isOpen = dialogs.some(dialog => dialog === id);
    const onClose = () => {
        dispatch(closeDialog(id));
    }
    const handleSubmit = (data) => {
        dispatch(addContactToProviderOrder({
            order: currentOrder,
            request: {
                ...data,
                type: name
            }
        }));
        onClose();
    }
    return (
        <FormDialog
            id={id}
            isOpen={isOpen}
            onClose={onClose}
            title={<IntlMessages id="add" />}
        >
            {isOpen &&
                <Form
                    id={id}
                    onSubmit={handleSubmit}
                />
            }
        </FormDialog>
    );
};

export default ContactDialog;