import {useDispatch, useSelector} from "react-redux";
import {generateFileObject} from "../../../../../utils/files";
import {fetchError} from "../../../../../redux/actions";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import React from "react";
import Form from "../DocumentsDialog/Form";
import validations from "./validations";
import {addEmployeeDocument} from "../../../../../redux/actions/RRHH/Documentation";

const AddDocumentDialog = ({open, setOpen}) => {
  const dispatch = useDispatch();
  const {filters} = useSelector(({rrhh}) => rrhh.documentation.list);
  const id = "add-employee-document-form";
  const onSubmit = data => {
    generateFileObject(data.file[0])
      .then(file => {
        dispatch(addEmployeeDocument({...data, file}, filters));
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
    onClose();
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <FormDialog
      id={id}
      isOpen={open}
      title={
        <IntlMessages
          id={"rrhh.employees.documentation.list.mode.detail.form.title"}
        />
      }
      onClose={onClose}
    >
      <Form
        id={id}
        initialData={{
          employee: "",
          type: "",
          expirationDate: "",
          noticeDays: "",
          url: "",
          file: []
        }}
        onSubmit={onSubmit}
        validations={validations}
      />
    </FormDialog>
  );
};

export default AddDocumentDialog;
