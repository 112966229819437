import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import React from "react";

const TableHeader = () => {
  return (
    <StyledTableRow>
      <StyledTableCell>
        <IntlMessages
          id={"rrhh.employees.documentation.list.mode.table.employee"}
        />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages
          id={"rrhh.employees.documentation.list.mode.table.type"}
        />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages
          id={"rrhh.employees.documentation.list.mode.table.expiration.date"}
        />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages
          id={"rrhh.employees.documentation.list.mode.table.notice.days"}
        />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages
          id={"rrhh.employees.documentation.list.mode.table.active"}
        />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages
          id={"rrhh.employees.documentation.list.mode.table.show.download"}
        />
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default TableHeader;
