import React from "react";
import PageContainer from "../../../../../components/Configuration/UserSettings/VehicleRoles/PageContainer";
import VehicleRolesTable from "../../../../../components/Configuration/UserSettings/VehicleRoles/VehicleRolesTable";
import {useDispatch, useSelector} from "react-redux";
import {
  resetConfigurationVehicleRoleState,
  setCurrentConfigurationVehicleRole,
  updateConfigurationVehicleRoleState
} from "../../../../../redux/actions/Configuration/VehicleRoles";
import Confirmation from "../../../../../components/Common/Dialogs/Confirmation";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import FloatingButtonWithAdd from "../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import AddVehicleRoleDialog from "../../../../../components/Configuration/UserSettings/VehicleRoles/AddVehicleRoleDialog";
import EditVehicleRoleDialog from "../../../../../components/Configuration/UserSettings/VehicleRoles/EditVehicleRoleDialog";

const VehicleRoles = () => {
  const dispatch = useDispatch();
  const {current} = useSelector(
    ({configuration}) => configuration.vehicleRoles
  );
  const [ openAdd, setOpenAdd ] = React.useState(false);
  const [ openEdit, setOpenEdit ] = React.useState(false);
  const [ openDelete, setOpenDelete ] = React.useState(false);
  const [ openRestore, setOpenRestore ] = React.useState(false);

  React.useEffect(() => {
    return () => {
      dispatch(resetConfigurationVehicleRoleState());
    };
  }, []);
  return (
    <PageContainer>
      <VehicleRolesTable
        openEdit={() => setOpenEdit(true)}
        openDelete={() => setOpenDelete(true)}
        openRestore={() => setOpenRestore(true)}
      />
      <AddVehicleRoleDialog openAdd={openAdd} setOpenAdd={setOpenAdd} />
      <EditVehicleRoleDialog openEdit={openEdit} setOpenEdit={setOpenEdit} />
      <Confirmation
        open={openDelete}
        text={
          <IntlMessages
            id={"configuration.user.settings.vehicle.roles.delete.text"}
          />
        }
        onReject={() => {
          setOpenDelete(false);
          dispatch(setCurrentConfigurationVehicleRole(null));
        }}
        onAccept={() => {
          setOpenDelete(false);
          dispatch(updateConfigurationVehicleRoleState(current, false));
        }}
      />
      <Confirmation
        open={openRestore}
        text={
          <IntlMessages
            id={"configuration.user.settings.vehicle.roles.restore.text"}
          />
        }
        onReject={() => {
          setOpenRestore(false);
          dispatch(setCurrentConfigurationVehicleRole(null));
        }}
        onAccept={() => {
          setOpenRestore(false);
          dispatch(updateConfigurationVehicleRoleState(current, true));
        }}
      />
      <FloatingButtonWithAdd onAddClicked={() => setOpenAdd(true)} />
    </PageContainer>
  );
};

export default VehicleRoles;
