import React from 'react';
import Select from "../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {fetchAllProductsSelect} from "../../../../../../../services/Logistics/Purchases/ProviderOrdersService";
import {useFetch} from "../../../../../../../hooks/Common/useFetch";

const ProductSelect = ({name = 'product', value, onChange, error, errorMessage, required = false}) => {
    const {data: products} = useFetch(fetchAllProductsSelect, {}, []);
    const handleOnChange = ({target}) => {
        const {value} = target;
        const product = products.find(service => service.id === value);
        onChange({
            value,
            name: product.name,
        });
    }
    return (
        <Select
            label={<IntlMessages id="product"/>}
            name={name}
            value={value}
            onChange={handleOnChange}
            error={error}
            errorMessage={errorMessage}
            fullWidth
            required={required}
        >
            {products?.map(product => (
                <MenuItem key={product.id} value={product.id}>
                    {product.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default ProductSelect;