import {useFetch} from "../../../../../../../../hooks/Common/useFetch";
import {fetchAllTagsByType} from "../../../../../../../../services/TagService";
import MultipleSelect from "../../../../../../../Common/Forms/Select/MultipleSelect";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";
import React from "react";

const TagsSelect = ({value, onChange}) => {
  const {data: tags} = useFetch(fetchAllTagsByType, 2, []);
  return (
    <MultipleSelect
      fullWidth
      label={
        <IntlMessages
          id={"configuration.user.settings.vehicle.roles.edit.filters.tags"}
        />
      }
      value={value}
      onChange={onChange}
      options={tags}
      limitTags={1}
    />
  );
};

export default TagsSelect;
