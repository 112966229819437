import React from "react";
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages id="provider.code" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="name" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="business.name" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="country" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="code" />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id="email" />
    </StyledTableCell>
  </StyledTableRow>
);

export default TableHeader;
