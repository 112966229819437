import React from 'react';
import {Bar, BarChart as BaseChart, Cell, LabelList, ResponsiveContainer, XAxis, YAxis} from 'recharts';
import {COLORS} from "./constants";


const BarChart = ({data}) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BaseChart width={150} height={40} data={data}>
                <Bar dataKey="value"  >
                    {data.map((entry, index) => (
                        <Cell fill={COLORS[index]} key={`cell-${index}`}/>
                    ))}
                    <LabelList dataKey="value" position="center" fill="#000" />
                </Bar>
                <YAxis />
                <XAxis dataKey="name" />
            </BaseChart>
        </ResponsiveContainer>
    );
};

export default BarChart;