import React from "react";

import Confirmation from "../../../../Common/Dialogs/Confirmation";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {useDispatch, useSelector} from "react-redux";
import {dialogsList} from "../../constants";
import {closeDialog} from "../../../../../redux/reducers/Treasury/costCenterType";
import {editCostCenterTypeState} from "../../../../../redux/thunks/Treasury/costCenterType";

const RestoreConfirmation = () => {
    const {dialogs, currentCostCenter} = useSelector(({treasury}) => treasury.costCenterType);
    const dispatch = useDispatch();
    const open = dialogs.some(dialog => dialog === dialogsList.restore);
    const handleClose = () => {
        dispatch(closeDialog(dialogsList.restore));
    }
    const onAccept = () => {
        dispatch(editCostCenterTypeState({currentCostCenter}));
        handleClose();
    }
    return(
        <Confirmation
            open={open}
            onAccept={onAccept}
            onReject={handleClose}
            text={
                <IntlMessages id="restore.confirmation" />
            }
        />
    )
};

export default RestoreConfirmation;
