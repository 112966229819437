import React from 'react';
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import ControlSelect from "./ControlSelect";
import Typography from "@material-ui/core/Typography";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import useForm from "../../../../../../hooks/Common/useForm";
import {useDispatch, useSelector} from "react-redux";
import {updateProviderOrderControlDetails} from "../../../../../../redux/thunks/Logistic/providerOrders";

const ControlForm = ({control, editable = true}) => {
    const {currentOrder} = useSelector(({logistic}) => logistic.providerOrders);
    const dispatch = useDispatch();
    const {formData, setFormData, handleSubmit} = useForm({
        type: control?.type ?? '',
        min: control?.min ?? '',
        max: control?.max ?? ''
    });
    const onSubmit = (data) => {
        dispatch(updateProviderOrderControlDetails({
            order: currentOrder,
            request: data
        }));
    }
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
                <legend>
                    <Typography>
                        <IntlMessages id="data.of"/>  <IntlMessages id="control" />
                    </Typography>
                </legend>
                <GridContainer spacing={2} className="control-form">
                    {editable && <>
                        <Grid
                            item xs={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <ContainedButton
                                text={<IntlMessages id="save"/>}
                                color="primary"
                                type='submit'
                            />
                        </Grid>
                    </>}
                    <Grid item xs={12} md={6}>
                        <ControlSelect
                            value={formData.type}
                            onChange={value => setFormData('type', value)}
                            disabled={!editable}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <OutlinedInput
                            label={<IntlMessages id="min" />}
                            fullWidth
                            value={formData.min}
                            onChange={value => setFormData('min', value)}
                            disabled={!editable || control.type === 2}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <OutlinedInput
                            label={<IntlMessages id="max" />}
                            fullWidth
                            value={formData.max}
                            onChange={value => setFormData('max', value)}
                            disabled={!editable || control.type === 2}
                        />
                    </Grid>
                </GridContainer>
            </fieldset>
        </form>
    );
};

export default ControlForm;