import React from "react";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";

export default {
  file: [
    {
      rule: v => v !== null,
      message: (
        <IntlMessages id="fleet.management.common.form.files.add.field.error" />
      )
    }
  ]
};
