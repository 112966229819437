import React from "react";
import {useFetch} from "../../../../../../../hooks/Common/useFetch";
import {useDispatch} from "react-redux";
import {
  downloadFile,
  getFileByName
} from "../../../../../../../services/FileService";
import {fetchError} from "../../../../../../../redux/actions";
import TableContainer from "../../../../../../Common/Tables/TableContainer";
import StyledTableRow from "../../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../../Common/Tables/StyledTableCell";
import {parseDateToDateString} from "../../../../../../../utils/dates";
import {IconButton} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import TableHeader from "./TableHeader";
import {getAllDocuments} from "../../../../../../../services/RRHH/DocumentationService";

const EmployeeDocumentsList = ({employee, type}) => {
  const {data: documents, executeService} = useFetch(
    getAllDocuments,
    {employee, type},
    []
  );
  const dispatch = useDispatch();
  React.useEffect(
    () => {
      executeService();
    },
    [ employee, type ]
  );
  const onDownload = doc => {
    getFileByName(doc.fileName)
      .then(({base64File, contentType}) => {
        downloadFile(doc.fileName, `data:${contentType};base64,${base64File}`);
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
  return (
    <TableContainer head={<TableHeader />}>
      {documents.map(doc => (
        <StyledTableRow key={doc.id}>
          <StyledTableCell>
            {parseDateToDateString(doc.expirationDate)}
          </StyledTableCell>
          <StyledTableCell>{doc.active ? "Si" : "No"}</StyledTableCell>
          <StyledTableCell>
            <IconButton onClick={() => onDownload(doc)}>
              <GetAppIcon />
            </IconButton>
          </StyledTableCell>
        </StyledTableRow>
      ))}
    </TableContainer>
  );
};

export default EmployeeDocumentsList;
