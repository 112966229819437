import {useDispatch, useSelector} from "react-redux";
import FormDialog from "../../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import React from "react";
import {
  closeMaintenancePlansDialog,
  editMaintenancePlan
} from "../../../../../../redux/actions/FleetManagement/MaintenancePlans";
import {dialogs} from "../../constants";
import Form from "../Form";

const EditDialog = () => {
  const id = "edit-maintenance-plan-form";
  const {current, dialog: {name, open}} = useSelector(
    ({fleetManagement}) => fleetManagement.maintenancePlans
  );
  const dispatch = useDispatch();
  const onEdit = data => {
    dispatch(
      editMaintenancePlan({id: current.id, status: current.status, ...data})
    );
    onClose();
  };
  const onClose = () => {
    dispatch(closeMaintenancePlansDialog());
  };
  return (
    <FormDialog
      id={id}
      title={
        <IntlMessages id={"fleet.management.maintenance.plan.edit.title"} />
      }
      submitText={<IntlMessages id={"label.form.save"} />}
      cancelText={<IntlMessages id={"label.form.cancel"} />}
      isOpen={open && dialogs.EDIT_DIALOG === name}
      onClose={onClose}
    >
      {open &&
      dialogs.EDIT_DIALOG === name && (
        <Form
          initialData={{
            name: current.name || "",
            kilometers: current.kilometers || "",
            engineHours: current.engineHours || "",
            months: current.months || "",
            noticeKilometers: current.noticeKilometers || "",
            noticeHours: current.noticeHours || "",
            noticeDays: current.noticeDays || "",
            types: current.types || [],
            countManagement: current.countManagement || ""
          }}
          id={id}
          onSubmit={onEdit}
        />
      )}
    </FormDialog>
  );
};

EditDialog.propTypes = {};

export default EditDialog;
