import React from "react";
import MultipleSelect from "../../../../../Common/Forms/Select/MultipleSelect";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {useFetch} from "../../../../../../hooks/Common/useFetch";
import {getAllCorrectiveTypes} from "../../../../../../services/FleetManagement/DailyOperation/CorrectiveTypesService";

const CorrectiveTypesPicker = ({
  value,
  onChange,
  error,
  hasError,
  disabled
}) => {
  const {data} = useFetch(getAllCorrectiveTypes, {}, []);
  const [ types, setTypes ] = React.useState([]);
  React.useEffect(
    () => {
      setTypes(data.filter(t => !value.some(v => v.id === t.id)));
    },
    [ data, value ]
  );
  return (
    <MultipleSelect
      fullWidth
      label={
        <IntlMessages id="fleet.management.vehicle.corrective.form.type" />
      }
      value={value}
      onChange={onChange}
      options={types}
      disabled={disabled}
      limitTags={2}
    />
  );
};

CorrectiveTypesPicker.propTypes = {};

export default CorrectiveTypesPicker;
