import {
  ADD_MAINTENANCE_TYPE,
  APPLY_MAINTENANCE_TYPES_FILTERS,
  EDIT_MAINTENANCE_TYPE,
  FETCH_ALL_MAINTENANCE_TYPES,
  RESET_CURRENT_MAINTENANCE_TYPE,
  RESET_MAINTENANCE_TYPES_FILTERS,
  RESET_MAINTENANCE_TYPES_STATE,
  SET_CURRENT_MAINTENANCE_TYPE
} from "../../types/FleetManagement/MaintenanceTypesTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import MaintenanceType from "../../../domain/FleetManagement/DailyOperations/MaintenanceType";
import {
  createMaintenanceType,
  getAllMaintenanceTypes,
  updateMaintenanceType,
  updateMaintenanceTypeStatus
} from "../../../services/FleetManagement/DailyOperation/MaintenanceTypesService";

let id = 0;
const types = [
  new MaintenanceType(id++, "Refrigerante. Anticorrosivo", true),
  new MaintenanceType(id++, "Filtro Secador de Aire\n", false)
];

export const resetMaintenanceTypesState = () => ({
  type: RESET_MAINTENANCE_TYPES_STATE
});
export const applyMaintenanceTypesFilters = filters => ({
  type: APPLY_MAINTENANCE_TYPES_FILTERS,
  payload: filters
});
export const resetMaintenanceTypesFilters = () => ({
  type: RESET_MAINTENANCE_TYPES_FILTERS
});
export const setCurrentMaintenanceType = type => ({
  type: SET_CURRENT_MAINTENANCE_TYPE,
  payload: type
});
export const resetCurrentMaintenanceType = () => ({
  type: RESET_CURRENT_MAINTENANCE_TYPE
});
export const fetchAllMaintenanceTypes = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllMaintenanceTypes()
      .then(types => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_MAINTENANCE_TYPES, payload: types});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const addMaintenanceType = type => {
  return async dispatch => {
    dispatch(fetchStart());
    createMaintenanceType(type)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const newType = new MaintenanceType(
          response.transactionKey,
          type.description,
          true
        );
        dispatch({type: ADD_MAINTENANCE_TYPE, payload: newType});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const editMaintenanceType = type => {
  return async dispatch => {
    dispatch(fetchStart());
    updateMaintenanceType(type)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const editType = new MaintenanceType(
          response.transactionKey,
          type.description,
          type.status
        );
        dispatch({type: EDIT_MAINTENANCE_TYPE, payload: editType});
        dispatch(resetCurrentMaintenanceType());
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const editMaintenanceTypeState = (type, status) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateMaintenanceTypeStatus(type, status)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const editType = new MaintenanceType(
          response.transactionKey,
          type.description,
          status
        );
        dispatch({type: EDIT_MAINTENANCE_TYPE, payload: editType});
        dispatch(resetCurrentMaintenanceType());
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};
