import React from "react";
import useForm from "../../../../../../hooks/Common/useForm";
import validations from "./validations";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Box, Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import Checkbox from "../../../../../Common/Forms/Checkbox";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import MarkerMap from "../../../../../Common/Maps/OpenStreetMap/MarkerMap";
import PolygonMap from "../../../../../Common/Maps/OpenStreetMap/PolygonMap";
import Tabs from "../../../../../Common/Tabs";
import UbigeoSelect from "../../../UbigeoSelect";

const initialData = {
  name: "",
  fiscal: true,
  address: "",
  number: "",
  postalCode: "",
  municipality: "",
  province: "",
  country: "",
  latitude: 0,
  longitude: 0,
    ubigeo: "",
};

const Form = ({id, onSubmit, initData = null}) => {
  const {formData, setFormData, errors, handleSubmit} = useForm(
    initData || initialData,
    validations
  );
  const [tab, setTab] = React.useState('1');
  const handleSetMapCoordinates = ({latitude, longitude}) => {
      setFormData('longitude', longitude);
      setFormData('latitude', latitude);
  }
  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.name.length > 0}
            error={errors.name[0]}
            value={formData.name}
            id="add-headquarter-name"
            label={
              <IntlMessages id="administrator.clients.add.headquarter.form.headquarter.name" />
            }
            onChange={value => setFormData("name", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Checkbox
            checked={formData.fiscal}
            onChange={value => setFormData("fiscal", value)}
            name="add-headquarter-fiscal"
            label={
              <IntlMessages id="administrator.clients.add.headquarter.form.fiscal" />
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.address.length > 0}
            error={errors.address[0]}
            value={formData.address}
            id="add-headquarter-address"
            label={
              <IntlMessages id="administrator.clients.add.headquarter.form.address" />
            }
            onChange={value => setFormData("address", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.number.length > 0}
            error={errors.number[0]}
            value={formData.number}
            id="add-headquarter-number"
            label={
              <IntlMessages id="administrator.clients.add.headquarter.form.number" />
            }
            onChange={value => setFormData("number", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.postalCode.length > 0}
            error={errors.postalCode[0]}
            value={formData.postalCode}
            id="add-headquarter-postal-code"
            label={
              <IntlMessages id="administrator.clients.add.headquarter.form.postal.code" />
            }
            onChange={value => setFormData("postalCode", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.municipality.length > 0}
            error={errors.municipality[0]}
            value={formData.municipality}
            id="add-headquarter-municipality"
            label={
              <IntlMessages id="administrator.clients.add.headquarter.form.municipality" />
            }
            onChange={value => setFormData("municipality", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.province.length > 0}
            error={errors.province[0]}
            value={formData.province}
            id="add-headquarter-province"
            label={
              <IntlMessages id="administrator.clients.add.headquarter.form.province" />
            }
            onChange={value => setFormData("province", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.country.length > 0}
            error={errors.country[0]}
            value={formData.country}
            id="add-headquarter-country"
            label={
              <IntlMessages id="administrator.clients.add.headquarter.form.country" />
            }
            onChange={value => setFormData("country", value)}
          />
        </Grid>
          <Grid item xs={12} sm={4}>
              <OutlinedInput
                  fullWidth
                  type="number"
                  hasError={errors.latitude.length > 0}
                  error={errors.latitude[0]}
                  value={formData.latitude}
                  id="add-headquarter-latitude"
                  label={
                      <IntlMessages id="administrator.clients.add.headquarter.form.latitude" />
                  }
                  onChange={value => setFormData("latitude", value)}
              />
          </Grid>
          <Grid item xs={12} sm={4} style={{alignContent: 'center'}}>
              <OutlinedInput
                  fullWidth
                  type="number"
                  hasError={errors.longitude.length > 0}
                  error={errors.longitude[0]}
                  value={formData.longitude}
                  id="add-headquarter-longitude"
                  label={
                      <IntlMessages id="administrator.clients.add.headquarter.form.longitude" />
                  }
                  onChange={value => setFormData("longitude", value)}
              />
          </Grid>
          <Grid item xs={12} sm={8}>
              <UbigeoSelect
                  value={formData.ubigeo}
                  onChange={value => setFormData("ubigeo", value)}
                  initValue={initData?.ubigeo}
              />
          </Grid>
      </GridContainer>
      <Box  mt={2}>
        <Tabs
            tabs={[
              {
                value: '1', label: <IntlMessages id='marker' />
              },
              {
                value: '2', label: <IntlMessages id='polygon' />
              }
            ]}
            value={tab}
            onChange={tab => setTab(tab)}
        >
          {tab === '1'
              ? <MarkerMap
                  position={{
                    latitude: Number(formData.latitude),
                    longitude: Number(formData.longitude),
                  }}
                  setPosition={handleSetMapCoordinates}
              />
              : <PolygonMap
                  clearMap
                  center={[Number(formData.latitude), Number(formData.longitude)]}
              />
          }
        </Tabs>
      </Box>
    </form>
  );
};

export default Form;
