import {useDispatch, useSelector} from "react-redux";
import useForm from "../../../../../../hooks/Common/useForm";
import React from "react";
import {arrayMove} from "react-sortable-hoc";
import FormDialog from "../../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import Checkbox from "../../../../../Common/Forms/Checkbox";
import HeadersList from "../../../../../FleetManagement/Vehicles/Documentation/SummaryMode/ButtonsArea/HeaderDialog/HeadersList";
import {editDocumentsHeaders} from "../../../../../../redux/actions/RRHH/Documentation";

const HeaderDialog = ({open, setOpen}) => {
  const id = "edit-documents-headers-form";
  const {headers: tableHeaders, filters, selectedInfo, views} = useSelector(
    ({rrhh}) => rrhh.documentation.summary
  );
  const {formData, setFormData, setValues} = useForm({
    name: selectedInfo.name,
    isPublic: selectedInfo.isPublic,
    predetermined: selectedInfo.predetermined
  });
  const dispatch = useDispatch();
  const [ headers, setHeaders ] = React.useState(Object.values(tableHeaders));
  React.useEffect(
    () => {
      setHeaders(Object.values(tableHeaders));
    },
    [ tableHeaders ]
  );
  const onChangeHeader = (e, header) => {
    e.preventDefault();
    setHeaders(
      headers.map(h => {
        if (header.id === h.id) {
          h.active = !h.active;
        }
        return h;
      })
    );
  };
  const onSortEnd = ({oldIndex, newIndex}) => {
    setHeaders(arrayMove(headers, oldIndex, newIndex));
  };
  const onSubmit = e => {
    e.preventDefault();
    dispatch(
      editDocumentsHeaders(selectedInfo, {formData, headers}, filters, views)
    );
    setOpen(false);
  };
  React.useEffect(
    () => {
      setValues(selectedInfo);
    },
    [ selectedInfo ]
  );
  return (
    <FormDialog
      id={id}
      title={
        <IntlMessages id="rrhh.employees.documentation.summary.mode.headers.dialog.visualization" />
      }
      open={open}
      onClose={() => setOpen(false)}
      submitText={<IntlMessages id="form.button.save" />}
      cancelText={<IntlMessages id="form.button.cancel" />}
    >
      <form id={id} onSubmit={onSubmit}>
        <GridContainer>
          <Grid item xs={12} sm={4}>
            <OutlinedInput
              fullWidth
              label={
                <IntlMessages
                  id={
                    "rrhh.employees.documentation.summary.mode.headers.dialog.name"
                  }
                />
              }
              value={formData.name}
              onChange={value => setFormData("name", value)}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <Checkbox
              fullWidth
              label={
                <IntlMessages
                  id={
                    "rrhh.employees.documentation.summary.mode.headers.dialog.public"
                  }
                />
              }
              onChange={value => setFormData("isPublic", value)}
              checked={formData.isPublic}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <Checkbox
              fullWidth
              label={
                <IntlMessages
                  id={
                    "rrhh.employees.documentation.summary.mode.headers.dialog.predetermined"
                  }
                />
              }
              onChange={value => setFormData("predetermined", value)}
              checked={formData.predetermined}
            />
          </Grid>
          <Grid item xs={12}>
            <HeadersList
              headers={headers}
              onChangeStarred={onChangeHeader}
              onSortEnd={onSortEnd}
              useDragHandle={true}
            />
          </Grid>
        </GridContainer>
      </form>
    </FormDialog>
  );
};

export default HeaderDialog;
