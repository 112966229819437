import {connect} from "react-redux";
import CrudTableRow from "../../../../Common/Tables/CrudTable/CrudTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import RestoreTableRow from "../../../../Common/Tables/CrudTable/RestoreTableRow";
import TableComponent from "../../../../Common/Tables/Table";
import React from "react";
import TableHeader from "./TableHeader";
import {
  fetchAllMaintenanceTypes,
  setCurrentMaintenanceType
} from "../../../../../redux/actions/FleetManagement/MaintenanceTypes";
import Filters from "./Filters";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import FilterBuilder from "../../../../../utils/filters";

const Table = ({
  setOpenEdit,
  setOpenDelete,
  setOpenRestore,
  dispatch,
  data,
  filters
}) => {
  const onEdit = type => {
    dispatch(setCurrentMaintenanceType(type));
    setOpenEdit && setOpenEdit(true);
  };
  const onDelete = type => {
    dispatch(setCurrentMaintenanceType(type));
    setOpenDelete && setOpenDelete(true);
  };
  const onRestore = type => {
    dispatch(setCurrentMaintenanceType(type));
    setOpenRestore && setOpenRestore(true);
  };

  React.useEffect(() => {
    dispatch(fetchAllMaintenanceTypes(filters));
  }, []);

  const RenderRow = ({row}) =>
    row.status ? (
      <CrudTableRow onEdit={() => onEdit(row)} onDelete={() => onDelete(row)}>
        <StyledTableCell>{row.description}</StyledTableCell>
        <StyledTableCell>
          <IntlMessages id={row.status ? "table.cell.yes" : "table.cell.no"} />
        </StyledTableCell>
      </CrudTableRow>
    ) : (
      <RestoreTableRow onRestore={() => onRestore(row)}>
        <StyledTableCell>{row.description}</StyledTableCell>
        <StyledTableCell>
          <IntlMessages id={row.status ? "table.cell.yes" : "table.cell.no"} />
        </StyledTableCell>
      </RestoreTableRow>
    );

  return (
    <React.Fragment>
      <Filters />
      <TableComponent
        data={Object.values(data)}
        head={<TableHeader />}
        renderRow={RenderRow}
        getRowKey={row => row.id}
      />
    </React.Fragment>
  );
};

const mapStateToProps = ({fleetManagement}) => {
  const {data, filters} = fleetManagement.maintenanceTypes;
  const filteredData = new FilterBuilder(Object.values(data))
    .byFieldIncludes("description", filters.description)
    .byFieldIncludes("status", filters.status)
    .build();
  return {data: filteredData, filters};
};

export default connect(mapStateToProps)(Table);
