import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Box, Grid, Typography} from "@material-ui/core";
import OutlinedDatePicker from "../../../../../../Common/Forms/OutlinedDatePicker";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import FileInput from "../../../../../../Common/Forms/FileInput";
import React from "react";

const FileInfoArea = ({formData, setFormData, edit=false, errors}) => {
    return (
        <GridContainer spacing={2}>
            <Grid item xs={12}>
                <OutlinedDatePicker
                    label={<IntlMessages id={ "rrhh.employees.documentation.list.mode.detail.form.expiration.date"}/>}
                    value={formData.expirationDate}
                    onChange={value => setFormData('expirationDate', value)}
                    fullWidth
                    hasError={errors?.expirationDate?.length>0}
                    error={errors?.expirationDate[0]}
                />
            </Grid>
            <Grid item xs={12}>
                <OutlinedInput
                    label={<IntlMessages id={ "rrhh.employees.documentation.list.mode.detail.form.notice.days"}/>}
                    value={formData.noticeDays}
                    onChange={value => setFormData('noticeDays', value)}
                    fullWidth
                    type="number"
                    hasError={errors?.noticeDays?.length>0}
                    error={errors?.noticeDays[0]}
                />
            </Grid>
            <Grid item xs={12}>
                {edit
                    ?<Box textAlign="center">
                        <Typography variant="body2">
                            url: {formData.url}
                        </Typography>
                    </Box>
                    :<FileInput
                        files={formData.file}
                        maxFiles={1}
                        onChange={file => setFormData('file', file)}
                        error={errors?.file?.length>0}
                        helperText={errors?.file[0]}
                    />
                }
            </Grid>
        </GridContainer>
    );
};

export default FileInfoArea;