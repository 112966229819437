import React from "react";
import {useDispatch, useSelector} from "react-redux";
import FormDialog from "../../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import Form from "../Form";
import {dialogs} from "../../constants";
import {
  closeVehicleCorrectivesDialog,
  editVehicleCorrectives
} from "../../../../../../redux/actions/FleetManagement/VehicleCorrectives";
import {parseDateToInput} from "../../../../../../utils/dates";

const EditDialog = () => {
  const id = "edit-vehicle-correctives-form";
  const {current, dialog: {name}} = useSelector(
    ({fleetManagement}) => fleetManagement.vehicleCorrectives
  );
  const dispatch = useDispatch();
  const onEdit = data => {
    dispatch(editVehicleCorrectives({id: current.id, ...data}));
    onClose();
  };
  const onClose = () => {
    dispatch(closeVehicleCorrectivesDialog());
  };
  return (
    <FormDialog
      id={id}
      title={
        <IntlMessages id={"fleet.management.vehicle.corrective.edit.title"} />
      }
      submitText={<IntlMessages id={"label.form.save"} />}
      cancelText={<IntlMessages id={"label.form.cancel"} />}
      isOpen={dialogs.EDIT_DIALOG === name}
      onClose={onClose}
    >
      {name === dialogs.EDIT_DIALOG && (
        <Form
          initialData={{
            vehicle: current.vehicle || "",
            types: current.correctives || [],
            status: current.status.id || "",
            files: current.files || [],
            postponeDate: parseDateToInput(current.postponeDate) || "",
            observations: current.observations || ""
          }}
          id={id}
          onSubmit={onEdit}
        />
      )}
    </FormDialog>
  );
};

EditDialog.propTypes = {};

export default EditDialog;
