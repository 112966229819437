import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import React from "react";

export default {
  description: [
    {
      rule: v => v.toString().trim() !== "",
      message: <IntlMessages id="form.field.error" />
    }
  ]
  // securityControl: [{ rule: v => v.trim() !== '', message: <IntlMessages id="form.field.error" /> }],
};
