import React from 'react';
import {useSelector} from "react-redux";
import {useIntl} from "react-intl";
import {DataGrid} from "@mui/x-data-grid";
import {parseDateToGlobalFormat} from "../../../../../../utils/dates";

const Changelog = () => {
    const {currentIndexFile} = useSelector(({backOffice}) => backOffice.indexFiles);
    const intl = useIntl();
    const columns = [
        {
            field: 'id',
            hide: true
        },
        {
            field: 'loadedAt',
            headerName: intl.messages['loaded.at'],
            width: 150,
            valueFormatter: ({value}) => parseDateToGlobalFormat(value)
        },
        {
            field: 'field',
            headerName: intl.messages['field'],
            width: 150,
        },
        {
            field: 'valueFrom',
            headerName: intl.messages['value.from'],
            width: 150,
        },
        {
            field: 'valueTo',
            headerName: intl.messages['value.to'],
            width: 150,
        }
    ]

    return (
        <DataGrid
            style={{
                height: '250px'
            }}
            rows={currentIndexFile?.changelog || []}
            columns={columns}
            disableSelectionOnClick
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            rowHeight={40}
        />
    );
};

export default Changelog;