import {
    CLOSE_REFUNDS_DIALOG,
    FETCH_REFUNDS,
    OPEN_REFUNDS_DIALOG,
    RESET_REFUNDS_FILTERS,
    RESET_REFUNDS_STATE,
    SET_REFUNDS_FILTERS,
    SET_SELECTED_REFUNDS
} from "../../types/Guardian/RefundTypes";
import {
    cancelPrevFetchAllAccountingByGuardian,
    fetchAllAccountingByUser,
    refundAccounting
} from "../../../services/AccountingService";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import Accounting from "../../../domain/Accounting";

export const resetRefundState = () => ({type: RESET_REFUNDS_STATE});
export const openRefundsDialog = dialog => ({type: OPEN_REFUNDS_DIALOG, payload: dialog});
export const closeRefundsDialog = dialog => ({type: CLOSE_REFUNDS_DIALOG, payload: dialog});
export const setRefundsFilters = filters => ({type: SET_REFUNDS_FILTERS, payload: filters});
export const resetRefundsFilters = () => ({type: RESET_REFUNDS_FILTERS});

export const setSelectedRefunds = (selected) => ({type: SET_SELECTED_REFUNDS, payload: selected});
export const fetchAllAvailableRefunds = (filters) => {
    return async dispatch => {
        try {
            dispatch(setSelectedRefunds([]));
            dispatch(fetchStart());
            cancelPrevFetchAllAccountingByGuardian();
            const refunds = await fetchAllAccountingByUser({
                ...filters,
                state: [{id: 3}]
            });
            dispatch(fetchSuccess());
            dispatch({type: FETCH_REFUNDS, payload: [...refunds]});
        } catch (e) {
            dispatch(fetchError(e.message));
        }
    }
}


export const refundSelectedAccounting = (selected, filters) => {
    return async dispatch => {
        try{
            dispatch(fetchStart());
            const response = await refundAccounting(selected);
            dispatch(fetchSuccess(response.transactionMessage));
            dispatch(fetchAllAvailableRefunds(filters));
        }catch (e) {
            dispatch(fetchError(e.message));
        }
    }
}