import React from 'react';
import Typography from "@material-ui/core/Typography";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import StateSelect from "../../common/StateSelect";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {useDispatch, useSelector} from "react-redux";
import {updateProviderOrderState} from "../../../../../redux/thunks/Logistic/providerOrders";
import {parseDateToDateString} from "../../../../../utils/dates";

const BasicInfoForm = ({initData}) => {
    const {currentOrder} = useSelector(({logistic}) => logistic.providerOrders);
    const dispatch = useDispatch();
    const handleChangeState = (value) => {
        dispatch(updateProviderOrderState({
            order: currentOrder,
            stateId: value,
        }));
    }
    return (
        <fieldset>
            <legend>
                <Typography>
                    <IntlMessages id="general.data" />
                </Typography>
            </legend>
            <GridContainer
                spacing={2}
                className="basic-info-form"
            >
                <Grid xs={12} md={5}>
                    <Typography>
                        ID: {initData.id}
                    </Typography>
                </Grid>
                <Grid xs={12} md={2}>
                    <Typography>
                        {parseDateToDateString(initData.orderDate)}
                    </Typography>
                </Grid>
                <Grid xs={12} md={5}>
                    <StateSelect
                        value={initData.state}
                        onChange={handleChangeState}
                    />
                </Grid>
            </GridContainer>
        </fieldset>
    );
};

export default BasicInfoForm;