import React from "react";
import FilterBuilder from "../../../../../utils/filters";
import {connect} from "react-redux";
import {Box} from "@material-ui/core";
import TableContainer from "../../../../Common/Tables/TableContainer";
import CrudTableRow from "../../../../Common/Tables/CrudTable/CrudTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import RestoreTableRow from "../../../../Common/Tables/CrudTable/RestoreTableRow";
import Filters from "./Filters";
import TableHeader from "./TableHeader";
import {
  fetchAllConfigurationVehicleRoles,
  setCurrentConfigurationVehicleRole
} from "../../../../../redux/actions/Configuration/VehicleRoles";

const VehicleRolesTable = ({
  data,
  dispatch,
  openEdit,
  openDelete,
  openRestore
}) => {
  const onEdit = role => {
    openEdit && openEdit();
    dispatch(setCurrentConfigurationVehicleRole(role));
  };
  const onDelete = role => {
    openDelete && openDelete();
    dispatch(setCurrentConfigurationVehicleRole(role, 2));
  };
  const onRestore = role => {
    openRestore && openRestore();
    dispatch(setCurrentConfigurationVehicleRole(role, 2));
  };
  React.useEffect(() => {
    dispatch(fetchAllConfigurationVehicleRoles());
  }, []);

  return (
    <Box display="flex" flexDirection="column" style={{gap: "10px"}}>
      <Filters />
      <TableContainer head={<TableHeader />}>
        {Object.values(data).map(role => (
          <React.Fragment key={role.id}>
            {role.state ? (
              <CrudTableRow
                onEdit={() => onEdit(role)}
                onDelete={() => onDelete(role)}
              >
                <StyledTableCell>{role.id}</StyledTableCell>
                <StyledTableCell>{role.description}</StyledTableCell>
              </CrudTableRow>
            ) : (
              <RestoreTableRow onRestore={() => onRestore(role)}>
                <StyledTableCell>{role.id}</StyledTableCell>
                <StyledTableCell>{role.description}</StyledTableCell>
              </RestoreTableRow>
            )}
          </React.Fragment>
        ))}
      </TableContainer>
    </Box>
  );
};

const mapStateToProps = ({configuration}) => {
  const {data, filters} = configuration.vehicleRoles;
  const filteredData = new FilterBuilder(Object.values(data))
    .byFieldIncludes("id", filters.code)
    .byFieldIncludes("description", filters.description)
    .build();
  return {data: filteredData};
};

export default connect(mapStateToProps)(VehicleRolesTable);
