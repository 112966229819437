import {
  ADD_VEHICLE_DOCUMENTS_TYPE,
  APPLY_VEHICLE_DOCUMENTS_TYPES_FILTERS,
  CLOSE_VEHICLE_DOCUMENTS_TYPES_DIALOG,
  EDIT_VEHICLE_DOCUMENTS_TYPE,
  FETCH_ALL_VEHICLE_DOCUMENTS_TYPES,
  OPEN_VEHICLE_DOCUMENTS_TYPES_DIALOG,
  RESET_CURRENT_VEHICLE_DOCUMENTS_TYPE,
  RESET_VEHICLE_DOCUMENTS_TYPES_FILTERS,
  RESET_VEHICLE_DOCUMENTS_TYPES_STATE,
  SET_CURRENT_VEHICLE_DOCUMENTS_TYPE
} from "../../types/FleetManagement/DocumentsTypesTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import DocumentsTypes from "../../../domain/FleetManagement/Vehicles/DocumentsTypes";
import {
  createVehicleDocumentsType,
  getAllVehicleDocumentsTypes,
  updateVehicleDocumentsType
} from "../../../services/FleetManagement/Vehicles/DocumentsTypesService";

let id = 0;
const types = [
  new DocumentsTypes(id++, "Tipo 1", "code 1", true),
  new DocumentsTypes(id++, "Tipo 2", "code 2", false)
];

export const resetVehicleDocumentsTypesState = () => ({
  type: RESET_VEHICLE_DOCUMENTS_TYPES_STATE
});
export const applyVehicleDocumentsTypesFilters = filters => ({
  type: APPLY_VEHICLE_DOCUMENTS_TYPES_FILTERS,
  payload: filters
});
export const resetVehicleDocumentsTypesFilters = () => ({
  type: RESET_VEHICLE_DOCUMENTS_TYPES_FILTERS
});
export const setCurrentVehicleDocumentsType = type => ({
  type: SET_CURRENT_VEHICLE_DOCUMENTS_TYPE,
  payload: type
});
export const resetCurrentVehicleDocumentsType = () => ({
  type: RESET_CURRENT_VEHICLE_DOCUMENTS_TYPE
});
export const openVehicleDocumentsTypesDialog = name => ({
  type: OPEN_VEHICLE_DOCUMENTS_TYPES_DIALOG,
  payload: name
});
export const closeVehicleDocumentsTypesDialog = () => ({
  type: CLOSE_VEHICLE_DOCUMENTS_TYPES_DIALOG
});

export const fetchAllVehicleDocumentsTypes = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllVehicleDocumentsTypes()
      .then(types => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_VEHICLE_DOCUMENTS_TYPES, payload: types});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const addVehicleDocumentsType = type => {
  return async dispatch => {
    dispatch(fetchStart());
    createVehicleDocumentsType(type)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const newType = new DocumentsTypes(
          id++,
          type.description,
          type.securityControl,
          true
        );
        dispatch({type: ADD_VEHICLE_DOCUMENTS_TYPE, payload: newType});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const editVehicleDocumentsType = type => {
  return async dispatch => {
    dispatch(fetchStart());
    updateVehicleDocumentsType(type)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const editType = new DocumentsTypes(
          type.id,
          type.description,
          type.securityControl,
          type.status
        );
        dispatch({type: EDIT_VEHICLE_DOCUMENTS_TYPE, payload: editType});
        dispatch(resetCurrentVehicleDocumentsType());
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const editVehicleDocumentsTypeState = (type, status) => {
  return async dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess("Se actualizo el estado correctamente"));
      const editType = new DocumentsTypes(
        type.id,
        type.description,
        type.securityControl,
        status
      );
      dispatch({type: EDIT_VEHICLE_DOCUMENTS_TYPE, payload: editType});
      dispatch(resetCurrentVehicleDocumentsType());
    }, 1500);
    // updateVehicleDocumentsTypeStatus(type, status)
    //     .then(response => {
    //         dispatch(fetchSuccess(response.transactionMessage));
    //         const editType = new VehicleDocumentsType(
    //             response.transactionKey,
    //             type.description,
    //             type.code,
    //             status
    //         )
    //         dispatch({type:EDIT_VEHICLE_DOCUMENTS_TYPE, payload: editType});
    //         dispatch(resetCurrentVehicleDocumentsType());
    //     }).catch(err => {
    //     dispatch(fetchError(err.message));
    // });
  };
};
