import React from 'react';
import PropTypes from 'prop-types';
import Select from "../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {useFetch} from "../../../../../../../hooks/Common/useFetch";
import {getAllWorkshops} from "../../../../../../../services/FleetManagement/DailyOperation/WorkshopRequestService";

const WorkshopSelect = ({id, value, onChange}) => {
    const {data: workshops} = useFetch(getAllWorkshops, {}, []);

    return (
        <Select
            id={id}
            label={
                <IntlMessages id="fleet.management.workshop.request.table.workshop" />
            }
            value={value}
            onChange={e => onChange(e.target.value)}
            fullWidth
        >
            <MenuItem value={""}>
                <IntlMessages id="select.all" />
            </MenuItem>
            {workshops.map(w => (
                <MenuItem value={w.id} key={w.id}>
                    {w.description}
                </MenuItem>
            ))}
        </Select>
    );
};

WorkshopSelect.propTypes = {

};

export default WorkshopSelect;