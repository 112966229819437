import React, {useEffect, useMemo} from 'react';
import Grid from "@material-ui/core/Grid";
import Select from "../../../../../../Common/Forms/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {devices} from "./contants";


const PackageTypesSelect = ({value, setValue, device}) => {
    const packageTypes = useMemo(() => {
        return Object.values(devices)?.find(d => device === d.id)?.packageTypes || {};
    }, [device])
    const onChange = ({target}) => {
        setValue && setValue(target.value)
    }
    useEffect(() => {
        setValue('');
    }, [device]);
    return (
        <Grid item xs={12} md={4}>
            <Select
                label={<IntlMessages id="package.type" />}
                value={value}
                onChange={onChange}
                fullWidth
                disabled={!(device)}
            >
                {Object.values(packageTypes)?.map(type => (
                    <MenuItem key={type.id} value={type.id}>
                        {type.name}
                    </MenuItem>
                ))}
            </Select>
        </Grid>
    );
};

export default PackageTypesSelect;