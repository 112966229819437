import React from "react";
import GridContainer from "../../../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import VehiclesSelect from "./VehiclesSelect";
import DocumentTypeSelect from "./DocumentTypeSelect";
import {useFetch} from "../../../../../../../../hooks/Common/useFetch";
import {getAllDocumentTypes} from "../../../../../../../../services/FleetManagement/Vehicles/DocumentTypeService";

const VehicleInfoDialog = ({vehicle, type, setFormData, edit, errors}) => {
  const {data: types, executeService} = useFetch(
    getAllDocumentTypes,
    {vehicle},
    []
  );
  React.useEffect(
    () => {
      executeService();
    },
    [ vehicle ]
  );
  return (
    <GridContainer spacing={2}>
      <Grid item xs={12}>
        <VehiclesSelect
          value={vehicle}
          onChange={value => setFormData("vehicle", value)}
          disabled={edit}
          errors={errors}
        />
      </Grid>
      <Grid item xs={12}>
        <DocumentTypeSelect
          value={type}
          onChange={value => setFormData("type", value)}
          types={types}
          disabled={edit}
          errors={errors}
        />
      </Grid>
    </GridContainer>
  );
};

export default VehicleInfoDialog;
