import React from "react";
import useForm from "../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import {editAavvServiceNonComplianceReason} from "../../../../../redux/thunks/Logistic/aavvServices";
import {setSelectedService} from "../../../../../redux/reducers/Logistic/aavvServices";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import {useIntl} from "react-intl";
import NonComplianceSelect from "./NonComplianceSelect";

const Form = ({id}) => {
  const dispatch = useDispatch();
  const {apiFilters, selectedService: service} = useSelector(({logistic}) => logistic.aavvServices);
  const {formData, setFormData, handleSubmit} = useForm({
      nonComplianceReason: service.nonComplianceReason,
      noComplianceDetail: service.noComplianceDetail,
  });
  const intl = useIntl();
  const onSubmit = data => {
    dispatch(
        editAavvServiceNonComplianceReason(
            {
                service: {serviceId: service.serviceId, ...data},
                filters: apiFilters
            }
      )
    );
    dispatch(setSelectedService(null));
  };
  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
        <NonComplianceSelect
            setValue={value => setFormData("nonComplianceReason", value)}
            value={formData.nonComplianceReason}
        />
          <Grid item xs={12}>
              <OutlinedInput
                  id={`${id}-detail`}
                  label={`${intl.messages['detail']} ${intl.messages['non.compliance']}`}
                  onChange={value => setFormData("noComplianceDetail", value)}
                  value={formData.noComplianceDetail}
                  fullWidth
                  multiline
                  rows={4}
              />
          </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
