import React from 'react';
import PageContainer from "../../../../../components/Logistic/Purchases/Providers/PageContainer";
import Table from "../../../../../components/Logistic/Purchases/Providers/Table";
import AddDialog from "../../../../../components/Logistic/Purchases/Providers/AddDialog";
import FloatingButtonWithAdd from "../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import {useDispatch} from "react-redux";
import {openDialog} from "../../../../../redux/reducers/Logistic/providers";
import {dialogsList} from "../../../../../components/Logistic/Purchases/Providers/constants";
import DetailDialog from "../../../../../components/Logistic/Purchases/Providers/DetailDialog";

const Providers = () => {
    const dispatch = useDispatch();
    return (
        <PageContainer>
            <Table />
            <AddDialog />
            <FloatingButtonWithAdd onAddClicked={() => dispatch(openDialog(dialogsList.addDialog))} />
            <DetailDialog />
        </PageContainer>
    );
};

export default Providers;