import {
  ADD_RETURN_TYPE,
  APPLY_RETURN_TYPES_FILTERS,
  EDIT_RETURN_TYPE,
  FETCH_ALL_RETURN_TYPES,
  RESET_CURRENT_RETURN_TYPE,
  RESET_RETURN_TYPES_FILTERS,
  RESET_RETURN_TYPES_STATE,
  SET_CURRENT_RETURN_TYPE
} from "../../types/Logistic/ReturnTypesTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {
  createReturnType,
  getAllReturnTypes,
  updateReturnType,
  updateReturnTypeState
} from "../../../services/Logistics/Distribution/ReturnTypeService";
import ReturnType from "../../../domain/Logistic/Distribution/ReturnType";

export const resetReturnTypesState = () => ({type: RESET_RETURN_TYPES_STATE});
export const applyReturnTypesFilters = filters => ({
  type: APPLY_RETURN_TYPES_FILTERS,
  payload: filters
});
export const resetReturnTypesFilters = () => ({
  type: RESET_RETURN_TYPES_FILTERS
});
export const setCurrentReturnType = type => ({
  type: SET_CURRENT_RETURN_TYPE,
  payload: type
});
export const resetCurrentReturnType = () => ({type: RESET_CURRENT_RETURN_TYPE});
export const fetchAllReturnTypes = () => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllReturnTypes()
      .then(types => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_RETURN_TYPES, payload: types});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const addReturnType = type => {
  return async dispatch => {
    dispatch(fetchStart());
    createReturnType(type)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const newType = new ReturnType(
          response.transactionKey,
          type.description,
          type.code,
          true
        );
        dispatch({type: ADD_RETURN_TYPE, payload: newType});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const editReturnType = type => {
  return async dispatch => {
    dispatch(fetchStart());
    updateReturnType(type)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const editType = new ReturnType(
          response.transactionKey,
          type.description,
          type.code,
          type.state
        );
        dispatch({type: EDIT_RETURN_TYPE, payload: editType});
        dispatch(resetCurrentReturnType());
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const editReturnTypeState = (type, state) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateReturnTypeState(type, state)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const editType = new ReturnType(
          response.transactionKey,
          type.description,
          type.code,
          state
        );
        dispatch({type: EDIT_RETURN_TYPE, payload: editType});
        dispatch(resetCurrentReturnType());
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};
