import React from 'react';
import Select from "../../../Common/Forms/Select";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {useFetch} from "../../../../hooks/Common/useFetch";
import {fetchAllProvidersSelect} from "../../../../services/Logistics/Purchases/ProviderOrdersService";

const ProviderSelect = ({name = 'provider', value, onChange, error, errorMessage, required = false}) => {
    const {data: providers} = useFetch(fetchAllProvidersSelect, {}, []);
    const handleOnChange = ({target}) => {
        const {value} = target;
        const provider = providers.find(provider => provider.id === value);
        onChange({
            value,
            ...provider,
        });
    }
    return (
        <Select
            label={<IntlMessages id="provider"/>}
            name={name}
            value={value}
            onChange={handleOnChange}
            error={error}
            errorMessage={errorMessage}
            fullWidth
            required={required}
        >
            {providers?.map(provider => (
                <MenuItem key={provider.id} value={provider.id}>
                    {provider.name}
                </MenuItem>
            ))}
        </Select>
    );
};
export default ProviderSelect;