import React from "react";
import FormDialog from "../../../../../../Common/Dialogs/FormDialog";
import useForm from "../../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import Checkbox from "../../../../../../Common/Forms/Checkbox";
import {useDispatch} from "react-redux";
import {addSummaryDocumentsView} from "../../../../../../../redux/actions/FleetManagement/Documents";

const AddViewDialog = ({open, setOpen}) => {
  const id = "add-view-dialog-form";
  const {formData, setFormData, resetForm, handleSubmit} = useForm({
    name: "",
    isPublic: false,
    predetermined: false
  });
  const dispatch = useDispatch();
  const onSubmit = data => {
    onClose();
    dispatch(addSummaryDocumentsView(data));
  };
  const onClose = () => {
    setOpen(false);
    resetForm();
  };
  return (
    <FormDialog
      id={id}
      isOpen={open}
      onClose={onClose}
      title={
        <IntlMessages
          id={
            "fleet.management.vehicles.documentation.summary.mode.headers.add.dialog.title"
          }
        />
      }
    >
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <GridContainer spacing={2}>
          <Grid item xs={12}>
            <OutlinedInput
              fullWidth
              label={
                <IntlMessages
                  id={
                    "fleet.management.vehicles.documentation.summary.mode.headers.dialog.name"
                  }
                />
              }
              value={formData.name}
              onChange={value => setFormData("name", value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              fullWidth
              label={
                <IntlMessages
                  id={
                    "fleet.management.vehicles.documentation.summary.mode.headers.dialog.public"
                  }
                />
              }
              onChange={value => setFormData("isPublic", value)}
              checked={formData.isPublic}
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              fullWidth
              label={
                <IntlMessages
                  id={
                    "fleet.management.vehicles.documentation.summary.mode.headers.dialog.predetermined"
                  }
                />
              }
              onChange={value => setFormData("predetermined", value)}
              checked={formData.predetermined}
            />
          </Grid>
        </GridContainer>
      </form>
    </FormDialog>
  );
};

export default AddViewDialog;
