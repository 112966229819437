import React from 'react';
import ProviderSelect from "../../common/ProviderSelect";
import {Grid, IconButton} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import TableContainer from "../../../../Common/Tables/TableContainer";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import DeleteIcon from "@material-ui/icons/Delete";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import ContainedButton from "../../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Typography from "@material-ui/core/Typography";
import {useDispatch, useSelector} from "react-redux";
import {
    addProviderToProviderOrder,
    deleteProviderToProviderOrder
} from "../../../../../redux/thunks/Logistic/providerOrders";

const ProvidersList = ({providers = [], editable = true}) => {
    const [provider, setProvider] = React.useState('');
    const {currentOrder} = useSelector(({logistic}) => logistic.providerOrders);
    const dispatch = useDispatch();
    const handleAddProvider = () => {
        dispatch(addProviderToProviderOrder({
            order: currentOrder,
            providerId: provider.id
        }));
        setProvider('');
    }
    const handleDeleteProvider = (providerId) => {
        dispatch(deleteProviderToProviderOrder({
            order: currentOrder,
            providerId,
        }));
    }
    return (
        <fieldset>
            <legend>
                <Typography
                    style={{
                        marginBottom: '10px'
                    }}
                >
                    <IntlMessages id="providers" />
                </Typography>
            </legend>
            <GridContainer
                spacing={2}
                style={{
                    marginBottom: '2px',
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {editable && <>
                    <Grid item xs={6}>
                        <ProviderSelect
                            value={provider.value}
                            onChange={value => setProvider(value)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton onClick={handleAddProvider}>
                            <AddIcon/>
                        </IconButton>
                    </Grid>
                </>}
                <Grid item xs={editable ? 4 : 12} className="grid">
                    <ContainedButton
                        color="primary"
                        text={<IntlMessages id="see.history" />}
                        className="add-button"
                    />
                </Grid>
            </GridContainer>
            <TableContainer>
                {providers.map((p, index) => (
                    <StyledTableRow key={index}>
                        <StyledTableCell>
                            {p.name}
                        </StyledTableCell>
                        <StyledTableCell>
                            {p.state}
                        </StyledTableCell>
                        <StyledTableCell>
                            {p.date}
                        </StyledTableCell>
                        {editable && <StyledTableCell>
                            <IconButton onClick={() => handleDeleteProvider(p.id)}>
                                <DeleteIcon/>
                            </IconButton>
                        </StyledTableCell>}
                    </StyledTableRow>
                ))}
            </TableContainer>


            
        </fieldset>
    );
};

export default ProvidersList;