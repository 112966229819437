import React from 'react';
import {useDispatch} from "react-redux";
import useForm from "../../../../../hooks/Common/useForm";
import Grid from "@material-ui/core/Grid";
import ContainedButton from "../../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {resetFilters} from "../../../../../redux/reducers/Logistic/Planner";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import OutlinedDatePicker from "../../../../Common/Forms/OutlinedDatePicker";
import moment from "moment";
import {getAllServicesByDate} from "../../../../../redux/thunks/Logistic/planner";
import {parseDateToInput} from "../../../../../utils/dates";

const initialData = {
    searchDate: parseDateToInput(Date.now()),
}
const Form = () => {
    const id = 'planner-filters';
    const dispatch = useDispatch();
    const {formData, setFormData, handleSubmit, resetForm} = useForm(initialData);

    const onSubmit = data => {
        dispatch(getAllServicesByDate(data));
    }
    const onClearFilters = () => {
        resetForm();
        dispatch(resetFilters());
    }
    React.useEffect(() => {
        onSubmit(formData);
    }, []);
    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12} md={4}>
                    <OutlinedDatePicker
                        label={<IntlMessages id='date' />}
                        value={formData.searchDate}
                        onChange={value => setFormData('searchDate', value)}
                        fullWidth
                        inputProps={{
                            min:moment().format('YYYY-MM-DD')
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <ContainedButton
                        type='submit'
                        form={id}
                        color='primary'
                        text={<IntlMessages id='filters.button.apply' />}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <ContainedButton
                        onClick={onClearFilters}
                        text={<IntlMessages id='filters.button.clear' />}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form;