import React from "react";
import PropTypes from "prop-types";
import {useSelector} from "react-redux";
import {useStyles} from "../../../Vehicles/Documentation/SummaryMode/DocumentsTable/styles";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import TableCellWithSorting from "../../../../Common/Tables/TableCellWithSorting";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";

const TableHeader = ({order, orderBy, onSort}) => {
  const {headers} = useSelector(
    ({fleetManagement}) => fleetManagement.vehicleMaintenances
  );
  const classes = useStyles();

  return (
    <StyledTableRow>
      <TableCellWithSorting
        property="vehicle"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
        className={classes.stickyHeaderCell}
      >
        <IntlMessages
          id={
            "fleet.management.vehicles.documentation.summary.mode.table.vehicle"
          }
        />
      </TableCellWithSorting>
      {Object.values(headers)
        .filter(h => h.active)
        .map((header, index) => (
          <StyledTableCell key={index}>{header.label}</StyledTableCell>
        ))}
    </StyledTableRow>
  );
};

TableHeader.propTypes = {};

export default TableHeader;
