import React from 'react';
import {getToken} from "../../../../services/StorageService";
import Box from "@material-ui/core/Box";

const WallMap = () => {
    return (
        <Box
            src={`${process.env.REACT_APP_TMS_MODULES_URL}/${getToken()}?module=wall-maps`}
            width="100%"
            height="100%"
            component='iframe'
            style={{
                border: 'none'
            }}
        />
    );
};

WallMap.propTypes = {

};

export default WallMap;