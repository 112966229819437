import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId, getUserId} from "../../StorageService";
import Response from "../../../domain/Response";
import VehicleDocumentItem from "../../../domain/FleetManagement/Vehicles/VehicleDocumentItem";
import SummaryView from "../../../domain/FleetManagement/Vehicles/SummaryView";
import SummaryHeader from "../../../domain/FleetManagement/Vehicles/SummaryHeader";

export const getAllDocuments = async (request) => {
    try {
        const {data} = await axiosInstance.post(
            '/Vehiculo_Documentos/sel',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_vehiculo: request?.vehicle,
                nu_id_tipo_documento: request?.type
            }
        )
        return data.map(t => VehicleDocumentItem.map(t));
    }catch (e) {
        return Promise.reject(e);
    }
}

export const createDocument = async doc => {
    try {
        const {data} = await axiosInstance.post(
            '/Vehiculo_Documentos/ins_documento_vehiculo',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_vehiculo: doc.vehicle,
                nu_id_tipo_documento: doc.type,
                dt_fec_caducidad: doc.expirationDate,
                nu_dias_preaviso: doc.noticeDays,
                archivo: {
                    archivobase64: doc.file.base64File,
                    contentType: doc.file.type,
                    extension: doc.file.extension,
                    nombre: doc.file.name
                }
            }
        )
        const response = Response.map(data);
        if (response.transactionState !== "0") {
            return response;
        }
        throw Error(response.transactionMessage);
    }catch (e) {
        return Promise.reject(e);
    }
}

export const editDocument = async doc => {
    try {
        const {data} = await axiosInstance.post(
            '/Vehiculo_Documentos/upd_documento_vehiculo',
            {
                nu_id_documento: doc.id,
                dt_fec_caducidad: doc.expirationDate,
                nu_dias_preaviso: doc.noticeDays,
            }
        )
        const response = Response.map(data);
        if (response.transactionState !== "0") {
            return response;
        }
        throw Error(response.transactionMessage);
    }catch (e) {
        return Promise.reject(e);
    }
}

const summaryData = [
    {
        id: 1,
        vehiculo: 'ASE-845',
        documentos: [
            {
                label: "embarkationCard",
                caducidad: 1
            },
            {
                label: "trafficPermission",
                caducidad: 2
            }
        ]
    },
    {
        id: 2,
        vehiculo: 'FUV-456',
        documentos: [
            {
                label: "embarkationCard",
                caducidad: 2
            },
            {
                label: "trafficPermission",
                caducidad: 3
            }
        ]
    }
]

export const getAllSummaryDocuments = async (filters) => {
    try {
        const {data} = await axiosInstance.post(
            '/Vehiculo_Documentos/sel_resumen',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_tipo_vizualizacion: filters.view
            }
        )
        return data.map(vehicle => {
            let summary = {};
            vehicle.documentos.forEach(d => {
                summary[d.nu_id_tipo_documento] = d.nu_estado;
            });
            return {
                id: vehicle.nu_id_vehiculo,
                vehicle: vehicle.vc_placa,
                ...summary,
                documentation: Object.values(summary),
                tags: vehicle.tags.map(t => t.nu_id_tag)
            };
        });
    }catch (e) {
        return Promise.reject(e);
    }
}

export const getDocumentsDashboardData = async (filters) => {
    try {
        const {data} = await axiosInstance.post(
            '/Vehiculo_Documentos/sel_resumen_dashboard',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_tipo_vizualizacion: filters.view
            }
        )
        return data;
    }catch (e) {
        return Promise.reject(e);
    }
}

export const getAllSummaryViews = async ()  => {
    try {
        const {data} = await axiosInstance.post(
            '/Vehiculo_Tipo_Visualizacion/sel',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_usuario: getUserId()
            }
        )
        return data.map(v => SummaryView.map(v));
    }catch (e) {
        return Promise.reject(e);
    }
}

export const createSummaryView = async view => {
    try {
        const {data} = await axiosInstance.post(
            '/Vehiculo_Tipo_Visualizacion/ins',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_usuario: getUserId(),
                vc_desc_tipo_visualizacion: view.name,
                bi_predeterminado: view.predetermined,
                bi_publico: view.isPublic,
            }
        );
        const response = Response.map(data);
        if (response.transactionState !== "0") {
            return response;
        }
        throw Error(response.transactionMessage);
    }catch (e) {
        return Promise.reject(e);
    }
}

export const deleteSummaryView = async (viewId) => {
    try {
        const {data} = await axiosInstance.post(
            '/Vehiculo_Tipo_Visualizacion/del',
            {
                nu_id_tipo_visualizacion: viewId,
            }
        );
        const response = Response.map(data);
        if (response.transactionState !== "0") {
            return response;
        }
        throw Error(response.transactionMessage);
    }catch (e) {
        return Promise.reject(e);
    }
}

export const getAllSummaryHeaders = async view => {
    try {
        const {data} = await axiosInstance.post(
            '/Vehiculo_Tipo_Visualizacion/sel_tipos_documentos',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_tipo_vizualizacion: view
            }
        );
        return data.map(h => SummaryHeader.map(h)).sort((a, b) => a.order - b.order);
    }catch (e) {
        return Promise.reject(e);
    }
}

export const editSummaryHeaders = async (request) => {
    try {
        const {data} = await axiosInstance.post(
            '/Vehiculo_Tipo_Visualizacion/upd_tipos_documentos',
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_tipo_vizualizacion: request.view.viewId,
                vc_desc_tipo_visualizacion: request.formData.name,
                bi_publico: request.formData.isPublic,
                bi_predeterminado: request.formData.predetermined,
                ls_tipos_documento: request.headers.map((h, index) => ({
                    nu_id_tipo_documento: h.id,
                    nu_orden: index+1,
                    bi_estado: h.active
                })),
            }
        );
        const response = Response.map(data);
        if (response.transactionState !== "0") {
            return response;
        }
        throw Error(response.transactionMessage);
    }catch (e) {
        return Promise.reject(e);
    }
}