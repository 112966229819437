import {connect} from "react-redux";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import TableComponent from "../../../../Common/Tables/Table";
import React from "react";
import TableHeader from "./TableHeader";
import Filters from "./Filters";
import FilterBuilder from "../../../../../utils/filters";
import {dialogs} from "../constants";
import {
  fetchAllVehicleCorrectives,
  openVehicleCorrectivesDialog,
  setCurrentVehicleCorrectives
} from "../../../../../redux/actions/FleetManagement/VehicleCorrectives";
import EditTableRow from "../../../../Common/Tables/CrudTable/EditTableRow";

const Table = ({dispatch, data, filters}) => {
  const onEdit = type => {
    dispatch(setCurrentVehicleCorrectives(type));
    dispatch(openVehicleCorrectivesDialog(dialogs.EDIT_DIALOG));
  };
  const onDelete = type => {
    dispatch(setCurrentVehicleCorrectives(type));
    dispatch(openVehicleCorrectivesDialog(dialogs.DELETE_DIALOG));
  };
  const onRestore = type => {
    dispatch(setCurrentVehicleCorrectives(type));
    dispatch(openVehicleCorrectivesDialog(dialogs.RESTORE_DIALOG));
  };

  React.useEffect(() => {
    dispatch(fetchAllVehicleCorrectives(filters));
  }, []);

  const RenderRow = ({row}) => (
    <EditTableRow onEdit={() => onEdit(row)}>
      <StyledTableCell>{row.alias}</StyledTableCell>
      <StyledTableCell>{row.plaque}</StyledTableCell>
      <StyledTableCell>
        {row.correctives.map(c => c.description).join(", ")}
      </StyledTableCell>
      <StyledTableCell>{row.status.description}</StyledTableCell>
    </EditTableRow>
  );
  // row.status ? (
  //     <CrudTableRow onEdit={() => onEdit(row)} onDelete={() => onDelete(row)}>
  //         <StyledTableCell>{row.description}</StyledTableCell>
  //         <StyledTableCell>{row.status}</StyledTableCell>
  //     </CrudTableRow>
  // ) : (
  //     <RestoreTableRow onRestore={() => onRestore(row)}>
  //         <StyledTableCell>{row.description}</StyledTableCell>
  //         <StyledTableCell>
  //             <IntlMessages id={row.status? "table.cell.yes" : "table.cell.no"} />
  //         </StyledTableCell>
  //     </RestoreTableRow>
  // );

  return (
    <React.Fragment>
      <Filters />
      <TableComponent
        data={Object.values(data)}
        head={<TableHeader />}
        renderRow={RenderRow}
        getRowKey={row => row.id}
      />
    </React.Fragment>
  );
};

const mapStateToProps = ({fleetManagement}) => {
  const {data, filters} = fleetManagement.vehicleCorrectives;
  const filteredData = new FilterBuilder(Object.values(data))
    .byFieldIncludes("alias", filters.alias)
    .byFieldIncludes("plaque", filters.plaque)
    // .byFieldIncludes('type', filters.type)
    // .byFieldIncludesMultiOptions('tags', filters.tags)
    // .byFieldIncludes('status', filters.status)
    .build();
  return {data: filteredData, filters};
};

export default connect(mapStateToProps)(Table);
