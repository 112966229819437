import React from "react";

import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import Form from "../Form";
import {useDispatch, useSelector} from "react-redux";
import {closeDialog} from "../../../../../redux/reducers/Treasury/costCenterType";
import {dialogsList} from "../../constants";
import {addCostCenterTypes} from "../../../../../redux/thunks/Treasury/costCenterType";

const CreateDialog = () => {
  const id = 'create-form';
  const {dialogs} = useSelector(({treasury}) => treasury.costCenterType);
  const dispatch = useDispatch();
  const isOpen = dialogs.some(dialog => dialog === dialogsList.add);
  const handleClose = () => {
      dispatch(closeDialog(dialogsList.add));
  }
  const onSubmit = (data) => {
      dispatch(addCostCenterTypes({request: data}));
      handleClose();
  }
  return (
      <FormDialog
          id={id}
          isOpen={isOpen}
          title={<IntlMessages id="administrator.rejectionTypes.createFormTitle" />}
          onClose={handleClose}
      >
          {isOpen && <Form id={id} onSubmit={onSubmit}/>}
      </FormDialog>
  )
};

export default CreateDialog;
