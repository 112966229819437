import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId, getUserId} from "../../StorageService";
import axios from "axios";

const ACCESS_URL = '/Acceso';
const getAccessUrl = (url) => `${ACCESS_URL}/${url}`;
export const getAccessData = async () => {
    try {
        const {data: {cadEncriptada, company}} = await axiosInstance.post(
            getAccessUrl('sel_acceso_empleado'),
            {
                nu_id_entidad: getCompanyEntityId(),
                nu_id_entidad_empleado: getUserId()
            }
        );
        if(!cadEncriptada) {
            throw new Error("Fallo al obtener los datos iniciales");
        }
        const {data: {d: data}}  = await axios.post(
            `https://${company}.4gflota.com/swGestionFlotaDesa/GestionFlota.svc/obtener_Informacion?timestamp=${Date.now()}`,
            {
                a: {
                    cadEntrada: cadEncriptada
                }
            }
        );
        if(!Array.isArray(data)) {
            throw new Error("Fallo al obtener el número de sesión");
        }
        const sessionId = data?.at(3);
        const response = await axiosInstance.post(
            getAccessUrl('sel_acceso_empleado_token'),
            {
                vc_sesion: sessionId,
                nu_id_app: 4,
                vc_nombre_empresa: company,
            }
        )
        if(!response.data) {
            throw new Error("Fallo al obtener el token del módulo");
        }
        return {
            organizationName: company,
            token: response.data,
        };
    }catch (e) {
        return Promise.reject(e);
    }
}
