import React from 'react';
import {useSelector} from "react-redux";
import CmtCarousel from "../../../../@coremat/CmtCarousel";
import {Box} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const EventMediaSlider = () => {
    const {currentEvent} = useSelector(({localization}) => localization.drivingControl);
    const {medias = []} = currentEvent;
    const sliders = medias.map(media => {
        if (media.contentType.includes('image')) {
            return (<Box component='picture' sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%'
            }}>
                <img src={`data:${media.contentType};base64,${media.base64}`} alt={media.downloadName} width='auto'
                     height='100%'/>
            </Box>);
        } else if (media.contentType.includes('video')) {
            return <video src={`data:${media.contentType};base64,${media.base64}`} width='100%' height='100%'
                          controls/>
        }
        return null;
    });
    if (!medias.length) {
        return (
            <Typography variant='subtitle1' style={{
                width: '100%',
                height: '250px',
                textAlign: 'center',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                border: '1px solid #000'
            }}>
                No media
            </Typography>
        );
    }
    ;
    return (
        <CmtCarousel
            renderRow={(item, index) => (
                <Box
                    height='250px'
                    key={index}
                >
                    {item}
                </Box>
            )}
            data={sliders}
            settings={{
                slidesToScroll: 1,
                slidesToShow: 1,
                arrows: true,
            }}
            height='100%'
        />
    );
};

export default EventMediaSlider;