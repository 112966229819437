import React from 'react';
import {useFetch} from "../../../../../../hooks/Common/useFetch";
import {
    fetchAllLicenses,
} from "../../../../../../services/BackOffice/Licenses/HistoryDataService";
import Grid from "@material-ui/core/Grid";
import Select from "../../../../../Common/Forms/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const LicenseSelect = ({value, setValue, organizationId}) => {
    const {data, executeService} = useFetch(fetchAllLicenses, {organizationId}, []);
    const onChange = ({target}) => {
        setValue && setValue(target.value)
    }
    React.useEffect(() => {
        setValue('');
        executeService();
    }, [organizationId]);
    return (
        <Grid item xs={12} md={4}>
            <Select
                value={value}
                onChange={onChange}
                fullWidth
                label={<IntlMessages id="license" />}
            >
                {data.map(e => (
                    <MenuItem value={e.id} key={e.id}>
                        {e.name}
                    </MenuItem>
                ))}
            </Select>
        </Grid>
    );
};

export default LicenseSelect;