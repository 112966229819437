import React from "react";
import {useDispatch, useSelector} from "react-redux";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {parseDateToInput} from "../../../../../utils/dates";
import validations from "./Form/validations";
import {
  editEmployeeDocument,
  resetCurrentListDocument
} from "../../../../../redux/actions/RRHH/Documentation";
import Form from "./Form";

const DocumentsDialog = () => {
  const {current, filters} = useSelector(({rrhh}) => rrhh.documentation.list);
  const dispatch = useDispatch();
  const id = "edit-employee-document-form";
  const onSubmit = data => {
    dispatch(editEmployeeDocument({id: current.id, ...data}, filters));
    onClose();
  };
  const onClose = () => {
    dispatch(resetCurrentListDocument());
  };
  return (
    <FormDialog
      id={id}
      isOpen={Boolean(current)}
      title={
        <IntlMessages
          id={"rrhh.employees.documentation.list.mode.detail.form.title"}
        />
      }
      onClose={onClose}
    >
      {current && (
        <Form
          id={id}
          initialData={{
            employee: current.employeeId || "",
            type: current.typeId || "",
            expirationDate: parseDateToInput(current.expirationDate) || "",
            noticeDays: current.noticeDays || "",
            url: current.fileName || "",
            file: null
          }}
          onSubmit={onSubmit}
          edit
          validations={validations}
        />
      )}
    </FormDialog>
  );
};

export default DocumentsDialog;
