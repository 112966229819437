import useForm from "../../../../hooks/Common/useForm";
import {useDispatch} from "react-redux";
import React from "react";
import GridContainer from "../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import ExpirationSelect from "../../../FleetManagement/Vehicles/Documentation/SummaryMode/ExpirationSelect";
import OutlinedInput from "../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import ViewSelect from "./ViewSelect";
import DocumentsTable from "./DocumentsTable";
import {
  applyEmployeeDocumentsSummaryFilters,
  fetchAllSummaryEmployeeDocumentsView
} from "../../../../redux/actions/RRHH/Documentation";
import TagsPicker from "../Common/TagsPicker";
import ButtonsArea from "./ButtonsArea";

const initialData = {
  view: "",
  expiration: "",
  employee: "",
  tags: []
};
const SummaryMode = () => {
  const {formData, setFormData} = useForm(initialData);
  const dispatch = useDispatch();
  React.useEffect(
    () => {
      dispatch(applyEmployeeDocumentsSummaryFilters(formData));
    },
    [ formData ]
  );
  React.useEffect(() => {
    dispatch(fetchAllSummaryEmployeeDocumentsView());
  }, []);

  return (
    <GridContainer spacing={3}>
      <Grid item xs={12} sm={8}>
        <ViewSelect
          value={formData.view}
          onChange={value => setFormData("view", value)}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ButtonsArea view={formData.view} />
      </Grid>
      <Grid item xs={12}>
        <ExpirationSelect
          value={formData.expiration}
          onChange={value => setFormData("expiration", value)}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <OutlinedInput
          label={
            <IntlMessages
              id={"rrhh.employees.documentation.list.mode.table.employee"}
            />
          }
          value={formData.employee}
          onChange={value => setFormData("employee", value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TagsPicker
          value={formData.tags}
          onChange={values => setFormData("tags", values)}
        />
      </Grid>
      <Grid item xs={12}>
        <DocumentsTable />
      </Grid>
    </GridContainer>
  );
};

export default SummaryMode;
