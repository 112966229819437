import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {closeDialog, openDialog, setCurrentProvider} from "../../../../../redux/reducers/Logistic/providers";
import FullScreenDialog from "../../../../Common/Dialogs/FullScreenDialog";
import {dialogsList} from "../constants";
import {Box} from "@material-ui/core";
import DetailTable from "./DetailTable";
import HeadquartersTable from "./HeadquartersTable";
import AddHeadquarterDialog from "./AddHeadquarterDialog";
import EditHeadquarterDialog from "./EditHeadquarterDialog";
import FloatingButtonWithAdd from "../../../../Common/Buttons/FloatingButtonWithAdd";


const DetailDialog = () => {
  const dispatch = useDispatch();
  const {currentProvider, dialogs} = useSelector(({logistic}) => logistic.providers);
  const isOpen = dialogs.some(dialog => dialog === dialogsList.detailsDialog);
  const handleClose = () => {
    dispatch(closeDialog(dialogsList.detailsDialog));
    dispatch(setCurrentProvider(null));
  };


  return (
    <FullScreenDialog
      open={isOpen}
      handleClose={handleClose}
      title={"details"}
    >
      {currentProvider && (
        <Box display="flex" flexDirection="column" alignItems="center">
          <DetailTable />
          <HeadquartersTable />
          <AddHeadquarterDialog/>
          <EditHeadquarterDialog />
          <FloatingButtonWithAdd onAddClicked={() => dispatch(openDialog(dialogsList.addHeadquarterDialog))} />
        </Box>
      )}
    </FullScreenDialog>
  );
};

export default DetailDialog;
