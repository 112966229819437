import React from "react";
import {useDispatch} from "react-redux";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import {IconButton} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteIcon from "@material-ui/icons/Delete";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import {editProviderState, getProviderHeadquarters} from "../../../../../redux/thunks/Logistic/providers";
import RestoreIcon from "@material-ui/icons/Restore";
import {openDialog} from "../../../../../redux/reducers/Logistic/providers";
import {dialogsList} from "../constants";


const TableBody = ({provider}) => {
  const dispatch = useDispatch();

  const onShowDetail = () => {
      dispatch(getProviderHeadquarters({provider}));
      dispatch(openDialog(dialogsList.detailsDialog));
  };
  const onDelete = () => {
      dispatch(editProviderState({provider}));
  };
  return (
    <StyledTableRow >
      <StyledTableCell>{provider.providerCode}</StyledTableCell>
      <StyledTableCell>{provider.name}</StyledTableCell>
      <StyledTableCell>{provider.businessName}</StyledTableCell>
      <StyledTableCell>{provider.countryName}</StyledTableCell>
      <StyledTableCell>{provider.code}</StyledTableCell>
      <StyledTableCell>{provider.email}</StyledTableCell>
      <StyledTableCell>
          {provider.state && <IconButton onClick={onShowDetail}>
              <VisibilityIcon/>
          </IconButton>}
        <IconButton onClick={onDelete}>
            {provider.state
                ? <DeleteIcon />
                : <RestoreIcon />
            }
        </IconButton>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default TableBody;
