import React from 'react';
import PageContainerComponent from "../../Common/Layouts/PageContainer";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import PropTypes from "prop-types";

const breadcrumbs = [
    {
        label: <IntlMessages id={"sidebar.logistic.distribution"} />,
        link: "/logistic/distribution/planner"
    },
    {
        label: <IntlMessages id={"logistic.distribution.planner"} />,
        isActive: true
    }
];


const PageContainer =({children}) => (
    <PageContainerComponent
        heading={<IntlMessages id="logistic.distribution.planner" />}
        description={
            <IntlMessages id="logistic.distribution.planner.description" />
        }
        breadcrumbs={breadcrumbs}
    >
        {children}
    </PageContainerComponent>
);

PageContainer.propTypes = {
    children: PropTypes.node
};

export default PageContainer;