import React from "react";
import validations from "./validations";
import {Box, Grid} from "@material-ui/core";
import useForm from "../../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import Checkbox from "../../../../../../Common/Forms/Checkbox";
import Tabs from "../../../../../../Common/Tabs";
import MarkerMap from "../../../../../../Common/Maps/OpenStreetMap/MarkerMap";
import PolygonMap from "../../../../../../Common/Maps/OpenStreetMap/PolygonMap";


const initialData = {
  name: "",
  isFiscal: true,
  streetName: "",
  number: "",
  zipCode: "",
  district: "",
  province: "",
  country: "",
  latitude: 0,
  longitude: 0
};

const Form = ({id, initData = {}, onSubmit}) => {
  const {formData, setFormData, errors, handleSubmit, setValues} = useForm(
    initialData,
    validations
  );
  const [tab, setTab] = React.useState('1');
  const handleSetMapCoordinates = ({latitude, longitude}) => {
      setFormData('longitude', longitude);
      setFormData('latitude', latitude);
  }

  React.useEffect(() => {
      setValues(initData);
  }, []);
  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.name.length > 0}
            error={errors.name[0]}
            value={formData.name}
            label={
              <IntlMessages id="name" />
            }
            onChange={value => setFormData("name", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Checkbox
            checked={formData.isFiscal}
            onChange={value => setFormData("isFiscal", value)}
            name="isFiscal"
            label={
              <IntlMessages id="fiscal" />
            }
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.streetName.length > 0}
            error={errors.streetName[0]}
            value={formData.streetName}
            label={
              <IntlMessages id="street" />
            }
            onChange={value => setFormData("streetName", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.number.length > 0}
            error={errors.number[0]}
            value={formData.number}
            label={
              <IntlMessages id="number" />
            }
            onChange={value => setFormData("number", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.zipCode.length > 0}
            error={errors.zipCode[0]}
            value={formData.zipCode}
            label={
              <IntlMessages id="postal.code" />
            }
            onChange={value => setFormData("zipCode", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.district.length > 0}
            error={errors.district[0]}
            value={formData.district}
            label={
              <IntlMessages id="district" />
            }
            onChange={value => setFormData("district", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.province.length > 0}
            error={errors.province[0]}
            value={formData.province}
            label={
              <IntlMessages id="province" />
            }
            onChange={value => setFormData("province", value)}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <OutlinedInput
            fullWidth
            hasError={errors.country.length > 0}
            error={errors.country[0]}
            value={formData.country}
            label={
              <IntlMessages id="country" />
            }
            onChange={value => setFormData("country", value)}
          />
        </Grid>
          <Grid item xs={12} sm={4}>
              <OutlinedInput
                  fullWidth
                  type="number"
                  hasError={errors.latitude.length > 0}
                  error={errors.latitude[0]}
                  value={formData.latitude}
                  label={
                      <IntlMessages id="latitude" />
                  }
                  onChange={value => setFormData("latitude", value)}
              />
          </Grid>
          <Grid item xs={12} sm={4}>
              <OutlinedInput
                  fullWidth
                  type="number"
                  hasError={errors.longitude.length > 0}
                  error={errors.longitude[0]}
                  value={formData.longitude}
                  label={
                      <IntlMessages id="longitude" />
                  }
                  onChange={value => setFormData("longitude", value)}
              />
          </Grid>
      </GridContainer>
      <Box  mt={2}>
        <Tabs
            tabs={[
              {
                value: '1', label: <IntlMessages id='marker' />
              },
              {
                value: '2', label: <IntlMessages id='polygon' />
              }
            ]}
            value={tab}
            onChange={tab => setTab(tab)}
        >
          {tab === '1'
              ? <MarkerMap
                  position={{
                    latitude: Number(formData.latitude),
                    longitude: Number(formData.longitude),
                  }}
                  setPosition={handleSetMapCoordinates}
              />
              : <PolygonMap
                  clearMap
                  center={[Number(formData.latitude), Number(formData.longitude)]}
              />
          }
        </Tabs>
      </Box>
    </form>
  );
};

export default Form;
