import {mapToBoolean} from "../../utils/mappers";

class DocumentsTypes {
  constructor(id, description, securityControl, status) {
    this.id = id;
    this.description = description;
    this.securityControl = securityControl;
    this.status = status;
  }

  static map = type => {
    return new DocumentsTypes(
      type.nu_id_tipo_documento,
      type.vc_desc_tipo_documento,
      mapToBoolean(type.bi_control_seguridad),
      type.bi_estado || true
    );
  };
}

export default DocumentsTypes;
