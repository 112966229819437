import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId, getUserId} from "../../StorageService";
import Response from "../../../domain/Response";
import VehicleCorrective from "../../../domain/FleetManagement/DailyOperations/VehicleCorrective";
import {generateFileObject} from "../../../utils/files";

export const getAllVehicleCorrectives = async () => {
  try {
    const {data} = await axiosInstance.post("/VehiculoCorrectivo/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(d => VehicleCorrective.map(d));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createVehicleCorrectives = async request => {
  try {
    const files = await Promise.all(
      request.files.map(async f => ({
        ...f,
        file: await generateFileObject(f.file)
      }))
    );
    const {data} = await axiosInstance.post("/VehiculoCorrectivo/ins", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_solicitante: getUserId(),
      nu_id_vehiculo: request.vehicle,
      dt_fec_posponer: request.postponeDate,
      nu_id_estado: request.status,
      vc_observacion: request.observations,
      ls_tipos: request.types.map(t => t.id),
      ls_documentos: files.map(f => ({
        // vc_desc_documento: f.name,
        // nu_dias_preaviso: f.notice,
        // dt_fec_caducidad: f.expirationDate,
        archivo: {
          archivobase64: f.file.base64File,
          contentType: f.file.contentType,
          extension: f.file.extension,
          nombre: f.file.name.split(".")[0]
        }
      }))
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateVehicleCorrectives = async request => {
  try {
    const files = await Promise.all(
      request.files.filter(f => f.id === 0).map(async f => ({
        ...f,
        file: await generateFileObject(f.file)
      }))
    );
    const {data} = await axiosInstance.post("/VehiculoCorrectivo/upd", {
      nu_id_correctivo: request.id,
      nu_id_solicitante: getUserId(),
      nu_id_vehiculo: request.vehicle,
      dt_fec_posponer: request.postponeDate,
      nu_id_estado: request.status,
      vc_observacion: request.observations,
      ls_tipos: request.types.map(t => t.id),
      ls_documentos: files.map(f => ({
        // vc_desc_documento: f.name,
        // nu_dias_preaviso: f.notice,
        // dt_fec_caducidad: f.expirationDate,
        archivo: {
          archivobase64: f.file.base64File,
          contentType: f.file.contentType,
          extension: f.file.extension,
          nombre: f.file.name.split(".")[0]
        }
      }))
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAllVehicleCorrectivesStatus = async () => {
  try {
    const {data} = await axiosInstance.post("/VehiculoCorrectivo/sel_estados", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(d => ({
      id: d.nu_id_tipo_estado,
      description: d.vc_desc_tipo_estado
    }));
  } catch (e) {
    return Promise.reject(e);
  }
};
