import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {updateOrganizationCard} from "../../../../redux/thunks/FleetManagement/Tachograph/cards";
import FormDialog from "../../../Common/Dialogs/FormDialog";
import CardForm from "./CardForm";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {closeDialog, setCurrentCard} from "../../../../redux/reducers/FleetManagement/tachographCards";
import {dialogsList} from "./constants";

const EditCardDialog = () => {
    const id = 'edit-card-dialog';
    const { currentCard, dialogs } = useSelector(({fleetManagement}) => fleetManagement.tachographCards);
    const open = dialogs.some(d => d === dialogsList.editCard);
    const dispatch = useDispatch();
    const onSubmit = (data) => {
         dispatch(updateOrganizationCard({
             ...currentCard,
             expirationDate: data.expirationDate,
             cardNumber: data.cardNumber,
             name: data.name,
         }));
         handleOnClose();
    }
    const handleOnClose = () => {
      dispatch(closeDialog(dialogsList.editCard));
      dispatch(setCurrentCard(null));
    }
    return (
        <FormDialog
            id={id}
            title={<IntlMessages id='edit' />}
            onClose={handleOnClose}
            isOpen={open}
        >
            {open && <CardForm
                id={id}
                initData={currentCard}
                onSubmit={onSubmit}
            />}
        </FormDialog>
    );
};

export default EditCardDialog;