import React from "react";
import PropTypes from "prop-types";
import Select from "../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {useFetch} from "../../../../../../hooks/Common/useFetch";
import {getAllCorrectiveTypes} from "../../../../../../services/FleetManagement/DailyOperation/CorrectiveTypesService";

const CorrectiveTypesSelect = ({id, value, onChange}) => {
  const {data: types} = useFetch(getAllCorrectiveTypes, {}, []);

  return (
    <Select
      id={id}
      label={
        <IntlMessages id="fleet.management.vehicle.corrective.form.type" />
      }
      value={value}
      onChange={e => onChange(e.target.value)}
      fullWidth
    >
      <MenuItem value={""}>
        <IntlMessages id="select.all" />
      </MenuItem>
      {types.map(s => (
        <MenuItem value={s.id} key={s.id}>
          {s.description}
        </MenuItem>
      ))}
    </Select>
  );
};

CorrectiveTypesSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

export default CorrectiveTypesSelect;
