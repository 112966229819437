import React from 'react';
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {useFetch} from "../../../../../hooks/Common/useFetch";
import {getAllEventTypes} from "../../../../../services/Localization/DailyOperation/DrivingControlService";
import MultipleSelect from "../../../../Common/Forms/Select/MultipleSelect";

const EventsTypesSelect = ({name = 'eventTypes', value, onChange}) => {
    const {data} = useFetch(getAllEventTypes, {}, []);
    return (
        <MultipleSelect
            name={name}
            onChange={value => onChange(value)}
            value={value}
            label={<IntlMessages id='event.type'/>}
            fullWidth
            options={data}
        />
    );
};

export default EventsTypesSelect;