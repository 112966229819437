import React from "react";
import {useSelector} from "react-redux";
import TableHeader from "./TableHeader";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead
} from "@material-ui/core";
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";

const DetailTable = () => {
  const {currentProvider} = useSelector(({logistic}) => logistic.providers);
  return (
    <TableContainer
      component={Paper}
      style={{
        width: "90%",
        margin: "36px 40px"
      }}
    >
      <Table>
        <TableHead>
          <TableHeader />
        </TableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell>{currentProvider.providerCode}</StyledTableCell>
            <StyledTableCell>{currentProvider.name}</StyledTableCell>
            <StyledTableCell>{currentProvider.businessName}</StyledTableCell>
            <StyledTableCell>{currentProvider.countryName}</StyledTableCell>
            <StyledTableCell>{currentProvider.code}</StyledTableCell>
            <StyledTableCell>{currentProvider.email}</StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DetailTable;
