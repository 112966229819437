import React from 'react';
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import PageContainerComponent from "../../Common/Layouts/PageContainer";
import PropTypes from "prop-types";

const breadcrumbs = [
    {
        label: <IntlMessages id={"sidebar.masterData"} />,
        link: "/treasury/invoicing/taxes"
    },
    {label: <IntlMessages id={"treasury.services.title"} />, isActive: true}
];

const PageContainer = ({children}) => (
    <PageContainerComponent
        heading={<IntlMessages id="treasury.services.title" />}
        description={
            <IntlMessages id="treasury.services.description" />
        }
        breadcrumbs={breadcrumbs}
    >
        {children}
    </PageContainerComponent>
);

PageContainer.propTypes = {
    children: PropTypes.node
};

export default PageContainer;