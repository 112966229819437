import React from 'react';
import {Cell, Pie, PieChart as BaseChart, ResponsiveContainer} from 'recharts';
import {COLORS} from "./constants";




const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value, name}) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
        <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(value)}-${name}`}
        </text>
    );
};
const PieChart = ({data}) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <BaseChart width={800} height={800}>
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={200}
                    fill="#8884d8"
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index]} />
                    ))}
                </Pie>
            </BaseChart>
        </ResponsiveContainer>
    );
};

export default PieChart;