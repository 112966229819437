import {
  ADD_CORRECTIVE_TYPE,
  APPLY_CORRECTIVE_TYPES_FILTERS,
  CLOSE_CORRECTIVE_TYPES_DIALOG,
  EDIT_CORRECTIVE_TYPE,
  FETCH_ALL_CORRECTIVE_TYPES,
  OPEN_CORRECTIVE_TYPES_DIALOG,
  RESET_CORRECTIVE_TYPES_FILTERS,
  RESET_CORRECTIVE_TYPES_STATE,
  RESET_CURRENT_CORRECTIVE_TYPE,
  SET_CURRENT_CORRECTIVE_TYPE
} from "../../types/FleetManagement/CorrectiveTypesTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import CorrectiveType from "../../../domain/FleetManagement/DailyOperations/CorrectiveType";
import {
  createCorrectiveType,
  getAllCorrectiveTypes,
  updateCorrectiveType,
  updateCorrectiveTypeStatus
} from "../../../services/FleetManagement/DailyOperation/CorrectiveTypesService";

export const resetCorrectiveTypesState = () => ({
  type: RESET_CORRECTIVE_TYPES_STATE
});
export const applyCorrectiveTypesFilters = filters => ({
  type: APPLY_CORRECTIVE_TYPES_FILTERS,
  payload: filters
});
export const resetCorrectiveTypesFilters = () => ({
  type: RESET_CORRECTIVE_TYPES_FILTERS
});
export const setCurrentCorrectiveType = type => ({
  type: SET_CURRENT_CORRECTIVE_TYPE,
  payload: type
});
export const resetCurrentCorrectiveType = () => ({
  type: RESET_CURRENT_CORRECTIVE_TYPE
});
export const openCorrectiveTypesDialog = name => ({
  type: OPEN_CORRECTIVE_TYPES_DIALOG,
  payload: name
});
export const closeCorrectiveTypesDialog = () => ({
  type: CLOSE_CORRECTIVE_TYPES_DIALOG
});

export const fetchAllCorrectiveTypes = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllCorrectiveTypes()
      .then(types => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_CORRECTIVE_TYPES, payload: types});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const addCorrectiveType = type => {
  return async dispatch => {
    dispatch(fetchStart());
    createCorrectiveType(type)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const newType = new CorrectiveType(
          response.transactionKey,
          type.description,
          true
        );
        dispatch({type: ADD_CORRECTIVE_TYPE, payload: newType});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const editCorrectiveType = type => {
  return async dispatch => {
    dispatch(fetchStart());
    updateCorrectiveType(type)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const editType = new CorrectiveType(
          response.transactionKey,
          type.description,
          type.status
        );
        dispatch({type: EDIT_CORRECTIVE_TYPE, payload: editType});
        dispatch(resetCurrentCorrectiveType());
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const editCorrectiveTypeState = (type, status) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateCorrectiveTypeStatus(type, status)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const editType = new CorrectiveType(
          response.transactionKey,
          type.description,
          status
        );
        dispatch({type: EDIT_CORRECTIVE_TYPE, payload: editType});
        dispatch(resetCurrentCorrectiveType());
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};
