import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import React from "react";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages
        id={"fleet.management.vehicle.maintenance.plan.table.vehicle"}
      />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages
        id={"fleet.management.vehicle.maintenance.plan.table.plan"}
      />
    </StyledTableCell>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
