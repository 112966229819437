import React from "react";
import PropTypes from "prop-types";
import Select from "../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {useFetch} from "../../../../../../../hooks/Common/useFetch";
import {
  getAllWorkshopRequestStates
} from "../../../../../../../services/FleetManagement/DailyOperation/WorkshopRequestService";

const StatusSelect = ({id, value, onChange}) => {
  const {data: states} = useFetch(getAllWorkshopRequestStates, {}, []);

  return (
    <Select
      id={id}
      label={
        <IntlMessages id="fleet.management.workshop.request.table.status" />
      }
      value={value}
      onChange={e => onChange(e.target.value)}
      fullWidth
    >
      <MenuItem value={""}>
        <IntlMessages id="select.all" />
      </MenuItem>
      {states.map(s => (
        <MenuItem value={s.id} key={s.id}>
          {s.description}
        </MenuItem>
      ))}
    </Select>
  );
};

StatusSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func
};

StatusSelect.defaultProps = {
  value: "",
  onChange: () => {}
};

export default StatusSelect;
