import {
  APPLY_VEHICLE_MAINTENANCE_PLANS_FILTERS,
  CLOSE_VEHICLE_MAINTENANCE_PLANS_DIALOG,
  EDIT_VEHICLE_MAINTENANCE_PLAN,
  EDIT_VEHICLE_MAINTENANCE_PLAN_STATE,
  FETCH_ALL_VEHICLE_MAINTENANCE_PLANS,
  OPEN_VEHICLE_MAINTENANCE_PLANS_DIALOG,
  RESET_VEHICLE_MAINTENANCE_PLANS_FILTERS,
  RESET_VEHICLE_MAINTENANCE_PLANS_STATE,
  SET_CURRENT_VEHICLE_MAINTENANCE_PLAN
} from "../../types/FleetManagement/VehicleMaintenancePlansTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import VehicleMaintenancePlan from "../../../domain/FleetManagement/DailyOperations/VehicleMaintenancePlan";
import MaintenancePlan from "../../../domain/FleetManagement/DailyOperations/MaintenancePlan";
import {
  editVehicleMaintenancePlan,
  getAllVehicleMaintenancePlans
} from "../../../services/FleetManagement/DailyOperation/VehicleMaintenancePlansService";

let id = 0;

const vehicles = [
  new VehicleMaintenancePlan(
    ++id,
    "ASD-123",
    [
      new MaintenancePlan(id++, "Plan 1", true, 4, 2, 4, 4, 2, 4, [], 1),
      new MaintenancePlan(id++, "Plan 2", false, 4, 2, 4, 4, 2, 4, [], 1)
    ],
    true
  ),
  new VehicleMaintenancePlan(
    ++id,
    "XYZ-123",
    [ new MaintenancePlan(id++, "Plan 2", false, 4, 2, 4, 4, 2, 4, [], 1) ],
    false
  )
];

export const resetVehicleMaintenancePlansState = () => ({
  type: RESET_VEHICLE_MAINTENANCE_PLANS_STATE
});
export const setCurrentVehicleMaintenancePlan = user => ({
  type: SET_CURRENT_VEHICLE_MAINTENANCE_PLAN,
  payload: user
});
export const applyVehicleMaintenancePlansFilters = filters => ({
  type: APPLY_VEHICLE_MAINTENANCE_PLANS_FILTERS,
  payload: filters
});
export const resetVehicleMaintenancePlansFilters = () => ({
  type: RESET_VEHICLE_MAINTENANCE_PLANS_FILTERS
});
export const openVehicleMaintenancePlansDialog = name => ({
  type: OPEN_VEHICLE_MAINTENANCE_PLANS_DIALOG,
  payload: name
});
export const closeVehicleMaintenancePlansDialog = () => ({
  type: CLOSE_VEHICLE_MAINTENANCE_PLANS_DIALOG
});

export const fetchAllVehicleMaintenancePlans = () => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllVehicleMaintenancePlans()
      .then(vehicles => {
        dispatch(fetchSuccess());
        dispatch({
          type: FETCH_ALL_VEHICLE_MAINTENANCE_PLANS,
          payload: vehicles
        });
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const updateVehicleMaintenancePlan = (vehicle, plans) => {
  return async dispatch => {
    dispatch(fetchStart());
    editVehicleMaintenancePlan(vehicle, plans)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        // const updatedVehicle = new VehicleMaintenancePlan(
        //     vehicle.id,
        //     vehicle.vehicle,
        //     plans,
        //     vehicle.state
        // )
        // dispatch({type: EDIT_VEHICLE_MAINTENANCE_PLAN, payload: updatedVehicle})
        dispatch(fetchAllVehicleMaintenancePlans());
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const updateVehicleMaintenancePlanState = (vehicle, state) => {
  return async dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess("Estado actualizado"));
      dispatch({
        type: EDIT_VEHICLE_MAINTENANCE_PLAN_STATE,
        payload: {id: vehicle.id, state}
      });
      dispatch(setCurrentVehicleMaintenancePlan(null));
    }, 1500);
    // editVehicleMaintenancePlanState(vehicle, state)
    //     .then(response => {
    //         dispatch(fetchSuccess(response.transactionMessage));
    //         dispatch({type: EDIT_VEHICLE_MAINTENANCE_PLAN_STATE, payload: {id: vehicle.id, state}});
    //         dispatch(setCurrentVehicleMaintenancePlan(null));
    //     }).catch(err => {
    //     dispatch(fetchError(err.message));
    // })
  };
};
