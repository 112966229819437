import React from "react";
import Select from "../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {getAllElevatorsCategories} from "../../../../../../../services/ElevatorsManagement/CategoriesService";

const CategorySelect = ({id, value, setValue, disabled}) => {
  const [ categories, setCategories ] = React.useState([]);
  React.useEffect(() => {
    getAllElevatorsCategories()
      .then(categories => setCategories(categories))
      .catch(e => setCategories([]));
  }, []);
  return (
    <Select
      id={id}
      value={value}
      disabled={disabled}
      onChange={e => setValue(e.target.value)}
      label={
        <IntlMessages
          id={
            "elevators.management.elevators.index.card.detail.dialog.form.elevator.category"
          }
        />
      }
      fullWidth
    >
      {categories.map(category => (
        <MenuItem value={category.id} key={category.id}>
          {category.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default CategorySelect;
