import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId, getUserId} from "../../StorageService";
import Response from "../../../domain/Response";
import VehicleMaintenanceHeader from "../../../domain/FleetManagement/DailyOperations/VehicleMaintenanceHeader";
import VehicleMaintenanceView from "../../../domain/FleetManagement/DailyOperations/VehicleMaintenanceView";

export const getAllVehicleMaintenancesViews = async () => {
  try {
    const {data} = await axiosInstance.post("/PreventivoVehiculos/sel", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_usuario: getUserId()
    });
    return data.map(v => VehicleMaintenanceView.map(v));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createVehicleMaintenancesView = async view => {
  try {
    const {data} = await axiosInstance.post("/PreventivoVehiculos/ins", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_usuario: getUserId(),
      vc_desc_tipo_visualizacion: view.name,
      bi_predeterminado: view.predetermined,
      bi_publico: view.isPublic
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAllVehicleMaintenances = async view => {
  try {
    const {
      data
    } = await axiosInstance.post("/PreventivoVehiculos/sel_resumen", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_tipo_vizualizacion: view
    });
    return data.map(vehicle => {
      let summary = {};
      vehicle.planes.forEach(d => {
        summary[d.nu_id_plan_mantenimiento] = d.nu_estado;
      });
      return {
        id: vehicle.nu_id_vehiculo,
        vehicle: vehicle.vc_placa,
        ...summary,
        plans: Object.values(summary),
        tags: vehicle.tags.map(t => t.nu_id_tag)
      };
    });
  } catch (e) {
    return Promise.reject(e);
  }
};

export const getAllVehicleMaintenancesHeaders = async view => {
  try {
    const {
      data
    } = await axiosInstance.post(
      "/PreventivoVehiculos/sel_planes_mantenimiento",
      {
        nu_id_entidad: getCompanyEntityId(),
        nu_id_tipo_vizualizacion: view
      }
    );
    return data
      .map(h => VehicleMaintenanceHeader.map(h))
      .sort((a, b) => a.order - b.order);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const editVehicleMaintenancesHeaders = async request => {
  try {
    const {
      data
    } = await axiosInstance.post(
      "/PreventivoVehiculos/upd_planes_mantenimiento",
      {
        nu_id_entidad: getCompanyEntityId(),
        nu_id_tipo_vizualizacion: request.view.viewId,
        vc_desc_tipo_visualizacion: request.formData.name,
        bi_publico: request.formData.isPublic,
        bi_predeterminado: request.formData.predetermined,
        ls_planes_mantenimiento: request.headers.map((h, index) => ({
          nu_id_plan_mantenimiento: h.id,
          nu_orden: index + 1,
          bi_estado: h.active
        }))
      }
    );
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};
