import {
  ADD_SINISTER_TYPE,
  APPLY_SINISTER_TYPES_FILTERS,
  CLOSE_SINISTER_TYPES_DIALOG,
  EDIT_SINISTER_TYPE,
  FETCH_ALL_SINISTER_TYPES,
  OPEN_SINISTER_TYPES_DIALOG,
  RESET_CURRENT_SINISTER_TYPE,
  RESET_SINISTER_TYPES_FILTERS,
  RESET_SINISTER_TYPES_STATE,
  SET_CURRENT_SINISTER_TYPE
} from "../../types/FleetManagement/SinisterTypesTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import SinisterType from "../../../domain/FleetManagement/DailyOperations/SinisterType";
import {
  createSinisterType,
  getAllSinisterTypes,
  updateSinisterType,
  updateSinisterTypeStatus
} from "../../../services/FleetManagement/DailyOperation/SinisterTypesService";

export const resetSinisterTypesState = () => ({
  type: RESET_SINISTER_TYPES_STATE
});
export const applySinisterTypesFilters = filters => ({
  type: APPLY_SINISTER_TYPES_FILTERS,
  payload: filters
});
export const resetSinisterTypesFilters = () => ({
  type: RESET_SINISTER_TYPES_FILTERS
});
export const setCurrentSinisterType = type => ({
  type: SET_CURRENT_SINISTER_TYPE,
  payload: type
});
export const resetCurrentSinisterType = () => ({
  type: RESET_CURRENT_SINISTER_TYPE
});
export const openSinisterTypesDialog = name => ({
  type: OPEN_SINISTER_TYPES_DIALOG,
  payload: name
});
export const closeSinisterTypesDialog = () => ({
  type: CLOSE_SINISTER_TYPES_DIALOG
});

export const fetchAllSinisterTypes = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllSinisterTypes()
      .then(types => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_SINISTER_TYPES, payload: types});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const addSinisterType = type => {
  return async dispatch => {
    dispatch(fetchStart());
    createSinisterType(type)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const newType = new SinisterType(
          response.transactionKey,
          type.description,
          true
        );
        dispatch({type: ADD_SINISTER_TYPE, payload: newType});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const editSinisterType = type => {
  return async dispatch => {
    dispatch(fetchStart());
    updateSinisterType(type)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const editType = new SinisterType(
          response.transactionKey,
          type.description,
          type.status
        );
        dispatch({type: EDIT_SINISTER_TYPE, payload: editType});
        dispatch(resetCurrentSinisterType());
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const editSinisterTypeState = (type, status) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateSinisterTypeStatus(type, status)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const editType = new SinisterType(
          response.transactionKey,
          type.description,
          status
        );
        dispatch({type: EDIT_SINISTER_TYPE, payload: editType});
        dispatch(resetCurrentSinisterType());
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};
