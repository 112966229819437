import React from "react";
import {useDispatch, useSelector} from "react-redux";
import FormDialog from "../../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import Form from "../VehicleDocumentsDialog/Form";
import {addVehicleDocument} from "../../../../../../redux/actions/FleetManagement/Documents";
import {generateFileObject} from "../../../../../../utils/files";
import {fetchError} from "../../../../../../redux/actions";
import validations from "./validations";

const AddDocumentDialog = ({open, setOpen}) => {
  const dispatch = useDispatch();
  const {filters} = useSelector(
    ({fleetManagement}) => fleetManagement.documents.list
  );
  const id = "add-vehicle-document-form";
  const onSubmit = data => {
    generateFileObject(data.file[0])
      .then(file => {
        dispatch(addVehicleDocument({...data, file}, filters));
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
    onClose();
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <FormDialog
      id={id}
      isOpen={open}
      title={
        <IntlMessages
          id={
            "fleet.management.vehicles.documentation.list.mode.detail.form.title"
          }
        />
      }
      onClose={onClose}
    >
      <Form
        id={id}
        initialData={{
          vehicle: "",
          type: "",
          expirationDate: "",
          noticeDays: "",
          url: "",
          file: []
        }}
        onSubmit={onSubmit}
        validations={validations}
      />
    </FormDialog>
  );
};

export default AddDocumentDialog;
