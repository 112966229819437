import React from 'react';
import {dialogsList} from "../../../constants";
import {useDispatch, useSelector} from "react-redux";
import {closeDialog} from "../../../../../../../redux/reducers/Logistic/providerOrders";
import FormDialog from "../../../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import Form from "./Form";
import {addItemToProviderOrder} from "../../../../../../../redux/thunks/Logistic/providerOrders";

const ItemForm = () => {
    const id = dialogsList.itemForm;
    const {dialogs, currentOrder} = useSelector(({logistic}) => logistic.providerOrders);
    const dispatch = useDispatch();
    const isOpen = dialogs.some(dialog => dialog === id);
    const handleSubmit = (item) => {
        dispatch(addItemToProviderOrder({
            order: currentOrder,
            request: {
                id: item.item.value,
                quantity: item.quantity,
                type: item.type,
            }
        }));
        onClose();
    }
    const onClose = () => {
        dispatch(closeDialog(id));
    }
    return (
        <FormDialog
            id={id}
            isOpen={isOpen}
            onClose={onClose}
            title={<IntlMessages id="add" />}
        >
            {isOpen &&
                <Form
                    id={id}
                    onSubmit={handleSubmit}
                />
            }
        </FormDialog>
    );
};

export default ItemForm;