import React from "react";
import {TableHead} from "@material-ui/core";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => (
  <TableHead>
    <StyledTableRow>
      <StyledTableCell>
        <IntlMessages id="name" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="fiscal" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="address" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="number" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="postal.code" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="district" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="province" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="country" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="latitude" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="longitude" />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id="actions" />
      </StyledTableCell>
    </StyledTableRow>
  </TableHead>
);

export default TableHeader;
