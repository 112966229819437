import React from 'react';
import PageContainer from "../../../../components/Treasury/CostCenterTypes/PageContainer";
import Table from "../../../../components/Treasury/CostCenterTypes/Table";
import Filters from "../../../../components/Treasury/CostCenterTypes/Filters";
import FloatingButtonWithAdd from "../../../../components/Common/Buttons/FloatingButtonWithAdd";
import {useDispatch} from "react-redux";
import {openDialog} from "../../../../redux/reducers/Treasury/costCenterType";
import {dialogsList} from "../../../../components/Treasury/CostCenterTypes/constants";
import CreateDialog from "../../../../components/Treasury/CostCenterTypes/SaveCostCenterTypeDialogs/CreateDialog";
import EditDialog from "../../../../components/Treasury/CostCenterTypes/SaveCostCenterTypeDialogs/EditDialog";
import RestoreConfirmation from "../../../../components/Treasury/CostCenterTypes/Confirmations/RestoreConfirmation";
import DeleteConfirmation from "../../../../components/Treasury/CostCenterTypes/Confirmations/DeleteConfirmation";

const CostCenterTypes = () => {
    const dispatch = useDispatch();
    const openAddDialog = () => {
        dispatch(openDialog(dialogsList.add));
    }
    return (
        <PageContainer>
            <Filters />
            <Table />
            <CreateDialog />
            <EditDialog />
            <RestoreConfirmation />
            <DeleteConfirmation />
            <FloatingButtonWithAdd
                onAddClicked={openAddDialog}
            />
        </PageContainer>
    );
};

export default CostCenterTypes;