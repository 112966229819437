import React from "react";
import useForm from "../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import validations from "./validations";
import StatusSelect from "../../Table/Filters/StatusSelect";
import VehiclesSelect from "../../../../Vehicles/Documentation/ListMode/VehicleDocumentsDialog/Form/VehicleInfoArea/VehiclesSelect";
import CorrectiveTypesPicker from "../../../Common/Selects/CorrectiveTypesPicker";
import OutlinedDatePicker from "../../../../../Common/Forms/OutlinedDatePicker";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import FilesArea from "./FilesArea";

const Form = ({initialData, id, onSubmit, add = false}) => {
  const {formData, setFormData, handleSubmit, errors} = useForm(
    initialData,
    validations
  );

  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={2}>
        <Grid item xs={12}>
          <VehiclesSelect
            disabled={!add}
            value={formData.vehicle}
            onChange={value => setFormData("vehicle", value)}
          />
        </Grid>
        <Grid item xs={12}>
          <CorrectiveTypesPicker
            value={formData.types}
            id={id + "-type"}
            onChange={value => setFormData("types", value)}
          />
        </Grid>
        <Grid item xs={12}>
          <StatusSelect
            form
            value={formData.status}
            id={id + "-status"}
            onChange={value => setFormData("status", value)}
          />
        </Grid>
        {(formData.status === 5 || formData.status === "5") && (
          <Grid item xs={12}>
            <OutlinedDatePicker
              value={formData.postponeDate}
              id={id + "-postpone-date"}
              fullWidth
              onChange={value => setFormData("postponeDate", value)}
              label={
                <IntlMessages
                  id={"fleet.management.vehicle.corrective.form.postpone.date"}
                />
              }
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <OutlinedInput
            value={formData.observations}
            id={id + "-observations"}
            fullWidth
            onChange={value => setFormData("observations", value)}
            label={
              <IntlMessages
                id={"fleet.management.vehicle.corrective.form.observations"}
              />
            }
          />
        </Grid>
        <Grid item xs={12}>
          <FilesArea
            formId={id}
            files={formData.files}
            setFiles={files => setFormData("files", files)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
