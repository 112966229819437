import React from 'react';
import Grid from "@material-ui/core/Grid";
import Select from "../../../../../../Common/Forms/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {decodeTypes} from "./contants";

const DecodeTypeSelect = ({value, setValue, disabled}) => {
    const onChange = ({target}) => {
        setValue && setValue(target.value)
    }
    return (
        <Grid item xs={12} md={4}>
            <Select
                label={<IntlMessages id="decode.type" />}
                value={value}
                onChange={onChange}
                fullWidth
                disabled={disabled}
            >

                {Object.values(decodeTypes).map(type => (
                    <MenuItem key={type.id} value={type.id}>
                        {type.name}
                    </MenuItem>
                ))}
            </Select>
        </Grid>
    );
};

export default DecodeTypeSelect;