import React from "react";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import Form from "../Form";
import {useDispatch} from "react-redux";
import {addReturnType} from "../../../../../redux/actions/Logistic/ReturnTypes";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const AddReturnType = ({addOpen, setAddOpen}) => {
  const id = "add-return-type-form";
  const dispatch = useDispatch();
  const onSubmit = data => {
    dispatch(addReturnType(data));
    setAddOpen(false);
  };
  return (
    <FormDialog
      id={id}
      title={
        <IntlMessages id={"logistic.distribution.return.types.add.title"} />
      }
      isOpen={addOpen}
      onClose={() => setAddOpen(false)}
    >
      {addOpen && (
        <Form
          id={id}
          initData={{
            description: "",
            code: ""
          }}
          onSubmit={onSubmit}
        />
      )}
    </FormDialog>
  );
};

export default AddReturnType;
