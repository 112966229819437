import React from 'react';
import BaseTable from "../../Common/BaseTable";
import {connect} from "react-redux";
import {
    openManufacturersDialog,
    setCurrentManufacturers,
    setManufacturersFilters
} from "../../../../../../redux/reducers/ElevatorsManagement/manufacturers";
import {dialogsList} from "../../Common/constants";
import {fetchAllManufacturers} from "../../../../../../redux/thunks/ElevatorManagement/manufacturers";
import FilterBuilder from "../../../../../../utils/filters";

const Table = ({data, filters, dispatch}) => {
    React.useEffect(() => {
        dispatch(fetchAllManufacturers());
    }, []);
    const onEdit = current => {
        dispatch(setCurrentManufacturers(current));
        dispatch(openManufacturersDialog(dialogsList.editDialog));
    };
    const onDelete = current => {
        dispatch(setCurrentManufacturers(current));
        dispatch(openManufacturersDialog(dialogsList.deleteDialog));

    };
    const onRestore = current => {
        dispatch(setCurrentManufacturers(current));
        dispatch(openManufacturersDialog(dialogsList.restoreDialog));
    };
    const onApplyFilters = filters => {
        dispatch(setManufacturersFilters(filters));
    };
    const onResetFilters = () => {
        dispatch(setManufacturersFilters(null));
    };
    return (
        <BaseTable
            data={Object.values(data)}
            initData={filters}
            onRestore={onRestore}
            onEdit={onEdit}
            onDelete={onDelete}
            onApplyFilters={onApplyFilters}
            onResetFilters={onResetFilters}
        />
    );
};

const mapStateToProps = state => {
    const {data, filters} = state.elevatorsManagement.manufacturers;
    const filteredData = new FilterBuilder(Object.values(data))
        .byFieldIncludes("id", filters.code)
        .byFieldIncludes("name", filters.name)
        .build();
    return {data: filteredData, filters};
};

export default connect(mapStateToProps)(Table);