import React from "react";
import {fetchAllClients} from "../../../../../../../services/ClientsService";
import {MenuItem, TextField} from "@material-ui/core";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Select from "../../../../../../Common/Forms/Select";

const ClientSelect = ({
  id,
  value,
  setValue,
  disabled,
  autocomplete = false
}) => {
  const [ clients, setClients ] = React.useState([]);
  React.useEffect(() => {
    fetchAllClients()
      .then(data =>
        setClients(data.sort((a, b) => a.name.localeCompare(b.name)))
      )
      .catch(e => setClients([]));
  }, []);
  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  return autocomplete ? (
    <Autocomplete
      id={id}
      getOptionSelected={(option, value) => option.id === value.id}
      getOptionLabel={option => option.name || ""}
      options={clients}
      onChange={handleChange}
      value={value}
      autoComplete
      renderInput={params => (
        <TextField
          {...params}
          disabled={disabled}
          label={
            <IntlMessages
              id={
                "elevators.management.elevators.index.card.detail.dialog.form.client"
              }
            />
          }
          fullWidth
          variant="outlined"
        />
      )}
    />
  ) : (
    <Select
      value={value}
      onChange={e => setValue(e.target.value)}
      disabled={disabled}
      label={
        <IntlMessages
          id={
            "elevators.management.elevators.index.card.detail.dialog.form.client"
          }
        />
      }
      fullWidth
    >
      {clients.map(client => (
        <MenuItem value={client.id} key={client.id}>
          {client.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ClientSelect;
