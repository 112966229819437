import React from "react";
import PropTypes from "prop-types";
import Select from "../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const MaintenanceTypesSelect = ({
  id,
  value,
  setValue,
  error,
  hasError,
  types = []
}) => {
  return (
    <Select
      id={id}
      label={
        <IntlMessages id={"fleet.management.common.select.maintenance.types"} />
      }
      value={value}
      onChange={e => setValue(e.target.value)}
      fullWidth
      error={hasError}
      errorMessage={error}
    >
      {types.map(t => (
        <MenuItem key={t.id} value={t.id}>
          {t.description}
        </MenuItem>
      ))}
    </Select>
  );
};

MaintenanceTypesSelect.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  setValue: PropTypes.func.isRequired,
  hasError: PropTypes.bool,
  error: PropTypes.node
};

export default MaintenanceTypesSelect;
