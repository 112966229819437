import React from "react";
import useForm from "../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid, Typography} from "@material-ui/core";
import validations from "./validations";
import StatusSelect from "../../Table/Filters/StatusSelect";
import VehiclesSelect
  from "../../../../Vehicles/Documentation/ListMode/VehicleDocumentsDialog/Form/VehicleInfoArea/VehiclesSelect";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import OutlinedDatePicker from "../../../../../Common/Forms/OutlinedDatePicker";
import WorkshopSelect from "./WorkshopSelect";
import TasksTable from "./TasksTable";

const Form = ({initialData, id, onSubmit, add = false}) => {
  const {formData, setFormData, handleSubmit, errors} = useForm(
    initialData,
    validations
  );

  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={2}>
        <Grid item xs={12} md={6}>
          <VehiclesSelect
            disabled={!add}
            value={formData.vehicle}
            onChange={value => setFormData("vehicle", value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <WorkshopSelect
            value={formData.workshop}
            onChange={value => setFormData("workshop", value)}
          />
        </Grid>
        {!add && (
          <React.Fragment>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <IntlMessages id="fleet.management.workshop.request.form.general.data.title" />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <OutlinedDatePicker
                fullWidth
                value={formData.date}
                id={id + "-date"}
                disabled={formData.status > 2}
                label={
                  <IntlMessages id="fleet.management.workshop.request.form.date" />
                }
                onChange={value => setFormData("date", value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StatusSelect
                  value={formData.status}
                  id={id + "-status"}
                  onChange={value => setFormData("status", value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <OutlinedDatePicker
                  fullWidth
                  value={formData.responseDate}
                  id={id + "-response-date"}
                  // disabled={formData.status > 2}
                  label={
                    <IntlMessages id="fleet.management.workshop.request.form.response.date" />
                  }
                  onChange={value => setFormData("responseDate", value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <OutlinedDatePicker
                  fullWidth
                  value={formData.entranceDate}
                  id={id + "-entrance-date"}
                  // disabled={formData.status > 2}
                  label={
                    <IntlMessages id="fleet.management.workshop.request.form.entrance.date" />
                  }
                  onChange={value => setFormData("entranceDate", value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <OutlinedInput
                fullWidth
                type="number"
                value={formData.amount}
                id={id + "-amount"}
                disabled={formData.status > 2}
                label={
                  <IntlMessages id="fleet.management.workshop.request.form.amount" />
                }
                onChange={value => setFormData("amount", value)}
              />
            </Grid>

            <Grid item xs={12}>
              <TasksTable
                  tasks={formData.tasks}
                  setTasks={tasks => setFormData('tasks', tasks)}
                  vehicle={formData.vehicle}
              />
            </Grid>
          </React.Fragment>
        )}
      </GridContainer>
    </form>
  );
};

export default Form;
