import React from 'react';
import Grid from "@material-ui/core/Grid";
import {useFetch} from "../../../../../hooks/Common/useFetch";
import {fetchAllVehicles} from "../../../../../services/Logistics/Distribution/PlannerService";
import Select from "../../../../Common/Forms/Select";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const VehicleSelects = ({head, setHead, trailer, setTrailer}) => {
    const {data} = useFetch(fetchAllVehicles, {}, []);
    return (
        <>
            <Grid item xs={12}>
                <Select
                    options={data}
                    value={head}
                    onChange={e => setHead(e.target.value)}
                    fullWidth
                    label={<IntlMessages id="vehicle.head" />}
                />
            </Grid>
            <Grid item xs={12}>
                <Select
                    options={data}
                    value={trailer}
                    onChange={e => setTrailer(e.target.value)}
                    fullWidth
                    label={<IntlMessages id="trailer" />}

                />
            </Grid>
        </>
    );
};

export default VehicleSelects;