import React from "react";
import FormControl from "@material-ui/core/FormControl";
import {FormHelperText, Grid, TextField} from "@material-ui/core";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const ObservationsInput = ({
  observations,
  setObservations,
  isLoading,
  errors,
  label
}) => (
  <Grid item xs={12} sm={12}>
    <FormControl
      error={errors.observations.length > 0}
      variant="outlined"
      size="small"
      fullWidth
    >
      <TextField
        variant="outlined"
        value={observations}
        id="observations"
        rows={8}
        multiline
        label={<IntlMessages id={label} />}
        onChange={e => setObservations(e.target.value)}
        disabled={isLoading}
      />
      <FormHelperText>{errors.observations[0]}</FormHelperText>
    </FormControl>
  </Grid>
);

export default ObservationsInput;
