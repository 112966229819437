import React from 'react';
import PageContainer from "../../../../../components/FleetManagement/Tachograph/FilesReader/PageContainer";
import Header from "../../../../../components/FleetManagement/Tachograph/FilesReader/Header";
import Viewer from "../../../../../components/FleetManagement/Tachograph/FilesReader/Viewer";
import ImageDialog from "../../../../../components/FleetManagement/Tachograph/FilesReader/Viewer/ImageDialog";

const FilesReader = () => {
    return (
        <PageContainer>
            <Header />
            <Viewer />
            <ImageDialog />
        </PageContainer>
    );
};

export default FilesReader;