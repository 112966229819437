import React from "react";
import PageContainer from "../../../../../components/FleetManagement/DailyOperation/MaintenanceTypes/PageContainer";
import Table from "../../../../../components/FleetManagement/DailyOperation/MaintenanceTypes/Table";
import {useDispatch, useSelector} from "react-redux";
import FloatingButtonWithAdd from "../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import AddDialog from "../../../../../components/FleetManagement/DailyOperation/MaintenanceTypes/TransactionsDialogs/AddDialog";
import EditDialog from "../../../../../components/FleetManagement/DailyOperation/MaintenanceTypes/TransactionsDialogs/EditDialog";
import Confirmation from "../../../../../components/Common/Dialogs/Confirmation";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {
  editMaintenanceTypeState,
  setCurrentMaintenanceType
} from "../../../../../redux/actions/FleetManagement/MaintenanceTypes";

const MaintenanceTypes = () => {
  const dispatch = useDispatch();
  const {current} = useSelector(
    ({fleetManagement}) => fleetManagement.maintenanceTypes
  );
  const [ openAdd, setOpenAdd ] = React.useState(false);
  const [ openEdit, setOpenEdit ] = React.useState(false);
  const [ openDelete, setOpenDelete ] = React.useState(false);
  const [ openRestore, setOpenRestore ] = React.useState(false);

  return (
    <PageContainer>
      <Table
        setOpenEdit={setOpenEdit}
        setOpenDelete={setOpenDelete}
        setOpenRestore={setOpenRestore}
      />
      <FloatingButtonWithAdd onAddClicked={() => setOpenAdd(true)} />
      <AddDialog openAdd={openAdd} setOpenAdd={setOpenAdd} />
      <EditDialog openEdit={openEdit} setOpenEdit={setOpenEdit} />
      <Confirmation
        open={openDelete}
        text={
          <IntlMessages id={"fleet.management.maintenance.type.delete.text"} />
        }
        onReject={() => {
          setOpenDelete(false);
          dispatch(setCurrentMaintenanceType(null));
        }}
        onAccept={() => {
          setOpenDelete(false);
          dispatch(editMaintenanceTypeState(current, false));
        }}
      />
      <Confirmation
        open={openRestore}
        text={
          <IntlMessages id={"fleet.management.maintenance.type.restore.text"} />
        }
        onReject={() => {
          setOpenRestore(false);
          dispatch(setCurrentMaintenanceType(null));
        }}
        onAccept={() => {
          setOpenRestore(false);
          dispatch(editMaintenanceTypeState(current, true));
        }}
      />
    </PageContainer>
  );
};

MaintenanceTypes.propTypes = {};

export default MaintenanceTypes;
