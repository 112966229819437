import CorrectiveType from "./CorrectiveType";

class VehicleCorrective {
  constructor(
    id,
    vehicle,
    alias,
    plaque,
    correctives,
    status,
    postponeDate,
    observations,
    files
  ) {
    this.id = id;
    this.vehicle = vehicle;
    this.alias = alias;
    this.plaque = plaque;
    this.correctives = correctives;
    this.status = status;
    this.postponeDate = postponeDate;
    this.observations = observations;
    this.files = files;
  }

  static map = element => {
    return new VehicleCorrective(
      element.nu_id_correctivo,
      element.nu_id_vehiculo,
      element.vc_placa,
      element.vc_placa,
      element.tipos.map(
        t =>
          new CorrectiveType(
            t.nu_id_tipo_correctivo.toString(),
            t.vc_desc_tipo_correctivo,
            true
          )
      ),
      {id: element.nu_id_estado, description: element.vc_desc_estado},
      element.dt_fec_posponer || "",
      element.vc_observacion,
      element.documentos.map(f => ({
        id: f.nu_id_correctivo_documento,
        description: f.vc_desc_documento,
        name: f.vc_nombre_archivo,
        notice: f.nu_dias_preaviso,
        expirationDate: f.dt_fec_caducidad
      }))
    );
  };
}

export default VehicleCorrective;
