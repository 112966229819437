import React from "react";
import PageContainer from "../../../../../components/FleetManagement/Vehicles/DocumentsTypes/PageContainer";
import Table from "../../../../../components/FleetManagement/Vehicles/DocumentsTypes/Table";
import FloatingButtonWithAdd from "../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import {dialogs} from "../../../../../components/FleetManagement/Vehicles/DocumentsTypes/constants";
import {useDispatch, useSelector} from "react-redux";
import Confirmation from "../../../../../components/Common/Dialogs/Confirmation";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {
  closeVehicleDocumentsTypesDialog,
  editVehicleDocumentsTypeState,
  openVehicleDocumentsTypesDialog,
  resetCurrentVehicleDocumentsType,
  setCurrentVehicleDocumentsType
} from "../../../../../redux/actions/FleetManagement/DocumentsTypes";
import AddDialog from "../../../../../components/FleetManagement/Vehicles/DocumentsTypes/TransactionsDialogs/AddDialog";
import EditDialog from "../../../../../components/FleetManagement/Vehicles/DocumentsTypes/TransactionsDialogs/EditDialog";

const DocumentsTypes = props => {
  const {current, dialog: {name}} = useSelector(
    ({fleetManagement}) => fleetManagement.documentsTypes
  );
  const dispatch = useDispatch();
  const onAdd = () => {
    dispatch(openVehicleDocumentsTypesDialog(dialogs.ADD_DIALOG));
  };
  const onReject = () => {
    dispatch(closeVehicleDocumentsTypesDialog());
    dispatch(resetCurrentVehicleDocumentsType());
  };
  const onDelete = () => {
    dispatch(closeVehicleDocumentsTypesDialog());
    dispatch(editVehicleDocumentsTypeState(current, false));
  };
  const onRestore = () => {
    dispatch(closeVehicleDocumentsTypesDialog());
    dispatch(editVehicleDocumentsTypeState(current, true));
  };
  return (
    <PageContainer>
      <Table />
      <AddDialog />
      <EditDialog />
      <FloatingButtonWithAdd onAddClicked={onAdd} />
      <Confirmation
        open={dialogs.DELETE_DIALOG === name}
        text={
          <IntlMessages
            id={"fleet.management.vehicles.documents.type.delete.text"}
          />
        }
        onReject={onReject}
        onAccept={onDelete}
      />
      <Confirmation
        open={dialogs.RESTORE_DIALOG === name}
        text={
          <IntlMessages
            id={"fleet.management.vehicles.documents.type.restore.text"}
          />
        }
        onReject={onReject}
        onAccept={onRestore}
      />
    </PageContainer>
  );
};

DocumentsTypes.propTypes = {};

export default DocumentsTypes;
