import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {dialogsList} from "../constants";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {closeRefundsDialog, refundSelectedAccounting} from "../../../../redux/actions/Guardians/Refunds";
import FormDialog from "../../../Common/Dialogs/FormDialog";
import GridContainer from "../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import useForm from "../../../../hooks/Common/useForm";
import ObservationsInput from "../../Common/Inputs/ObservationsInput";
import FileInput from "../../../Common/Forms/FileInput";
import {validations} from "./validations";

const initialData = {
    observations: '',
    files: []
}

const RefundConfirmationDialog = () => {
    const id = "refund-confirmation";
    const {dialogs, selectedRefunds, filters} = useSelector(({guardian}) => guardian.refunds);
    const dispatch = useDispatch();
    const {formData, setFormData, errors, handleSubmit, resetForm} = useForm(initialData, validations);
    const handleClose = () => {
        dispatch(closeRefundsDialog(dialogsList.refundConfirmation));
        resetForm();
    }
    const handleAccept = (data) => {
        dispatch(refundSelectedAccounting({
            accounting: selectedRefunds[0],
            ...data
        }, filters));
        handleClose();
    }
    return (
        <FormDialog
            id={id}
            open={dialogs.some(d => d === dialogsList.refundConfirmation)}
            title={<IntlMessages id="petty.cash.refund.confirmation" />}
            onClose={handleClose}
        >
            <form id={id} onSubmit={handleSubmit(handleAccept)}>
                <GridContainer spacing={2}>
                    <ObservationsInput
                        observations={formData.observations}
                        setObservations={data => setFormData('observations', data)}
                        errors={errors}
                        label={'observations'}
                    />
                    <Grid item xs={12}>
                        <FileInput
                            files={formData.files}
                            error={errors.files.length > 0}
                            helperText={errors.files[0]}
                            maxFiles={1}
                            label={<IntlMessages id={'file'} />}
                            onChange={value => setFormData('files', value)}
                        />
                    </Grid>
                </GridContainer>
            </form>
        </FormDialog>
    );
};

export default RefundConfirmationDialog;