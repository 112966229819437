import React from 'react';
import {useSelector} from "react-redux";
import ReactJson from "react-json-view";
import {Box} from "@material-ui/core";
import {devices} from "../FIlters/Form/contants";
import './styles.css';

const JsonViewer = () => {
    const {data, filters} = useSelector(({backOffice}) => backOffice.parser);
    return (
        <Box
            m={4}
            p={2}
            style={{
                backgroundColor: '#f8f5f5',
                boxShadow: "5px 5px 12px 3px #d5d5d5",
            }}
        >
            {filters?.packageType === devices.teltonika.packageTypes.codec12.id
                ? <p
                    className='text-wrapper'
                >
                    {data}
                </p>
                : <ReactJson src={data}/>
            }
        </Box>
    );
};

export default JsonViewer;