import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DataGrid} from "@mui/x-data-grid";
import {useIntl} from "react-intl";
import {getIndexFiles} from "../../../../../redux/thunks/BackOffice/indexFile";
import {Box, Chip} from "@material-ui/core";
import {parseDateToGlobalFormat} from "../../../../../utils/dates";
import {setCurrentIndexFile} from "../../../../../redux/reducers/BackOffice/indexFiles";

const Table = () => {
    const {data} = useSelector(({backOffice}) => backOffice.indexFiles);
    const intl = useIntl();
    const dispatch = useDispatch();
    const getHeaders = useCallback(() => {
        return [
            {
                field: 'id',
                hide: true,
            },
            {
                field: 'information',
                headerName: intl.messages["information"],
                minWidth: 150,
            },
            {
                field: 'imei',
                headerName: intl.messages["imei"],
                minWidth: 150,
            },
            {
                field: 'offline',
                headerName: intl.messages["offline"],
                minWidth: 150,
                renderCell: ({value}) => (
                    <Chip
                        color={value? 'primary' : 'secondary'}
                        label={value? 'Online' : 'Offline'}
                    />
                )
            },
            {
                field: 'queue',
                headerName: intl.messages["queue"],
                minWidth: 150,
                renderCell: ({value}) => (
                    <Chip
                        color={value? 'primary' : 'secondary'}
                        label={value? 'Vacío' : 'Pendiente'}
                    />
                )
            },
            {
                field: 'description',
                headerName: intl.messages["description"],
                minWidth: 150,
            },
            {
                field: 'firmware',
                headerName: intl.messages["firmware"],
                minWidth: 150,
            },
            {
                field: 'configuration',
                headerName: intl.messages["configuration"],
                minWidth: 150,
            },
            {
                field: 'lastViewed',
                headerName: intl.messages["last.viewed"],
                type: 'date',
                minWidth: 150,
                valueFormatter: ({value}) => parseDateToGlobalFormat(value)
            },

        ]
    }, [dispatch]);

    useEffect(() => {
        dispatch(getIndexFiles());
    }, []);

    const handleClickRow = ({row}) => {
        dispatch(setCurrentIndexFile(row));
    }
    return (
        <DataGrid
            style={{
                height: '50vh'
            }}
            columns={getHeaders()}
            rows={data.map(row => ({...row, ...row.extraData}))}
            disableSelectionOnClick
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            rowHeight={40}
            onRowClick={handleClickRow}
        />
    );
};

export default Table;