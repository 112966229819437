import React from 'react';
import BaseTable from "../../Common/BaseTable";
import {connect} from "react-redux";
import {
    openTypesDialog,
    setCurrentType,
    setTypesFilters
} from "../../../../../../redux/reducers/ElevatorsManagement/types";
import {dialogsList} from "../../Common/constants";
import {fetchAllTypes} from "../../../../../../redux/thunks/ElevatorManagement/types";
import FilterBuilder from "../../../../../../utils/filters";

const Table = ({data, filters, dispatch}) => {
    React.useEffect(() => {
        dispatch(fetchAllTypes());
    }, []);
    const onEdit = current => {
        dispatch(setCurrentType(current));
        dispatch(openTypesDialog(dialogsList.editDialog));
    };
    const onDelete = current => {
        dispatch(setCurrentType(current));
        dispatch(openTypesDialog(dialogsList.deleteDialog));

    };
    const onRestore = current => {
        dispatch(setCurrentType(current));
        dispatch(openTypesDialog(dialogsList.restoreDialog));
    };
    const onApplyFilters = filters => {
        dispatch(setTypesFilters(filters));
    };
    const onResetFilters = () => {
        dispatch(setTypesFilters(null));
    };
    return (
        <BaseTable
            data={Object.values(data)}
            initData={filters}
            onRestore={onRestore}
            onEdit={onEdit}
            onDelete={onDelete}
            onApplyFilters={onApplyFilters}
            onResetFilters={onResetFilters}
        />
    );
};

const mapStateToProps = state => {
    const {data, filters} = state.elevatorsManagement.types;
    const filteredData = new FilterBuilder(Object.values(data))
        .byFieldIncludes("id", filters.code)
        .byFieldIncludes("name", filters.name)
        .build();
    return {data: filteredData, filters};
};

export default connect(mapStateToProps)(Table);