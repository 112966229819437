import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import useForm from "../../../../../../hooks/Common/useForm";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import OutlinedDatePicker from "../../../../../Common/Forms/OutlinedDatePicker";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import {setServiceFilters} from "../../../../../../redux/reducers/BackOffice/historyData";
import {getLicensesHistoryData} from "../../../../../../redux/thunks/BackOffice/historyData";
import OrganizationSelect from "./OrganizationSelect";
import LicenseSelect from "./LicenseSelect";
import DataOptionsSelect from "./DataOptionsSelect";

const initialData = {
    organization: '',
    license: '',
    startDate: '',
    endDate: '',
    dataOptions: [],
}

const Form = () => {
    const id = 'history-data-filters';
    const {serviceFilters} = useSelector(({backOffice}) => backOffice.historyData);
    const dispatch = useDispatch();
    const {formData, setFormData, handleSubmit, resetForm} = useForm(initialData);
    const onSubmit = data => {
        dispatch(getLicensesHistoryData(data));
    }
    const onClearFilters = () => {
        resetForm();
        dispatch(setServiceFilters(initialData));
    }
    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <OrganizationSelect
                    value={formData.organization}
                    setValue={value => setFormData('organization', value)}
                />
                <LicenseSelect
                    value={formData.license}
                    setValue={value => setFormData('license', value)}
                    organizationId={formData.organization}
                />
                <Grid item xs={12} md={4}>
                    <OutlinedDatePicker
                        label={<IntlMessages id='start.date' />}
                        value={formData.startDate}
                        onChange={value => setFormData('startDate', value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <OutlinedDatePicker
                        label={<IntlMessages id='end.date' />}
                        value={formData.endDate}
                        onChange={value => setFormData('endDate', value)}
                        fullWidth
                    />
                </Grid>
                <DataOptionsSelect
                    value={formData.dataOptions}
                    setValue={value => setFormData('dataOptions', value)}
                />
                <Grid item xs={12} md={3}>
                    <ContainedButton
                        type='submit'
                        form={id}
                        color='primary'
                        text={<IntlMessages id='filters.button.apply' />}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <ContainedButton
                        onClick={onClearFilters}
                        text={<IntlMessages id='filters.button.clear' />}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form;