import {
    APPLY_VEHICLES_LIST_DOCUMENTS_FILTERS,
    APPLY_VEHICLES_SUMMARY_DOCUMENTS_FILTERS,
    FETCH_ALL_LIST_VEHICLES_DOCUMENTS,
    FETCH_ALL_SUMMARY_VEHICLES_DOCUMENTS,
    FETCH_ALL_SUMMARY_VEHICLES_DOCUMENTS_VIEWS,
    FETCH_VEHICLES_DOCUMENTS_DASHBOARD,
    RESET_ALL_SUMMARY_VEHICLES_DOCUMENTS_DATA,
    RESET_CURRENT_LIST_DOCUMENT,
    RESET_VEHICLES_DOCUMENTS_STATE,
    RESET_VEHICLES_LIST_DOCUMENTS_FILTERS,
    RESET_VEHICLES_SUMMARY_DOCUMENTS_FILTERS,
    SET_CURRENT_LIST_DOCUMENT
} from "../../types/FleetManagement/DocumentsTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {
    createDocument,
    createSummaryView, deleteSummaryView,
    editDocument,
    editSummaryHeaders,
    getAllDocuments,
    getAllSummaryDocuments,
    getAllSummaryHeaders,
    getAllSummaryViews,
    getDocumentsDashboardData
} from "../../../services/FleetManagement/Vehicles/DocumentService";


export const resetVehiclesDocumentsState = () => ({type: RESET_VEHICLES_DOCUMENTS_STATE});

export const setCurrentListDocument = item => ({type: SET_CURRENT_LIST_DOCUMENT, payload: item});
export const resetCurrentListDocument = () => ({type: RESET_CURRENT_LIST_DOCUMENT});
export const applyVehicleDocumentsListFilters = filters => ({
    type: APPLY_VEHICLES_LIST_DOCUMENTS_FILTERS,
    payload: filters
});
export const resetVehicleDocumentsListFilters = () => ({type: RESET_VEHICLES_LIST_DOCUMENTS_FILTERS});
export const fetchAllVehicleDocuments = filters => {
    return async dispatch => {
        dispatch(fetchStart());
        getAllDocuments()
            .then(docs => {
                dispatch(fetchSuccess());
                dispatch({type: FETCH_ALL_LIST_VEHICLES_DOCUMENTS, payload: docs});
            }).catch(e => {
                dispatch(fetchError(e.message));
        });
    }
}

export const addVehicleDocument = (doc, filters) => {
    return async dispatch => {
        dispatch(fetchStart());
        createDocument(doc)
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                setTimeout(() => {
                    dispatch(fetchAllVehicleDocuments(filters));
                }, 600);
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    }
}

export const editVehicleDocument = (doc, filters) => {
    return async dispatch => {
        dispatch(fetchStart());
        editDocument(doc)
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                setTimeout(() => {
                    dispatch(fetchAllVehicleDocuments(filters));
                }, 600);
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    }
}


export const applyVehicleDocumentsSummaryFilters = filters => ({
    type: APPLY_VEHICLES_SUMMARY_DOCUMENTS_FILTERS,
    payload: filters
});
export const resetVehicleDocumentsSummaryFilters = () => ({type: RESET_VEHICLES_SUMMARY_DOCUMENTS_FILTERS});
export const fetchAllSummaryVehicleDocuments = (filters,  views) => {
    return async dispatch => {
        if(filters.view !== ''){
            dispatch(fetchStart());
            try {
                const data = await getAllSummaryDocuments(filters);
                const headers = await getAllSummaryHeaders(filters.view);
                dispatch(fetchSuccess());
                dispatch({
                    type: FETCH_ALL_SUMMARY_VEHICLES_DOCUMENTS,
                    payload: {
                        data,
                        headers,
                        selectedInfo: {
                            viewId: filters.view,
                            editable: views.find(v => v.id === filters.view)?.editable,
                            isPublic: views.find(v => v.id === filters.view)?.isPublic,
                            name: views.find(v => v.id === filters.view)?.name,
                            predetermined: views.find(v => v.id === filters.view)?.predetermined
                        }
                    }
                });
            }catch (e) {
                dispatch(fetchError(e.message));
            }
        }
        else {
            dispatch({type: RESET_ALL_SUMMARY_VEHICLES_DOCUMENTS_DATA});
        }
    }
}

export const fetchAllSummaryVehicleDocumentsView = () => {
    return async dispatch => {
        dispatch(fetchStart());
        try {
            dispatch(fetchSuccess());
            const views = await getAllSummaryViews();
            dispatch({type: FETCH_ALL_SUMMARY_VEHICLES_DOCUMENTS_VIEWS, payload: views});
        }catch (e) {
            dispatch(fetchError(e.message));
        }
    }
}

export const addSummaryDocumentsView = data => {
    return async dispatch => {
        dispatch(fetchStart());
        createSummaryView(data)
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                setTimeout(() => {
                    dispatch(fetchAllSummaryVehicleDocumentsView());
                }, 500);
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    }
}

export const removeSummaryDocumentsView = viewId => {
    return async dispatch => {
        dispatch(fetchStart());
        deleteSummaryView(viewId)
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                setTimeout(() => {
                    dispatch(fetchAllSummaryVehicleDocumentsView());
                }, 500);
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    }
}

export const editDocumentsHeaders = (view, data, filters, views) => {
    return async dispatch => {
        dispatch(fetchStart());
        editSummaryHeaders({view, ...data})
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                setTimeout(() => {
                    dispatch(fetchAllSummaryVehicleDocuments(filters, views));
                }, 500);
            }).catch(e => {
                dispatch(fetchError(e.message));
        });
    }
}


export const fetchVehiclesDocumentsDashboard = ({view}) => {
    return async dispatch => {
        try {
            dispatch(fetchStart());
            let data = [];
            if(view) {
                data = await getDocumentsDashboardData({view});
            }
            dispatch({
                type: FETCH_VEHICLES_DOCUMENTS_DASHBOARD,
                payload: data
            });
            dispatch(fetchSuccess());
        }catch (e) {
            dispatch(fetchError(e.message));
        }
    }
}