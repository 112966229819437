import React from 'react';
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import PageContainerComponent from "../../../Common/Layouts/PageContainer";
import PropTypes from "prop-types";

const breadcrumbs = [
    {
        label: <IntlMessages id={"sidebar.masterData"} />,
        link: "/treasury/logistic/providers-orders"
    },
    {label: <IntlMessages id={"logistic.purchases.provider.orders.title"} />, isActive: true}
];

const PageContainer = ({children}) => (
    <PageContainerComponent
        heading={<IntlMessages id="logistic.purchases.provider.orders.title" />}
        description={
            <IntlMessages id="logistic.purchases.provider.orders.description" />
        }
        breadcrumbs={breadcrumbs}
    >
        {children}
    </PageContainerComponent>
);

PageContainer.propTypes = {
    children: PropTypes.node
};

export default PageContainer;