import React from "react";
import FormDialog from "../../../../../Common/Dialogs/FormDialog";
import {useDispatch, useSelector} from "react-redux";
import Form from "./Form";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {
  editVehicleDocument,
  resetCurrentListDocument
} from "../../../../../../redux/actions/FleetManagement/Documents";
import {parseDateToInput} from "../../../../../../utils/dates";
import validations from "./validations";

const VehicleDocumentsDialog = () => {
  const {current, filters} = useSelector(
    ({fleetManagement}) => fleetManagement.documents.list
  );
  const dispatch = useDispatch();
  const id = "edit-vehicle-document-form";
  const onSubmit = data => {
    dispatch(editVehicleDocument({
      id: current?.id,
      ...data,
    }, filters));
    onClose();
  };
  const onClose = () => {
    dispatch(resetCurrentListDocument());
  };
  return (
    <FormDialog
      id={id}
      isOpen={Boolean(current)}
      title={
        <IntlMessages
          id={
            "fleet.management.vehicles.documentation.list.mode.detail.form.title"
          }
        />
      }
      onClose={onClose}
    >
      {current && (
        <Form
          id={id}
          initialData={{
            vehicle: current.vehicleId || "",
            type: current.typeId || "",
            expirationDate: parseDateToInput(current.expirationDate) || "",
            noticeDays: current.noticeDays || "",
            url: current.fileName || "",
            file: null
          }}
          onSubmit={onSubmit}
          edit
          validations={validations}
        />
      )}
    </FormDialog>
  );
};

export default VehicleDocumentsDialog;
