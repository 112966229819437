import React from "react";
import {useDispatch} from "react-redux";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {addConfigurationVehicleRole} from "../../../../../redux/actions/Configuration/VehicleRoles";
import Form from "./Form";

const AddVehicleRoleDialog = ({openAdd, setOpenAdd}) => {
  const id = "add-vehicle-role-form";
  const dispatch = useDispatch();
  const onAddRole = role => {
    dispatch(addConfigurationVehicleRole(role));
    setOpenAdd(false);
  };
  return (
    <FormDialog
      id={id}
      title={
        <IntlMessages
          id={"configuration.user.settings.vehicle.roles.add.title"}
        />
      }
      submitText={<IntlMessages id={"label.form.save"} />}
      cancelText={<IntlMessages id={"label.form.cancel"} />}
      isOpen={openAdd}
      onClose={() => setOpenAdd(false)}
    >
      {openAdd && (
        <Form initialData={{description: ""}} id={id} onAddRole={onAddRole} />
      )}
    </FormDialog>
  );
};

export default AddVehicleRoleDialog;
