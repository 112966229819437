import {
    FETCH_CARBON_FOOTPRINT_DATA,
    RESET_CARBON_FOOTPRINT_FILTERS,
    RESET_CARBON_FOOTPRINT_STATE,
    SET_CARBON_FOOTPRINT_FILTERS
} from "../../types/Logistic/CarbonFootprintTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {getAllCarbonFootprintData} from "../../../services/Logistics/Distribution/CarbonFootprintService";

export const resetCarbonFootprintState = () => ({type: RESET_CARBON_FOOTPRINT_STATE});
export const resetCarbonFootprintFilters = () => ({type: RESET_CARBON_FOOTPRINT_FILTERS});
export const setCarbonFootprintFilters = (filters) => ({type: SET_CARBON_FOOTPRINT_FILTERS, payload: filters});

export const fetchCarbonFootprintData = (filters) => {
    return async dispatch => {
        try {
            dispatch(fetchStart());
            const data = await getAllCarbonFootprintData(filters);
            dispatch({type: FETCH_CARBON_FOOTPRINT_DATA, payload: data});
            dispatch(fetchSuccess());
        }catch (e) {
            dispatch(fetchError(e.message));
        }
    }
}
