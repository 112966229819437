import React from "react";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import TableCellWithSorting from "../../../../Common/Tables/TableCellWithSorting";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";

const TableHeader = ({order, orderBy, onSort}) => (
  <StyledTableRow>
    <TableCellWithSorting
      property="providerCode"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="provider.code" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="name"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="name" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="businessName"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="business.name" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="country"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="country" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="code"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="code" />
    </TableCellWithSorting>
    <TableCellWithSorting
      property="email"
      orderBy={orderBy}
      order={order}
      onSort={onSort}
    >
      <IntlMessages id="email" />
    </TableCellWithSorting>
    <StyledTableCell>
      <IntlMessages id="options" />
    </StyledTableCell>
  </StyledTableRow>
);

export default TableHeader;
