import React, {useEffect} from "react";

import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import ActiveSelect from "../../../../Common/Forms/Select/ActiveSelect";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import useForm from "../../../../../hooks/Common/useForm";
import {useDispatch} from "react-redux";
import {setFilters} from "../../../../../redux/reducers/Treasury/costCenterType";

const initialData = {
  active: true,
  description: ""
};

const Form = () => {
  const {formData, setFormData} = useForm(initialData);
  const dispatch = useDispatch();

  useEffect(
    () => {
      dispatch(setFilters(formData));
    },
    [ formData ]
  );

  return (
    <form>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4} lg={3}>
          <ActiveSelect
            id="state-filter"
            value={formData.active}
            onChange={value => setFormData("active", value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.description}
            id="description-filter"
            label={
              <IntlMessages id="description" />
            }
            onChange={value => setFormData("description", value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
