import React from 'react';
import {GridToolbarFilterButton} from "@mui/x-data-grid";

const FilterToolbar = () => {
    return (
        <div>
            <GridToolbarFilterButton/>
        </div>
    );
};

export default FilterToolbar;