import {mapToBoolean} from "../../../utils/mappers";

class MaintenancePlan {
    constructor(id, name, status, kilometers, months,
                engineHours, noticeKilometers, noticeHours,
                noticeDays, types, countManagement) {
        this.id = id;
        this.name = name;
        this.quantity = types.length;
        this.cost = types.map(t => Number(t.cost)).reduce((a, b) => a + b, 0);
        this.status = status;
        this.months = months;
        this.kilometers = kilometers;
        this.engineHours = engineHours;
        this.noticeKilometers = noticeKilometers;
        this.noticeHours = noticeHours;
        this.noticeDays = noticeDays;
        this.types = types;
        this.quantity = types.length;
        this.countManagement = countManagement;
    }

    static map = (plan) => {
        return new MaintenancePlan(
            plan.nu_id_plan_mantenimiento,
            plan.vc_desc_plan_mantenimiento,
            mapToBoolean(plan.bi_estado),
            plan.nu_nro_kilometros,
            plan.nu_nro_meses,
            plan.nu_nro_horas,
            plan.nu_nro_kilometros_preaviso,
            plan.nu_nro_horas_preaviso,
            plan.nu_nro_dias_preaviso,
            plan?.ls_tipos?.map(t => ({
                id: t.nu_id_tipo_mantenimiento,
                description: t.vc_desc_tipo_mantenimiento,
                cost: t.nu_costo
            })),
            plan.ch_gestion_contador,
        );
    }
}

export default MaintenancePlan;