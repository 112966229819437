import React from "react";
import PropTypes from "prop-types";

import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import CrudTableRow from "../../../Common/Tables/CrudTable/CrudTableRow";
import RestoreTableRow from "../../../Common/Tables/CrudTable/RestoreTableRow";
import useTableSort from "../../../../hooks/Common/useTableSort";
import TableComponent from "../../../Common/Tables/Table";
import TableHeader from "./TableHeader";
import {connect} from "react-redux";
import FilterBuilder from "../../../../utils/filters";
import {openDialog, setCurrentCostCenter} from "../../../../redux/reducers/Treasury/costCenterType";
import {dialogsList} from "../constants";
import {fetchAllCostCenterTypes} from "../../../../redux/thunks/Treasury/costCenterType";

const Table = ({data, dispatch}) => {
  const {tableData, onSort, order, orderBy} = useTableSort(data);

  React.useEffect(() => {
      dispatch(fetchAllCostCenterTypes());
  }, []);
  const handleClickAction = (dialog, costCenter) => {
      dispatch(openDialog(dialog));
      dispatch(setCurrentCostCenter(costCenter));
  }
  const RenderRow = ({row}) =>
    row.active ? (
      <CrudTableRow
        onEdit={() => handleClickAction(dialogsList.edit, row)}
        onDelete={() => handleClickAction(dialogsList.delete, row)}
      >
        <StyledTableCell>{row.description}</StyledTableCell>
      </CrudTableRow>
    ) : (
      <RestoreTableRow onRestore={() => handleClickAction(dialogsList.restore, row)}>
        <StyledTableCell>{row.description}</StyledTableCell>
      </RestoreTableRow>
    );

  return (
    <TableComponent
      data={tableData}
      head={<TableHeader orderBy={orderBy} order={order} onSort={onSort} />}
      renderRow={RenderRow}
      getRowKey={row => row.id}
    />
  );
};

Table.propTypes = {
    data: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.any,
            description: PropTypes.string,
            active: PropTypes.bool
        })
    ),
    dispatch: PropTypes.func,
}

Table.defaultProps = {
  data: [],
};

const mapStateToProps = state => {
    const {costCenters, filters} = state.treasury.costCenterType;
    const filtered = new FilterBuilder(costCenters)
        .byFieldEquals("active", filters.active)
        .byFieldIncludes("description", filters.description)
        .build();
    return {data: filtered};
};

export default connect(mapStateToProps)(Table);
