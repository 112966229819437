import React from "react";
import {Button, Grid, IconButton, Typography} from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import {SortableElement, SortableHandle} from "react-sortable-hoc";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import {useStyles} from "./styles";
import GridContainer from "../../../../../../../../@jumbo/components/GridContainer";

const DragHandle = SortableHandle(() => <DragIndicatorIcon />);

const HeaderCell = SortableElement(({header, onChangeStarred}) => {
  const classes = useStyles();
  return (
    <GridContainer key={header.id} wrap="nowrap" alignItems="center">
      <Grid item>
        <Button size="small" className={classes.dragButton}>
          <DragHandle />
        </Button>
      </Grid>
      <Grid item>
        <Typography variant="body2">{header.label}</Typography>
      </Grid>
      <Grid item>
        <IconButton onClick={e => onChangeStarred(e, header)}>
          {header.active ? <VisibilityIcon /> : <VisibilityOffIcon />}
        </IconButton>
      </Grid>
    </GridContainer>
  );
});

export default HeaderCell;
