import React from "react";
import {useDispatch, useSelector} from "react-redux";
import FormDialog from "../../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import Form from "../Form";
import {dialogs} from "../../constants";
import {
  closeVehicleDocumentsTypesDialog,
  editVehicleDocumentsType
} from "../../../../../../redux/actions/FleetManagement/DocumentsTypes";

const EditDialog = () => {
  const id = "edit-documents-types-form";
  const {current, dialog: {name}} = useSelector(
    ({fleetManagement}) => fleetManagement.documentsTypes
  );
  const dispatch = useDispatch();
  const onEdit = data => {
    dispatch(
      editVehicleDocumentsType({
        id: current.id,
        status: current.status,
        ...data
      })
    );
    onClose();
  };
  const onClose = () => {
    dispatch(closeVehicleDocumentsTypesDialog());
  };
  return (
    <FormDialog
      id={id}
      title={
        <IntlMessages
          id={"fleet.management.vehicles.documents.types.edit.title"}
        />
      }
      submitText={<IntlMessages id={"label.form.save"} />}
      cancelText={<IntlMessages id={"label.form.cancel"} />}
      isOpen={dialogs.EDIT_DIALOG === name}
      onClose={onClose}
    >
      {name === dialogs.EDIT_DIALOG && (
        <Form
          initialData={{
            description: current.description || "",
            securityControl: current.securityControl
          }}
          id={id}
          onSubmit={onEdit}
        />
      )}
    </FormDialog>
  );
};

EditDialog.propTypes = {};

export default EditDialog;
