import React from 'react';
import PageContainer from "../../../../components/Localization/DailyOperation/DrivingControl/PageContainer";
import Table from "../../../../components/Localization/DailyOperation/DrivingControl/Table";
import Filters from "../../../../components/Localization/DailyOperation/DrivingControl/Filters";
import DetailsDialog from "../../../../components/Localization/DailyOperation/DrivingControl/DetailsDialog";

const DrivingControl = () => {
    return (
        <PageContainer>
            <Filters />
            <Table />
            <DetailsDialog />
        </PageContainer>
    );
};

export default DrivingControl;