import React from 'react';
import Grid from "@material-ui/core/Grid";
import Select from "../../../../../Common/Forms/Select";
import {useFetch} from "../../../../../../hooks/Common/useFetch";
import {fetchAllOrganizations} from "../../../../../../services/BackOffice/Licenses/HistoryDataService";
import MenuItem from "@material-ui/core/MenuItem";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const OrganizationSelect = ({value, setValue}) => {
    const {data, executeService} = useFetch(fetchAllOrganizations, {}, []);
    const onChange = ({target}) => {
        setValue && setValue(target.value)
    }
    React.useEffect(() => {
        executeService();
    }, []);
    return (
        <Grid item xs={12} md={4}>
            <Select
                value={value}
                onChange={onChange}
                fullWidth
                label={<IntlMessages id="organization" />}
            >
                {data.map(e => (
                  <MenuItem value={e.id} key={e.id}>
                      {e.name}
                  </MenuItem>
                ))}
            </Select>
        </Grid>
    );
};

export default OrganizationSelect;