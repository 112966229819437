import {mapToBoolean} from "../../utils/mappers";

class SummaryHeader {
  constructor(id, label, field, active, order) {
    this.id = id;
    this.label = label;
    this.field = field;
    this.active = active;
    this.order = order;
  }

  static map = header => {
    return new SummaryHeader(
      header.nu_id_tipo_documento,
      header.vc_desc_tipo_documento,
      header.nu_id_tipo_documento,
      mapToBoolean(header.bi_estado),
      header.nu_orden
    );
  };
}

export default SummaryHeader;
