import React from 'react';
import ContainedButton from "../../../../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import TableContainer from "../../../../../../Common/Tables/TableContainer";
import StyledTableRow from "../../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../../Common/Tables/StyledTableCell";
import ContactDialog from "./ContactDialog";
import {IconButton} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import {useDispatch, useSelector} from "react-redux";
import {openDialog} from "../../../../../../../redux/reducers/Logistic/providerOrders";
import {dialogsList} from "../../../constants";
import {deleteContactToProviderOrder} from "../../../../../../../redux/thunks/Logistic/providerOrders";

const ContactsList = ({contacts, name, editable = true}) => {
    const dispatch = useDispatch();
    const {currentOrder} = useSelector(({logistic}) => logistic.providerOrders);
    const handleDeleteContact = (contactId) => {
        dispatch(deleteContactToProviderOrder({
            order: currentOrder,
            contactId
        }));
    }
    return (
        <div className="table-list">
            {editable && <ContainedButton
                text={<><IntlMessages id="add"/>{' '}<IntlMessages id="contact"/></>}
                color="primary"
                onClick={() => dispatch(openDialog(`${dialogsList.contactForm}-${name}`))}
                className="add-button"
            />}
            <TableContainer
                head={
                    <StyledTableRow>
                        <StyledTableCell>
                            <IntlMessages id="contact" />
                        </StyledTableCell>
                        <StyledTableCell>
                            <IntlMessages id="email" />
                        </StyledTableCell>
                        <StyledTableCell>
                            <IntlMessages id="phone" />
                        </StyledTableCell>
                        {editable && <StyledTableCell width="10%"/>}
                    </StyledTableRow>
                }
            >
                {contacts.map((contact, index) => (
                  <StyledTableRow key={index}>
                      <StyledTableCell>
                          {contact.name}
                      </StyledTableCell>
                      <StyledTableCell>
                          {contact.email}
                      </StyledTableCell>
                      <StyledTableCell>
                          {contact.phone}
                      </StyledTableCell>
                      {editable && <StyledTableCell>
                          <IconButton onClick={() => handleDeleteContact(contact.id)}>
                              <DeleteIcon/>
                          </IconButton>
                      </StyledTableCell>}
                  </StyledTableRow>
                ))}
            </TableContainer>
            <ContactDialog
                name={name}
            />
        </div>
    );
};

export default ContactsList;