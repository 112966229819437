import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import VehicleDocumentType from "../../../domain/FleetManagement/Vehicles/VehicleDocumentType";
import Response from "../../../domain/Response";

export const getAllDocumentTypes = async () => {
  try {
    const {data} = await axiosInstance.post("/Vehiculo_Tipo_Documento/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(t => VehicleDocumentType.map(t));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createDocumentType = async type => {
  try {
    const {data} = await axiosInstance.post("/Vehiculo_Tipo_Documento/ins", {
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_tipo_documento: type.name
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};
