import React from "react";
import PropTypes from "prop-types";
import TableComponent from "../../../../../../Common/Tables/Table";
import StyledTableCell from "../../../../../../Common/Tables/StyledTableCell";
import TableHeader from "./TableHeader";
import CrudTableRow from "../../../../../../Common/Tables/CrudTable/CrudTableRow";

const TypesTable = ({types, setSelectedType, onRemoveType}) => {
  const onDelete = type => {
    onRemoveType(type);
  };
  const onEdit = type => {
    setSelectedType(type);
  };
  const RenderRow = ({row}) => (
    <CrudTableRow onDelete={() => onDelete(row)} onEdit={() => onEdit(row)}>
      <StyledTableCell>{row.description}</StyledTableCell>
      <StyledTableCell>{row.cost}</StyledTableCell>
    </CrudTableRow>
  );

  return (
    <React.Fragment>
      <TableComponent
        data={types}
        head={<TableHeader />}
        renderRow={RenderRow}
        getRowKey={row => row.id}
      />
    </React.Fragment>
  );
};

TypesTable.propTypes = {
  types: PropTypes.array,
  setSelectedType: PropTypes.func.isRequired,
  onRemoveType: PropTypes.func.isRequired
};

export default TypesTable;
