import React from "react";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import FiltersContainer from "../../../../../../Common/Forms/FiltersContainer";
import TagsSelect from "./TagsSelect";

const Filters = ({formData, setFormData}) => {
  return (
    <FiltersContainer active onClose={() => {}}>
      <form>
        <GridContainer spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"config-vehicle-roles-plaque"}
              fullWidth
              value={formData.plaque}
              onChange={value => setFormData("plaque", value)}
              label={
                <IntlMessages
                  id={
                    "configuration.user.settings.vehicle.roles.edit.filters.plaque"
                  }
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TagsSelect
              value={formData.tags}
              onChange={values => setFormData("tags", values)}
            />
          </Grid>
        </GridContainer>
      </form>
    </FiltersContainer>
  );
};

Filters.propTypes = {};

export default Filters;
