import React from 'react';
import Select from "../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {itemTypes} from "../../../constants";

const TypeSelect = ({name = 'type', value, onChange, error, errorMessage, required = false}) => {
    const types = Object.values(itemTypes)
        .map(type => ({
            id: type,
            name: <IntlMessages id={type} />
        }));
    const handleOnChange = ({target}) => {
        const {value} = target;
        onChange(value);
    }
    return (
        <Select
            label={<IntlMessages id="type"/>}
            name={name}
            value={value}
            onChange={handleOnChange}
            error={error}
            errorMessage={errorMessage}
            fullWidth
            required={required}
        >
            {types?.map(type => (
                <MenuItem key={type.id} value={type.id}>
                    {type.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default TypeSelect;