import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import ReturnType from "../../../domain/Logistic/Distribution/ReturnType";
import Response from "../../../domain/Response";

export const getAllReturnTypes = async () => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Despacho_Devoluciones/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(t => ReturnType.map(t));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createReturnType = async type => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Despacho_Devoluciones/ins", {
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_tipo_devolucion: type.description,
      vc_cod_tipo_devolucion: type.code
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateReturnType = async type => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Despacho_Devoluciones/upd", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_tipo_devolucion: type.id,
      vc_desc_tipo_devolucion: type.description,
      vc_cod_tipo_devolucion: type.code
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateReturnTypeState = async (type, state) => {
  try {
    const {data} = await axiosInstance.post("/Tipo_Despacho_Devoluciones/del", {
      nu_id_tipo_devolucion: type.id,
      bi_estado: state
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};
