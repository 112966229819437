import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import React from "react";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages
        id={"configuration.user.settings.vehicle.roles.table.code"}
      />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages
        id={"configuration.user.settings.vehicle.roles.table.description"}
      />
    </StyledTableCell>
    <StyledTableCell />
  </StyledTableRow>
);

export default TableHeader;
