import React from "react";
import {useFetch} from "../../../../../../../hooks/Common/useFetch";
import MultipleSelect from "../../../../../../Common/Forms/Select/MultipleSelect";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {getAllDocumentTypes} from "../../../../../../../services/RRHH/DocumentationTypeService";

const TypeSelect = ({values, onChange}) => {
  const {data: types} = useFetch(getAllDocumentTypes, {}, []);
  return (
    <MultipleSelect
      fullWidth
      label={
        <IntlMessages
          id={"rrhh.employees.documentation.list.mode.table.type"}
        />
      }
      value={values}
      onChange={onChange}
      options={types}
      limitTags={2}
    />
  );
};

export default TypeSelect;
