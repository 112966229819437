import axiosInstance from "../../../utils/axiosInstance";
import {getCompanyEntityId} from "../../StorageService";
import Response from "../../../domain/Response";
import VehicleMaintenancePlan from "../../../domain/FleetManagement/DailyOperations/VehicleMaintenancePlan";

export const getAllVehicleMaintenancePlans = async request => {
  try {
    const {
      data
    } = await axiosInstance.post("/Vehiculo/sel_vehiculo_plan_mantenimiento", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(d => VehicleMaintenancePlan.map(d));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const editVehicleMaintenancePlan = async request => {
  try {
    const {
      data
    } = await axiosInstance.post("/Vehiculo/upd_vehiculo_plan_mantenimiento", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_vehiculo: request.id,
      ls_plnaes: request.plans.map(p => p.id)
    });
    const response = Response.map(data);
    if (response.transactionState === "0") {
      throw Error(response.transactionMessage);
    }
    return response;
  } catch (e) {
    return Promise.reject(e);
  }
};
