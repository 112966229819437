import React from "react";
import PropTypes from "prop-types";

import FiltersContainer from "../../../Common/Forms/FiltersContainer";
import Form from "./Form";

const Filters = () => {
  return(
      <FiltersContainer active marginBottom={5}>
        <Form />
      </FiltersContainer>
  )
};

Filters.propTypes = {
  onFilter: PropTypes.func,
  onClose: PropTypes.func
};

Filters.defaultProps = {
  onFilter: () => {},
  onClose: () => {}
};

export default Filters;
