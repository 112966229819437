import React from 'react';
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import useForm from "../../../../../hooks/Common/useForm";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import validations from "./validations";

const Form = ({id, initData, onSubmit}) => {
    const {formData, setFormData, handleSubmit, errors} = useForm(initData, validations);
    return(
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12}>
                    <OutlinedInput
                       label={<IntlMessages id={"logistic.distribution.return.types.form.description"}/>}
                       value={formData.description}
                       onChange={value => setFormData('description', value)}
                       fullWidth
                       hasError={errors?.description?.length > 0}
                       error={errors?.description[0]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedInput
                        label={<IntlMessages id={"logistic.distribution.return.types.form.code"}/>}
                        value={formData.code}
                        onChange={value => setFormData('code', value)}
                        fullWidth
                        hasError={errors?.code?.length > 0}
                        error={errors?.code[0]}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
}

export default Form;