import React from 'react';
import Grid from "@material-ui/core/Grid";
import Select from "../../../../../Common/Forms/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const ParamSelect = ({value, setValue}) => {
    const onChange = ({target}) => {
        setValue && setValue(target.value)
    }
    return (
        <Grid item xs={12} md={4}>
            <Select
                value={value}
                onChange={onChange}
                fullWidth
            >
                <MenuItem value={1}>
                    <IntlMessages id='serial.number' />
                </MenuItem>
                <MenuItem value={2}>
                    <IntlMessages id='imei' />
                </MenuItem>
            </Select>
        </Grid>
    );
};

export default ParamSelect;