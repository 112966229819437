import React from 'react';
import Grid from "@material-ui/core/Grid";
import Select from "../../../../../../Common/Forms/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {devices} from "./contants";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";

const DevicesSelect = ({value, setValue}) => {
    const onChange = ({target}) => {
        setValue && setValue(target.value)
    }
    return (
        <Grid item xs={12} md={4}>
            <Select
                label={<IntlMessages id="devices" />}
                value={value}
                onChange={onChange}
                fullWidth
            >
                {Object.values(devices)?.map(device => (
                    <MenuItem key={device.id} value={device.id}>
                        {device.name}
                    </MenuItem>
                ))}

            </Select>
        </Grid>
    );
};
export default DevicesSelect;