import React from "react";
import PropTypes from "prop-types";
import useForm from "../../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";

const Form = ({id, initialData, onSubmit}) => {
  const {formData, setFormData, handleSubmit} = useForm(initialData);
  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <GridContainer spacing={2}>
        <Grid item xs={12}>
          <OutlinedInput
            id={id + "cost"}
            label={
              <IntlMessages
                id={"fleet.management.maintenance.plan.form.table.types.cost"}
              />
            }
            value={formData.cost}
            onChange={value => setFormData("cost", value)}
            fullWidth
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

Form.propTypes = {
  id: PropTypes.string.isRequired,
  initialData: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default Form;
