import React from "react";
import {useDispatch, useSelector} from "react-redux";
import useForm from "../../../../../hooks/Common/useForm";
import {applyReturnTypesFilters} from "../../../../../redux/actions/Logistic/ReturnTypes";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import FiltersContainer from "../../../../Common/Forms/FiltersContainer";

const INIT_DATA = {
  description: "",
  code: ""
};

const Filters = () => {
  const {filters} = useSelector(({logistic}) => logistic.returnTypes);
  const {formData, setFormData, setValues} = useForm(INIT_DATA);
  const dispatch = useDispatch();
  React.useEffect(() => {
    setValues(filters);
  }, []);
  React.useEffect(
    () => {
      dispatch(applyReturnTypesFilters(formData));
    },
    [ formData ]
  );
  return (
    <FiltersContainer active onClose={() => {}}>
      <form>
        <GridContainer spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"return-types-description"}
              fullWidth
              value={formData.description}
              onChange={value => setFormData("description", value)}
              label={
                <IntlMessages
                  id={"logistic.distribution.return.types.table.description"}
                />
              }
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <OutlinedInput
              id={"return-types-code"}
              fullWidth
              value={formData.code}
              onChange={value => setFormData("code", value)}
              label={
                <IntlMessages
                  id={"logistic.distribution.return.types.table.code"}
                />
              }
            />
          </Grid>
        </GridContainer>
      </form>
    </FiltersContainer>
  );
};
export default Filters;
