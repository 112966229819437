import React from 'react';
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";

export const devices = {
    teltonika: {
        id: 'Teltonika',
        name: <IntlMessages id='teltonika'/>,
        packageTypes: {
            tcp: {
                id: 'TCP',
                name: <IntlMessages id='tcp'/>
            },
            udp: {
                id: 'UDP',
                name: <IntlMessages id='udp'/>
            },
            codec12: {
                id: 'Codec12',
                name: <IntlMessages id='codec12' />
            }
        }
    }
}

export const decodeTypes = {
    original: {
        id: 'Original',
        name: <IntlMessages id='original' />
    },
    formatted: {
        id: 'Formateada',
        name: <IntlMessages id='formatted' />
    }
}

