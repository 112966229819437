import React from 'react';
import {useSelector} from "react-redux";
import {DataGrid} from "@mui/x-data-grid";
import {useIntl} from "react-intl";
import {Chip} from "@material-ui/core";
import {parseDateToGlobalFormat} from "../../../../../../utils/dates";



const Tasks = () => {
    const {currentIndexFile} = useSelector(({backOffice}) => backOffice.indexFiles);
    const intl = useIntl();
    const columns = [
        {
            field: 'id',
            hide: true
        },
        {
            field: 'state',
            headerName: intl.messages['state'],
            width: 150,
            renderCell: ({value}) => (
                <Chip
                    color={value? 'primary' : 'secondary'}
                    label={value? 'Completado' : 'Caducado'}
                />
            )
        },
        {
            field: 'lotId',
            headerName: intl.messages['lot.id'],
            width: 150,
        },
        {
            field: 'taskId',
            headerName: intl.messages['task.id'],
            width: 150,
        },
        {
            field: 'taskType',
            headerName: intl.messages['task.type'],
            width: 150,
        },
        {
            field: 'createdAt',
            headerName: intl.messages['created.at'],
            width: 150,
            valueFormatter: ({value}) => parseDateToGlobalFormat(value)
        }
    ]

    return (
        <DataGrid
            style={{
                height: '250px'
            }}
            rows={currentIndexFile?.tasks || []}
            columns={columns}
            disableSelectionOnClick
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            rowHeight={40}
        />
    );
};

export default Tasks;