import React, {useEffect} from 'react';
import {useFetch} from "../../../hooks/Common/useFetch";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import {getAllDeductions} from "../../../services/Treasury/BillServiceService";
import Autocomplete from "../../Common/Forms/Autocomplete";

const DeductionTypeSelect = ({name = 'deductionType', value, onChange, initValue = null}) => {
    const {data: deductions} = useFetch(getAllDeductions, {}, []);
    const handleOnChange = (e, newValue) => {
        onChange(newValue);
    }
    useEffect(() => {
        if(initValue && deductions.length) {
            onChange(deductions.find(deduction => deduction.id === initValue.id));
        }
    }, [initValue, deductions]);
    return (
        <Autocomplete
            label={<IntlMessages id="deduction.type"/>}
            name={name}
            value={value}
            onChange={handleOnChange}
            options={deductions}
        />
    );
};

export default DeductionTypeSelect;