import React from "react";
import TableContainer from "../../../Common/Tables/TableContainer";
import TableHeader from "./TableHeader";
import {connect} from "react-redux";
import {
  fetchAllReturnTypes,
  setCurrentReturnType
} from "../../../../redux/actions/Logistic/ReturnTypes";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import CrudTableRow from "../../../Common/Tables/CrudTable/CrudTableRow";
import RestoreTableRow from "../../../Common/Tables/CrudTable/RestoreTableRow";
import {Box} from "@material-ui/core";
import FilterBuilder from "../../../../utils/filters";
import Filters from "./Filters";

const ReturnTypesTable = ({
  dispatch,
  data,
  openEdit,
  openDelete,
  openRestore
}) => {
  React.useEffect(() => {
    dispatch(fetchAllReturnTypes());
  }, []);
  const onEdit = type => {
    openEdit && openEdit();
    dispatch(setCurrentReturnType(type));
  };
  const onDelete = type => {
    openDelete && openDelete();
    dispatch(setCurrentReturnType(type));
  };
  const onRestore = type => {
    openRestore && openRestore();
    dispatch(setCurrentReturnType(type));
  };
  return (
    <Box display="flex" flexDirection="column" style={{gap: "1em"}}>
      <Filters />
      <TableContainer head={<TableHeader />}>
        {Object.values(data).map(
          type =>
            type.state ? (
              <CrudTableRow
                onEdit={() => onEdit(type)}
                onDelete={() => onDelete(type)}
                key={type.id}
              >
                <StyledTableCell>{type.description}</StyledTableCell>
                <StyledTableCell>{type.code}</StyledTableCell>
              </CrudTableRow>
            ) : (
              <RestoreTableRow key={type.id} onRestore={() => onRestore(type)}>
                <StyledTableCell>{type.description}</StyledTableCell>
                <StyledTableCell>{type.code}</StyledTableCell>
              </RestoreTableRow>
            )
        )}
      </TableContainer>
    </Box>
  );
};

const mapStateToProps = ({logistic}) => {
  const {data, filters} = logistic.returnTypes;
  const filteredData = new FilterBuilder(Object.values(data))
    .byFieldIncludes("description", filters.description)
    .byFieldIncludes("code", filters.code)
    .build();
  return {data: filteredData};
};

export default connect(mapStateToProps)(ReturnTypesTable);
