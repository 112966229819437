import React from 'react';
import {useFetch} from "../../../../../../hooks/Common/useFetch";
import {fetchAllDataOptions} from "../../../../../../services/BackOffice/Licenses/HistoryDataService";
import Grid from "@material-ui/core/Grid";
import MultipleSelect from "../../../../../Common/Forms/Select/MultipleSelect";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const DataOptionsSelect =  ({value, setValue}) => {
    const {data, executeService} = useFetch(fetchAllDataOptions, {}, []);
    const onChange = (newValues) => {
        setValue && setValue(newValues)
    }
    React.useEffect(() => {
        executeService();
    }, []);
    return (
        <Grid item xs={12} md={4}>
            <MultipleSelect
                value={value}
                onChange={onChange}
                fullWidth
                options={data}
                label={<IntlMessages id="data" />}
            />
        </Grid>
    );
};

export default DataOptionsSelect;