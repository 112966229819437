import React from 'react';
import FiltersContainer from "../../../Common/Forms/FiltersContainer";
import GridContainer from "../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import OutlinedDatePicker from "../../../Common/Forms/OutlinedDatePicker";
import useForm from "../../../../hooks/Common/useForm";
import {useDispatch, useSelector} from "react-redux";
import {openRefundsDialog, resetRefundsFilters, setRefundsFilters} from "../../../../redux/actions/Guardians/Refunds";
import ContainedButton from "../../../Common/Buttons/ContainedButton";
import {dialogsList} from "../constants";

const initState = {
    accountingNumber: "",
    lowerDate: "",
    upperDate: "",
    quantity: "",
    pettyCashAmount: ""
};
const Filters = () => {
    const {selectedRefunds} = useSelector(({guardian}) => guardian.refunds);

    const {formData, setFormData, resetForm} = useForm(initState);
    const dispatch = useDispatch();
    const handleApplyFilters = () => {
        dispatch(setRefundsFilters(formData));
    }
    const handleClearFilters = () => {
        resetForm();
        dispatch(resetRefundsFilters());
    }
    const handleRefund = () => {
        dispatch(openRefundsDialog(dialogsList.refundConfirmation));
    }
    return (
        <FiltersContainer active mb={3}>
            <GridContainer spacing={4}>
                <Grid item xs={12} sm={4} lg={3}>
                    <OutlinedInput
                        fullWidth
                        value={formData.id}
                        id="accountingNumber"
                        label={<IntlMessages id="guardian.accounting.table.id.filter"/>}
                        onChange={value => setFormData("accountingNumber", value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <OutlinedDatePicker
                        fullWidth
                        id="lowerDate"
                        label={
                            <IntlMessages id="guardian.accounting.table.lower.date.filter"/>
                        }
                        value={formData.lowerDate}
                        onChange={value => setFormData("lowerDate", value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <OutlinedDatePicker
                        fullWidth
                        id="upperDate"
                        label={
                            <IntlMessages id="guardian.accounting.table.upper.date.filter"/>
                        }
                        value={formData.upperDate}
                        onChange={value => setFormData("upperDate", value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <OutlinedInput
                        fullWidth
                        value={formData.quantity}
                        id="quantity"
                        label={
                            <IntlMessages id="guardian.accounting.table.quantity.filter"/>
                        }
                        onChange={value => setFormData("quantity", value)}
                    />
                </Grid>
                <Grid item xs={12} sm={4} lg={3}>
                    <OutlinedInput
                        fullWidth
                        value={formData.pettyCashAmount}
                        id="pettyCashAmount"
                        label={
                            <IntlMessages id="petty.cash.amount"/>
                        }
                        onChange={value => setFormData("pettyCashAmount", value)}
                        type='number'
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <ContainedButton
                        onClick={handleApplyFilters}
                        text={<IntlMessages id="filters.button.apply"/>}
                        color='primary'
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <ContainedButton
                        onClick={handleClearFilters}
                        text={<IntlMessages id="filters.button.clear"/>}
                    />
                </Grid>


                    <Grid item xs={12} md={3}>
                        <ContainedButton
                            onClick={handleRefund}
                            text={<IntlMessages id="refund"/>}
                            color='primary'
                            disabled={!(selectedRefunds.length > 0)}
                        />
                    </Grid>

            </GridContainer>
        </FiltersContainer>
    );
};

export default Filters;