import React from 'react';
import Grid from "@material-ui/core/Grid";
import Select from "../../../../Common/Forms/Select";
import {useFetch} from "../../../../../hooks/Common/useFetch";
import {fetchAllDrivers} from "../../../../../services/Logistics/Distribution/PlannerService";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const DriverSelect = ({value, setValue}) => {
    const {data} = useFetch(fetchAllDrivers, {}, []);
    return (
        <Grid item xs={12}>
            <Select
                options={data}
                value={value}
                onChange={e => setValue(e.target.value)}
                fullWidth
                label={<IntlMessages id="driver" />}
            />
        </Grid>
    );
};

export default DriverSelect;