import React from "react";
import StyledTableRow from "../../../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => (
  <StyledTableRow>
    <StyledTableCell>
      <IntlMessages
        id={
          "fleet.management.vehicles.documentation.list.mode.table.expiration.date"
        }
      />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages
        id={"fleet.management.vehicles.documentation.list.mode.table.active"}
      />
    </StyledTableCell>
    <StyledTableCell>
      <IntlMessages id={"form.button.download"} />
    </StyledTableCell>
  </StyledTableRow>
);

export default TableHeader;
