import React from 'react';
import FiltersContainer from "../../../../Common/Forms/FiltersContainer";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {useDispatch, useSelector} from "react-redux";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import {setLocalFilters} from "../../../../../redux/reducers/BackOffice/teltonika";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Form from "./Form";

const Filters = () => {
    const {localFilters} = useSelector(({backOffice}) => backOffice.teltonika);
    const dispatch = useDispatch();
    const onChangeFilter = (field, value) => {
        dispatch(setLocalFilters({
            ...localFilters,
            [field]: value
        }))
    }
    return (
        <>
            <FiltersContainer active >
                <Form />
            </FiltersContainer>
            <GridContainer
                spacing={2}
                style={{
                    margin: '1rem 0'
                }}
            >
                <Grid item xs={12} md={4}>
                    <OutlinedInput
                        label={<IntlMessages id='event' />}
                       onChange={value => onChangeFilter('event', value)}
                       value={localFilters.event}
                       fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <OutlinedInput
                        label={<IntlMessages id='time.fix' />}
                        onChange={value => onChangeFilter('timeFix', value)}
                        value={localFilters.timeFix}
                        fullWidth
                        type="number"
                    />
                </Grid>

            </GridContainer>
        </>
    );
};

export default Filters;