import {
  ADD_CONFIG_VEHICLE_ROLE,
  APPLY_CONFIG_VEHICLE_ROLES_FILTERS,
  EDIT_CONFIG_VEHICLE_ROLE,
  EDIT_CONFIG_VEHICLE_ROLE_STATE,
  FETCH_ALL_CONFIG_VEHICLE_ROLES,
  RESET_CONFIG_VEHICLE_ROLES_FILTERS,
  RESET_CONFIG_VEHICLE_ROLES_STATES,
  SET_CURRENT_CONFIG_VEHICLE_ROLE
} from "../../types/Configuration/VehicleRolesTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import VehicleRole from "../../../domain/Configuration/VehicleRole";
import {
  addConfigVehicleRole,
  fetchAllConfigVehicleRoles,
  fetchAllVehiclesByVehicleRole,
  updateConfigVehicleRoleData,
  updateConfigVehicleRoleState,
  updateConfigVehicleRoleVehicleState
} from "../../../services/Configuration/ConfigVehicleRoleService";

export const resetConfigurationVehicleRoleState = () => ({
  type: RESET_CONFIG_VEHICLE_ROLES_STATES
});
export const applyConfigVehicleRolesFilters = filters => ({
  type: APPLY_CONFIG_VEHICLE_ROLES_FILTERS,
  payload: filters
});
export const resetConfigVehicleRolesFilters = () => ({
  type: RESET_CONFIG_VEHICLE_ROLES_FILTERS
});

export const setCurrentConfigurationVehicleRole = (role, process = 1) => {
  return async dispatch => {
    if (role !== null && process === 1) {
      dispatch(fetchStart());
      fetchAllVehiclesByVehicleRole(role)
        .then(vehicles => {
          dispatch(fetchSuccess());
          // let modules = {};
          // vehicles.map(u => {
          //     modules[u.id] = u;
          //     return null;
          // });
          // role.permissions = modules;
          role.vehicles = vehicles;
          dispatch({type: SET_CURRENT_CONFIG_VEHICLE_ROLE, payload: role});
        })
        .catch(e => {
          dispatch(fetchError(e.message));
        });
    } else {
      dispatch({type: SET_CURRENT_CONFIG_VEHICLE_ROLE, payload: role});
    }
  };
};

export const fetchAllConfigurationVehicleRoles = () => {
  return async dispatch => {
    dispatch(fetchStart());
    fetchAllConfigVehicleRoles()
      .then(roles => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_CONFIG_VEHICLE_ROLES, payload: roles});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const updateConfigurationVehicleRoleState = (role, state) => {
  return async dispatch => {
    dispatch(fetchStart());
    updateConfigVehicleRoleState(role, state)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        dispatch({
          type: EDIT_CONFIG_VEHICLE_ROLE_STATE,
          payload: {id: role.id, state}
        });
        dispatch(setCurrentConfigurationVehicleRole(null));
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const updateConfigurationVehicleRole = (role, vehicles) => {
  return async dispatch => {
    dispatch(fetchStart());
    Promise.all(
      vehicles.map(vehicle =>
        updateConfigVehicleRoleVehicleState(role, vehicle)
      )
    )
      .then(response => {
        dispatch(
          fetchSuccess("Los vehiculos del rol se modificaron correctamente")
        );
        dispatch(setCurrentConfigurationVehicleRole(null));
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const addConfigurationVehicleRole = role => {
  return async dispatch => {
    dispatch(fetchStart());
    addConfigVehicleRole(role)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const newVehicleRole = new VehicleRole(
          response.transactionKey,
          role.description,
          true,
          []
        );
        dispatch({type: ADD_CONFIG_VEHICLE_ROLE, payload: newVehicleRole});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};

export const updateConfigurationVehicleRoleData = (role, data) => {
  return async dispatch => {
    const updatedData = {id: role.id, ...data};
    dispatch(fetchStart());
    updateConfigVehicleRoleData(updatedData)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        dispatch({type: EDIT_CONFIG_VEHICLE_ROLE, payload: updatedData});
      })
      .catch(e => {
        dispatch(fetchError(e.message));
      });
  };
};
