import React from 'react';
import PageContainer from "../../../../../../components/BackOffice/Devices/Management/Parser/PageContainer";
import Filters from "../../../../../../components/BackOffice/Devices/Management/Parser/FIlters";
import JsonViewer from "../../../../../../components/BackOffice/Devices/Management/Parser/JsonViewer";

const Parser = () => {
    return (
        <PageContainer>
            <Filters />
            <JsonViewer />
        </PageContainer>
    );
};

export default Parser;