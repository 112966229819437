import React from "react";
import TableContainer from "../../../../../Common/Tables/TableContainer";
import DocumentsHeader from "./DocumentsHeader";
import {connect} from "react-redux";
import {fetchAllSummaryVehicleDocuments} from "../../../../../../redux/actions/FleetManagement/Documents";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import CancelIcon from "@material-ui/icons/Cancel";
import RemoveIcon from "@material-ui/icons/Remove";
import WarningIcon from "@material-ui/icons/Warning";
import FilterBuilder from "../../../../../../utils/filters";
import {useStyles} from "./styles";
import useTableSort from "../../../../../../hooks/Common/useTableSort";

const DocumentsTable = ({dispatch, headers, data, filters, views}) => {
  const classes = useStyles();
  const {tableData, onSort, order, orderBy} = useTableSort(Object.values(data));
  React.useEffect(
    () => {
      dispatch(fetchAllSummaryVehicleDocuments(filters, views));
    },
    [ filters.view ]
  );
  const renderSymbol = {
    1: <RemoveIcon />, //Not documents
    2: <ThumbUpIcon style={{color: '#64dd17'}} />, // ok
    3: <WarningIcon style={{color: '#ff6d00'}} />, // notice
    4: <CancelIcon style={{color: '#ff1744'}} /> //expired
  };
  return (
    <TableContainer
      head={<DocumentsHeader onSort={onSort} order={order} orderBy={orderBy} />}
    >
      {Object.values(tableData).map((vehicle, index) => (
        <StyledTableRow key={vehicle.id}>
          <StyledTableCell
            component="th"
            scope="row"
            className={classes.stickyBodyCell}
          >
            {vehicle.vehicle}
          </StyledTableCell>
          {Object.values(headers)
            .filter(h => h.active)
            .map((h, index) => (
              <StyledTableCell key={vehicle.id + index}>
                {renderSymbol[vehicle[h.field]]}
              </StyledTableCell>
            ))}
        </StyledTableRow>
      ))}
    </TableContainer>
  );
};

const mapStateToProps = state => {
  const {
    headers,
    data,
    filters,
    views
  } = state.fleetManagement.documents.summary;
  const filteredData = new FilterBuilder(data)
    .byFieldIncludes("vehicle", filters.vehicle)
    .byFieldIncludes("documentation", filters.expiration)
    .byFieldIncludesMultiOptions("tags", filters.tags.map(t => t.id))
    .build();

  return {data: filteredData, filters, headers, views};
};

export default connect(mapStateToProps)(DocumentsTable);
