import React, {useState} from 'react';
import Form from "./Form";
import FormDialog from "../../../Common/Dialogs/FormDialog";
import {useDispatch, useSelector} from "react-redux";
import {dialogsList} from "../constants";
import {closeDialog} from "../../../../redux/reducers/Logistic/Planner";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {updateSelectedServices} from "../../../../redux/thunks/Logistic/planner";
import Typography from "@material-ui/core/Typography";

const MassiveUpdateDialog = () => {
    const id = 'massive-update';
    const {dialogs, servicesList} = useSelector(({logistic}) => logistic.planner);
    const dispatch = useDispatch();
    const open = dialogs.some(dialog => dialog === dialogsList.updateRows);
    const [disabled, setDisabled] = useState(true);
    const handleClose = () => {
        dispatch(closeDialog(dialogsList.updateRows));
    }
    const onSubmit = (data) => {
        dispatch(updateSelectedServices({services: servicesList, request: data}));
        handleClose();
    }
    return (
        <FormDialog
            id={id}
            isOpen={open}
            onClose={handleClose}
            title={<IntlMessages id="massive.change" />}
            disabled={disabled}
        >
            {open &&
                <Form
                    id={id}
                    onSubmit={onSubmit}
                    setDisabled={setDisabled}
                />
            }
            <Typography
                component='p'
                style={{
                    margin: '1rem 0'
                }}
            >
                Pedidos: {servicesList.join(', ')}
            </Typography>
        </FormDialog>
    );
};

export default MassiveUpdateDialog;