import React from "react";
import Form from "./Form";
import {useDispatch, useSelector} from "react-redux";
import FormDialog from "../../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {dialogsList} from "../../constants";
import {closeDialog, setCurrentProviderHeadquarter} from "../../../../../../redux/reducers/Logistic/providers";

const AddHeadquarterDialog = () => {
    const {dialogs} = useSelector(({logistic}) => logistic.providers);
    const isOpen = dialogs.some(dialog => dialog === dialogsList.addHeadquarterDialog)
    const id = "add-headquarter-form";
    const dispatch = useDispatch();
    const onClose = () => {
        dispatch(closeDialog(dialogsList.addHeadquarterDialog));
        dispatch(setCurrentProviderHeadquarter(null));
    }
    const onSubmit = () => {
        onClose();
    }
  return (
    <FormDialog
      id={id}
      isOpen={isOpen}
      title={
        <IntlMessages id="add" />
      }
      onClose={onClose}
    >
      <Form id={id} onSubmit={onSubmit} />
    </FormDialog>
  );
};

export default AddHeadquarterDialog;
