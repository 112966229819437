import {
  ADD_VEHICLE_CORRECTIVES_TYPE,
  APPLY_VEHICLE_CORRECTIVES_FILTERS,
  CLOSE_VEHICLE_CORRECTIVES_DIALOG,
  EDIT_VEHICLE_CORRECTIVES_TYPE,
  FETCH_ALL_VEHICLE_CORRECTIVES,
  OPEN_VEHICLE_CORRECTIVES_DIALOG,
  RESET_VEHICLE_CORRECTIVES_FILTERS,
  RESET_VEHICLE_CORRECTIVES_STATE,
  RESET_CURRENT_VEHICLE_CORRECTIVES_TYPE,
  SET_CURRENT_VEHICLE_CORRECTIVES_TYPE
} from "../../types/FleetManagement/VehicleCorrectiveTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import VehicleCorrective from "../../../domain/FleetManagement/DailyOperations/VehicleCorrective";
import CorrectiveType from "../../../domain/FleetManagement/DailyOperations/CorrectiveType";
import {
  createVehicleCorrectives,
  getAllVehicleCorrectives,
  updateVehicleCorrectives
} from "../../../services/FleetManagement/DailyOperation/VehicleCorrectivesService";

let id = 0;
let typeId = 0;
const data = [
  new VehicleCorrective(
    ++id,
    "ABC-123",
    "ABC-123",
    [
      new CorrectiveType(++typeId, "Tipo 1", true),
      new CorrectiveType(++typeId, "Tipo 2", true)
    ],
    {
      id: 1,
      description: "Pendiente"
    }
  )
];

export const resetVehicleCorrectivesState = () => ({
  type: RESET_VEHICLE_CORRECTIVES_STATE
});
export const applyVehicleCorrectivesFilters = filters => ({
  type: APPLY_VEHICLE_CORRECTIVES_FILTERS,
  payload: filters
});
export const resetVehicleCorrectivesFilters = () => ({
  type: RESET_VEHICLE_CORRECTIVES_FILTERS
});
export const setCurrentVehicleCorrectives = type => ({
  type: SET_CURRENT_VEHICLE_CORRECTIVES_TYPE,
  payload: type
});
export const resetCurrentVehicleCorrectives = () => ({
  type: RESET_CURRENT_VEHICLE_CORRECTIVES_TYPE
});
export const openVehicleCorrectivesDialog = name => ({
  type: OPEN_VEHICLE_CORRECTIVES_DIALOG,
  payload: name
});
export const closeVehicleCorrectivesDialog = () => ({
  type: CLOSE_VEHICLE_CORRECTIVES_DIALOG
});

export const fetchAllVehicleCorrectives = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllVehicleCorrectives()
      .then(types => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_VEHICLE_CORRECTIVES, payload: types});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const addVehicleCorrectives = data => {
  return async dispatch => {
    dispatch(fetchStart());
    createVehicleCorrectives(data)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        setTimeout(() => {
          dispatch(fetchAllVehicleCorrectives());
        }, 1500);
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const editVehicleCorrectives = data => {
  return async dispatch => {
    dispatch(fetchStart());
    updateVehicleCorrectives(data)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        dispatch(resetCurrentVehicleCorrectives());
        setTimeout(() => {
          dispatch(fetchAllVehicleCorrectives());
        }, 1500);
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const editVehicleCorrectivesState = (type, status) => {
  return async dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess("Se actualizo el estado correctamente"));
      const editCorrective = new VehicleCorrective(
        type.id,
        type.description,
        status
      );
      dispatch({type: EDIT_VEHICLE_CORRECTIVES_TYPE, payload: editCorrective});
      dispatch(resetCurrentVehicleCorrectives());
    }, 1500);
    // updateVehicleCorrectivesStatus(type, status)
    //     .then(response => {
    //         dispatch(fetchSuccess(response.transactionMessage));
    //         const editType = new VehicleCorrectives(
    //             response.transactionKey,
    //             type.description,
    //             type.code,
    //             status
    //         )
    //         dispatch({type:EDIT_VEHICLE_CORRECTIVES_TYPE, payload: editType});
    //         dispatch(resetCurrentVehicleCorrectives());
    //     }).catch(err => {
    //     dispatch(fetchError(err.message));
    // });
  };
};
