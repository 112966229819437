import React from 'react';
import PageContainer from "../../../../components/Logistic/CarbonFootprint/PageContainer";
import Filters from "../../../../components/Logistic/CarbonFootprint/Filters";
import Table from "../../../../components/Logistic/CarbonFootprint/Table";

const CarbonFootprint = () => {
    return (
        <PageContainer>
            <Filters />
            <Table />
        </PageContainer>
    );
};

export default CarbonFootprint;