import {
  ADD_EMPLOYEE_DOCUMENTS_TYPE,
  APPLY_EMPLOYEE_DOCUMENTS_TYPES_FILTERS,
  CLOSE_EMPLOYEE_DOCUMENTS_TYPES_DIALOG,
  EDIT_EMPLOYEE_DOCUMENTS_TYPE,
  FETCH_ALL_EMPLOYEE_DOCUMENTS_TYPES,
  OPEN_EMPLOYEE_DOCUMENTS_TYPES_DIALOG,
  RESET_CURRENT_EMPLOYEE_DOCUMENTS_TYPE,
  RESET_EMPLOYEE_DOCUMENTS_TYPES_FILTERS,
  RESET_EMPLOYEE_DOCUMENTS_TYPES_STATE,
  SET_CURRENT_EMPLOYEE_DOCUMENTS_TYPE
} from "../../types/RRHH/DocumentsTypesTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import DocumentsTypes from "../../../domain/RRHH/DocumentsTypes";
import {
  createEmployeeDocumentsType,
  getAllEmployeeDocumentsTypes,
  updateEmployeeDocumentsType
} from "../../../services/RRHH/DocumentsTypesService";

let id = 0;
const types = [
  new DocumentsTypes(id++, "Tipo 1", "code 1", true),
  new DocumentsTypes(id++, "Tipo 2", "code 2", false)
];

export const resetEmployeeDocumentsTypesState = () => ({
  type: RESET_EMPLOYEE_DOCUMENTS_TYPES_STATE
});
export const applyEmployeeDocumentsTypesFilters = filters => ({
  type: APPLY_EMPLOYEE_DOCUMENTS_TYPES_FILTERS,
  payload: filters
});
export const resetEmployeeDocumentsTypesFilters = () => ({
  type: RESET_EMPLOYEE_DOCUMENTS_TYPES_FILTERS
});
export const setCurrentEmployeeDocumentsType = type => ({
  type: SET_CURRENT_EMPLOYEE_DOCUMENTS_TYPE,
  payload: type
});
export const resetCurrentEmployeeDocumentsType = () => ({
  type: RESET_CURRENT_EMPLOYEE_DOCUMENTS_TYPE
});
export const openEmployeeDocumentsTypesDialog = name => ({
  type: OPEN_EMPLOYEE_DOCUMENTS_TYPES_DIALOG,
  payload: name
});
export const closeEmployeeDocumentsTypesDialog = () => ({
  type: CLOSE_EMPLOYEE_DOCUMENTS_TYPES_DIALOG
});

export const fetchAllEmployeeDocumentsTypes = filters => {
  return async dispatch => {
    dispatch(fetchStart());
    getAllEmployeeDocumentsTypes()
      .then(types => {
        dispatch(fetchSuccess());
        dispatch({type: FETCH_ALL_EMPLOYEE_DOCUMENTS_TYPES, payload: types});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const addEmployeeDocumentsType = type => {
  return async dispatch => {
    dispatch(fetchStart());
    createEmployeeDocumentsType(type)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const newType = new DocumentsTypes(
          response.transactionKey,
          type.description,
          type.securityControl,
          true
        );
        dispatch({type: ADD_EMPLOYEE_DOCUMENTS_TYPE, payload: newType});
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const editEmployeeDocumentsType = type => {
  return async dispatch => {
    dispatch(fetchStart());
    updateEmployeeDocumentsType(type)
      .then(response => {
        dispatch(fetchSuccess(response.transactionMessage));
        const editType = new DocumentsTypes(
          response.transactionKey,
          type.description,
          type.securityControl,
          type.status
        );
        dispatch({type: EDIT_EMPLOYEE_DOCUMENTS_TYPE, payload: editType});
        dispatch(resetCurrentEmployeeDocumentsType());
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };
};

export const editEmployeeDocumentsTypeState = (type, status) => {
  return async dispatch => {
    dispatch(fetchStart());
    setTimeout(() => {
      dispatch(fetchSuccess("Se actualizo el estado correctamente"));
      const editType = new DocumentsTypes(
        type.id,
        type.description,
        type.securityControl,
        status
      );
      dispatch({type: EDIT_EMPLOYEE_DOCUMENTS_TYPE, payload: editType});
      dispatch(resetCurrentEmployeeDocumentsType());
    }, 1500);
    // updateEmployeeDocumentsTypeStatus(type, status)
    //     .then(response => {
    //         dispatch(fetchSuccess(response.transactionMessage));
    //         const editType = new EmployeeDocumentsType(
    //             response.transactionKey,
    //             type.description,
    //             type.code,
    //             status
    //         )
    //         dispatch({type:EDIT_EMPLOYEE_DOCUMENTS_TYPE, payload: editType});
    //         dispatch(resetCurrentEmployeeDocumentsType());
    //     }).catch(err => {
    //     dispatch(fetchError(err.message));
    // });
  };
};
