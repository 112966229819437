import React, {useCallback} from 'react';
import {useSelector} from "react-redux";
import moment from "moment";
import {DataGrid, GridToolbarContainer, GridToolbarExport} from "@mui/x-data-grid";
import {useIntl} from "react-intl";

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

const Table = () => {
    const {data, localFilters} = useSelector(({backOffice}) => backOffice.historyData);
    const intl = useIntl();

    const getHeaders = useCallback(() => {
        const headers = [
            {
                field: 'id',
                headerName: intl.messages["organization"],
                minWidth: 150
            },
            {
                field: 'asset',
                headerName: intl.messages["asset"],
                minWidth: 150,
            },
            {
                field: 'asset',
                headerName: intl.messages["asset"],
                minWidth: 180,
            },
            {
                field: 'driver',
                headerName: intl.messages["driver"],
                minWidth: 150,
            },
            {
                field: 'localTime',
                headerName: intl.messages["local.time"],
                valueFormatter: ({value, row}) => {
                    const {event} = row;
                    return calculateDate(event, value)
                },
                minWidth: 200,
            },
        ]
        const extraHeaders = Object.keys(data[0]?.extraData || {});
        return [
            ...headers,
            ...extraHeaders.map((header) => ({
                field: header,
                headerName: header,
                minWidth: 150
            }))
        ]
    }, [data, localFilters])
    const calculateDate = (event, date) => {
        let newDate = new Date(0).setUTCSeconds(date);
        newDate = moment(newDate)
            .add(Number(localFilters.timeFix || 0), 'hour')
            .format("DD/MM/YYYY HH:mm:ss");
        return newDate;
    }
    return (
        <DataGrid
            columns={getHeaders()}
            rows={data.map(row => ({...row, ...row.extraData}))}
            disableSelectionOnClick
            rowsPerPageOptions={[5, 10, 25]}
            pagination
            rowHeight={40}
            checkboxSelection
            components={{
                Toolbar: CustomToolbar,
            }}
        />
    );
};

export default Table;