import React from 'react';
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid, IconButton} from "@material-ui/core";
import ContactsList from "./ContactsList";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import OutlinedDateTimePicker from "../../../../../Common/Forms/OutlinedDateTimePicker";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import Typography from "@material-ui/core/Typography";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import ClientHeadquarterSelect from "../../../../WorkOrders/WorkOrderDialog/DestinationsDialog/ClientHeadquarteSelect";
import AddressMap from "../../../../WorkOrders/WorkOrderDialog/DestinationDetail/Form/AddressArea/AddressMap";
import useForm from "../../../../../../hooks/Common/useForm";
import {useDispatch, useSelector} from "react-redux";
import {updateProviderOrderLocationDetails} from "../../../../../../redux/thunks/Logistic/providerOrders";

const LocationForm = ({name, location, editable = true}) => {
    const dispatch = useDispatch();
    const {currentOrder} = useSelector(({logistic}) => logistic.providerOrders);
    const {formData, setFormData, handleSubmit} = useForm({
        location: location.location ?? {},
        date: location?.date ?? '',
        indications: location?.indications ?? '',
    });
    const [showMap, setShowMap] = React.useState(false);
    const handleShowMap = () => {
        setShowMap(!showMap);
    }
    const onSubmit = (data) => {
        dispatch(updateProviderOrderLocationDetails({
            order: currentOrder,
            request: data,
            type: name
        }));
    }
    return (
        <form id={`${name}-form`} onSubmit={handleSubmit(onSubmit)}>
            <fieldset>
                <legend>
                    <Typography>
                        <IntlMessages id="data.of"/>  <IntlMessages id={name} />
                    </Typography>
                </legend>
                <GridContainer spacing={2}
                               style={{
                                   display: "flex",
                                   alignItems: "center",
                               }}
                >
                    {editable && <>
                        <Grid
                            item xs={12}
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end'
                            }}
                        >
                            <ContainedButton
                                text={<IntlMessages id="save"/>}
                                color="primary"
                                type="submit"
                            />
                        </Grid>
                    </>}
                    <Grid item xs={10} md={6}>
                        <ClientHeadquarterSelect
                            setValue={value => setFormData('location', value)}
                            label={<IntlMessages id={name} />}
                            clientHeadquarter={formData.location}
                            disabled={!editable}
                            name={name}
                        />
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <IconButton onClick={handleShowMap}>
                            <LocationOnIcon />
                        </IconButton>
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <OutlinedDateTimePicker
                            value={formData.date}
                            onChange={value => setFormData('date', value)}
                            label={<IntlMessages id="date" />}
                            fullWidth
                            disabled={!editable}
                        />
                    </Grid>
                    {showMap && <Grid item xs={12}>
                        {(location.latitude && location.longitude)
                            ? <AddressMap latitude={Number(location.latitude)} longitude={Number(location.longitude)}
                                          name={location.location.description}/>
                            : <Typography
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    padding: '5px',
                                    border: "1px solid #000",
                                }}
                            >
                                <IntlMessages id="no.location.data" />
                            </Typography>
                        }
                    </Grid>}
                    <Grid item xs={12}>
                        <OutlinedInput
                            value={formData.indications}
                            onChange={value => setFormData('indications', value)}
                            multiline
                            rows={4}
                            label={<IntlMessages id="indications" />}
                            fullWidth
                            disabled={!editable}
                        />
                    </Grid>
                    <Grid item xs={12}
                          style={{
                              marginTop: '4px'
                          }}
                    >
                        <ContactsList
                            name={name}
                            contacts={location.contacts}
                            editable={editable}
                        />
                    </Grid>
                </GridContainer>
            </fieldset>

        </form>
    );
};

export default LocationForm;