import React from "react";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import Select from "../../../../../../Common/Forms/Select";
import {MenuItem} from "@material-ui/core";

const CountManagementSelect = ({
  value,
  onChange,
  error,
  hasError,
  disabled
}) => {
  const items = [
    {
      id: 1,
      description: "Suma respecto al anterior"
    },
    {
      id: 2,
      description: "Contador Fijo"
    }
  ];

  return (
    <Select
      id={"count-management"}
      label={
        <IntlMessages id="fleet.management.maintenance.plan.form.count.management" />
      }
      value={value}
      onChange={e => onChange(e.target.value)}
      fullWidth
      disabled={disabled}
      error={hasError}
      errorMessage={error}
    >
      {items.map(s => (
        <MenuItem value={s.id} key={s.id}>
          {s.description}
        </MenuItem>
      ))}
    </Select>
  );
};

CountManagementSelect.propTypes = {};

export default CountManagementSelect;
