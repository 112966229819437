import React from "react";
import VehicleInfoArea from "./VehicleInfoArea";
import FileInfoArea from "./FileInfoArea";
import VehicleDocumentsList from "./VehicleDocumentsList";
import {Box, Divider} from "@material-ui/core";
import useForm from "../../../../../../../hooks/Common/useForm";

const Form = ({id, initialData, onSubmit, edit = false, validations = {}}) => {
  const {formData, setFormData, handleSubmit, errors} = useForm(
    initialData,
    validations
  );
  return (
    <form id={id} onSubmit={handleSubmit(onSubmit)}>
      <Box display="flex" flexDirection="column" style={{gap: "1em"}}>
        <VehicleInfoArea
          vehicle={formData.vehicle}
          type={formData.type}
          setFormData={setFormData}
          edit={edit}
          errors={errors}
        />
        <Divider />
        <FileInfoArea
          formData={formData}
          setFormData={setFormData}
          edit={edit}
          errors={errors}
        />
        <Divider />
        {formData.vehicle &&
        formData.type && (
          <VehicleDocumentsList
            vehicle={formData.vehicle}
            type={formData.type}
          />
        )}
      </Box>
    </form>
  );
};

export default Form;
