import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {fetchAllTaxes, updateTaxState} from "../../../redux/thunks/Treasury/taxes";
import {DataGrid} from "@mui/x-data-grid";
import {useIntl} from "react-intl";
import {IconButton, Typography} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {dialogsList} from "./constants";
import {openDialog, setCurrentTax} from "../../../redux/reducers/Treasury/taxes";
import RestoreIcon from "@material-ui/icons/Restore";

const Table = () => {
    const dispatch = useDispatch();
    const {taxes} = useSelector(({treasury}) => treasury.taxes);
    const taxesList = Object.values(taxes);
    const intl = useIntl();
    React.useEffect(() => {
        dispatch(fetchAllTaxes());
    }, []);
    const handleEditTax = (row) => {
        dispatch(openDialog(dialogsList.EDIT_TAX));
        dispatch(setCurrentTax(row));
    }
    const handleDeleteTax = (row) => {
        dispatch(updateTaxState({taxId: row.id, state: !row.state}));
    }
    const columns = [
        {
            field: 'id',
            hide: true
        },
        {
            field: 'name',
            headerName: intl.messages['name'],
            width: 200,
        },
        {
            field: 'percentage',
            headerName: intl.messages['value'],
            valueFormatter: ({value}) => `${value}%`,
            width: 200,
        },
        {
            field: 'state',
            headerName: intl.messages['state'],
            width: 200,
            renderCell: ({value}) =>
                (<Typography
                    style={{
                        color: '#fff',
                        backgroundColor: value ? '#4caf50' : '#f44336',
                        padding: '4px 8px',
                    }}
                >
                    {value ? intl.messages['active'] : intl.messages['inactive']}
                </Typography>)

        },
        {
            field: 'actions',
            headerName: intl.messages["table.edit"],
            type: 'actions',
            renderCell: ({row}) => {
                const {state} = row;
                return (
                    <>
                        {state &&
                            <IconButton onClick={() => handleEditTax(row)}>
                                <EditIcon/>
                            </IconButton>
                        }
                        <IconButton onClick={() => handleDeleteTax(row)}>
                            {state? <DeleteIcon/> : <RestoreIcon />}
                        </IconButton>
                    </>
                )
            }
        }
    ]
    return (
        <DataGrid
            columns={columns}
            rows={taxesList}
            style={{
                height: '70vh'
            }}
            disableColumnMenu
            disableSelectionOnClick
        />
    );
};

export default Table;