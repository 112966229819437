import React from "react";
import {Box, IconButton} from "@material-ui/core";
import Filters from "./Filters";
import TableContainer from "../../../../../Common/Tables/TableContainer";
import TableHeader from "./TableHeader";
import FilterBuilder from "../../../../../../utils/filters";
import {connect} from "react-redux";
import GetAppIcon from "@material-ui/icons/GetApp";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import {
  fetchAllVehicleDocuments,
  setCurrentListDocument
} from "../../../../../../redux/actions/FleetManagement/Documents";
import ShowTableRow from "../../../../../Common/Tables/CrudTable/ShowTableRow";
import {
  downloadFile,
  getFileByName
} from "../../../../../../services/FileService";
import {fetchError} from "../../../../../../redux/actions";
import {parseDateToDateString} from "../../../../../../utils/dates";

const ListTable = ({filters, data, dispatch}) => {
  React.useEffect(() => {
    dispatch(fetchAllVehicleDocuments(filters));
  }, []);
  const onShow = doc => {
    dispatch(setCurrentListDocument(doc));
  };
  const onDownload = doc => {
    getFileByName(doc.fileName)
      .then(({base64File, contentType}) => {
        downloadFile(doc.fileName, `data:${contentType};base64,${base64File}`);
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
  return (
    <Box display="flex" flexDirection="column" style={{gap: "1em"}}>
      <Filters />
      <TableContainer head={<TableHeader />}>
        {data.map(doc => (
          <ShowTableRow
            key={doc.id}
            onShow={() => onShow(doc)}
            otherActions={
              <IconButton onClick={() => onDownload(doc)}>
                <GetAppIcon />
              </IconButton>
            }
          >
            <StyledTableCell>{doc.vehicle}</StyledTableCell>
            <StyledTableCell>{doc.type}</StyledTableCell>
            <StyledTableCell>
              {parseDateToDateString(doc.expirationDate)}
            </StyledTableCell>
            <StyledTableCell>{doc.noticeDays}</StyledTableCell>
            <StyledTableCell>{doc.active ? "Si" : "No"}</StyledTableCell>
          </ShowTableRow>
        ))}
      </TableContainer>
    </Box>
  );
};

const mapStateToProps = state => {
  const {filters, data} = state.fleetManagement.documents.list;
  const filteredData = new FilterBuilder(data)
    .byFieldIncludes("vehicle", filters.vehicle)
    .byFieldIncludesMultiOptions("typeId", filters.types.map(t => t.id))
    .byFieldEquals("active", filters.active)
    .byFieldIncludes("expired", filters.expiration)
    .build();

  return {data: filteredData, filters};
};

export default connect(mapStateToProps)(ListTable);
