import React from "react";
import Form from "../AddHeadquarterDialog/Form";
import {useDispatch, useSelector} from "react-redux";
import {dialogsList} from "../../constants";
import FormDialog from "../../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {closeDialog, setCurrentProviderHeadquarter} from "../../../../../../redux/reducers/Logistic/providers";

const EditHeadquarterDialog = () => {
  const id = "edit-headquarter-form";
    const {dialogs, currentHeadquarter} = useSelector(({logistic}) => logistic.providers);
    const isOpen = dialogs.some(dialog => dialog === dialogsList.editHeadquarterDialog)
    const dispatch = useDispatch();
    const onClose = () => {
        dispatch(closeDialog(dialogsList.editHeadquarterDialog));
        dispatch(setCurrentProviderHeadquarter(null));
    }
    const onSubmit = () => {
        onClose();
    }
  return (
    <FormDialog
      id={id}
      isOpen={isOpen}
      title={
        <IntlMessages id="edit" />
      }
      onClose={onClose}
    >
      {currentHeadquarter && (
        <Form id={id} initData={currentHeadquarter} onSubmit={onSubmit} />
      )}
    </FormDialog>
  );
};

export default EditHeadquarterDialog;
