import React from "react";
import PageContainer from "../../../../../components/FleetManagement/DailyOperation/MaintenancePlans/PageContainer";
import Table from "../../../../../components/FleetManagement/DailyOperation/MaintenancePlans/Table";
import {useDispatch, useSelector} from "react-redux";
import {
  closeMaintenancePlansDialog,
  editMaintenancePlanState,
  openMaintenancePlansDialog,
  setCurrentMaintenancePlan
} from "../../../../../redux/actions/FleetManagement/MaintenancePlans";
import {dialogs} from "../../../../../components/FleetManagement/DailyOperation/MaintenancePlans/constants";
import FloatingButtonWithAdd from "../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import Confirmation from "../../../../../components/Common/Dialogs/Confirmation";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import AddDialog from "../../../../../components/FleetManagement/DailyOperation/MaintenancePlans/TransactionDialogs/AddDialog";
import EditDialog from "../../../../../components/FleetManagement/DailyOperation/MaintenancePlans/TransactionDialogs/EditDialog";

const MaintenancePlans = props => {
  const dispatch = useDispatch();
  const {current, dialog: {name, open}} = useSelector(
    ({fleetManagement}) => fleetManagement.maintenancePlans
  );

  const onAdd = () => {
    dispatch(openMaintenancePlansDialog(dialogs.ADD_DIALOG));
  };
  const onReject = () => {
    dispatch(closeMaintenancePlansDialog());
    dispatch(setCurrentMaintenancePlan(null));
  };
  const onDelete = () => {
    dispatch(closeMaintenancePlansDialog());
    dispatch(editMaintenancePlanState(current, false));
  };
  const onRestore = () => {
    dispatch(closeMaintenancePlansDialog());
    dispatch(editMaintenancePlanState(current, true));
  };
  return (
    <PageContainer>
      <Table />
      <FloatingButtonWithAdd onAddClicked={onAdd} />
      <Confirmation
        open={open && dialogs.DELETE_DIALOG === name}
        text={
          <IntlMessages id={"fleet.management.maintenance.plan.delete.text"} />
        }
        onReject={onReject}
        onAccept={onDelete}
      />
      <Confirmation
        open={open && dialogs.RESTORE_DIALOG === name}
        text={
          <IntlMessages id={"fleet.management.maintenance.plan.restore.text"} />
        }
        onReject={onReject}
        onAccept={onRestore}
      />
      <AddDialog />
      <EditDialog />
    </PageContainer>
  );
};

MaintenancePlans.propTypes = {};

export default MaintenancePlans;
