import React from "react";
import PageContainer from "../../../../components/RRHH/DocumentsTypes/PageContainer";
import Table from "../../../../components/RRHH/DocumentsTypes/Table";
import FloatingButtonWithAdd from "../../../../components/Common/Buttons/FloatingButtonWithAdd";
import {dialogs} from "../../../../components/RRHH/DocumentsTypes/constants";
import {useDispatch, useSelector} from "react-redux";
import Confirmation from "../../../../components/Common/Dialogs/Confirmation";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {
  closeEmployeeDocumentsTypesDialog,
  editEmployeeDocumentsTypeState,
  openEmployeeDocumentsTypesDialog,
  resetCurrentEmployeeDocumentsType,
  setCurrentEmployeeDocumentsType
} from "../../../../redux/actions/RRHH/DocumentsTypes";
import AddDialog from "../../../../components/RRHH/DocumentsTypes/TransactionsDialogs/AddDialog";
import EditDialog from "../../../../components/RRHH/DocumentsTypes/TransactionsDialogs/EditDialog";

const EmployeesDocumentsTypes = props => {
  const {current, dialog: {name}} = useSelector(
    ({rrhh}) => rrhh.documentsTypes
  );
  const dispatch = useDispatch();
  const onAdd = () => {
    dispatch(openEmployeeDocumentsTypesDialog(dialogs.ADD_DIALOG));
  };
  const onReject = () => {
    dispatch(closeEmployeeDocumentsTypesDialog());
    dispatch(resetCurrentEmployeeDocumentsType());
  };
  const onDelete = () => {
    dispatch(closeEmployeeDocumentsTypesDialog());
    dispatch(editEmployeeDocumentsTypeState(current, false));
  };
  const onRestore = () => {
    dispatch(closeEmployeeDocumentsTypesDialog());
    dispatch(editEmployeeDocumentsTypeState(current, true));
  };
  return (
    <PageContainer>
      <Table />
      <AddDialog />
      <EditDialog />
      <FloatingButtonWithAdd onAddClicked={onAdd} />
      <Confirmation
        open={dialogs.DELETE_DIALOG === name}
        text={
          <IntlMessages id={"rrhh.employees.documents.types.restore.text"} />
        }
        onReject={onReject}
        onAccept={onDelete}
      />
      <Confirmation
        open={dialogs.RESTORE_DIALOG === name}
        text={
          <IntlMessages id={"rrhh.employees.documents.types.restore.text"} />
        }
        onReject={onReject}
        onAccept={onRestore}
      />
    </PageContainer>
  );
};

EmployeesDocumentsTypes.propTypes = {};

export default EmployeesDocumentsTypes;
