import {v4 as uuidV4} from 'uuid';
class CarbonFootprint {
    constructor(id, saleOrganization, client, deliveries,
                liters, tons, goingDeliveryKms, goingTotalKms,
                returnDeliveryKms, returnTotalKms,
                goingGOConsumption, returnGOConsumption,
                co2Kg, tnKms, emissionIntensity) {
        this.id = id;
        this.saleOrganization = saleOrganization;
        this.client = client;
        this.deliveries = deliveries;
        this.liters = liters;
        this.tons = tons;
        this.goingDeliveryKms = goingDeliveryKms;
        this.goingTotalKms = goingTotalKms;
        this.returnDeliveryKms = returnDeliveryKms;
        this.returnTotalKms = returnTotalKms;
        this.goingGOConsumption =goingGOConsumption;
        this.returnGOConsumption = returnGOConsumption;
        this.co2Kg = co2Kg;
        this.tnKms = tnKms;
        this.emissionIntensity = emissionIntensity;
    }

    static map = footprint => {
        return new CarbonFootprint(
            uuidV4(),
            footprint.Island,
            footprint.Client,
            footprint.Trips,
            footprint.Litres,
            footprint.Weights,
            footprint.OneWayKms,
            footprint.OneWayTotalKms,
            footprint.ReturnWayKms,
            footprint.ReturnWayTotalKms,
            footprint.OneWayGO,
            footprint.ReturnWayGO,
            footprint.CO2,
            footprint.TnKm,
            footprint.Intensity,
        )
    }
}

export default CarbonFootprint;