import React from "react";
import {Box} from "@material-ui/core";
import FloatingButtonWithAdd from "../../../Common/Buttons/FloatingButtonWithAdd";
import DocumentsDialog from "./DocumentsDialog";
import AddDocumentDialog from "./AddDocumentDialog";
import ListTable from "./ListTable";

const ListMode = () => {
  const [ openAdd, setOpenAdd ] = React.useState(false);
  return (
    <Box>
      <ListTable />
      <FloatingButtonWithAdd onAddClicked={() => setOpenAdd(true)} />
      <DocumentsDialog />
      <AddDocumentDialog open={openAdd} setOpen={setOpenAdd} />
    </Box>
  );
};

export default ListMode;
