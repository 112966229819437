import React from 'react';
import PropTypes from 'prop-types';
import FormDialog from "../../../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import Form from "./Form";

const TypeFormDialog = ({open, onClose, initialData, onSubmit}) => {
    const id = 'add-type-to table';
    return (
        <FormDialog
            id={id}
            title={initialData?.description}
            submitText={<IntlMessages id={"label.form.save"}/>}
            cancelText={<IntlMessages id={"label.form.cancel"}/>}
            isOpen={open}
            onClose={onClose}
        >
            {(open && initialData) &&
                <Form
                    initialData={initialData}
                    id={id}
                    onSubmit={onSubmit}
                />
            }
        </FormDialog>
    );
};

TypeFormDialog.propTypes = {
    type: PropTypes.object,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    initialData: PropTypes.object,
    onSubmit: PropTypes.func.isRequired
};

export default TypeFormDialog;