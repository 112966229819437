import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import PageContainerComponent from "../../../Common/Layouts/PageContainer";
import PropTypes from "prop-types";
import React from "react";

const breadcrumbs = [
    {
        label: <IntlMessages id={"back.office.licenses"} />,
        link: "/back-office/licenses/management/index-files"
    },
    {
        label: <IntlMessages id={"back.office.licenses.management.index.file"} />,
        isActive: true
    }
];

const PageContainer = ({children}) => (
    <PageContainerComponent
        heading={<IntlMessages id="back.office.licenses.management.index.file" />}
        description={
            <IntlMessages id="back.office.licenses.management.index.file.description" />
        }
        breadcrumbs={breadcrumbs}
    >
        {children}
    </PageContainerComponent>
);

PageContainer.propTypes = {
    children: PropTypes.node
};

export default PageContainer;