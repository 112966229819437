import React from "react";
import Form from "./Form";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {useDispatch, useSelector} from "react-redux";
import {closeDialog} from "../../../../../redux/reducers/Logistic/providers";
import {dialogsList} from "../constants";
import {addProvider} from "../../../../../redux/thunks/Logistic/providers";

const AddDialog = () => {
  const id = "add-provider-dialog";
  const dispatch = useDispatch();
  const {dialogs, filters} = useSelector(({logistic}) => logistic.providers);
  const isOpen = dialogs.some(dialog => dialog === dialogsList.addDialog);
  const onSubmit = (data) => {
    dispatch(addProvider({
      request: data,
      filters,
    }))
    onClose();
  };
  const onClose = () => {
    dispatch(closeDialog(dialogsList.addDialog))
  };
  return (
    <FormDialog
      id={id}
      isOpen={isOpen}
      title={<IntlMessages id="add" />}
      onClose={onClose}
    >
      <Form id={id} onSubmit={onSubmit} />
    </FormDialog>
  );
};

export default AddDialog;
