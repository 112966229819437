import React from "react";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";

export const validations = {
    observations: [{ rule: v => v.trim() !== '', message: <IntlMessages id="form.field.error" /> }],
    files: [
        {
            rule: v => v.length === 1,
            message: <IntlMessages id="guardian.add.expense.form.files.error.length" />,
        },
    ],
}