import React from 'react';
import {useIntl} from "react-intl";
import {charts} from "./constants";
import Box from "@material-ui/core/Box";
import {Typography} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import ViewSelect from "../SummaryMode/ViewSelect";
import ContainedButton from "../../../Common/Buttons/ContainedButton";
import {
    fetchAllSummaryEmployeeDocumentsView,
    fetchEmployeesDocumentsDashboard
} from "../../../../redux/actions/RRHH/Documentation";


const DashboardMode = () => {
    const {data: graphsData} = useSelector(({rrhh}) => rrhh.documentation.dashboard);
    const dispatch = useDispatch();
    const [chart, setChart] = React.useState(true);
    const [view, setView] = React.useState('');
    const intl = useIntl();
    const Chart = chart ? charts.barChart.chart : charts.pieChart.chart;
    const handleChartChange = () => {
        setChart(!chart);
    };
    React.useEffect(() => {
        dispatch(fetchEmployeesDocumentsDashboard({
            view
        }));
    }, [view]);
    React.useEffect(() => {
        dispatch(fetchAllSummaryEmployeeDocumentsView());
    }, []);
    return (
        <>
            <ViewSelect
                value={view}
                onChange={value => setView(value)}
            />
            <ContainedButton
                text={intl.messages["change.chart"]}
                onClick={handleChartChange}
                color="primary"
                style={{margin: "1rem 0"}}
            />
            <Typography variant='h1' style={{margin: "1rem 0"}}>
                {chart ? intl.messages["bar.chart"] : intl.messages["pie.chart"]}
            </Typography>
            {
                graphsData?.map(
                    (graph, index) => (
                    <Box
                        my={2}
                    >
                        <Typography variant='h1' style={{margin: "1rem 0"}}>
                            {graph.title}
                        </Typography>
                        <Box
                            width="100%"
                            height="50vh"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            paddingY={4}
                        >
                            <Chart data={graph.data} />
                        </Box>
                    </Box>
                ))
            }
        </>
    );
};

export default DashboardMode;