import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Box, IconButton} from "@material-ui/core";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Confirmation from "../../../../../Common/Dialogs/Confirmation";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {dialogs} from "../../constants";
import HeadersDialog from "./HeadersDialog";
import AddViewDialog from "./AddViewDialog";
import {
  closeVehicleMaintenancesDialog,
  openVehicleMaintenancesDialog
} from "../../../../../../redux/actions/FleetManagement/VehicleMaintenances";

const ButtonsArea = ({view}) => {
  const {selectedInfo, dialogs: {name, open}} = useSelector(
    ({fleetManagement}) => fleetManagement.vehicleMaintenances
  );
  const dispatch = useDispatch();
  const onDelete = () => {
    console.log(view);
    closeDialog();
  };
  const openDialog = dialog => {
    dispatch(openVehicleMaintenancesDialog(dialog));
  };
  const closeDialog = () => {
    dispatch(closeVehicleMaintenancesDialog());
  };

  return (
    <React.Fragment>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        style={{gap: "1em"}}
      >
        <IconButton onClick={() => openDialog(dialogs.ADD_VIEW_DIALOG)}>
          <AddCircleOutlineIcon />
        </IconButton>
        {selectedInfo.editable && (
          <React.Fragment>
            <IconButton onClick={() => openDialog(dialogs.EDIT_VIEW_DIALOG)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => openDialog(dialogs.DELETE_VIEW_DIALOG)}>
              <DeleteIcon />
            </IconButton>
          </React.Fragment>
        )}
      </Box>
      <Confirmation
        text={
          <IntlMessages
            id={
              "fleet.management.vehicles.maintenance.headers.delete.dialog.text"
            }
          />
        }
        open={open && name === dialogs.DELETE_VIEW_DIALOG}
        onAccept={onDelete}
        onReject={closeDialog}
      />
      <HeadersDialog />
      <AddViewDialog />
    </React.Fragment>
  );
};

export default ButtonsArea;
