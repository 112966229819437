import React, {useEffect} from "react";
import FiltersContainer from "../../../../../Common/Forms/FiltersContainer";
import useForm from "../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {useDispatch} from "react-redux";
import {applyVehicleDocumentsTypesFilters} from "../../../../../../redux/actions/FleetManagement/DocumentsTypes";

const initialData = {
  description: "",
  securityControl: ""
};

const Filters = () => {
  const {formData, setFormData} = useForm(initialData);
  const dispatch = useDispatch();
  useEffect(
    () => {
      dispatch(applyVehicleDocumentsTypesFilters(formData));
    },
    [ formData ]
  );
  return (
    <FiltersContainer active marginBottom={5} onClose={() => {}}>
      <form>
        <GridContainer spacing={4}>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.description}
              id="description-filter"
              label={
                <IntlMessages id="fleet.management.vehicles.documents.types.table.description" />
              }
              onChange={value => setFormData("description", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.securityControl}
              id="security-code-filter"
              label={
                <IntlMessages id="fleet.management.vehicles.documents.types.table.security.control" />
              }
              onChange={value => setFormData("securityControl", value)}
            />
          </Grid>
        </GridContainer>
      </form>
    </FiltersContainer>
  );
};

Filters.propTypes = {};

export default Filters;
