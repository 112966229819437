import {mapToBoolean} from "../../../utils/mappers";
import {getExpiredByDateAndDays} from "../../../utils/dates";

class VehicleDocumentItem {
  constructor(
    id,
    vehicle,
    vehicleId,
    type,
    typeId,
    expirationDate,
    noticeDays,
    active,
    fileName
  ) {
    this.id = id;
    this.vehicle = vehicle;
    this.vehicleId = vehicleId;
    this.type = type;
    this.typeId = typeId;
    this.expirationDate = expirationDate;
    this.noticeDays = noticeDays;
    this.active = active;
    this.fileName = fileName;
    this.expired = getExpiredByDateAndDays(expirationDate, noticeDays);
  }

  static map = doc => {
    return new VehicleDocumentItem(
      doc.nu_id_documento,
      doc.vc_placa,
      doc.nu_id_vehiculo,
      doc.vc_desc_tipo_documento,
      doc.nu_id_tipo_documento,
      doc.dt_fec_caducidad,
      doc.nu_dias_preaviso,
      doc.bi_estado ? mapToBoolean(doc.bi_estado) : true,
      doc.vc_nombre_documento
    );
  };

}

export default VehicleDocumentItem;
