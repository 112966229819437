import React from "react";
import {useDispatch, useSelector} from "react-redux";
import Form from "../Form";
import {dialogs} from "../../constants";
import {
  addEmployeeDocumentsType,
  closeEmployeeDocumentsTypesDialog
} from "../../../../../redux/actions/RRHH/DocumentsTypes";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import FormDialog from "../../../../Common/Dialogs/FormDialog";

const AddDialog = () => {
  const id = "add-documents-types-form";
  const {dialog: {name}} = useSelector(({rrhh}) => rrhh.documentsTypes);
  const dispatch = useDispatch();
  const onAdd = data => {
    dispatch(addEmployeeDocumentsType(data));
    onClose();
  };
  const onClose = () => {
    dispatch(closeEmployeeDocumentsTypesDialog());
  };
  return (
    <FormDialog
      id={id}
      title={<IntlMessages id={"rrhh.employees.documents.types.add.title"} />}
      submitText={<IntlMessages id={"label.form.save"} />}
      cancelText={<IntlMessages id={"label.form.cancel"} />}
      isOpen={name === dialogs.ADD_DIALOG}
      onClose={onClose}
    >
      {name === dialogs.ADD_DIALOG && (
        <Form
          add
          initialData={{
            description: "",
            securityControl: false
          }}
          id={id}
          onSubmit={onAdd}
        />
      )}
    </FormDialog>
  );
};

AddDialog.propTypes = {};

export default AddDialog;
