import React from "react";

import FiltersContainer from "../../../Common/Forms/FiltersContainer";
import Form from "./Form";

const Filters = () => (
  <FiltersContainer
    active
    marginBottom={5}
  >
    <Form/>
  </FiltersContainer>
);
export default Filters;
