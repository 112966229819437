import React from "react";
import PropTypes from "prop-types";
import PageContainer from "../../../../../components/FleetManagement/DailyOperation/CorrectiveTypes/PageContainer";
import FloatingButtonWithAdd from "../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import Confirmation from "../../../../../components/Common/Dialogs/Confirmation";
import {dialogs} from "../../../../../components/FleetManagement/DailyOperation/CorrectiveTypes/constants";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {useDispatch, useSelector} from "react-redux";
import {
  closeCorrectiveTypesDialog,
  editCorrectiveTypeState,
  openCorrectiveTypesDialog,
  setCurrentCorrectiveType
} from "../../../../../redux/actions/FleetManagement/CorrectiveTypes";
import AddDialog from "../../../../../components/FleetManagement/DailyOperation/CorrectiveTypes/TransactionsDialogs/AddDialog";
import EditDialog from "../../../../../components/FleetManagement/DailyOperation/CorrectiveTypes/TransactionsDialogs/EditDialog";
import Table from "../../../../../components/FleetManagement/DailyOperation/CorrectiveTypes/Table";

const CorrectiveTypes = () => {
  const {current, dialog: {name}} = useSelector(
    ({fleetManagement}) => fleetManagement.correctiveTypes
  );
  const dispatch = useDispatch();

  const onAdd = () => {
    dispatch(openCorrectiveTypesDialog(dialogs.ADD_DIALOG));
  };
  const onReject = () => {
    dispatch(closeCorrectiveTypesDialog());
    dispatch(setCurrentCorrectiveType(null));
  };
  const onDelete = () => {
    dispatch(closeCorrectiveTypesDialog());
    dispatch(editCorrectiveTypeState(current, false));
  };
  const onRestore = () => {
    dispatch(closeCorrectiveTypesDialog());
    dispatch(editCorrectiveTypeState(current, true));
  };
  return (
    <PageContainer>
      <Table />
      <AddDialog />
      <EditDialog />
      <FloatingButtonWithAdd onAddClicked={onAdd} />
      <Confirmation
        open={dialogs.DELETE_DIALOG === name}
        text={
          <IntlMessages id={"fleet.management.corrective.type.delete.text"} />
        }
        onReject={onReject}
        onAccept={onDelete}
      />
      <Confirmation
        open={dialogs.RESTORE_DIALOG === name}
        text={
          <IntlMessages id={"fleet.management.corrective.type.restore.text"} />
        }
        onReject={onReject}
        onAccept={onRestore}
      />
    </PageContainer>
  );
};

CorrectiveTypes.propTypes = {};

export default CorrectiveTypes;
