import axiosInstance from "../../utils/axiosInstance";
import {getCompanyEntityId} from "../StorageService";
import Response from "../../domain/Response";
import DocumentsTypes from "../../domain/RRHH/DocumentsTypes";

export const getAllEmployeeDocumentsTypes = async () => {
  try {
    const {data} = await axiosInstance.post("/EmpleadoTipoDocumento/sel", {
      nu_id_entidad: getCompanyEntityId()
    });
    return data.map(d => DocumentsTypes.map(d));
  } catch (e) {
    return Promise.reject(e);
  }
};

export const createEmployeeDocumentsType = async type => {
  try {
    const {data} = await axiosInstance.post("/EmpleadoTipoDocumento/ins", {
      nu_id_entidad: getCompanyEntityId(),
      vc_desc_tipo_documento: type.description,
      bi_control_seguridad: type.securityControl
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const updateEmployeeDocumentsType = async type => {
  try {
    const {data} = await axiosInstance.post("/EmpleadoTipoDocumento/upd", {
      nu_id_entidad: getCompanyEntityId(),
      nu_id_tipo_documento: type.id,
      vc_desc_tipo_documento: type.description,
      bi_control_seguridad: type.securityControl
    });
    const response = Response.map(data);
    if (response.transactionState !== "0") {
      return response;
    }
    throw Error(response.transactionMessage);
  } catch (e) {
    return Promise.reject(e);
  }
};
