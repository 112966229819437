import React from 'react';
import Confirmation from "../../Common/Dialogs/Confirmation";
import {useDispatch, useSelector} from "react-redux";
import {dialogsList} from "./constants";
import {closeDialog} from "../../../redux/reducers/Logistic/Planner";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import {activateSelectedServices, removeSelectedServices} from "../../../redux/thunks/Logistic/planner";

const ConfirmationDialog = () => {
    const {dialogs, servicesList} = useSelector(({logistic}) => logistic.planner);
    const dispatch = useDispatch();
    const handleClose = (dialog) => {
        dispatch(closeDialog(dialog));
    }
    const handleActivate = () => {
        dispatch(activateSelectedServices(servicesList));
        handleClose(dialogsList.activateRows);
    }
    const handleDelete = () => {
        dispatch(removeSelectedServices(servicesList));
        handleClose(dialogsList.deleteRows);
    }
    return (
        <>
            <Confirmation
                open={dialogs.some(dialog => dialog === dialogsList.activateRows)}
                onReject={() => handleClose(dialogsList.activateRows)}
                onAccept={handleActivate}
                text={<IntlMessages id="logistic.distribution.planner.activate" />}
            />
            <Confirmation
                open={dialogs.some(dialog => dialog === dialogsList.deleteRows)}
                onReject={() => handleClose(dialogsList.deleteRows)}
                onAccept={handleDelete}
                text={<IntlMessages id="logistic.distribution.planner.delete" />}
            />
        </>
    );
};

export default ConfirmationDialog;