import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DataGrid} from "@mui/x-data-grid";
import {useIntl} from "react-intl";
import {parseDateToDateString} from "../../../../utils/dates";
import CmtSearch from "../../../../@coremat/CmtSearch";
import useSearch from "../../../../hooks/Common/useSearch";
import {IconButton, Typography} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {removeOrganizationCard} from "../../../../redux/thunks/FleetManagement/Tachograph/cards";
import {openDialog, setCurrentCard} from "../../../../redux/reducers/FleetManagement/tachographCards";
import {dialogsList} from "./constants";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";

const DriversCardsTable = () => {
    const {driversCards} = useSelector(({fleetManagement}) => fleetManagement.tachographCards);
    const intl = useIntl();
    const {search, filteredData, onSearch} = useSearch(Object.values(driversCards), ['cardNumber']);
    const dispatch = useDispatch();
    const onEdit = (card) => {
        dispatch(setCurrentCard(card));
        dispatch(openDialog(dialogsList.editCard));
    }
    const columns = [
        {
            field: 'id',
            hide: true
        },
        {
            headerName: intl.messages['card.number'],
            field: 'cardNumber',
            width: 250,
        },
        {
            headerName: intl.messages['driver'],
            field: 'name',
            width: 400,
        },
        {
            headerName: intl.messages['expiration.date'],
            field: 'expirationDate',
            width: 250,
            valueFormatter: ({value}) => parseDateToDateString(value),
        },
        {
            field: 'actions',
            headerName: intl.messages["options"],
            type: 'actions',
            disableExport: true,
            renderCell: ({row: card}) => {
                return (
                    <>
                        <IconButton onClick={() => onEdit(card)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => dispatch(removeOrganizationCard(card))}>
                            <DeleteIcon />
                        </IconButton>
                    </>
                )
            }
        }
    ];
    const onAdd = () => {
        dispatch(openDialog(dialogsList.addCard));
        dispatch(setCurrentCard({typeId: "1"}));
    };
    return (
        <>
            <Box
                display='flex'
                justifyContent='space-between'
                my='1rem'
            >
                <Typography variant='h3'>
                    {intl.messages['driver.cards']}
                </Typography>
                <IconButton
                    style={{
                        backgroundColor: '#3f51b5',
                        color: 'white'
                    }}
                    onClick={onAdd}
                >
                    <AddIcon />
                </IconButton>
            </Box>
            <CmtSearch
                placeholder={`${intl.messages['search']} ${intl.messages['by']}  ${intl.messages['driver']}/${intl.messages['card']}`}
                containerStyle={{
                    margin: '1rem 0',
                }}
                value={search}
                onChange={({target}) => onSearch(target.value)}
            />
            <DataGrid
                rows={filteredData}
                columns={columns}
                disableColumnMenu
                style={{
                    height: '70vh'
                }}
            />
        </>
    );
};

export default DriversCardsTable;