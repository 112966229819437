import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DataGrid} from "@mui/x-data-grid";
import {useIntl} from "react-intl";
import {IconButton} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {dialogsList} from "./constants";
import {openDialog, setCurrentService} from "../../../redux/reducers/Treasury/services";
import {fetchAllServices, updateServiceState} from "../../../redux/thunks/Treasury/services";
import {parseNumberToMoney} from "../../../utils/parsers";
import RestoreIcon from "@material-ui/icons/Restore";

const Table = () => {
    const dispatch = useDispatch();
    const {services} = useSelector(({treasury}) => treasury.services);
    const servicesList = Object.values(services);
    const intl = useIntl();
    React.useEffect(() => {
        dispatch(fetchAllServices());
    }, []);
    const handleEditService = (row) => {
        dispatch(openDialog(dialogsList.EDIT_SERVICE));
        dispatch(setCurrentService(row));
    }
    const handleDeleteService = (row) => {
        dispatch(updateServiceState({serviceId: row.id, state: !row.state}));
    }
    const columns = [
        {
            field: 'id',
            hide: true
        },
        {
            field: 'name',
            headerName: intl.messages['name'],
            width: 200,
        },
        {
            field: 'code',
            headerName: intl.messages['code'],
            width: 200,
        },
        {
            field: 'unitPrice',
            headerName: intl.messages['subtotal'],
            valueFormatter: ({value}) => parseNumberToMoney(value),
            width: 200,
        },
        {
            field: 'total',
            headerName: intl.messages['total'],
            valueFormatter: ({value}) => parseNumberToMoney(value),
            width: 200,
        },
        {
            field: 'actions',
            headerName: intl.messages["table.edit"],
            type: 'actions',
            renderCell: ({row}) => {
                const {state} = row;
                return (
                    <>
                        {state &&
                            <IconButton onClick={() => handleEditService(row)}>
                                <EditIcon/>
                            </IconButton>
                        }
                        <IconButton onClick={() => handleDeleteService(row)}>
                            {state? <DeleteIcon/> : <RestoreIcon />}
                        </IconButton>
                    </>
                )
            }
        }
    ]
    return (
        <DataGrid
            columns={columns}
            rows={servicesList}
            style={{
                height: '70vh'
            }}
            disableColumnMenu
            disableSelectionOnClick
        />
    );
};

export default Table;