import React from "react";
import TableContainer from "../../../../Common/Tables/TableContainer";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import {useStyles} from "../../../Vehicles/Documentation/SummaryMode/DocumentsTable/styles";
import useTableSort from "../../../../../hooks/Common/useTableSort";
import RemoveIcon from "@material-ui/icons/Remove";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import WarningIcon from "@material-ui/icons/Warning";
import CancelIcon from "@material-ui/icons/Cancel";
import FilterBuilder from "../../../../../utils/filters";
import {connect} from "react-redux";
import TableHeader from "./TableHeader";
import Filters from "./Filters";
import {Tooltip} from "@material-ui/core";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const Table = ({headers, data}) => {
  const classes = useStyles();
  const {tableData, onSort, order, orderBy} = useTableSort(Object.values(data));
  const renderSymbol = {
    1: {
      label: (
        <IntlMessages
          id={
            "fleet.management.vehicles.maintenance.filters.expiration.no.maintenance"
          }
        />
      ),
      icon: <RemoveIcon />
    },
    2: {
      label: (
        <IntlMessages
          id={
            "fleet.management.vehicles.maintenance.filters.expiration.correct"
          }
        />
      ),
      icon: <ThumbUpIcon />
    },
    3: {
      label: (
        <IntlMessages
          id={"fleet.management.vehicles.maintenance.filters.expiration.notice"}
        />
      ),
      icon: <WarningIcon />
    },
    4: {
      label: (
        <IntlMessages
          id={
            "fleet.management.vehicles.maintenance.filters.expiration.expired"
          }
        />
      ),
      icon: <CancelIcon />
    }
  };
  return (
    <React.Fragment>
      <Filters />
      <TableContainer
        head={<TableHeader onSort={onSort} order={order} orderBy={orderBy} />}
      >
        {Object.values(tableData).map((vehicle, index) => (
          <StyledTableRow key={vehicle.id}>
            <StyledTableCell
              component="th"
              scope="row"
              className={classes.stickyBodyCell}
            >
              {vehicle.vehicle}
            </StyledTableCell>
            {Object.values(headers).filter(h => h.active).map((h, index) => (
              <StyledTableCell key={vehicle.id + index}>
                <Tooltip title={renderSymbol[vehicle[h.field]].label} arrow>
                  {renderSymbol[vehicle[h.field]].icon}
                </Tooltip>
              </StyledTableCell>
            ))}
          </StyledTableRow>
        ))}
      </TableContainer>
    </React.Fragment>
  );
};

Table.propTypes = {};
const mapStateToProps = state => {
  const {headers, data, filters} = state.fleetManagement.vehicleMaintenances;
  const filteredData = new FilterBuilder(data)
    .byFieldIncludes("vehicle", filters.vehicle)
    .byFieldIncludes('plans', filters.expiration)
    .byFieldIncludesMultiOptions('tags', filters.tags.map(t => t.id))
    .build();

  return {data: filteredData, headers};
};

export default connect(mapStateToProps)(Table);
