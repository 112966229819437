import React from "react";
import {Box, IconButton} from "@material-ui/core";
import TableContainer from "../../../Common/Tables/TableContainer";
import {connect} from "react-redux";
import FilterBuilder from "../../../../utils/filters";
import TableHeader from "./TableHeader";
import StyledTableRow from "../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import BasePagination from "../../../Common/Tables/BasePagination";
import EditIcon from "@material-ui/icons/Edit";
import {fetchAllAavvServices} from "../../../../redux/thunks/Logistic/aavvServices";
import {setSelectedService} from "../../../../redux/reducers/Logistic/aavvServices";

const ServicesTable = ({dispatch, data, apiFilters, headers}) => {
  const [ rowsPerPage, setRowsPerPage ] = React.useState(10);
  const [ page, setPage ] = React.useState(0);
  const fetchData = () => {
    dispatch(fetchAllAavvServices(apiFilters));
  };
  React.useEffect(
    () => {
      fetchData();
      const interval = setInterval(() => {
        fetchData();
      }, 300000);
      return () => clearInterval(interval);
    },
    [ apiFilters ]
  );
  React.useEffect(() => {
    fetchData();
  }, []);
  const onEdit = service => {
    dispatch(setSelectedService(service));
  };

  return (
    <React.Fragment>
      <TableContainer head={<TableHeader />}>
        {data
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(service => (
            <StyledTableRow key={service.id}>
              <StyledTableCell>
                <IconButton onClick={() => onEdit(service)}>
                  <EditIcon />
                </IconButton>
              </StyledTableCell>
              {headers.filter(h => h.state).map(header => {
                  return  <StyledTableCell
                      align="center"
                      key={header.id + "-" + service.id}
                  >
                      {service[header.content]}
                  </StyledTableCell>;
              })}
            </StyledTableRow>
          ))}
      </TableContainer>
      <Box display="flex" justifyContent="end" mt="4px">
        <BasePagination
          data={data}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          setPage={setPage}
          page={page}
        />
      </Box>
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  const {data, filters, apiFilters, tableHeaders} = state.logistic.aavvServices;
  const filteredData = new FilterBuilder(Object.values(data))
    .byFieldIncludesMultiOptions("tractPlaque", filters.hoppers)
    .byFieldIncludesMultiOptions("wagonPlaque", filters.trailers)
    .byFieldIncludesMultiOptions("staff", filters.clients)
    .byFieldIncludesMultiOptions("statesDescription", filters.states)
    .byFieldIncludes("ot", filters.serviceOrder)
    .build();
  return {data: filteredData, apiFilters, headers: Object.values(tableHeaders)};
};

export default connect(mapStateToProps)(ServicesTable);
