import {dateIsOverflowed, differenceBetweenDates} from "../../utils/dates";
import {mapToBoolean} from "../../utils/mappers";
import moment from "moment";

class EmployeeDocument {
  constructor(
    id,
    employee,
    employeeId,
    type,
    typeId,
    expirationDate,
    noticeDays,
    active,
    fileName
  ) {
    this.id = id;
    this.employee = employee;
    this.employeeId = employeeId;
    this.type = type;
    this.typeId = typeId;
    this.expirationDate = expirationDate;
    this.noticeDays = noticeDays;
    this.active = active;
    this.fileName = fileName;
    this.expired = this.getExpired(expirationDate, noticeDays);
  }

  static map = doc => {
    return new EmployeeDocument(
      doc.nu_id_documento,
      doc.vc_nombre_empleado,
      doc.nu_id_empleado,
      doc.vc_desc_tipo_documento,
      doc.nu_id_tipo_documento,
      doc.dt_fec_caducidad,
      doc.nu_dias_preaviso,
      doc.bi_estado ? mapToBoolean(doc.bi_estado) : true,
      doc.vc_nombre_documento
    );
  };

  getExpired(expirationDate, noticeDays) {
    if(dateIsOverflowed(moment(expirationDate).startOf('day')) > 0 ) {
      return 3;
    }
    const differenceDays = differenceBetweenDates(new Date().toISOString(), expirationDate, 'days');
    if( differenceDays >= 0 && differenceDays <= noticeDays) {
      return 2;
    }
    return 0;
  }
}

export default EmployeeDocument;
