import React from "react";
import {downloadFile, getFileByName} from "../../../../../services/FileService";
import {fetchError} from "../../../../../redux/actions";
import {Box, IconButton} from "@material-ui/core";
import TableContainer from "../../../../Common/Tables/TableContainer";
import ShowTableRow from "../../../../Common/Tables/CrudTable/ShowTableRow";
import GetAppIcon from "@material-ui/icons/GetApp";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import {parseDateToDateString} from "../../../../../utils/dates";
import FilterBuilder from "../../../../../utils/filters";
import {connect} from "react-redux";
import {
  fetchAllEmployeeDocuments,
  setCurrentListDocument
} from "../../../../../redux/actions/RRHH/Documentation";
import TableHeader from "./TableHeader";
import Filters from "./Filters";

const ListTable = ({filters, data, dispatch}) => {
  React.useEffect(() => {
    dispatch(fetchAllEmployeeDocuments(filters));
  }, []);
  const onShow = doc => {
    dispatch(setCurrentListDocument(doc));
  };
  const onDownload = doc => {
    getFileByName(doc.fileName)
      .then(({base64File, contentType}) => {
        downloadFile(doc.fileName, `data:${contentType};base64,${base64File}`);
      })
      .catch(error => {
        dispatch(fetchError(error.message));
      });
  };
  return (
    <Box display="flex" flexDirection="column" style={{gap: "1em"}}>
      <Filters />
      <TableContainer head={<TableHeader />}>
        {data.map(doc => (
          <ShowTableRow
            key={doc.id}
            onShow={() => onShow(doc)}
            otherActions={
              <IconButton onClick={() => onDownload(doc)}>
                <GetAppIcon />
              </IconButton>
            }
          >
            <StyledTableCell>{doc.employee}</StyledTableCell>
            <StyledTableCell>{doc.type}</StyledTableCell>
            <StyledTableCell>
              {parseDateToDateString(doc.expirationDate)}
            </StyledTableCell>
            <StyledTableCell>{doc.noticeDays}</StyledTableCell>
            <StyledTableCell>{doc.active ? "Si" : "No"}</StyledTableCell>
          </ShowTableRow>
        ))}
      </TableContainer>
    </Box>
  );
};

const mapStateToProps = state => {
  const {filters, data} = state.rrhh.documentation.list;
  const filteredData = new FilterBuilder(data)
    .byFieldIncludes("employee", filters.employee)
    .byFieldIncludesMultiOptions("typeId", filters.types.map(t => t.id))
    .byFieldEquals("active", filters.active)
    .byFieldIncludes("expired", filters.expiration)
    .build();

  return {data: filteredData, filters};
};

export default connect(mapStateToProps)(ListTable);
