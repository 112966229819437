import {useDispatch, useSelector} from "react-redux";
import useForm from "../../../../../../hooks/Common/useForm";
import React from "react";
import FiltersContainer from "../../../../../Common/Forms/FiltersContainer";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import ActiveSelect from "../../../../../Common/Forms/Select/ActiveSelect";
import ExpirationSelect from "../../../../../FleetManagement/Vehicles/Documentation/ListMode/ListTable/Filters/ExpirationSelect";
import {applyEmployeeDocumentsListFilters} from "../../../../../../redux/actions/RRHH/Documentation";
import TypeSelect from "./TypeSelect";

const initialData = {
  employee: "",
  types: [],
  active: true,
  expiration: ""
};

const Filters = () => {
  const {filters} = useSelector(({rrhh}) => rrhh.documentation.list);
  const dispatch = useDispatch();
  const {formData, setFormData, setValues} = useForm(initialData);
  React.useEffect(() => {
    setValues(filters);
  }, []);
  React.useEffect(
    () => {
      dispatch(applyEmployeeDocumentsListFilters(formData));
    },
    [ formData ]
  );
  return (
    <FiltersContainer active onClose={() => {}}>
      <GridContainer spacing={2}>
        <Grid item xs={12} md={4} lg={3}>
          <OutlinedInput
            label={
              <IntlMessages
                id={"rrhh.employees.documentation.list.mode.table.employee"}
              />
            }
            value={formData.employee}
            onChange={value => setFormData("employee", value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <TypeSelect
            value={formData.types}
            onChange={value => setFormData("types", value)}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <ActiveSelect
            value={formData.active}
            onChange={value => setFormData("active", value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <ExpirationSelect
            value={formData.expiration}
            onChange={value => setFormData("expiration", value)}
          />
        </Grid>
      </GridContainer>
    </FiltersContainer>
  );
};

export default Filters;
