import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import BaseDialog from "../../Common/BaseDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {closeGenreDialog} from "../../../../../../redux/reducers/ElevatorsManagement/genres";
import {dialogsList} from "../../Common/constants";
import {editGenre} from "../../../../../../redux/thunks/ElevatorManagement/genres";

const EditGenreDialog = () => {
    const {current, dialogs} = useSelector(({elevatorsManagement}) => elevatorsManagement.genres);
    const dispatch = useDispatch();
    const onSubmit = data => {
        dispatch(editGenre({current, request: data}));
        dispatch(closeGenreDialog(dialogsList.editDialog));
    }
    return(
        <BaseDialog
            id='edit-ken-form'
            title={<IntlMessages id="edit"/>}
            open={dialogs.some(dialog => dialog === dialogsList.editDialog)}
            setOpen={() => dispatch(closeGenreDialog(dialogsList.editDialog))}
            initData={{
                name: current?.name,
            }}
            onSubmit={onSubmit}
        />
    );
};

export default EditGenreDialog;