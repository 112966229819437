import React from "react";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";

export default {
  name: [
    {
      rule: v => v.trim() !== "",
      message: (
          <IntlMessages id="form.field.error" />
      )
    }
  ],
  streetName: [
    {
      rule: v => v.trim() !== "",
      message: (
          <IntlMessages id="form.field.error" />
      )
    }
  ],
  number: [
    {
      rule: v => v.trim() !== "",
      message: (
          <IntlMessages id="form.field.error" />
      )
    }
  ],
  zipCode: [
    {
      rule: v => v.trim() !== "",
      message: (
          <IntlMessages id="form.field.error" />
      )
    }
  ],
  district: [
    {
      rule: v => v.trim() !== "",
      message: (
          <IntlMessages id="form.field.error" />
      )
    }
  ],
  province: [
    {
      rule: v => v.trim() !== "",
      message: (
          <IntlMessages id="form.field.error" />
      )
    }
  ],
  country: [
    {
      rule: v => v.trim() !== "",
      message: (
          <IntlMessages id="form.field.error" />
      )
    }
  ],
  latitude: [
    {
      rule: v => v.toString().trim() !== "",
      message: (
          <IntlMessages id="form.field.error" />
      )
    }
  ],
  longitude: [
    {
      rule: v => v.toString().trim() !== "",
      message: (
          <IntlMessages id="form.field.error" />
      )
    }
  ]
};
