import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {editReturnType} from "../../../../../redux/actions/Logistic/ReturnTypes";
import FormDialog from "../../../../Common/Dialogs/FormDialog";
import Form from "../Form";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const EditReturnType = ({editOpen, setEditOpen}) => {
  const id = "edit-return-type-form";
  const {current} = useSelector(({logistic}) => logistic.returnTypes);
  const dispatch = useDispatch();
  const onSubmit = data => {
    dispatch(editReturnType({...current, ...data}));
    setEditOpen(false);
  };
  return (
    <FormDialog
      id={id}
      title={
        <IntlMessages id={"logistic.distribution.return.types.edit.title"} />
      }
      isOpen={editOpen}
      onClose={() => setEditOpen(false)}
    >
      {editOpen && (
        <Form
          id={id}
          initData={{
            description: current.description || "",
            code: current.code || ""
          }}
          onSubmit={onSubmit}
        />
      )}
    </FormDialog>
  );
};

export default EditReturnType;
