import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import BaseDialog from "../../Common/BaseDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {closeTypesDialog} from "../../../../../../redux/reducers/ElevatorsManagement/types";
import {dialogsList} from "../../Common/constants";
import {editType} from "../../../../../../redux/thunks/ElevatorManagement/types";

const EditDialog = () => {
    const {current, dialogs} = useSelector(({elevatorsManagement}) => elevatorsManagement.types);
    const dispatch = useDispatch();
    const onSubmit = data => {
        dispatch(editType({current, request: data}));
        dispatch(closeTypesDialog(dialogsList.editDialog));
    }
    return(
        <BaseDialog
            id='edit-ken-form'
            title={<IntlMessages id="edit"/>}
            open={dialogs.some(dialog => dialog === dialogsList.editDialog)}
            setOpen={() => dispatch(closeTypesDialog(dialogsList.editDialog))}
            initData={{
                name: current?.name,
            }}
            onSubmit={onSubmit}
        />
    );
};

export default EditDialog;