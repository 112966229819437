import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
  stickyHeaderCell: {
    position: "sticky",
    left: 0,
    background: "#242e68",
    zIndex: theme.zIndex.appBar + 2,
    width: "250px"
  },
  stickyBodyCell: {
    position: "sticky",
    left: 0,
    background: "white",
    zIndex: theme.zIndex.appBar + 1,
    width: "250px"
  }
}));
