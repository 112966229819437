import {createAsyncThunk} from "@reduxjs/toolkit";
import {fetchError, fetchStart, fetchSuccess} from "../../../actions";
import {getFileData, uploadFile} from "../../../../services/FleetManagement/Tachograph/FilesReaderService";

const basePrefix = 'filesReader';

export const getDataFromFile =  createAsyncThunk(
    `${basePrefix}/getDataFromFile`,
    async (file, {dispatch, rejectWithValue}) => {
        try {
            dispatch(fetchStart());
            const fileName = await uploadFile(file);
            const fileData = await getFileData(fileName);
            dispatch(fetchSuccess());
            return fileData;
        }catch (e) {
            dispatch(fetchError('Error por fallo de lectura o corrupción del fichero'));
            return rejectWithValue(e.message);
        }
    }
);