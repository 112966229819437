import React from "react";
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";

const TableHeader = () => {
  return (
    <StyledTableRow>
      <StyledTableCell>
        <IntlMessages
          id={"fleet.management.vehicles.documentation.list.mode.table.vehicle"}
        />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages
          id={"fleet.management.vehicles.documentation.list.mode.table.type"}
        />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages
          id={
            "fleet.management.vehicles.documentation.list.mode.table.expiration.date"
          }
        />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages
          id={
            "fleet.management.vehicles.documentation.list.mode.table.notice.days"
          }
        />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages
          id={"fleet.management.vehicles.documentation.list.mode.table.active"}
        />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages
          id={
            "fleet.management.vehicles.documentation.list.mode.table.show.download"
          }
        />
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default TableHeader;
