import React from 'react';
import useForm from "../../../hooks/Common/useForm";
import GridContainer from "../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";

const INIT_DATA = {
    name: '',
    percentage: '',
};

const Form = ({id, initData = {}, onSubmit}) => {
    const {formData, setFormData, setValues, handleSubmit} = useForm(INIT_DATA);
    React.useEffect(() => {
        setValues(initData);
    }, []);
    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        name="name"
                        label={<IntlMessages id="name"/>}
                        value={formData.name}
                        onChange={(value) => setFormData('name', value)}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        name="percentage"
                        label={<IntlMessages id="percentage"/>}
                        value={formData.percentage}
                        onChange={(value) => setFormData('percentage', value)}
                        fullWidth
                        required
                        type='number'
                        min={0}
                        max={100}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form;