import React from 'react';
import Select from "../../../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";
import {useFetch} from "../../../../../../../../../hooks/Common/useFetch";
import {fetchAllVehicleSelect} from "../../../../../../../../../services/VehicleServiceSelect";

const VehiclesSelect = ({value, onChange, disabled, errors}) => {
    const {data: vehicles} = useFetch(fetchAllVehicleSelect, {}, []);
    return (
        <Select
            id='vehicle-select'
            label={<IntlMessages id={"fleet.management.vehicles.documentation.list.mode.detail.form.vehicle"} />}
            value={value}
            onChange={e => onChange(e.target.value)}
            fullWidth
            disabled={disabled}
            error={errors?.vehicle?.length>0}
            errorMessage={errors?.vehicle[0]}
        >
            <MenuItem value={''} />
            {vehicles.map(vehicle => (
                <MenuItem value={vehicle.id} key={vehicle.id}>
                    {vehicle.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default VehiclesSelect;