import useForm from "../../../../../../hooks/Common/useForm";
import React from "react";
import {Box, Grid} from "@material-ui/core";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import MaintenancePlanSelect from "../../../../Common/Selects/MaintenancePlansSelect";

const Form = ({initialData, id, onSubmit}) => {
  const {formData, setFormData, handleSubmit, errors} = useForm(initialData);
  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        gap: 5
      }}
    >
      <form id={id} onSubmit={handleSubmit(onSubmit)}>
        <GridContainer spacing={1}>
          <Grid item xs={12} md={6}>
            <MaintenancePlanSelect
              id={id + "plans"}
              value={formData.plans}
              setValue={value => setFormData("plans", value)}
              fullWidth
            />
          </Grid>
        </GridContainer>
      </form>
    </Box>
  );
};

Form.propTypes = {};

export default Form;
