import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DataGrid} from "@mui/x-data-grid";
import {useIntl} from "react-intl";
import {Box, IconButton, Typography} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {dialogsList} from "./constants";
import {openDialog} from "../../../redux/reducers/Treasury/budgets";
import {
    billSelectedBudgets,
    fetchAllBudgets,
    getAndSetBudgetServices,
    removeBudget
} from "../../../redux/thunks/Treasury/budgets";
import {parseNumberToMoney} from "../../../utils/parsers";
import ContainedButton from "../../Common/Buttons/ContainedButton";
import {parseDateToDateString} from "../../../utils/dates";
import ShowIcon from "@material-ui/icons/Visibility";
import FilterToolbar from "../../Common/Tables/DataGrid/FilterToolbar";

const Table = () => {
    const dispatch = useDispatch();
    const {budgets} = useSelector(({treasury}) => treasury.budgets);
    const budgetsList = Object.values(budgets);
    const [selectedBudgets, setSelectedBudgets] = React.useState([]);
    const intl = useIntl();
    React.useEffect(() => {
        dispatch(fetchAllBudgets());
    }, []);
    const handleEditBudget = (row) => {
        dispatch(openDialog(dialogsList.EDIT_BUDGET));
        dispatch(getAndSetBudgetServices({budget: row}));
    }
    const handleDeleteBudget = (row) => {
        dispatch(removeBudget({budgetId: row.id}))
            .unwrap()
            .finally(() => {
                setSelectedBudgets([]);
            });
    }
    const handleBillBudgets =  () => {
        dispatch(billSelectedBudgets({selectedBudgets}))
            .unwrap()
            .finally(() => {
                setSelectedBudgets([]);
            });
    }
    const columns = [
        {
            field: 'id',
            hide: true,
            filterable: false,
        },
        {
            field: 'creationDate',
            headerName: intl.messages['date'],
            valueFormatter: ({value}) => parseDateToDateString(value),
            width: 200,
            filterable: false,
        },
        {
            field: 'billNumber',
            headerName: intl.messages['num'],
            width: 200,
            filterable: false,
        },
        {
            field: 'expirationDate',
            headerName: intl.messages['expiration'],
            valueFormatter: ({value}) => parseDateToDateString(value),
            width: 200,
            filterable: false,
        },
        {
            field: 'clientName',
            headerName: intl.messages['client'],
            width: 200,
        },
        {
            field: 'subtotal',
            headerName: intl.messages['subtotal'],
            valueFormatter: ({value}) => `${parseNumberToMoney(value)}`,
            width: 200,
            filterable: false,
        },
        {
            field: 'total',
            headerName: intl.messages['total'],
            valueFormatter: ({value}) => `${parseNumberToMoney(value)}`,
            width: 200,
            filterable: false,
        },
        {
            field: 'state',
            headerName: intl.messages['state'],
            width: 200,
            renderCell: ({value}) =>
                (<Typography
                    style={{
                        color: '#fff',
                        backgroundColor: !value?.toLowerCase()?.includes("pendiente") ? '#4caf50' : '#6a6a6a',
                        padding: '4px 8px',
                    }}
                >
                    {value}
                </Typography>)

        },
        {
            field: 'actions',
            headerName: intl.messages["table.edit"],
            type: 'actions',
            renderCell: ({row}) => {
                return (
                    row?.state?.toLowerCase()?.includes("pendiente")
                        ? <>
                            <IconButton onClick={() => handleEditBudget(row)}>
                                <EditIcon/>
                            </IconButton>
                            <IconButton onClick={() => handleDeleteBudget(row)}>
                                <DeleteIcon/>
                            </IconButton>
                        </>
                        :  <IconButton onClick={() => handleEditBudget(row)}>
                            <ShowIcon/>
                        </IconButton>
                )
            }
        }
    ]
    return (
        <>
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '1rem',
                    marginBottom: '1rem'
                }}
            >
                <ContainedButton
                    text={intl.messages['bill']}
                    color='primary'
                    onClick={handleBillBudgets}
                    disabled={selectedBudgets?.some(budgetId => budgets[budgetId]?.state?.toLowerCase()?.includes("facturado")|| +budgets[budgetId]?.total <= 0 || +budgets[budgetId]?.subtotal <= 0)}
                />
            </Box>
            <DataGrid
                columns={columns}
                rows={budgetsList}
                style={{
                    height: '70vh'
                }}
                disableColumnMenu
                disableSelectionOnClick
                checkboxSelection
                selectionModel={selectedBudgets}
                onSelectionModelChange={(newSelection) => {
                    setSelectedBudgets(newSelection);
                }}
                components={{Toolbar: FilterToolbar}}
            />
        </>
    );
};

export default Table;