import React from "react";
import PageContainer from "../../../../../components/FleetManagement/DailyOperation/WorkshopRequest/PageContainer";
import Table from "../../../../../components/FleetManagement/DailyOperation/WorkshopRequest/Table";
import AddDialog from "../../../../../components/FleetManagement/DailyOperation/WorkshopRequest/TransactionsDialogs/AddDialog";
import EditDialog from "../../../../../components/FleetManagement/DailyOperation/WorkshopRequest/TransactionsDialogs/EditDialog";
import FloatingButtonWithAdd from "../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import {dialogs} from "../../../../../components/FleetManagement/DailyOperation/WorkshopRequest/constants";
import {useDispatch} from "react-redux";
import {openWorkshopRequestDialog} from "../../../../../redux/actions/FleetManagement/WorkshopRequests";

const WorkshopRequest = props => {
  const dispatch = useDispatch();
  const onAdd = () => {
    dispatch(openWorkshopRequestDialog(dialogs.ADD_DIALOG));
  };
  return (
    <PageContainer>
      <Table />
      <FloatingButtonWithAdd onAddClicked={onAdd} />
      <AddDialog />
      <EditDialog />
    </PageContainer>
  );
};

WorkshopRequest.propTypes = {};

export default WorkshopRequest;
