import React, {useEffect} from 'react';
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import ContainedButton from "../../../../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import useForm from "../../../../../../../hooks/Common/useForm";
import {useDispatch} from "react-redux";
import {getParsedData} from "../../../../../../../redux/thunks/BackOffice/parser.";
import DevicesSelect from "./DevicesSelect";
import PackageTypesSelect from "./PackageTypesSelect";
import DecodeTypeSelect from "./DecodeTypeSelect";
import {decodeTypes, devices} from "./contants";
import {resetFilters} from "../../../../../../../redux/reducers/BackOffice/parser";

const initData = {
    device: '',
    packageType: '',
    decodeType: '',
    formatType: '',
    package: ''
}
const Form = () => {
    const id = 'parser-filters';
    const {formData, setFormData, handleSubmit, resetForm} = useForm(initData);
    const dispatch = useDispatch();
    const onSubmit = (data) => {
        dispatch(getParsedData(data));
    }
    const onClearFilters = () => {
        resetForm();
        dispatch(resetFilters());
    }
    useEffect(() => {
        setFormData('formatType', '');
    }, [formData.decodeType]);
    useEffect(() => {
        setFormData('decodeType', '');
    }, [formData.packageType]);
    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <DevicesSelect
                    value={formData.device}
                    setValue={value => setFormData('device', value)}
                />
                <PackageTypesSelect
                    value={formData.packageType}
                    setValue={value => setFormData('packageType', value)}
                    device={formData.device}
                />
                <DecodeTypeSelect
                    value={formData.decodeType}
                    setValue={value => setFormData('decodeType', value)}
                    disabled={formData.packageType === devices.teltonika.packageTypes.codec12.id}
                />
                <Grid item xs={12} md={4}>
                    <OutlinedInput
                        label={<IntlMessages id='format.type' />}
                        value={formData.formatType}
                        onChange={value => setFormData('formatType', value)}
                        fullWidth
                        disabled={formData.decodeType !== decodeTypes.formatted.id || formData.packageType === devices.teltonika.packageTypes.codec12.id}
                    />
                </Grid>
                <Grid item xs={12} >
                    <OutlinedInput
                        label={<IntlMessages id='package' />}
                        value={formData.package}
                        onChange={value => setFormData('package', value)}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <ContainedButton
                        type='submit'
                        form={id}
                        color='primary'
                        text={<IntlMessages id='filters.button.apply' />}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <ContainedButton
                        onClick={onClearFilters}
                        text={<IntlMessages id='filters.button.clear' />}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form;