import React from 'react';
import PageContainer from "../../../../../components/FleetManagement/Tachograph/Cards/PageContainer";
import OrganizationCardList from "../../../../../components/FleetManagement/Tachograph/Cards/OrganizationCardList";
import DriversCardsTable from "../../../../../components/FleetManagement/Tachograph/Cards/DriversCardsTable";
import EditCardDialog from "../../../../../components/FleetManagement/Tachograph/Cards/EditCardDialog";
import AddCardDialog from "../../../../../components/FleetManagement/Tachograph/Cards/AddCardDialog";

const Cards = () => {
    return (
        <PageContainer>
            <OrganizationCardList />
            <DriversCardsTable />
            <AddCardDialog />
            <EditCardDialog />
        </PageContainer>
    );
};

export default Cards;