import React from "react";
import {useDispatch, useSelector} from "react-redux";
import FormDialog from "../../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {dialogs} from "../../constants";
import {
  addMaintenancePlan,
  closeMaintenancePlansDialog
} from "../../../../../../redux/actions/FleetManagement/MaintenancePlans";
import Form from "../Form";

const AddDialog = () => {
  const id = "add-maintenance-plan-form";
  const {dialog: {name, open}} = useSelector(
    ({fleetManagement}) => fleetManagement.maintenancePlans
  );
  const dispatch = useDispatch();
  const onAdd = data => {
    dispatch(addMaintenancePlan(data));
    onClose();
  };
  const onClose = () => {
    dispatch(closeMaintenancePlansDialog());
  };
  return (
    <FormDialog
      id={id}
      title={
        <IntlMessages id={"fleet.management.maintenance.plan.add.title"} />
      }
      submitText={<IntlMessages id={"label.form.save"} />}
      cancelText={<IntlMessages id={"label.form.cancel"} />}
      isOpen={open && dialogs.ADD_DIALOG === name}
      onClose={onClose}
    >
      {open &&
      dialogs.ADD_DIALOG === name && (
        <Form
          initialData={{
            name: "",
            kilometers: "",
            engineHours: "",
            months: "",
            noticeKilometers: "",
            noticeHours: "",
            noticeDays: "",
            types: [],
            countManagement: ""
          }}
          id={id}
          onSubmit={onAdd}
        />
      )}
    </FormDialog>
  );
};

AddDialog.propTypes = {};

export default AddDialog;
