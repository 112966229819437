import React from "react";
import useForm from "../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import validations from "./validations";

const Form = ({initialData, id, onSubmit}) => {
    const {formData, setFormData, handleSubmit, errors} = useForm(initialData, validations);

    return(
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12}>
                    <OutlinedInput
                        id={id+'-description'}
                        label={<IntlMessages id={"fleet.management.corrective.type.form.description"}/>}
                        fullWidth
                        value={formData.description}
                        onChange={value => setFormData('description', value)}
                        error={errors?.description[0]}
                        hasError={errors?.description.length > 0}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form;