import React from 'react';
import PageContainer from "../../../../../components/Logistic/AavvServices/PageContainer";
import Filters from "../../../../../components/Logistic/AavvServices/Filters";
import ButtonsArea from "../../../../../components/Logistic/AavvServices/ButtonsArea";
import ServicesTable from "../../../../../components/Logistic/AavvServices/ServicesTable";
import EditServiceDialog from "../../../../../components/Logistic/AavvServices/EditServiceDialog";
import EditHeadersDialog from "../../../../../components/Logistic/AavvServices/EditHeadersDialog";
import {useDispatch} from "react-redux";
import {resetState} from "../../../../../redux/reducers/Logistic/aavvServices";

const AavvServices = () => {
    const dispatch = useDispatch();
    React.useEffect(() => {
        return () => {
            dispatch(resetState());
        }
    }, []);
    return (
        <PageContainer>
            <Filters />
            <ButtonsArea />
            <ServicesTable />
            <EditServiceDialog />
            <EditHeadersDialog />
        </PageContainer>
    );
};

export default AavvServices;