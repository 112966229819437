import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import FormShowDialog from "../../../Common/Dialogs/FormShowDialog";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import GridContainer from "../../../../@jumbo/components/GridContainer";
import {Box, Grid} from "@material-ui/core";
import {setCurrentEvent} from "../../../../redux/reducers/Localization/drivingControl";
import {parseDateToGlobalFormatWithSeconds} from "../../../../utils/dates";
import Map from "../../../Common/Maps/OpenStreetMap/Map";
import {Marker} from "react-leaflet";
import EventMediaSlider from "./EventMediaSlider";

const DetailsDialog = () => {
    const {currentEvent} = useSelector(({localization}) => localization.drivingControl);
    const dispatch = useDispatch();
    const handleClose = () => {
        dispatch(setCurrentEvent(null));
    };
    return (
        <FormShowDialog
            id='event-details-dialog'
            isOpen={!!currentEvent}
            title={<IntlMessages id='event.details'/>}
            onClose={handleClose}
        >
            <GridContainer spacing={2}>
                <Grid item xs={12} md={6}>
                    <Box display='flex' flexDirection='row' justifyContent='space-between' width='100%'>
                        <IntlMessages id='type'/>: <span>{currentEvent?.eventType}</span>
                    </Box>
                    <Box display='flex' flexDirection='row' justifyContent='space-between'>
                        <IntlMessages id='unit'/> <IntlMessages id='id'/>: <span>{currentEvent?.deviceID}</span>
                    </Box>
                    <Box display='flex' flexDirection='row' justifyContent='space-between'>
                        <IntlMessages
                            id='time'/>: <span>{parseDateToGlobalFormatWithSeconds(currentEvent?.eventDateTime)}</span>
                    </Box>
                    <Box display='flex' flexDirection='row' justifyContent='space-between'>
                        <IntlMessages id='location'/>: <span>{currentEvent?.lastKnownStreetAddress}</span>
                    </Box>
                    <Box display='flex' flexDirection='row' justifyContent='space-between'>
                        <IntlMessages id='location'/> <IntlMessages
                        id='time'/>: <span>{parseDateToGlobalFormatWithSeconds(currentEvent?.locationTime)}</span>
                    </Box>
                    <Box display='flex' flexDirection='row' justifyContent='space-between'>
                        <IntlMessages id='speed'/>: <span>{currentEvent?.speed}</span>
                    </Box>
                    <Box display='flex' flexDirection='row' justifyContent='space-between'>
                        <IntlMessages id='heading'/>: <span>{currentEvent?.headingAngle}</span>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                    {currentEvent &&
                        <Map
                            latitude={currentEvent?.lastKnownLocationLat}
                            longitude={currentEvent?.lastKnownLocationLong}
                            style={{height: '100%'}}
                        >
                            <Marker
                                position={[currentEvent?.lastKnownLocationLat, currentEvent?.lastKnownLocationLong]}>
                            </Marker>
                        </Map>
                    }
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{
                        height: '250px'
                    }}
                >
                    {currentEvent && <EventMediaSlider/>}
                </Grid>
            </GridContainer>
        </FormShowDialog>
    );
};

export default DetailsDialog;