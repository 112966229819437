import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import PageContainerComponent from "../../Common/Layouts/PageContainer";
import PropTypes from "prop-types";
import React from "react";

const breadcrumbs = [
    {
        label: <IntlMessages id={"sidebar.logistic.operative"} />,
        link: "/logistic/distribution/orders"
    },
    {
        label: <IntlMessages id={"logistic.aavv.services.title"} />,
        isActive: true
    }
];

const PageContainer = ({children}) => (
    <PageContainerComponent
        heading={
            <IntlMessages id="logistic.aavv.services.title" />
        }
        description={
            <IntlMessages id="logistic.aavv.services.description" />
        }
        breadcrumbs={breadcrumbs}
    >
        {children}
    </PageContainerComponent>
);

PageContainer.propTypes = {
    children: PropTypes.node
};

export default PageContainer;