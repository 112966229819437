import React from "react";
import {Grid} from "@material-ui/core";
import validations from "./validations";
import useForm from "../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import Checkbox from "../../../../Common/Forms/Checkbox";

const Form = ({initialData, id, onSubmit}) => {
    const {formData, setFormData, handleSubmit, errors} = useForm(initialData, validations);

    return(
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12}>
                    <OutlinedInput
                        id={id+'-description'}
                        label={<IntlMessages id="rrhh.employees.documents.types.form.description" /> }
                        value={formData.description}
                        onChange={value => setFormData('description', value)}
                        hasError={errors?.description?.length > 0}
                        error={errors?.description.join(', ')}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <Checkbox
                        id={id+'-security-control'}
                        label={<IntlMessages id="rrhh.employees.documents.types.form.security.control" /> }
                        checked={formData.securityControl}
                        onChange={value => setFormData('securityControl', !formData.securityControl)}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form;