import React from 'react';
import TableContainer from "../../../../../Common/Tables/TableContainer";
import StyledTableRow from "../../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../../Common/Tables/StyledTableCell";
import {IconButton} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {openDialog} from "../../../../../../redux/reducers/Logistic/providerOrders";
import {dialogsList} from "../../constants";
import ContainedButton from "../../../../../Common/Buttons/ContainedButton";
import {useDispatch, useSelector} from "react-redux";
import ItemForm from "./ItemForm";
import Typography from "@material-ui/core/Typography";
import {deleteItemToProviderOrder} from "../../../../../../redux/thunks/Logistic/providerOrders";

const ItemsList = ({items, editable = true}) => {
    const dispatch = useDispatch();
    const {currentOrder} = useSelector(({logistic}) => logistic.providerOrders);
    const handleDeleteItem = (itemId) => {
        dispatch(deleteItemToProviderOrder({
            order: currentOrder,
            itemId,
        }));
    }
    return (
        <fieldset className='table-list'>
            <legend>
                <Typography>
                    <IntlMessages id="products" /> y <IntlMessages id="services" />
                </Typography>
            </legend>
            {editable &&<div className="grid">
                <ContainedButton
                    text={<><IntlMessages id="add"/>{' '}<IntlMessages id="item"/></>}
                    color="primary"
                    onClick={() => dispatch(openDialog(dialogsList.itemForm))}
                    className="add-button"
                />
            </div>}
            <TableContainer
                head={
                    <StyledTableRow>
                        <StyledTableCell>
                            <IntlMessages id="product" /> / <IntlMessages id="service" />
                        </StyledTableCell>
                        <StyledTableCell>
                            <IntlMessages id="quantity" />
                        </StyledTableCell>
                        <StyledTableCell>
                            <IntlMessages id="description" />
                        </StyledTableCell>
                        {editable && <StyledTableCell width="10%"/>}
                    </StyledTableRow>
                }
            >
                {items.map((item) => (
                    <StyledTableRow key={item.id}>
                        <StyledTableCell>
                            {item.name}
                        </StyledTableCell>
                        <StyledTableCell>
                            {item.quantity}
                        </StyledTableCell>
                        <StyledTableCell>
                            {item.description}
                        </StyledTableCell>
                        {editable && <StyledTableCell>
                            <IconButton onClick={() => handleDeleteItem(item.id)}>
                                <DeleteIcon/>
                            </IconButton>
                        </StyledTableCell>}
                    </StyledTableRow>
                ))}
            </TableContainer>
            <ItemForm />
        </fieldset>
    );
};

export default ItemsList;