import React from 'react';
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import useForm from "../../../../../hooks/Common/useForm";
import OutlinedDatePicker from "../../../../Common/Forms/OutlinedDatePicker";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import ContainedButton from "../../../../Common/Buttons/ContainedButton";
import {useDispatch} from "react-redux";
import {
    resetCarbonFootprintFilters,
    setCarbonFootprintFilters
} from "../../../../../redux/actions/Logistic/CarbonFootprint";


const initData = {
    startDate: "",
    endDate: "",
    calcConsumption: '',
    theoreticalConsumption: '',
    emissionFactor: ''
}
const Form = () => {
    const {formData, setFormData} = useForm(initData);
    const dispatch = useDispatch();
    const applyFilters = () => {
        dispatch(setCarbonFootprintFilters(formData));
    }
    const clearFilters = () => {
        dispatch(resetCarbonFootprintFilters());
    }
    return (
        <GridContainer spacing={2}>
            <Grid item xs={12} md={6}>
                <OutlinedDatePicker
                    label={<IntlMessages id='start.date' />}
                    fullWidth
                    value={formData.startDate}
                    onChange={value => setFormData('startDate', value)}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <OutlinedDatePicker
                    label={<IntlMessages id='end.date' />}
                    fullWidth
                    value={formData.endDate}
                    onChange={value => setFormData('endDate', value)}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <OutlinedInput
                    label={<IntlMessages id='calculated.consumption' />}
                    fullWidth
                    type="number"
                    value={formData.calcConsumption}
                    onChange={value => setFormData('calcConsumption', value)}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <OutlinedInput
                    label={<IntlMessages id='theoretical.consumption' />}
                    fullWidth
                    type="number"
                    value={formData.theoreticalConsumption}
                    onChange={value => setFormData('theoreticalConsumption', value)}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <OutlinedInput
                    label={<IntlMessages id='emission.factor' />}
                    fullWidth
                    type="number"
                    value={formData.emissionFactor}
                    onChange={value => setFormData('emissionFactor', value)}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <ContainedButton
                    text={<IntlMessages id='filters.button.apply' />}
                    color='primary'
                    onClick={applyFilters}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <ContainedButton
                    text={<IntlMessages id='filters.button.clear' />}
                    color='primary'
                    onClick={clearFilters}
                />
            </Grid>
        </GridContainer>
    );
};

export default Form;