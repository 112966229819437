import React from 'react';
import PageContainer from "../../../../../components/Logistic/Planner/PageContainer";
import Filters from "../../../../../components/Logistic/Planner/Filters";
import Table from "../../../../../components/Logistic/Planner/Table";
import ConfirmationDialog from "../../../../../components/Logistic/Planner/ConfirmationDialog";
import MassiveUpdateDialog from "../../../../../components/Logistic/Planner/MassiveUpdateDialog";

const Planner = () => {
    return (
        <PageContainer>
            <Filters />
            <Table />
            <MassiveUpdateDialog />
            <ConfirmationDialog />
        </PageContainer>
    );
};

export default Planner;