import React from 'react';
import {useSelector} from "react-redux";
import {readerTypes} from "../constants";
import Driver from "./Driver";
import Vehicle from "./Vehicle";
import {Box} from "@material-ui/core";

const viewers = {
    [readerTypes.driver]: <Driver />,
    [readerTypes.vehicle]: <Vehicle />,
}

const Viewer = () => {
    const fileType = useSelector(({fleetManagement}) => fleetManagement.filesReader.fileType);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '1rem',
                gap: '1rem',
            }}
        >

            {viewers[fileType] ?? null}
        </Box>
    );

};

export default Viewer;