import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {DataGrid} from "@mui/x-data-grid";
import {useIntl} from "react-intl";
import {getAllProviderOrders, getOrderDetails} from "../../../../redux/thunks/Logistic/providerOrders";
import {parseDateToDateString} from "../../../../utils/dates";
import {IconButton} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {openDialog} from "../../../../redux/reducers/Logistic/providerOrders";
import {dialogsList} from "./constants";

const ProviderOrdersTable = () => {
    const {orders} = useSelector(({logistic}) => logistic.providerOrders);
    const intl = useIntl();
    const dispatch = useDispatch();
    const handleShowDetails = (order) => {
        dispatch(getOrderDetails({order}));
        dispatch(openDialog(dialogsList.editOrderDialog));
    }
    const columns = [
        {
            field: "id",
            hide: true,
            sortable: false,
        },
        {
            field: "providerName",
            headerName: intl.messages["provider"],
            width: 200,
            sortable: false,
        },
        {
            field: "orderDate",
            headerName: intl.messages["requested"],
            renderCell: ({value}) => parseDateToDateString(value),
            width: 200,
            sortable: false,
        },
        {
            field: "carryingOutDate",
            renderCell: ({value}) => parseDateToDateString(value),
            headerName: intl.messages["execution"],
            width: 200,
            sortable: false,
        },
        {
            field: "stateName",
            headerName: intl.messages["state"],
            width: 200,
            sortable: false,
        },
        {
            field: "typeName",
            headerName: intl.messages["type"],
            width: 200,
            sortable: false,
        },
        {
            field: 'actions',
            headerName: intl.messages["actions"],
            width: 200,
            sortable: false,
            renderCell: ({row}) => {
                return (
                    <IconButton onClick={() => handleShowDetails(row)}>
                        <MoreVertIcon/>
                    </IconButton>
                )
            }
        }
    ];
    React.useEffect(() => {
        dispatch(getAllProviderOrders());
    }, []);
    return (
        <DataGrid
            style={{
                height: '70vh',
                width: '100%',
            }}
            rowsPerPageOptions={[10, 25, 50]}
            disableColumnMenu
            disableColumnFilter
            disableSelectionOnClick
            rows={orders}
            columns={columns}
        >

        </DataGrid>
    );
};

export default ProviderOrdersTable;