import React from 'react';
import {useFetch} from "../../../../hooks/Common/useFetch";
import {getAccessData} from "../../../../services/ControlPanel/Kpi/GpsReportsService";

const GpsReports = () => {
    const {data: {organizationName, token}} = useFetch(getAccessData, {}, {});

    return (
        <iframe
            src={`https://${organizationName}.4gflota.com/4GInformes/InformesV5/pages/login/login4gflota.aspx?lang=es&prov=4gflota&accessCode=${token}`}
            width="100%"
            height="100%"
            title="GPS Report"
            frameBorder="0"
        />
    );
};

export default GpsReports;