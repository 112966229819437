import React, {useEffect} from "react";

import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import useForm from "../../../../../hooks/Common/useForm";
import CommonMultiSelect from "./CommonMultiSelect";
import {useDispatch, useSelector} from "react-redux";
import {applyFilters} from "../../../../../redux/reducers/Logistic/aavvServices";

const initialData = {
  hoppers: [],
  trailers: [],
  clients: [],
  states: [],
  serviceOrder: ""
};

const Form = () => {
  const id = "service-list-filters";
  const {data} = useSelector(({logistic}) => logistic.aavvServices);
  const {formData, setFormData} = useForm(initialData);
  const dispatch = useDispatch();
  useEffect(
    () => {
      dispatch(applyFilters(formData));
    },
    [ formData ]
  );

  return (
    <form id={id}>
      <GridContainer spacing={4}>
        <Grid item xs={12} sm={4} lg={3}>
          <CommonMultiSelect
            id={id + "-tractPlaque"}
            options={[
              ...new Set(Object.values(data).map(e => e.tractPlaque).sort())
            ]}
            filter={formData.hoppers}
            onChange={value => setFormData("hoppers", value)}
            label={<IntlMessages id="vehicle.head" />}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <CommonMultiSelect
            id={id + "-wagonPlaque"}
            options={[
              ...new Set(Object.values(data).map(e => e.wagonPlaque).sort())
            ]}
            filter={formData.trailers}
            onChange={value => setFormData("trailers", value)}
            label={<IntlMessages id="trailer" />}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <CommonMultiSelect
            id={id + "-clients"}
            options={[
              ...new Set(Object.values(data).map(e => e.staff).sort())
            ]}
            filter={formData.clients}
            onChange={value => setFormData("clients", value)}
            limitTags={1}
            label={<IntlMessages id="client" />}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <CommonMultiSelect
            id={id + "-states"}
            options={[
              ...new Set(
                Object.values(data).map(e => e.trtCompliance).sort()
              )
            ]}
            filter={formData.states}
            onChange={value => setFormData("states", value)}
            label={<IntlMessages id="state" />}
          />
        </Grid>
        <Grid item xs={12} sm={4} lg={3}>
          <OutlinedInput
            fullWidth
            value={formData.serviceOrder}
            id={id + "-serviceOrder"}
            label={
              <IntlMessages id="ot" />
            }
            onChange={value => setFormData("serviceOrder", value)}
          />
        </Grid>
      </GridContainer>
    </form>
  );
};

export default Form;
