import React, {useEffect} from "react";
import FiltersContainer from "../../../../../Common/Forms/FiltersContainer";
import useForm from "../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {useDispatch} from "react-redux";
import ActiveSelect from "../../../../../Common/Forms/Select/ActiveSelect";
import {applySinisterTypesFilters} from "../../../../../../redux/actions/FleetManagement/SinisterTypes";

const initialData = {
  description: "",
  status: null
};

const Filters = () => {
  const {formData, setFormData} = useForm(initialData);
  const dispatch = useDispatch();
  useEffect(
    () => {
      dispatch(applySinisterTypesFilters(formData));
    },
    [ formData ]
  );
  return (
    <FiltersContainer active marginBottom={5} onClose={() => {}}>
      <form>
        <GridContainer spacing={4}>
          <Grid item xs={12} sm={4} lg={3}>
            <OutlinedInput
              fullWidth
              value={formData.description}
              id="description-filter"
              label={
                <IntlMessages id="fleet.management.sinister.type.table.description" />
              }
              onChange={value => setFormData("description", value)}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={3}>
            <ActiveSelect
              fullWidth
              value={formData.status}
              id="status-filter"
              label={
                <IntlMessages id="fleet.management.sinister.type.table.active" />
              }
              onChange={value => setFormData("status", value)}
            />
          </Grid>
        </GridContainer>
      </form>
    </FiltersContainer>
  );
};

Filters.propTypes = {};

export default Filters;
