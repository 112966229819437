import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import PageContainerComponent from "../../../Common/Layouts/PageContainer";
import PropTypes from "prop-types";
import React from "react";

const breadcrumbs = [
    {
        label: <IntlMessages id={"sidebar.location"} />,
        link: "/localization/daily-operations/map"
    },
    {
        label: <IntlMessages id={"localization.daily.operation.driving.control.sidebar"} />,
        isActive: true
    }
];

const PageContainer = ({children}) => (
    <PageContainerComponent
        heading={<IntlMessages id="localization.daily.operation.driving.control.title" />}
        description={
            <IntlMessages id="localization.daily.operation.driving.control.description" />
        }
        breadcrumbs={breadcrumbs}
    >
        {children}
    </PageContainerComponent>
);

PageContainer.propTypes = {
    children: PropTypes.node
};

export default PageContainer;
