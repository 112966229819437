import React from "react";
import {Box, Typography} from "@material-ui/core";
import ContainedButton from "../../../Common/Buttons/ContainedButton";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import {useDispatch, useSelector} from "react-redux";
import useForm from "../../../../hooks/Common/useForm";
import OutlinedDatePicker from "../../../Common/Forms/OutlinedDatePicker";
import {parseDateToInput} from "../../../../utils/dates";
import ExportServices from "./ExportServices";
import moment from "moment";
import {applyApiFilters, openDialog} from "../../../../redux/reducers/Logistic/aavvServices";
import {dialogsList} from "../contants";

const initialData = {
  date: ""
};

const ButtonsArea = () => {
  const {apiFilters, lastUpdate} = useSelector(
    ({logistic}) => logistic.aavvServices
  );
  const dispatch = useDispatch();
  const {formData, setFormData, setValues} = useForm(initialData);
  React.useEffect(() => {
    setValues({
      ...apiFilters,
      date: parseDateToInput(apiFilters.date)
    });
  }, []);
  const onUpdate = e => {
    e.preventDefault();
    dispatch(applyApiFilters(formData));
  };
  const handleOpenEditHeadersDialog = () => {
    dispatch(openDialog(dialogsList.editHeaders));
  }
  return (
    <Box
      my="1em"
      display="flex"
      flexWrap="wrap"
      style={{gap: "1em"}}
      alignItems="center"
    >
      <Box>
        <ContainedButton
          text={<IntlMessages id={"form.button.view"} />}
          color="primary"
          onClick={handleOpenEditHeadersDialog}
        />
      </Box>
      <Box>
        <ExportServices>
          <ContainedButton
            text={<IntlMessages id={"form.button.export"} />}
            color="primary"
          />
        </ExportServices>
      </Box>
      <Box>
        <ContainedButton
          onClick={onUpdate}
          text={<IntlMessages id={"form.button.update"} />}
          color="primary"
        />
      </Box>
      <Box>
        <OutlinedDatePicker
          value={formData.date}
          onChange={value => setFormData("date", value)}
        />
      </Box>
      <Box>
        <Typography variant="body2">
          <IntlMessages id={"text.last.update.label"} />{" "}
          <b>{moment(new Date(lastUpdate)).format("DD-MM-YYYY HH:mm:ss")}</b>
        </Typography>
      </Box>
    </Box>
  );
};

export default ButtonsArea;
