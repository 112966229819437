import React from "react";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import FormDialog from "../../../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import {addMaintenanceType} from "../../../../../../redux/actions/FleetManagement/MaintenanceTypes";
import Form from "../Form";

const AddDialog = ({openAdd, setOpenAdd}) => {
  const id = "add-maintenance-type-form";
  const dispatch = useDispatch();
  const onAdd = data => {
    dispatch(addMaintenanceType(data));
    setOpenAdd(false);
  };
  return (
    <FormDialog
      id={id}
      title={
        <IntlMessages id={"fleet.management.maintenance.type.add.title"} />
      }
      submitText={<IntlMessages id={"label.form.save"} />}
      cancelText={<IntlMessages id={"label.form.cancel"} />}
      isOpen={openAdd}
      onClose={() => setOpenAdd(false)}
    >
      {openAdd && (
        <Form initialData={{description: ""}} id={id} onSubmit={onAdd} />
      )}
    </FormDialog>
  );
};

AddDialog.propTypes = {};

export default AddDialog;
