import React from "react";
import PropTypes from "prop-types";
import Select from "../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {useFetch} from "../../../../../../../hooks/Common/useFetch";
import {getAllVehicleCorrectivesStatus} from "../../../../../../../services/FleetManagement/DailyOperation/VehicleCorrectivesService";

const StatusSelect = ({id, value, onChange, form = false}) => {
  const {data: status} = useFetch(getAllVehicleCorrectivesStatus, {}, []);
  return (
    <Select
      id={id}
      label={
        <IntlMessages id="fleet.management.vehicle.corrective.table.state" />
      }
      value={value}
      onChange={e => onChange(e.target.value)}
      fullWidth
    >
      {!form && (
        <MenuItem value={""}>
          <IntlMessages id="select.all" />
        </MenuItem>
      )}
      {status.map(s => (
        <MenuItem value={s.id} key={s.id}>
          {s.description}
        </MenuItem>
      ))}
    </Select>
  );
};

StatusSelect.propTypes = {
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func,
  form: PropTypes.bool
};

StatusSelect.defaultProps = {
  value: "",
  onChange: () => {}
};

export default StatusSelect;
