import React from "react";
import FormDialog from "../../../Common/Dialogs/FormDialog";
import IntlMessages from "../../../../@jumbo/utils/IntlMessages";
import Form from "./Form";
import {useDispatch, useSelector} from "react-redux";
import {setSelectedService} from "../../../../redux/reducers/Logistic/aavvServices";

const EditServiceDialog = () => {
  const id = "edit-aavv-service-dialog";
  const dispatch = useDispatch();
  const {selectedService} = useSelector(({logistic}) => logistic.aavvServices);
  const onClose = () => {
      dispatch(setSelectedService(null));
  };
  return (
    <FormDialog
      id={id}
      isOpen={Boolean(selectedService)}
      onClose={onClose}
      title={
        <IntlMessages
          id={
            "logistic.distribution.daily.operation.services.list.edit.dialog.title"
          }
        />
      }
      cancelText={<IntlMessages id={"label.form.cancel"} />}
      submitText={<IntlMessages id={"label.form.save"} />}
    >
      {selectedService && (
        <Form
          id={id}
        />
      )}
    </FormDialog>
  );
};

export default EditServiceDialog;
