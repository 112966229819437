import React from "react";
import PropTypes from "prop-types";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const TableHeader = props => {
  return (
    <StyledTableRow>
      <StyledTableCell>
        <IntlMessages id={"fleet.management.vehicle.corrective.table.alias"} />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id={"fleet.management.vehicle.corrective.table.plaque"} />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id={"fleet.management.vehicle.corrective.table.type"} />
      </StyledTableCell>
      <StyledTableCell>
        <IntlMessages id={"fleet.management.vehicle.corrective.table.state"} />
      </StyledTableCell>
      <StyledTableCell />
    </StyledTableRow>
  );
};

TableHeader.propTypes = {};

export default TableHeader;
