import React from 'react';
import Select from "../../../../Common/Forms/Select";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {useFetch} from "../../../../../hooks/Common/useFetch";
import {getAllServicesForSelect} from "../../../../../services/Treasury/BillServiceService";


const ServiceSelect = ({name = 'service', value, onChange, error, errorMessage, required = false}) => {
    const {data: services} = useFetch(getAllServicesForSelect, {}, []);
    const handleOnChange = ({target}) => {
        const {value} = target;
        const service = services.find(service => service.id === value);
        onChange({
            value,
            name: service.name,
            unitPrice: service.unitPrice,
            taxId: service.taxId,
        });
    }
    return (
        <Select
            label={<IntlMessages id="service"/>}
            name={name}
            value={value}
            onChange={handleOnChange}
            error={error}
            errorMessage={errorMessage}
            fullWidth
            required={required}
        >
            {services?.map(service => (
                <MenuItem key={service.id} value={service.id}>
                    {service.name}
                </MenuItem>
            ))}
        </Select>
    );
};

export default ServiceSelect;