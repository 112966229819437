import {fetchError, fetchStart, fetchSuccess} from "../Common";

import {
    APPLY_EMPLOYEES_LIST_DOCUMENTS_FILTERS,
    APPLY_EMPLOYEES_SUMMARY_DOCUMENTS_FILTERS,
    FETCH_ALL_LIST_EMPLOYEES_DOCUMENTS,
    FETCH_ALL_SUMMARY_EMPLOYEES_DOCUMENTS,
    FETCH_ALL_SUMMARY_EMPLOYEES_DOCUMENTS_VIEWS,
    FETCH_EMPLOYEES_DOCUMENTS_DASHBOARD,
    RESET_ALL_SUMMARY_EMPLOYEES_DOCUMENTS_DATA,
    RESET_CURRENT_LIST_DOCUMENT,
    RESET_EMPLOYEES_DOCUMENTS_STATE,
    RESET_EMPLOYEES_LIST_DOCUMENTS_FILTERS,
    RESET_EMPLOYEES_SUMMARY_DOCUMENTS_FILTERS,
    SET_CURRENT_LIST_DOCUMENT
} from "../../types/RRHH/DocumentationTypes";
import {
    createDocument,
    createSummaryView,
    editDocument,
    editSummaryHeaders,
    getAllDocuments,
    getAllSummaryDocuments,
    getAllSummaryHeaders,
    getAllSummaryViews,
    getDocumentsDashboardData,
    deleteSummaryView,
} from "../../../services/RRHH/DocumentationService";

export const resetEmployeesDocumentsState = () => ({type: RESET_EMPLOYEES_DOCUMENTS_STATE});

export const setCurrentListDocument = item => ({type: SET_CURRENT_LIST_DOCUMENT, payload: item});
export const resetCurrentListDocument = () => ({type: RESET_CURRENT_LIST_DOCUMENT});
export const applyEmployeeDocumentsListFilters = filters => ({
    type: APPLY_EMPLOYEES_LIST_DOCUMENTS_FILTERS,
    payload: filters
});
export const resetEmployeeDocumentsListFilters = () => ({type: RESET_EMPLOYEES_LIST_DOCUMENTS_FILTERS});
export const fetchAllEmployeeDocuments = filters => {
    return async dispatch => {
        dispatch(fetchStart());
        getAllDocuments()
            .then(docs => {
                dispatch(fetchSuccess());
                dispatch({type: FETCH_ALL_LIST_EMPLOYEES_DOCUMENTS, payload: docs});
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    }
}

export const addEmployeeDocument = (doc, filters) => {
    return async dispatch => {
        dispatch(fetchStart());
        createDocument(doc)
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                setTimeout(() => {
                    dispatch(fetchAllEmployeeDocuments(filters));
                }, 600);
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    }
}

export const editEmployeeDocument = (doc, filters) => {
    return async dispatch => {
        dispatch(fetchStart());
        editDocument(doc)
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                setTimeout(() => {
                    dispatch(fetchAllEmployeeDocuments(filters));
                }, 600);
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    }
}


export const applyEmployeeDocumentsSummaryFilters = filters => ({
    type: APPLY_EMPLOYEES_SUMMARY_DOCUMENTS_FILTERS,
    payload: filters
});
export const resetEmployeeDocumentsSummaryFilters = () => ({type: RESET_EMPLOYEES_SUMMARY_DOCUMENTS_FILTERS});
export const fetchAllSummaryEmployeeDocuments = (filters, views) => {
    return async dispatch => {
        if (filters.view !== '') {
            dispatch(fetchStart());
            try {
                const data = await getAllSummaryDocuments(filters);
                const headers = await getAllSummaryHeaders(filters.view);
                dispatch(fetchSuccess());
                dispatch({
                    type: FETCH_ALL_SUMMARY_EMPLOYEES_DOCUMENTS,
                    payload: {
                        data,
                        headers,
                        selectedInfo: {
                            viewId: filters.view,
                            editable: views.find(v => v.id === filters.view)?.editable,
                            isPublic: views.find(v => v.id === filters.view)?.isPublic,
                            name: views.find(v => v.id === filters.view)?.name,
                            predetermined: views.find(v => v.id === filters.view)?.predetermined
                        }
                    }
                });
            } catch (e) {
                dispatch(fetchError(e.message));
            }
        } else {
            dispatch({type: RESET_ALL_SUMMARY_EMPLOYEES_DOCUMENTS_DATA});
        }
    }
}

export const fetchAllSummaryEmployeeDocumentsView = () => {
    return async dispatch => {
        dispatch(fetchStart());
        try {
            dispatch(fetchSuccess());
            const views = await getAllSummaryViews();
            dispatch({type: FETCH_ALL_SUMMARY_EMPLOYEES_DOCUMENTS_VIEWS, payload: views});
        } catch (e) {
            dispatch(fetchError(e.message));
        }
    }
}

export const addSummaryDocumentsView = data => {
    return async dispatch => {
        dispatch(fetchStart());
        createSummaryView(data)
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                setTimeout(() => {
                    dispatch(fetchAllSummaryEmployeeDocumentsView());
                }, 500);
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    }
}

export const removeSummaryDocumentsView = viewId => {
    return async dispatch => {
        dispatch(fetchStart());
        deleteSummaryView(viewId)
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                setTimeout(() => {
                    dispatch(fetchAllSummaryEmployeeDocumentsView());
                }, 500);
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    }
}

export const editDocumentsHeaders = (view, data, filters, views) => {
    return async dispatch => {
        dispatch(fetchStart());
        editSummaryHeaders({view, ...data})
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                setTimeout(() => {
                    dispatch(fetchAllSummaryEmployeeDocuments(filters, views));
                }, 500);
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    }
}

export const fetchEmployeesDocumentsDashboard = ({view}) => {
    return async dispatch => {
        try {
            dispatch(fetchStart());
            let data = [];
            if (view) {
                data = await getDocumentsDashboardData({view});
            }
            dispatch({
                type: FETCH_EMPLOYEES_DOCUMENTS_DASHBOARD,
                payload: data
            });
            dispatch(fetchSuccess());
        } catch (e) {
            dispatch(fetchError(e.message));
        }
    }
}
