import React from "react";
import TableComponent from "../../../../Common/Tables/Table";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import {connect} from "react-redux";
import {
  fetchAllMaintenancePlans,
  openMaintenancePlansDialog,
  setCurrentMaintenancePlan
} from "../../../../../redux/actions/FleetManagement/MaintenancePlans";
import Filters from "./Filters";
import {dialogs} from "../constants";
import TableHeader from "./TableHeader";
import CrudTableRow from "../../../../Common/Tables/CrudTable/CrudTableRow";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import RestoreTableRow from "../../../../Common/Tables/CrudTable/RestoreTableRow";
import FilterBuilder from "../../../../../utils/filters";

const Table = ({dispatch, data, filters}) => {
  const onEdit = plan => {
    dispatch(setCurrentMaintenancePlan(plan));
    dispatch(openMaintenancePlansDialog(dialogs.EDIT_DIALOG));
  };
  const onDelete = plan => {
    dispatch(setCurrentMaintenancePlan(plan));
    dispatch(openMaintenancePlansDialog(dialogs.DELETE_DIALOG));
  };
  const onRestore = plan => {
    dispatch(setCurrentMaintenancePlan(plan));
    dispatch(openMaintenancePlansDialog(dialogs.RESTORE_DIALOG));
  };

  const RenderRow = ({row}) =>
    row.status ? (
      <CrudTableRow onEdit={() => onEdit(row)} onDelete={() => onDelete(row)}>
        <StyledTableCell>{row.name}</StyledTableCell>
        <StyledTableCell>{row.quantity}</StyledTableCell>
        <StyledTableCell>{row.cost}</StyledTableCell>
        <StyledTableCell>
          <IntlMessages id={row.status ? "table.cell.yes" : "table.cell.no"} />
        </StyledTableCell>
      </CrudTableRow>
    ) : (
      <RestoreTableRow onRestore={() => onRestore(row)}>
        <StyledTableCell>{row.name}</StyledTableCell>
        <StyledTableCell>{row.quantity}</StyledTableCell>
        <StyledTableCell>{row.cost}</StyledTableCell>
        <StyledTableCell>
          <IntlMessages id={row.status ? "table.cell.yes" : "table.cell.no"} />
        </StyledTableCell>
      </RestoreTableRow>
    );
  React.useEffect(() => {
    dispatch(fetchAllMaintenancePlans(filters));
  }, []);
  return (
    <React.Fragment>
      <Filters />
      <TableComponent
        data={data}
        head={<TableHeader />}
        renderRow={RenderRow}
        getRowKey={row => row.id}
      />
    </React.Fragment>
  );
};

Table.propTypes = {};

const mapStateToProps = ({fleetManagement}) => {
  const {data, filters} = fleetManagement.maintenancePlans;
  const filteredData = new FilterBuilder(Object.values(data))
    .byFieldIncludes("name", filters.name)
    .byFieldIncludes("status", filters.status)
    .build();
  return {data: filteredData, filters};
};

export default connect(mapStateToProps)(Table);
