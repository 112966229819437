import React from 'react';
import {Grid} from "@material-ui/core";
import {useIntl} from "react-intl";
import Select from "../../../../Common/Forms/Select";
import MenuItem from "@material-ui/core/MenuItem";

const options = [
    "CONGESTION VEHICULAR EN RUTA",
    "FALLA MECANICA DEL VEHICULO",
    "MARCHA LENTA DEL VEHICULO",
    "DETENIDO EN RUTA",
    "ACCIDENTE VEHICULAR",
    "DEMORA EN CONTROLES",
    "OTROS",
]

const NonComplianceSelect = ({id, value, setValue}) => {
    const intl = useIntl();

    return (
        <Grid item xs={12}>
            <Select
                id={`${id}-reason`}
                label={`${intl.messages['reason']} ${intl.messages['non.compliance']}`}
                onChange={e => setValue(e.target.value)}
                value={value}
                fullWidth
            >
                {options.map((option, index) => (
                  <MenuItem key={index} value={option}>
                      {option}
                  </MenuItem>
                ))}
            </Select>
        </Grid>
    );
};

export default NonComplianceSelect;