import React from "react";
import Select from "../../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import {MenuItem} from "@material-ui/core";
import {fetchAllHeadquartersByClientId} from "../../../../../../../services/HeadquarterService";

const HeadquarterSelect = ({id, value, setValue, disabled, client}) => {
  const [ headquarters, setHeadquarters ] = React.useState([]);
  React.useEffect(
    () => {
      client && fetchAllHeadquartersByClientId(client)
        .then(data => setHeadquarters(data))
        .catch(e => setHeadquarters([]));
    },
    [ client ]
  );
  return (
    <Select
      id={id}
      value={value}
      disabled={disabled}
      onChange={e => setValue(e.target.value)}
      label={
        <IntlMessages
          id={
            "elevators.management.elevators.index.card.detail.dialog.form.headquarter"
          }
        />
      }
      fullWidth
    >
      {headquarters.map(headquarter => (
        <MenuItem value={headquarter.id} key={headquarter.id}>
          {headquarter.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default HeadquarterSelect;
