import {
    APPLY_ELEVATORS_FILTERS,
    FETCH_ALL_ELEVATORS,
    RESET_CURRENT_ELEVATOR,
    RESET_ELEVATORS_FILTERS,
    RESET_ELEVATORS_STATE,
    SET_CURRENT_ELEVATOR
} from "../../types/ElevatorsManagement/ElevatorsTypes";
import {fetchError, fetchStart, fetchSuccess} from "../Common";
import {
    createElevator,
    getAllElevators,
    getElevatorById,
    updateElevator
} from "../../../services/ElevatorsManagement/ElevatorsService";
import {calculateDaysOfDifferenceBetweenDates} from "../../../utils/dates";


export const resetElevatorsState = () => ({type: RESET_ELEVATORS_STATE});
export const applyElevatorsFilters = filters => ({type: APPLY_ELEVATORS_FILTERS, payload: filters});
export const resetElevatorsFilters = () => ({type: RESET_ELEVATORS_FILTERS});
export const resetCurrentElevator = () => ({type: RESET_CURRENT_ELEVATOR});
export const setCurrentElevator = current => {
    return async dispatch => {
        dispatch(fetchStart());
        getElevatorById(current)
            .then(elevator => {
                dispatch(fetchSuccess());
                dispatch({type: SET_CURRENT_ELEVATOR, payload: elevator})
            }).catch(e => {
                dispatch(fetchError(e.message));
        })
    }
};
export const fetchAllElevators = filters => {
    return async dispatch => {
        dispatch(fetchStart());
        getAllElevators(filters)
            .then(elevators => {
                dispatch(fetchSuccess());
                dispatch({type: FETCH_ALL_ELEVATORS, payload: elevators});
            }).catch(e => {
                dispatch(fetchError(e.message));
        });
    }
}
export const addElevator = (data, filters) => {
    return async dispatch => {
        dispatch(fetchStart());
        createElevator(data)
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                dispatch(fetchAllElevators(filters));
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    }
}

export const editElevatorInfo = (current, data, filters) => {
    return async dispatch => {
        dispatch(fetchStart());
        const updatedElevator = {
            id: current.id,
            ...data,
            shipmentDate: (data.shipmentDate),
            type: current.contract?.id,
            price: current.contract?.price,
            currency: current.contract?.priceType?.id,
            freeMaintenanceStartDate: current.contract?.freeMaintenance?.startDate,
            freeMaintenanceEndDate: current.contract?.freeMaintenance?.endDate,
            warrantyStartDate: current.contract?.warranty?.startDate,
            warrantyEndDate: current.contract?.warranty?.endDate,
            startDate: current.contract?.startDate,
            endDate: (current.contract?.endDate),
            state: current.state?.id,
            detail: current.state?.detailId,
            reason: current.state?.reason,
            analyst: current.responsibleList?.analyst,
            supervisor: current.responsibleList?.supervisor,
            technical: current.responsibleList?.technical,
            contactList: current.contactClients,
        }
        updateElevator(updatedElevator)
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                setTimeout(() => dispatch(fetchAllElevators(filters)), 2000);
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    }
}
export const editElevatorContractInfo = (current, data, filters) => {
    return async dispatch => {
        dispatch(fetchStart());
        const updatedElevator = {
            id: current.id,
            name: current.name,
            client: current.client,
            headquarter: current.headquarter,
            model: current.model,
            ken: current.ken,
            category: current.category,
            stops: current.stops,
            shipmentDate: current.shipmentDate,
            emergency: current.emergency,
            ...data,
            freeMaintenanceStartDate: (data.freeMaintenanceStartDate),
            freeMaintenanceEndDate: (data.freeMaintenanceEndDate),
            freeMaintenanceDuration: calculateDaysOfDifferenceBetweenDates(data.freeMaintenanceStartDate, data.freeMaintenanceEndDate),
            warrantyStartDate: (data.warrantyStartDate),
            warrantyEndDate: (data.warrantyEndDate),
            warrantyDuration: calculateDaysOfDifferenceBetweenDates(data.warrantyStartDate, data.warrantyEndDate),
            startDate: (data.startDate),
            endDate: (data.endDate),
            duration: calculateDaysOfDifferenceBetweenDates(data.startDate, data.endDate),
            state: current.state?.id,
            detail: current.state?.detailId,
            reason: current.state?.reason,
            analyst: current.responsibleList?.analyst,
            supervisor: current.responsibleList?.supervisor,
            technical: current.responsibleList?.technical,
            contactList: current.contactClients,

        }
        updateElevator(updatedElevator)
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                setTimeout(() => dispatch(fetchAllElevators(filters)), 2000);
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    }
}

export const editElevatorStateInfo = (current, data, filters) => {
    return async dispatch => {
        dispatch(fetchStart());
        const updatedElevator = {
            id: current.id,
            name: current.name,
            client: current.client,
            headquarter: current.headquarter,
            model: current.model,
            ken: current.ken,
            category: current.category,
            stops: current.stops,
            shipmentDate: current.shipmentDate,
            emergency: current.emergency,
            type: current.contract?.id,
            price: current.contract?.price,
            currency: current.contract?.priceType?.id,
            freeMaintenanceStartDate: current.contract?.freeMaintenance?.startDate,
            freeMaintenanceEndDate: current.contract?.freeMaintenance?.endDate,
            warrantyStartDate: current.contract?.warranty?.startDate,
            warrantyEndDate: current.contract?.warranty?.endDate,
            startDate: current.contract?.startDate,
            endDate: (current.contract?.endDate),
            ...data,
            analyst: current.responsibleList?.analyst,
            supervisor: current.responsibleList?.supervisor,
            technical: current.responsibleList?.technical,
            contactList: current.contactClients,
        }
        updateElevator(updatedElevator)
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                setTimeout(() => dispatch(fetchAllElevators(filters)), 2000);
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    }
}

export const editElevatorResponsibleInfo = (current, data, filters) => {
    return async dispatch => {
        dispatch(fetchStart());
        const updatedElevator = {
            id: current.id,
            name: current.name,
            client: current.client,
            headquarter: current.headquarter,
            model: current.model,
            ken: current.ken,
            category: current.category,
            stops: current.stops,
            shipmentDate: current.shipmentDate,
            emergency: current.emergency,
            type: current.contract?.id,
            price: current.contract?.price,
            currency: current.contract?.priceType?.id,
            freeMaintenanceStartDate: current.contract?.freeMaintenance?.startDate,
            freeMaintenanceEndDate: current.contract?.freeMaintenance?.endDate,
            warrantyStartDate: current.contract?.warranty?.startDate,
            warrantyEndDate: current.contract?.warranty?.endDate,
            startDate: current.contract?.startDate,
            endDate: (current.contract?.endDate),
            state: current.state?.id,
            detail: current.state?.detailId,
            reason: current.state?.reason,
            contactList: current.contactClients,
            ...data,
        }
        updateElevator(updatedElevator)
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                setTimeout(() => dispatch(fetchAllElevators(filters)), 2000);
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    }
}

export const editElevatorContactList = (current, list, filters) => {
    return async dispatch => {
        dispatch(fetchStart());
        const updatedElevator = {
            id: current.id,
            name: current.name,
            client: current.client,
            headquarter: current.headquarter,
            model: current.model,
            ken: current.ken,
            category: current.category,
            stops: current.stops,
            shipmentDate: current.shipmentDate,
            emergency: current.emergency,
            type: current.contract?.id,
            price: current.contract?.price,
            currency: current.contract?.priceType?.id,
            freeMaintenanceStartDate: current.contract?.freeMaintenance?.startDate,
            freeMaintenanceEndDate: current.contract?.freeMaintenance?.endDate,
            warrantyStartDate: current.contract?.warranty?.startDate,
            warrantyEndDate: current.contract?.warranty?.endDate,
            startDate: current.contract?.startDate,
            endDate: (current.contract?.endDate),
            state: current.state?.id,
            detail: current.state?.detailId,
            reason: current.state?.reason,
            analyst: current.responsibleList?.analyst,
            supervisor: current.responsibleList?.supervisor,
            technical: current.responsibleList?.technical,
            contactList: list,
        }
        updateElevator(updatedElevator)
            .then(response => {
                dispatch(fetchSuccess(response.transactionMessage));
                setTimeout(() => dispatch(fetchAllElevators(filters)), 2000);
            }).catch(e => {
            dispatch(fetchError(e.message));
        });
    }
}