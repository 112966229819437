import React from "react";
import {useFetch} from "../../../../../hooks/Common/useFetch";
import {fetchAllTagsByType} from "../../../../../services/TagService";
import MultipleSelect from "../../../../Common/Forms/Select/MultipleSelect";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";

const TagsPicker = ({value, onChange, error, hasError, disabled}) => {
  const {data: tags} = useFetch(fetchAllTagsByType, 0, []);
  return (
    <MultipleSelect
      fullWidth
      label={<IntlMessages id={"rrhh.employees.documentation.common.tags"} />}
      value={value}
      onChange={onChange}
      options={tags}
      disabled={disabled}
      limitTags={2}
    />
  );
};

export default TagsPicker;
