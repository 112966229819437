import React from 'react';
import PageContainer
    from "../../../../../../components/ElevatorsManagement/Elevators/MasterData/EquipmentTypes/PageContainer";
import Table from "../../../../../../components/ElevatorsManagement/Elevators/MasterData/EquipmentTypes/Table";
import AddDialog from "../../../../../../components/ElevatorsManagement/Elevators/MasterData/EquipmentTypes/AddDialog";
import EditDialog
    from "../../../../../../components/ElevatorsManagement/Elevators/MasterData/EquipmentTypes/EditDialog";
import Confirmation from "../../../../../../components/Common/Dialogs/Confirmation";
import {dialogsList} from "../../../../../../components/ElevatorsManagement/Elevators/MasterData/Common/constants";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import FloatingButtonWithAdd from "../../../../../../components/Common/Buttons/FloatingButtonWithAdd";
import {useDispatch, useSelector} from "react-redux";
import {
    closeTypesDialog,
    openTypesDialog, setCurrentType
} from "../../../../../../redux/reducers/ElevatorsManagement/types";
import {editTypeState} from "../../../../../../redux/thunks/ElevatorManagement/types";

const EquipmentTypes = () => {
    const dispatch = useDispatch();
    const { dialogs, current } = useSelector(({elevatorsManagement}) => elevatorsManagement.types);
    const openDialog = dialog => {
        dispatch(openTypesDialog(dialog));
    }
    const closeDialog = (dialog) => {
        dispatch(closeTypesDialog(dialog));
        dispatch(setCurrentType(null));
    }
    const onDelete = () => {
        dispatch(editTypeState({id: current.id, state: false}));
        closeDialog(dialogsList.deleteDialog);
    }
    const onRestore = () => {
        dispatch(editTypeState({id: current.id, state: true}));
        closeDialog(dialogsList.restoreDialog);
    }
    return (
        <PageContainer>
            <Table />
            <AddDialog />
            <EditDialog />
            <Confirmation
                open={dialogs.some(dialog => dialog === dialogsList.deleteDialog)}
                text={<IntlMessages id="want.to.delete"/>}
                onReject={() => closeDialog(dialogsList.deleteDialog)}
                onAccept={onDelete}
            />
            <Confirmation
                open={dialogs.some(dialog => dialog === dialogsList.restoreDialog)}
                text={<IntlMessages id="want.to.delete"/>}
                onReject={() => closeDialog(dialogsList.restoreDialog)}
                onAccept={onRestore}
            />
            <FloatingButtonWithAdd onAddClicked={() => openDialog(dialogsList.addDialog)} />
        </PageContainer>
    );
};

export default EquipmentTypes;