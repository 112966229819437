import React from 'react';
import BillDetails from "./BillDetails";
import ServicesTable from "./ServicesTable";
import useForm from "../../../../hooks/Common/useForm";
import BillSummary from "./BillSummary";

const INIT_DATA = {
    client: '',
    billNumber: '',
    creationDate: '',
    expirationDate: '',
    discount: 0,
    services: [],
    applyDeduction: false,
}

const BillForm = ({id, initialData = {}, onSubmit, disableEdit = false, isAdd}) => {
    const {formData, setFormData, setValues, handleSubmit} = useForm(INIT_DATA);
    React.useEffect(() => {
        if(Object.keys(initialData).length >  0) {
            setValues(initialData);
        }
    }, []);
    return (
        <form
            id={id}
            onSubmit={handleSubmit(onSubmit)}
        >
            <BillDetails formData={formData} setFormData={setFormData} isAdd={isAdd}/>
            <ServicesTable
                services={formData.services}
                setServices={newServices => setFormData('services', newServices)}
                disableEdit={disableEdit}
            />
            <BillSummary
                discount={formData.discount}
                services={formData.services}
                setDiscount={discount => setFormData('discount', discount)}
            />
        </form>
    );
};

export default BillForm;