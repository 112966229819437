import React from 'react';
import PageContainer from "../../../../components/Guardian/Refund/PageContainer";
import RefundsTable from "../../../../components/Guardian/Refund/RefundsTable";
import Filters from "../../../../components/Guardian/Refund/Filters";
import RefundConfirmationDialog from "../../../../components/Guardian/Refund/RefundConfirmationDialog";

const Refunds = () => {
    return (
        <PageContainer>
            <Filters />
            <RefundsTable />
            <RefundConfirmationDialog />
        </PageContainer>
    );
};

export default Refunds;