import React from 'react';
import PageContainer from "../../../../../components/BackOffice/Licenses/IndexFile/PageContainer";
import Table from "../../../../../components/BackOffice/Licenses/IndexFile/Table";
import InfoFooter from "../../../../../components/BackOffice/Licenses/IndexFile/InfoFooter";

const IndexFiles = () => {
    return (
        <PageContainer>
            <Table />
            <InfoFooter />
        </PageContainer>
    );
};

export default IndexFiles;