import React from 'react';
import useForm from "../../../../../../../hooks/Common/useForm";
import GridContainer from "../../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../../@jumbo/utils/IntlMessages";
import TypeSelect from "./TypeSelect";
import {itemTypes} from "../../../constants";
import ServiceSelect from "../../../../../../Treasury/common/BillForm/ServicesTable/ServiceSelect";
import ProductSelect from "./ProductSelect";

const initData = {
    type: itemTypes.product,
    item: '',
    quantity: 1,
    description: ''
}

const Form = ({id, onSubmit}) => {
    const {formData, setFormData, handleSubmit} = useForm(initData);
    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12}>
                    <TypeSelect
                        name="type"
                        value={formData.type}
                        onChange={value => setFormData('type', value)}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    {formData.type === itemTypes.service
                        ? (
                            <ServiceSelect
                                name="item"
                                value={formData.item.value}
                                onChange={value => setFormData('item', value)}
                                required
                            />
                        )
                        : (
                            <ProductSelect
                                name="item"
                                value={formData.item.value}
                                onChange={value => setFormData('item', value)}
                                required
                            />
                        )
                    }
                </Grid>
                <Grid item xs={12}>
                    <OutlinedInput
                        fullWidth
                        name="quantity"
                        type="number"
                        value={formData.quantity}
                        onChange={value => setFormData('quantity', value)}
                        label={<IntlMessages id="quantity" />}
                        required
                        inputProps={{min: 1}}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedInput
                        fullWidth
                        name="description"
                        value={formData.description}
                        onChange={value => setFormData('description', value)}
                        label={<IntlMessages id="description" />}
                        required
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default Form;