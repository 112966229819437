import React from "react";
import TableComponent from "../../../../Common/Tables/Table";
import Filters from "./Filters";
import {connect} from "react-redux";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import {dialogs} from "../constants";
import TableHeader from "./TableHeaders";
import {
  fetchAllVehicleMaintenancePlans,
  openVehicleMaintenancePlansDialog,
  setCurrentVehicleMaintenancePlan
} from "../../../../../redux/actions/FleetManagement/VehicleMaintenancePlans";
import FilterBuilder from "../../../../../utils/filters";
import EditTableRow from "../../../../Common/Tables/CrudTable/EditTableRow";

const Table = ({dispatch, data, filters}) => {
  const onEdit = vehicle => {
    dispatch(setCurrentVehicleMaintenancePlan(vehicle));
    dispatch(openVehicleMaintenancePlansDialog(dialogs.EDIT_DIALOG));
  };
  // const onDelete = (vehicle) => {
  //     dispatch(setCurrentVehicleMaintenancePlan(vehicle));
  //     dispatch(openVehicleMaintenancePlansDialog(dialogs.DELETE_DIALOG));
  // }
  // const onRestore = (vehicle) => {
  //     dispatch(setCurrentVehicleMaintenancePlan(vehicle));
  //     dispatch(openVehicleMaintenancePlansDialog(dialogs.RESTORE_DIALOG));
  // }
  const RenderRow = ({row}) => (
    <EditTableRow onEdit={() => onEdit(row)}>
      <StyledTableCell>{row.vehicle}</StyledTableCell>
      <StyledTableCell>{row.plansString}</StyledTableCell>
    </EditTableRow>
  );
  //     row.state ? (
  //     <CrudTableRow onEdit={() => onEdit(row)} onDelete={() => onDelete(row)}>
  //         <StyledTableCell>{row.vehicle}</StyledTableCell>
  //         <StyledTableCell>{row.plansString}</StyledTableCell>
  //     </CrudTableRow>
  // ) : (
  //     <RestoreTableRow onRestore={() => onRestore(row)}>
  //         <StyledTableCell>{row.vehicle}</StyledTableCell>
  //         <StyledTableCell>{row.plansString}</StyledTableCell>
  //     </RestoreTableRow>
  // );
  React.useEffect(() => {
    dispatch(fetchAllVehicleMaintenancePlans(filters));
  }, []);
  return (
    <React.Fragment>
      <Filters />
      <TableComponent
        data={Object.values(data)}
        head={<TableHeader />}
        renderRow={RenderRow}
        getRowKey={row => row.id}
      />
    </React.Fragment>
  );
};

Table.propTypes = {};

const mapStateToProps = ({fleetManagement}) => {
  const {data, filters} = fleetManagement.vehicleMaintenancePlans;
  const filteredData = new FilterBuilder(Object.values(data))
    .byFieldIncludes("vehicle", filters.vehicle)
    .byFieldIncludes("state", filters.status)
    .build();
  return {data: filteredData, filters};
};

export default connect(mapStateToProps)(Table);
