import React from "react";
import StyledTableCell from "../../../Common/Tables/StyledTableCell";
import IconButton from "@material-ui/core/IconButton";
import {useDispatch} from "react-redux";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {setCurrentClients} from "../../../../redux/actions/Administrator/Clients";
import VisibilityIcon from "@material-ui/icons/Visibility";
import useUiState from "../../../../hooks/Administrator/useUiState";

const TableBody = ({client}) => {
  const dispatch = useDispatch();
  const ui = useUiState();

  const onShowDetail = () => {
    dispatch(setCurrentClients(client));
  };

  const onEdit = () => {
    dispatch(ui.tableProps.onEdit(client.id));
  };
  const onDelete = () => {
    // dispatch();
  };
  return (
    <React.Fragment>
      <StyledTableCell>{client.clientCode}</StyledTableCell>
      <StyledTableCell>{client.name}</StyledTableCell>
      <StyledTableCell>{client.businessName}</StyledTableCell>
      <StyledTableCell>{client.country}</StyledTableCell>
      <StyledTableCell>{client.code}</StyledTableCell>
      <StyledTableCell>{client.email}</StyledTableCell>
      <StyledTableCell>
        <IconButton onClick={onShowDetail}>
          <VisibilityIcon />
        </IconButton>
        <IconButton onClick={onEdit}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={onDelete}>
          <DeleteIcon />
        </IconButton>
      </StyledTableCell>
    </React.Fragment>
  );
};

export default TableBody;
