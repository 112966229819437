import {useDispatch} from "react-redux";
import {fetchError, fetchStart, fetchSuccess} from "../../redux/actions";
import {downloadFile, getFileByName} from "../../services/FileService";

export const useDownloadFile = () => {
  const dispatch = useDispatch();

  const downloadFileByName = fileName => {
    dispatch(fetchStart());
    getFileByName(fileName)
      .then(({base64File, contentType}) => {
        downloadFile(fileName, `data:${contentType};base64,${base64File}`);
        dispatch(fetchSuccess());
      })
      .catch(err => {
        dispatch(fetchError(err.message));
      });
  };

  return {downloadFileByName};
};
