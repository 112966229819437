import React from "react";
import Select from "../../../../../Common/Forms/Select";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import MenuItem from "@material-ui/core/MenuItem";

const ExpirationSelect = ({value, onChange}) => {
  const options = [
    {
      id: "",
      name: (
        <IntlMessages
          id={
            "fleet.management.vehicles.documentation.summary.mode.filters.expiration.all"
          }
        />
      )
    },
    {
      id: 1,
      name: (
        <IntlMessages
          id={
            "fleet.management.vehicles.documentation.summary.mode.filters.expiration.no.documentation"
          }
        />
      )
    },
    {
      id: 2,
      name: (
        <IntlMessages
          id={
            "fleet.management.vehicles.documentation.summary.mode.filters.expiration.correct"
          }
        />
      )
    },
    {
      id: 3,
      name: (
        <IntlMessages
          id={
            "fleet.management.vehicles.documentation.summary.mode.filters.expiration.notice"
          }
        />
      )
    },
    {
      id: 4,
      name: (
        <IntlMessages
          id={
            "fleet.management.vehicles.documentation.summary.mode.filters.expiration.expired"
          }
        />
      )
    }
  ];
  return (
    <Select
      id="vehicle-documents-expiration-select"
      label={
        <IntlMessages
          id={
            "fleet.management.vehicles.documentation.summary.mode.filters.expiration"
          }
        />
      }
      value={value}
      onChange={e => onChange(e.target.value)}
      fullWidth
    >
      {options.map(option => (
        <MenuItem value={option.id} key={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ExpirationSelect;
