import React, {useEffect} from "react";
import {Paper, Table, TableBody, TableContainer} from "@material-ui/core";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";
import {fetchAllHeadquartersByClientIdStarted} from "../../../../../redux/actions/Administrator/Clients";
import {useDispatch, useSelector} from "react-redux";

const HeadquartersTable = ({client, onOpenEditDialog}) => {
  const {headquarters} = useSelector(
    state => state.administrator.clients.current
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchAllHeadquartersByClientIdStarted(client.id));
  }, []);
  const onEdit = headquarter => {
    onOpenEditDialog(headquarter);
  };
  const onDelete = headquarter => {
    console.log("delete");
  };
  return (
    <TableContainer
      component={Paper}
      style={{
        width: "90%",
        margin: "36px 40px"
      }}
    >
      <Table>
        <TableHeader />
        <TableBody>
          {headquarters?.map(headquarter => (
            <TableRow
              key={headquarter.id}
              headquarter={headquarter}
              onEdit={onEdit}
              onDelete={onDelete}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default HeadquartersTable;
