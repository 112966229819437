import React from "react";
import GridContainer from "../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import ButtonsArea from "./ButtonsArea";
import ViewSelect from "./ViewSelect";
import useForm from "../../../../../hooks/Common/useForm";
import ExpirationSelect from "./ExpirationSelect";
import DocumentsTable from "./DocumentsTable";
import {useDispatch} from "react-redux";
import {
  applyVehicleDocumentsSummaryFilters,
  fetchAllSummaryVehicleDocumentsView
} from "../../../../../redux/actions/FleetManagement/Documents";
import OutlinedInput from "../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import TagsPicker from "../../File/VehicleDetailDialog/BasicFile/Form/TagsPicker";

const initialData = {
  view: "",
  expiration: "",
  vehicle: "",
  tags: []
};
const SummaryMode = () => {
  const {formData, setFormData} = useForm(initialData);
  const dispatch = useDispatch();
  React.useEffect(
    () => {
      dispatch(applyVehicleDocumentsSummaryFilters(formData));
    },
    [ formData ]
  );
  React.useEffect(() => {
    dispatch(fetchAllSummaryVehicleDocumentsView());
  }, []);
  return (
    <GridContainer spacing={3}>
      <Grid item xs={12} sm={8}>
        <ViewSelect
          value={formData.view}
          onChange={value => setFormData("view", value)}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <ButtonsArea view={formData.view} />
      </Grid>
      <Grid item xs={12}>
        <ExpirationSelect
          value={formData.expiration}
          onChange={value => setFormData("expiration", value)}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <OutlinedInput
          label={
            <IntlMessages
              id={
                "fleet.management.vehicles.documentation.list.mode.table.vehicle"
              }
            />
          }
          value={formData.vehicle}
          onChange={value => setFormData("vehicle", value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TagsPicker
          value={formData.tags}
          onChange={values => setFormData("tags", values)}
        />
      </Grid>
      <Grid item xs={12}>
        <DocumentsTable />
      </Grid>
    </GridContainer>
  );
};

export default SummaryMode;
