import React from "react";
import GridContainer from "../../../../../../@jumbo/components/GridContainer";
import {Grid} from "@material-ui/core";
import OutlinedInput from "../../../../../Common/Forms/OutlinedInput";
import IntlMessages from "../../../../../../@jumbo/utils/IntlMessages";
import TagsPicker from "../../../../Vehicles/File/VehicleDetailDialog/BasicFile/Form/TagsPicker";
import useForm from "../../../../../../hooks/Common/useForm";
import {useDispatch} from "react-redux";
import ExpirationSelect from "./ExpirationSelect";
import {applyVehicleMaintenancesFilters} from "../../../../../../redux/actions/FleetManagement/VehicleMaintenances";

const initialData = {
  expiration: "",
  vehicle: "",
  tags: []
};

const Filters = () => {
  const {formData, setFormData} = useForm(initialData);
  const dispatch = useDispatch();
  React.useEffect(
    () => {
      dispatch(applyVehicleMaintenancesFilters(formData));
    },
    [ formData ]
  );
  return (
    <GridContainer
      style={{
        marginBottom: 4
      }}
      spacing={3}
    >
      <Grid item xs={12}>
        <ExpirationSelect
          value={formData.expiration}
          onChange={value => setFormData("expiration", value)}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <OutlinedInput
          label={
            <IntlMessages
              id={
                "fleet.management.vehicles.documentation.list.mode.table.vehicle"
              }
            />
          }
          value={formData.vehicle}
          onChange={value => setFormData("vehicle", value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TagsPicker
          value={formData.tags}
          onChange={values => setFormData("tags", values)}
        />
      </Grid>
    </GridContainer>
  );
};

Filters.propTypes = {};

export default Filters;
